export function initRollbar(user) {
  window.Rollbar.configure({
    payload: {
      person: {
        guid: user.guid,
        customerId: user.auth_customer_id,
        email: user.email,
      },
      uiVersion: window.classroomUIVersion,
    },
    checkIgnore: shouldNotSend,
  });
}

export function shutdownRollbar() {
  window.Rollbar.configure({
    payload: {
      person: {},
    },
  });
}

function shouldNotSend(isUncaught, args, _payload) {
  const ratio = getReportRatio(isUncaught, args);
  const rand = Math.random();

  return (rand >= ratio);
}

function getReportRatio(isUncaught, args) {
  const data = typeof args[1] === 'object' ? args[1] : {};

  if (!isUncaught) {
    // reportRatio has been specified in the rollbar data
    if (typeof data.reportRatio === 'number') {
      return data.reportRatio;
    }
  } else {
    // TODO remove this check once all ably errors are being caught
    const isAblyPresence = data.code === 90001;

    if (isAblyPresence) {
      return 0.01;
    }
  }

  return 1;
}
