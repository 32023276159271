import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { DndProvider } from 'react-dnd';
import Backend from 'react-dnd-html5-backend';

import { setCurrentGroup } from '../../redux/groups/actions';
import { checkInClass } from '../../redux/users/actions';
import { closeFlyout } from '../../redux/ui/actions';
import { getCurrentGroup, getCurrentGroupIsLoaded } from '../../utilities/groups';


import GroupShowPage from './GroupShowPage';
import GroupSettingsPage from './GroupSettingsPage';
import PermissionDenied from './PermissionDenied';


const GroupPageContainer = ({
  match,
  currentGroup,
  currentGroupIsLoaded,
  setCurrentGroup,
  closeFlyout,
  inSession,
  checkInClass,
  hasPendingCheckOutUpdate,
}) => {
  useEffect(() => {
    closeFlyout();
    setCurrentGroup(match.params.groupGuid);
  }, [match.params.groupGuid, closeFlyout, setCurrentGroup]);

  useEffect(() => {
    const isInSession = inSession.includes(currentGroup.guid);
    if ((isInSession && currentGroupIsLoaded) || hasPendingCheckOutUpdate) {
      checkInClass(currentGroup);
    }
  }, [hasPendingCheckOutUpdate, inSession, currentGroup, currentGroupIsLoaded, checkInClass]);

  if (!currentGroup.guid) {
    return <PermissionDenied />;
  }

  return (
    <DndProvider backend={Backend}>
      <Switch>
        <Route exact path='/groups/:groupGuid' component={GroupShowPage} />
        <Route exact path='/groups/:groupGuid/settings' component={GroupSettingsPage} />
      </Switch>
    </DndProvider>
  );
};

GroupPageContainer.propTypes = {
  checkInClass: PropTypes.func.isRequired,
  currentGroup: PropTypes.object.isRequired,
  currentGroupIsLoaded: PropTypes.bool.isRequired,
  closeFlyout: PropTypes.func.isRequired,
  hasPendingCheckOutUpdate: PropTypes.bool.isRequired,
  inSession: PropTypes.array.isRequired,
  match: PropTypes.object.isRequired,
  setCurrentGroup: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  currentGroup: getCurrentGroup(state.groups),
  currentGroupIsLoaded: getCurrentGroupIsLoaded(state.groups),
  hasPendingCheckOutUpdate: state.users.hasPendingCheckOutUpdate,
  inSession: state.classes.inSession,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  setCurrentGroup,
  closeFlyout,
  checkInClass,
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GroupPageContainer));
