import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import * as microsoftTeams from '@microsoft/teams-js';

import I18n from '../../utilities/i18n';
import { parseQueryParams } from '../../utilities/urls';

import { ReactComponent as StudentSitting } from '../../images/studentSitting.svg';


const TeamsConfig = ({
  location,
}) => {
  const queryParams = parseQueryParams(location);
  const isInTeams = typeof queryParams.teamsId === 'string';

  useEffect(() => {
    if (isInTeams) {
      microsoftTeams.initialize();
      microsoftTeams.settings.registerOnSaveHandler((saveEvent) => {
        microsoftTeams.settings.setSettings({
          websiteUrl: process.env.REACT_APP_CLASSROOM_URL,
          contentUrl: process.env.REACT_APP_CLASSROOM_URL,
          entityId: I18n.t('classroom'),
          suggestedDisplayName: I18n.t('classroom'),
        });
        saveEvent.notifySuccess();
      });
      microsoftTeams.settings.setValidityState(true);
    }
  }, [isInTeams]);

  if (!isInTeams) {
    return <Redirect to='/' />;
  }

  return (
    <div className='co-teamsConfig-container' data-testid='teamsConfig-content'>
      <main className='co-teamsConfig'>
        <section className='co-teamsConfig-text'>
          <div>
            <div className='co-teamsConfig-title'>
              {I18n.t('welcome_to_classroom')}
            </div>
            <div className='co-teamsConfig-subtitle'>
              {I18n.t('for_microsoft_teams')}
            </div>
          </div>
        </section>
        <section className='co-teamsConfig-image'>
          <StudentSitting className='co-teamsConfig-foreground' />
        </section>
      </main>
    </div>
  );
};

TeamsConfig.propTypes = {
  location: PropTypes.object.isRequired,
};

export default TeamsConfig;
