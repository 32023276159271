import { removeProperty } from '../../utilities/types';

import Webrtc from '../../helpers/webrtc';

import {
  ADD_WEBRTC,
  REMOVE_WEBRTC,
  ADD_SECOND_VIEWER,
  REMOVE_SECOND_VIEWER,
  ADD_STREAM,
  REMOVE_STREAM,
  ADD_HANDLER,
  REMOVE_HANDLER,
  ADD_WEBRTC_ERROR,
} from './actions';

export const defaultState = {
  all: {},
  secondViewers: {},
  streams: {},
  handler: null,
};

export function webrtcsReducer(state = defaultState, action) {
  switch (action.type) {
    case ADD_WEBRTC_ERROR: {
      const webrtc = state.all[action.name];
      webrtc.error = action.error;
      return {
        ...state,
        all: {
          ...state.all,
          [action.name]: webrtc,
        },
      };
    }
    case ADD_WEBRTC: {
      const webrtc = new Webrtc(
        action.sessionId,
        action.channel,
        action.iceServers,
        action.streamCallback,
        action.streamId,
      );
      return {
        ...state,
        all: {
          ...state.all,
          [action.name]: webrtc,
        },
      };
    }
    case REMOVE_WEBRTC: {
      const webrtc = state.all[action.name];
      if (webrtc) {
        webrtc.shutdown();
      }
      const all = removeProperty(state.all, action.name);
      const streams = removeProperty(state.streams, action.name);
      const secondViewers = removeProperty(state.secondViewers, action.name);
      return {
        ...state,
        all,
        streams,
        secondViewers,
      };
    }
    case REMOVE_HANDLER:
      return {
        ...state,
        handler: null,
      };
    case ADD_SECOND_VIEWER:
      return {
        ...state,
        secondViewers: {
          ...state.secondViewers,
          [action.name]: true,
        },
      };
    case REMOVE_SECOND_VIEWER: {
      const secondViewers = removeProperty(state.secondViewers, action.name);
      return {
        ...state,
        secondViewers,
      };
    }
    case ADD_STREAM:
      return {
        ...state,
        streams: {
          ...state.streams,
          [action.name]: action.stream,
        },
      };
    case ADD_HANDLER:
      return {
        ...state,
        handler: action.handler,
      };
    case REMOVE_STREAM: {
      const prunedStreams = { ...state.streams };
      delete prunedStreams[action.name];
      return {
        ...state,
        streams: {
          ...prunedStreams,
        },
      };
    }
    default:
      return state;
  }
}
