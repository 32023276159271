import React, { Fragment, useReducer, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { sendLink } from '../../redux/ably/actions';
import { openModal, closeModal } from '../../redux/ui/actions';

import { canLaunchVideoChat, videoChatApps } from '../../utilities/policy';
import I18n from '../../utilities/i18n';

import { useLocalStorage } from '../../helpers/hooks';

import Icon from '../common/Icon';
import Modal from '../common/Modal';
import TextInput from '../common/TextInput';


function getDefaultSettings() {
  return {
    app: 'zoom',
    zoomUrl: '',
  };
}

function getLaunchAnalytics(app) {
  switch (app) {
    default:
      return 'analytics-startZoomCall';
  }
}

function getLaunchText(app) {
  switch (app) {
    default:
      return 'launch_zoom_video';
  }
}

function validateLaunch(settings) {
  switch (settings.app) {
    default:
      return settings.zoomUrl !== '' && validateZoomUrl(settings.zoomUrl);
  }
}

function validateZoomUrl(url) {
  if (url === '') {
    return true;
  }
  if (typeof url !== 'string') {
    return false;
  }
  return url.startsWith('https://') && url.includes('zoom');
}

function getUrl(settings) {
  switch (settings.app) {
    default:
      return settings.zoomUrl;
  }
}

function currentReducer(state, action) {
  switch (action.type) {
    case 'reset':
      return { ...action.state };
    case 'update':
      return { ...state, [action.key]: action.value };
    default:
      throw new Error(`unknown action ${action.type}`);
  }
}

const VideoChat = ({
  inSession,
  groupPolicy,
  currentlyOpenModal,
  closeModal,
  openModal,
  sendLink,
}) => {
  const modalName = 'videoChatSettings';
  const apps = videoChatApps(groupPolicy);
  const [settings, setSettings] = useLocalStorage('ClassroomVideoChatSettings', getDefaultSettings());
  const [current, dispatchCurrent] = useReducer(currentReducer, { ...settings });
  const [zoomUrlIsValid, setZoomUrlIsValid] = useState(true);
  const currentIsValid = validateLaunch(current);
  const launchIsValid = inSession && validateLaunch(settings);

  const checkZoomUrl = () => {
    setZoomUrlIsValid(validateZoomUrl(current.zoomUrl));
  };

  const setCurrentSetting = (key, value) => {
    if (key === 'zoomUrl' && validateZoomUrl(value)) {
      setZoomUrlIsValid(true);
    }
    dispatchCurrent({ type: 'update', key, value });
  };

  const applySettings = () => {
    closeModal();
    setSettings({ ...current });
  };

  const cancelSettings = () => {
    closeModal();
    dispatchCurrent({ type: 'reset', state: { ...settings } });
    setZoomUrlIsValid(true);
  };

  const launchChat = () => {
    const url = getUrl(settings);

    window.open(url);
    sendLink(url);
  };

  const buildLauncher = () => {
    const launchClasses = classNames(getLaunchAnalytics(settings.app), {
      'button-link': true,
      'co-videoChat-launchButton': true,
      'co-videoChat-launchButton--disabled': !launchIsValid,
    });
    const settingsClasses = classNames({
      'button-link': true,
      'co-videoChat-settingsButton': true,
    });

    return (
      <div className='co-videoChat-launcher'>
        <button
          data-testid='videoChat-launch'
          className={launchClasses}
          disabled={!launchIsValid}
          onClick={launchChat}
        >
          {I18n.t(getLaunchText(settings.app))}
        </button>
        <button
          data-testid='videoChat-settings'
          className={settingsClasses}
          onClick={() => openModal(modalName)}
        >
          <Icon name='icon-settings' />
        </button>
      </div>
    );
  };

  const buildZoomUrl = (disabled) => {
    const inputClasses = classNames({
      'co-videoChat-zoomUrl': true,
      'co-videoChat-zoomUrl--error': !zoomUrlIsValid,
    });
    const url = typeof current.zoomUrl === 'string' ? current.zoomUrl : '';

    return (
      <div className='co-videoChat-setting'>
        <TextInput
          testId='videoChat-zoomUrl'
          disabled={disabled}
          classes={inputClasses}
          placeholder={I18n.t('enter_your_zoom_url')}
          value={url}
          onChange={(value) => setCurrentSetting('zoomUrl', value)}
          onSubmit={() => checkZoomUrl()}
          onLeave={() => checkZoomUrl()}
        />
        {!zoomUrlIsValid &&
          <div className='co-videoChat-zoomUrlErrorBox'>
            <div data-testid='videoChat-zoomUrl-error' className='co-videoChat-zoomUrlError'>
              {I18n.t('please_enter_a_full_url')}
            </div>
          </div>
        }
      </div>
    );
  };

  const buildAppSettings = (app, disabled) => {
    switch (app) {
      default:
        return (
          <Fragment>
            <div className='co-videoChat-setting text--gray'>
              {I18n.t('before_pasting_in_your_meeting_url')}
            </div>
            <div className='co-videoChat-zoomSettings'>
              <div className='co-videoChat-label'>{I18n.t('meeting_url')}</div>
              {buildZoomUrl(disabled)}
            </div>
          </Fragment>
        );
    }
  };

  const buildSettingsModal = () => {
    const applyButton = (
      <button
        data-testid='videoChat-apply'
        className='button button--blue'
        disabled={!currentIsValid}
        onClick={applySettings}>
        {I18n.t('apply')}
      </button>
    );

    return (
      <Modal
        show={currentlyOpenModal === modalName}
        title={I18n.t('video_meeting_preferences')}
        customSaveButton={applyButton}
        closeModal={cancelSettings}
        legacyModalVersion
        isSmall
        modalRedesign2022Q1
      >
        <section data-testid='videoChat-modal'>
          {apps.map((app) => {
            const isSelected = app === current.app;

            return (
              <Fragment key={app}>
                <input
                  data-testid={`videoChat-radio-${app}`}
                  type='radio'
                  id={app}
                  name='app'
                  className='co-videoChat-radio'
                  checked={isSelected}
                  onChange={() => setCurrentSetting('app', app)}
                />
                <label htmlFor={app} className='co-videoChat-radioLabel'>
                  {I18n.t(app)}
                </label>
                <div className='co-videoChat-settings'>
                  {buildAppSettings(app, !isSelected)}
                </div>
              </Fragment>
            );
          })}
        </section>
      </Modal>
    );
  };

  const canLaunch = canLaunchVideoChat(groupPolicy);
  const hasApps = apps.length > 0;

  if (!canLaunch || !hasApps) {
    // Spacer for top of Insights if video launcher is hidden
    return (
      <div data-testid='videoChat-spacer' className='co-videoChat-spacer'></div>
    );
  }

  return (
    <Fragment>
      <section className='co-videoChat-container'>
        {buildLauncher()}
      </section>
      {buildSettingsModal()}
    </Fragment>
  );
};

VideoChat.propTypes = {
  inSession: PropTypes.bool.isRequired,
  groupPolicy: PropTypes.object.isRequired,
  currentlyOpenModal: PropTypes.string,
  closeModal: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  sendLink: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  groupPolicy: state.policy.group,
  currentlyOpenModal: state.ui.currentlyOpenModal,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  closeModal,
  openModal,
  sendLink,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(VideoChat);
