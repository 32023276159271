import cn from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useDrag } from 'react-dnd';
import { connect } from 'react-redux';

import I18n from '../../../utilities/i18n';
import { getCurrentGroup } from '../../../utilities/groups';
import {
  getNameWithPossession,
  getFirstInitialLastName,
  getAttendanceStatus,
} from '../../../utilities/users';

import Icon from '../../common/Icon';
import AttendanceStatusIndicator from '../../common/AttendanceStatusIndicator';
import TaskStatusIcon from '../../common/TaskStatusIcon';
import Tooltip from '../../common/Tooltip/Tooltip';
import UpdateStatus from '../../common/UpdateStatus';

const UsersHeatMapItem = ({
  currentSegments,
  currentGroup,
  userInfo,
  hasInsight,
  viewingAllScreens,
  userBeingViewed,
  stream,
  openFlyout,
  toggleViewUserScreen,
  dragType,
  isMoving,
  classes,
}) => {
  const {
    guid,
    email,
    firstName,
    initials,
    online,
    state,
  } = userInfo;

  const isInSession = classes.inSession.includes(currentGroup.guid);
  const videoElement = useRef(null);
  const status = getAttendanceStatus(userInfo, currentGroup);
  const [isHover, setIsHover] = useState(false);
  const [isOnline, setIsOnline] = useState(false);
  const [{ isDragging }, drag, preview] = useDrag({
    item: { type: dragType, guid: userInfo.guid },
    collect: (monitor) => ({
      isDragging: Boolean(monitor.isDragging()),
    }),
  });
  const showContent = !isDragging && !isMoving;

  useEffect(() => {
    return () => toggleViewUserScreen(email, false);
  }, [email, toggleViewUserScreen]);

  useEffect(() => {
    if (online !== isOnline) {
      setIsOnline(online);
      if (online && viewingAllScreens && !userBeingViewed) {
        toggleViewUserScreen(email, true);
      }
      if (!online && userBeingViewed) {
        toggleViewUserScreen(email, false);
      }
    }
  }, [email, online, isOnline, viewingAllScreens, userBeingViewed, toggleViewUserScreen]);

  useEffect(() => {
    if (userBeingViewed && stream) {
      activateViewScreen(stream);
    }
  }, [userBeingViewed, stream]);

  const toggleViewUserScreenHandler = (e) => {
    e.stopPropagation();
    toggleViewUserScreen(email, !userBeingViewed);
  };

  const activateViewScreen = (src) => {
    if (videoElement.current) {
      videoElement.current.srcObject = src;
    }
  };

  const buildProfileAvatar = () => {
    return (
      <div data-testid='statusIcon-container' className={cn([
        'co-heatMap-item-profile',
        { 
          'co-heatMap-item-profile--offline': !online,
          'co-heatMap-item-profile--hasInsight': online && hasInsight,
        },
      ])}>
        {initials}
        {online && state && state !== 'working' && (
          <TaskStatusIcon className='co-heatMap-item-profile-statusIcon' status={state} />
        )}
      </div>
    );
  };

  const buildScreenView = () => {
    const videoContainerClasses = cn({
      'co-usersHeatMap-screenVideoContainer': true,
      'co-usersHeatMap-screenVideoContainer--empty': !userBeingViewed,
    });

    return (
      <div className={videoContainerClasses}>
        <video
          className='co-usersHeatMap-screenVideo'
          ref={videoElement}
          autoPlay
          muted
          data-testid='UsersHeatMapItem-video'
        />
      </div>
    );
  };

  const DragHandle = () => {
    return (
      <button
        className='co-heatMap-item-dragHandle'
        aria-label={I18n.t('drag_segment_member')}
        data-testid='segmentMember-drag-button'
        ref={drag}
      >
        <Icon name='icon-move-handle' />
      </button>
    );
  };

  const ViewScreenButton = () => {
    return (
      <button
        className='co-heatMap-item-screenButton'
        onClick={toggleViewUserScreenHandler}
        data-testid='UsersHeatMapItem-screen-button'
      >
        <Icon name='icon-screen' />
      </button>
    );
  };

  const content = userBeingViewed ? buildScreenView() : buildProfileAvatar();

  const getTooltipMessage = () => {
    if (status !== 'not_checked_in') {
      return null;
    }

    const teacher = getNameWithPossession(getFirstInitialLastName(userInfo.checkedInto.teacher_name));
    if (userInfo.checkedInto.name && userInfo.checkedInto.teacher_name) {
      return I18n.t('currently_in_teacher_class', {
        class: userInfo.checkedInto.name, teacher,
      });
    }
    return I18n.t('currently_in_another_teachers_class');
  };

  const profileNameClasses = cn([
    'co-heatMap-item-profileName',
    'truncate',
    {
      'co-heatMap-item-profileName--disabled': !isInSession || !userInfo.online,
    },
  ]);

  const heatMapUser = (
    <li
      key={email}
      ref={preview}
      className='co-heatMap-item'
      data-testid='UsersHeatMapItem-user-button'
      onClick={() => openFlyout(guid, true)}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      {showContent && (
        <>
          {content}
          <div className={profileNameClasses}>
            {firstName}
          </div>
          <AttendanceStatusIndicator 
            status={status} 
            className='co-indicator-border'
          />
          {currentSegments.length > 0 && isHover && <DragHandle />}
          {online && isHover && (status !== 'not_checked_in') && <ViewScreenButton />}
        </>
      )}
    </li>
  );
  
  return (
    <UpdateStatus isActive={isMoving}>
      {status === 'not_checked_in' ? (
        <Tooltip
          content={getTooltipMessage()}
          aria-label={getTooltipMessage()} 
          position='top'
          size='notCheckedIn'
          customClassOne='heatMapNotCheckedIn'
        >
          {heatMapUser}
        </Tooltip>
      ) : (
        <>
          {heatMapUser}
        </>
      )}
    </UpdateStatus>
  );
};

UsersHeatMapItem.propTypes = {
  currentSegments: PropTypes.array.isRequired,
  currentGroup: PropTypes.object.isRequired,
  userInfo: PropTypes.object.isRequired,
  hasInsight: PropTypes.bool.isRequired,
  viewingAllScreens: PropTypes.bool.isRequired,
  userBeingViewed: PropTypes.bool.isRequired,
  stream: PropTypes.object,
  openFlyout: PropTypes.func.isRequired,
  toggleViewUserScreen: PropTypes.func.isRequired,
  dragType: PropTypes.string.isRequired,
  isMoving: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  currentSegments: state.groupSegments.segments,
  currentGroup: getCurrentGroup(state.groups),
  classes: state.classes,
});

export default connect(mapStateToProps, null)(UsersHeatMapItem);
