import 'webrtc-adapter';

export default class Webrtc {
  constructor(sessionId, channel, iceServers, streamCallback, streamId) {
    this.channel = channel;
    this.connection = new RTCPeerConnection({ iceServers });

    init(sessionId, this.channel, this.connection, streamCallback, streamId);

    this.channel.publish('request_rtc', { sessionId, customIce: iceServers });
  }

  shutdown() {
    this.channel.unsubscribe('offer_rtc');
    this.channel.unsubscribe('offer_rtc_ice');
    this.connection.close();
  }

  getStatus() {
    return {
      connectionState: this.connection.connectionState,
      signalingState: this.connection.signalingState,
      iceConnectionState: this.connection.iceConnectionState,
    };
  }
}

function findStream(streams, streamId) {
  return streams.find((stream) => {
    return stream.id === streamId;
  });
}

function init(sessionId, channel, connection, callback, streamId) {
  const queuedIce = [];

  connection.onicecandidate = (e) => {
    channel.publish('answer_rtc_ice', { sessionId, ice: e.candidate });
  };

  connection.ontrack = (e) => {
    const stream = streamId ? findStream(e.streams, streamId) : e.streams[0];
    callback(stream);
  };

  channel.subscribe('offer_rtc', async (msg) => {
    try {
      const state = connection.connectionState;

      if (msg.data.sessionId === sessionId && state !== 'closed') {
        await connection.setRemoteDescription(msg.data.offer);
        await addQueuedIce(sessionId, connection, queuedIce);
        const answer = await connection.createAnswer();
        await connection.setLocalDescription(answer);

        channel.publish('answer_rtc', { sessionId, answer });
      }
    } catch (error) {
      const data = { offer: msg.data.offer };
      sendRollbar(sessionId, connection, 'offer_rtc', error, data);
    }
  });

  channel.subscribe('offer_rtc_ice', async (msg) => {
    try {
      const state = connection.connectionState;

      if (msg.data.sessionId === sessionId && state !== 'closed') {
        if (connection.remoteDescription) {
          await connection.addIceCandidate(msg.data.ice);
        } else {
          queuedIce.push(msg.data.ice);
        }
      }
    } catch (error) {
      const data = { ice: msg.data.ice };
      sendRollbar(sessionId, connection, 'offer_rtc_ice', error, data);
    }
  });
}

async function addQueuedIce(sessionId, connection, queuedIce) {
  try {
    for (const ice of queuedIce) {
      await connection.addIceCandidate(ice);
    }
  } catch (error) {
    const data = { queuedIce };
    sendRollbar(sessionId, connection, 'queued_ice', error, data);
  }
}

function sendRollbar(sessionId, connection, message, error, data = {}) {
  window.Rollbar.error(`${message}: ${error.name}`, {
    reportRatio: 0.1,
    message: error.message,
    stack: error.stack,
    sessionId,
    connectionState: connection.connectionState,
    signalingState: connection.signalingState,
    iceConnectionState: connection.iceConnectionState,
    ...data,
  });
}
