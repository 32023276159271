import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import cn from 'classnames';
import { CSSTransition } from 'react-transition-group';

import { clearAllInsights, removeInsight } from '../../../redux/insights/actions';
import { openFlyout } from '../../../redux/ui/actions';

import I18n from '../../../utilities/i18n';

import { ReactComponent as ArrowDownIcon } from '../../../images/arrowDown.svg';
import { ReactComponent as DooWatchingIcon } from '../../../images/dooWatching.svg';
import { ReactComponent as QuestionMarkIcon } from '../../../images/questionMark.svg';

import InsightsItem from './InsightsItem';
import Tooltip from '../../common/Tooltip/Tooltip';

const Insights = ({
  activeInsights,
  userInfos,
  openFlyout,
  removeInsight,
  clearAllInsights,
}) => {
  const onlineInfos = userInfos.filter((info) => info.online);
  const onlineGuids = onlineInfos.map((info) => info.guid);
  const currentInsights = activeInsights.filter((insight) => onlineGuids.includes(insight.guid));
  const wasCollapsed = localStorage.getItem('insights');
  const hasInsights = currentInsights.length > 0;

  const [isExpanded, setExpanded] = useState(wasCollapsed ? wasCollapsed === 'true' : true);
  const [screenWidth, setScreenWidth] = useState(0);
  
  useEffect(() => {
    const updateInnerWidth = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener('resize', updateInnerWidth);
    updateInnerWidth();
    return () => window.removeEventListener('resize', updateInnerWidth);
  }, []);

  useEffect(() => {
    localStorage.setItem('insights', isExpanded);
  }, [isExpanded]);


  const buildUserItems = () => {
    return currentInsights.map((insight) => (
      <InsightsItem
        key={insight.guid}
        userGuid={insight.guid}
        firstName={insight.firstName}
        lastName={insight.lastName}
        timestamp={insight.timestamp}
        openFlyout={openFlyout}
        removeInsight={removeInsight}
      />
    ));
  };

  const buildInsightsTooltip = () => {
    return (
      <Tooltip
        content={I18n.t('insights_are_displayed_based')}
        aria-label={I18n.t('insights_are_displayed_based')} 
        position={screenWidth < 769 ? 'bottom' : 'right'}
        size='large'
        className='l-flex'
      >
        <QuestionMarkIcon className='l-flex l-flex--vAlignCenter' />
      </Tooltip>
    );
  };

  return (
    <section className='co-insights-wrapper'>
      <div className='co-insights-header'>
        <div
          className='co-insights-labelgroup'
          onClick={() => setExpanded(!isExpanded)}
        >
          <ArrowDownIcon className={
            cn(['co-insights-label-arrow',
              { 'co-insights-label-arrow--collapsed': !isExpanded }])}
          />
          <div className='co-insights-labelgrid'>
            <div className='co-insights-label'>
              {I18n.t('insights')}
            </div>
            <div className='co-insights-counter'>
              {currentInsights.length}
            </div>
            {buildInsightsTooltip()}
          </div>
        </div>
        <div>
          <button
            className='co-insights-buttonlink'
            type='button'
            onClick={clearAllInsights}
          >
            {I18n.t('clear_all')}
          </button>
        </div>
      </div>
      <CSSTransition
        in={isExpanded}
        timeout={300}
        classNames='co-insights-listarea'
        unmountOnExit
      >
        <div className='co-insights-list-container'>
          {hasInsights
            ? <ul className='co-insights'>{buildUserItems()}</ul>
            : <div className='co-insights-empty-container' data-testid='insights-empty'>
              <DooWatchingIcon className='co-insights-empty-icon' />
              <p className='co-insights-empty-text'>{I18n.t('there_are_no_insights')}</p>
            </div>}
        </div>
      </CSSTransition>
    </section>
  );
};

Insights.propTypes = {
  activeInsights: PropTypes.arrayOf(PropTypes.object).isRequired,
  userInfos: PropTypes.arrayOf(PropTypes.object).isRequired,
  openFlyout: PropTypes.func.isRequired,
  removeInsight: PropTypes.func.isRequired,
  clearAllInsights: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
  openFlyout,
  removeInsight,
  clearAllInsights,
}, dispatch);

export default connect(null, mapDispatchToProps)(Insights);
