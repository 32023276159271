import differenceInMinutes from 'date-fns/difference_in_minutes';
import isAfter from 'date-fns/is_after';
import isBefore from 'date-fns/is_before';
import isWithinRange from 'date-fns/is_within_range';

import { parseScheduleDays } from './schedule';

export function getDayScheduleTimes(schedule, now = new Date()) {
  const day = now.getDay();
  const todaysSchedule = parseScheduleDays(schedule)[day];

  if (todaysSchedule && todaysSchedule.start && todaysSchedule.end) {
    const startParts = todaysSchedule.start.split(':');
    const endParts = todaysSchedule.end.split(':');

    const start = new Date(now.getTime());
    const end = new Date(now.getTime());

    start.setHours(startParts[0], startParts[1], 0, 0);
    end.setHours(endParts[0], endParts[1], 0, 0);

    return { start, end };
  }
  return null;
}

export function isValidScheduleTimes(scheduleTimes) {
  if (!scheduleTimes) {
    return false;
  }
  if (!(scheduleTimes.start instanceof Date) || !(scheduleTimes.end instanceof Date)) {
    return false;
  }
  if (scheduleTimes.end.getTime() <= scheduleTimes.start.getTime()) {
    return false;
  }

  return true;
}

export function isBeforeSession(scheduleTimes, now = new Date()) {
  if (!isValidScheduleTimes(scheduleTimes)) {
    return false;
  }
  return isBefore(now, scheduleTimes.start);
}

export function isInSession(scheduleTimes, now = new Date()) {
  if (!isValidScheduleTimes(scheduleTimes)) {
    return false;
  }
  return isWithinRange(now, scheduleTimes.start, scheduleTimes.end);
}

export function isAfterSession(scheduleTimes, now = new Date()) {
  if (!isValidScheduleTimes(scheduleTimes)) {
    return false;
  }
  return isAfter(now, scheduleTimes.end);
}

export function getEndAt(schedule, now = new Date()) {
  const scheduleTimes = getDayScheduleTimes(schedule, now);

  if (scheduleTimes?.end instanceof Date) {
    return scheduleTimes.end.getTime();
  }
}

export function getTimeRemainingString(schedule, now = new Date()) {
  const scheduleTimes = getDayScheduleTimes(schedule, now);
  if (!scheduleTimes) {
    return '';
  }

  const difference = differenceInMinutes(scheduleTimes.end, now);
  const remaining = difference >= 0 ? difference + 1 : 0;
  return `${remaining}m`;
}
