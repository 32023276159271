import { addError } from '../errors/actions';

import { ClassroomApi } from '../../helpers/classroomApi';

export const RECEIVE_GROUP_BETAS = 'RECEIVE_GROUP_BETAS';
export const RECEIVE_SCHOOL_BETAS = 'RECEIVE_SCHOOL_BETAS';
export const RECEIVE_DISTRICT_BETAS = 'RECEIVE_DISTRICT_BETAS';
export const CLEAR_POLICIES = 'CLEAR_POLICIES';

export function getBetas(guid, groupType) {
  return async function(dispatch) {
    const response = await ClassroomApi.get(`/classroom_betas?group_guid=${guid}`);

    if (response?.status === 200) {
      const betas = response.data?.betas ?? {};

      switch (groupType) {
        case 'group':
          // TODO: remove this after granular allowed hours has been in prod for awhile (~ 2022)
          dispatch({ type: RECEIVE_GROUP_BETAS, betas });
          break;
        // the current groups's school (not always the same as the current user's school)
        case 'school':
          dispatch({ type: RECEIVE_SCHOOL_BETAS, betas });
          break;
        case 'district':
          dispatch({ type: RECEIVE_DISTRICT_BETAS, betas });
          break;
        default:
          break;
      }
    }

    return dispatch(addError(`betas - ${groupType}`, response));
  };
}
