import React, { Fragment, useEffect } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import I18n from '../../utilities/i18n';

import { CloseIcon } from 'design-system';
import PopFadeInOut from './animations/PopFadeInOut';


const Modal = ({
  title,
  children,
  saveHandler,
  closeModal,
  closeText = I18n.t('cancel'),
  saveText = I18n.t('apply'),
  show,
  isSmall,
  preventClosing,
  customSaveButton,
  modalRedesign2022Q1,
  legacyModalVersion,
}) => {
  const modalRoot = document.getElementById('modalRoot');

  useEffect(() => {
    const keyDownEventHandler = (event) => {
      if (event.keyCode === 27 && !preventClosing) {
        closeModal();
      }
    };

    document.addEventListener('keydown', keyDownEventHandler);
    return () => document.removeEventListener('keydown', keyDownEventHandler);
  }, [closeModal, preventClosing]);

  let saveButton;

  if (customSaveButton) {
    saveButton = customSaveButton;
  } else if (saveHandler) {
    saveButton = (
      <button 
        className='co-modal-save button button--blue' 
        onClick={saveHandler} 
        data-testid='modal-save-button'>
        {saveText}
      </button>
    );
  }

  // TODO: update all modals to new celeritas design system after migration
  // legacyModalVersion is to support styles for modals that have not been specifically redesigned
  // modalRedesign2022Q1 is to support styles for the web rule lists partial modal redesign that occurred 2022 Q1
  const modalClasses = classNames({
    'co-modal--small': isSmall && !modalRedesign2022Q1,
    'co-modal': true,
    'co-modal-modalRedesign2022Q1-large': modalRedesign2022Q1 && !isSmall,
    'co-modal-modalRedesign2022Q1-small': modalRedesign2022Q1 && isSmall,
  });

  const headerClasses = classNames({
    'co-modal-header': true,
    'co-modal-header-modalRedesign2022Q1': modalRedesign2022Q1 && !legacyModalVersion,
  });

  const mainClasses = classNames({
    'co-modal-main': true,
    'co-modal-main-modalRedesign2022Q1': modalRedesign2022Q1 && !legacyModalVersion,
  });

  const footerClasses = classNames({
    'co-modal-footer': true,
    'co-modal-footer-modalRedesign2022Q1': modalRedesign2022Q1,
  });

  const cancelButtonClasses = classNames({
    button: true,
    'co-modal-cancel': true,
    'button--secondaryWhite': true,
    'co-modal-cancel-quietButton-modalRedesign2022Q1': modalRedesign2022Q1,
  });

  const backdropCloseAttrs = !preventClosing && closeModal && {
    role: 'button',
    tabIndex: '0',
    onClick: closeModal,
  };

  const modalContent = () => {
    return (
      <PopFadeInOut condition={show}>
        <Fragment>
          <section className={modalClasses} role='dialog'>
            <header className={headerClasses}>
              <h3>{title}</h3>
              {!preventClosing && (
                <button data-testid='modal-close-x' className='button-link co-modal-cancelButton' onClick={closeModal}>
                  <CloseIcon name='icon-cancel'/>
                </button>
              )}
            </header>

            <main className={mainClasses}>
              {children}
            </main>

            <footer className={footerClasses}>
              {!preventClosing && (
                <button
                  data-testid='modal-close-button'
                  className={cancelButtonClasses}
                  onClick={closeModal}
                >
                  {closeText}
                </button>
              )}
              {saveButton}
            </footer>
          </section>

          <div
            data-testid='modal-backdrop'
            className='co-modal-backdrop'
            {...backdropCloseAttrs}
          />
        </Fragment>
      </PopFadeInOut>
    );
  };

  return ReactDOM.createPortal(
    modalContent(),
    modalRoot,
  );
};

Modal.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  children: PropTypes.object,
  saveHandler: PropTypes.func,
  closeModal: PropTypes.func,
  show: PropTypes.bool.isRequired,
  isSmall: PropTypes.bool,
  preventClosing: PropTypes.bool,
  closeText: PropTypes.string,
  customSaveButton: PropTypes.object,
  legacyModalVersion: PropTypes.bool,
  modalRedesign2022Q1: PropTypes.bool,
};

export default Modal;
