import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { lockScreen, unlockScreen } from '../../../../redux/ably/actions';
import { closeModal } from '../../../../redux/ui/actions';
import I18n from '../../../../utilities/i18n';

import { getCurrentGroup, getDefaultSelection, getSelectedSegmentEmails } from '../../../../utilities/groups';

import Modal from '../../Modal';
import SegmentSelector from '../../SegmentSelector';


const ScreenLockModal = ({
  currentlyOpenModal,
  currentlyOpenModalSegment,
  closeModal,
  lockScreen,
  unlockScreen,
  currentSegments,
  allUsers,
}) => {
  const [selected, setSelected] = useState('lock');
  const [selectedSegments, setSelectedSegments] = useState(getDefaultSelection(currentSegments));

  useEffect(() => {
  }, [selected]);

  useEffect(() => {
    setSelectedSegments(getDefaultSelection(currentSegments, currentlyOpenModalSegment));
  }, [currentlyOpenModalSegment, currentSegments]);

  const saveHandler = () => {
    const emails = getSelectedSegmentEmails(allUsers, selectedSegments, currentSegments);
    if (selected === 'lock') {
      emails.forEach((email) => {
        lockScreen(email);
      });
    } else {
      emails.forEach((email) => {
        unlockScreen(email);
      });
    }
    closeModal();
  };

  const buildSegmentSelector = () => {
    if (currentlyOpenModalSegment) {
      // navigated from specific segment options
      return null;
    }

    return (
      <SegmentSelector
        currentSegments={currentSegments}
        selectedSegments={selectedSegments}
        setSelectedSegments={setSelectedSegments}
      />
    );
  };


  const modalSaveButton = (
    <button className='button button--orange' onClick={saveHandler} data-testid='screenLockModal-save-button'>
      {I18n.t('apply')}
    </button>
  );

  return (
    <Modal
      show={currentlyOpenModal === 'lockScreens'}
      title={I18n.t('screen_lock')}
      closeModal={closeModal}
      isSmall
      modalRedesign2022Q1
      legacyModalVersion
      customSaveButton={modalSaveButton}
    >
      <ul className='co-screenLockModal'>
        <li>
          <label className='cursorPointer l-flex'>
            <input
              type='radio'
              name='lockScreens'
              value='lock'
              checked={selected === 'lock'}
              onChange={() => setSelected('lock')}
              className='co-screenLockModal-input m-right--8'
              data-testid='screenLockModal-lock-radio'
            />
            <div>
              {I18n.t('lock')}
              <p className='co-screenLockModal-desc'>{I18n.t('lock_screens_lock_description')}</p>
            </div>
          </label>
        </li>

        <li>
          <label className='cursorPointer l-flex'>
            <input
              type='radio'
              name='lockScreens'
              value='unlock'
              checked={selected === 'unlock'}
              onChange={() => setSelected('unlock')}
              className='co-screenLockModal-input m-right--8'
              data-testid='screenLockModal-unlock-radio'
            />
            <div>
              {I18n.t('unlock')}
            </div>
          </label>
        </li>

        <li data-testid='segment-selector-container'>
          {buildSegmentSelector()}
        </li>
      </ul>
    </Modal>
  );
};

ScreenLockModal.propTypes = {
  currentlyOpenModal: PropTypes.string,
  currentlyOpenModalSegment: PropTypes.string,
  closeModal: PropTypes.func,
  lockScreen: PropTypes.func.isRequired,
  unlockScreen: PropTypes.func.isRequired,
  currentSegments: PropTypes.arrayOf(PropTypes.object).isRequired,
  allUsers: PropTypes.object.isRequired,
};


const mapStateToProps = (state) => ({
  currentGroup: getCurrentGroup(state.groups),
  currentlyOpenModal: state.ui.currentlyOpenModal,
  currentlyOpenModalSegment: state.ui.currentlyOpenModalSegment,
  currentSegments: state.groupSegments.segments,
  allUsers: state.users.all,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  closeModal,
  lockScreen,
  unlockScreen,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ScreenLockModal);
