export function setupFullStory(user) {
  const params = {
    displayName: user.guid,
    uiVersion: window.classroomUIVersion,
    role_str: user.role, // FullStory requires customer attributes to be in the format paramName_type
    customerId_str: user.customer_id,
  };
  window.FS && window.FS.identify(user.guid, params);
}

export function resetFullStory() {
  window.FS && window.FS.shutdown();
}
