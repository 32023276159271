import ReactDOMServer from 'react-dom/server';

import { updateBrowsingToggle } from '../webzones/actions';

export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';
export const OPEN_MODAL = 'OPEN_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';
export const OPEN_DROPDOWN = 'OPEN_DROPDOWN';
export const CLOSE_DROPDOWN = 'CLOSE_DROPDOWN';
export const OPEN_FLYOUT = 'OPEN_FLYOUT';
export const CLOSE_FLYOUT = 'CLOSE_FLYOUT';
export const RESET_UI = 'RESET_UI';
export const BROWSING_TIMEOUT = 'BROWSING_TIMEOUT';
export const OPEN_SUB_FLYOUT = 'OPEN_SUB_FLYOUT';
export const CLOSE_SUB_FLYOUT = 'CLOSE_SUB_FLYOUT';
export const GET_WEB_RULES_SECTION = 'GET_WEB_RULES_SECTION';
export const GET_CURRENT_SEGMENT_GUID = 'GET_CURRENT_SEGMENT_GUID';
export const EXPAND_NAVIGATION = 'EXPAND_NAVIGATION';
export const COLLAPSE_NAVIGATION = 'COLLAPSE_NAVIGATION';
export const BROWSING_TOGGLE_UPDATE = 'browsingToggleUpdate';

const ablyGroupChannelUiUpdateObj = {
  // The functions in this object, defined below, are used for updating additional teachers' UI.
  // For example, when teacher A toggles Browsing, that change needs to be shown in teacher B's UI.

  // The key names of this object, defined below, are being stored in the ABLY_GROUP_CHANNEL_UI_UPDATE_EVENTS
  // array. The connectChannel() function is subscribing a channel to event types using the key names stored
  // in the ABLY_GROUP_CHANNEL_UI_UPDATE_EVENTS array.
};

ablyGroupChannelUiUpdateObj[BROWSING_TOGGLE_UPDATE] = (msg) => {
  return updateBrowsingToggle(msg);
};

export const ablyGroupChannelUiUpdateFunctions = ablyGroupChannelUiUpdateObj;

export const ABLY_GROUP_CHANNEL_UI_UPDATE_EVENTS = Object.keys(ablyGroupChannelUiUpdateFunctions);

export function addErrorNotification(content) {
  const options = {
    notificationType: 'error',
    autoDismiss: true,
  };
  return addNotification(content, options);
}

export function addNotification(content, options = {}) {
  return {
    type: ADD_NOTIFICATION,
    stringifiedContent: ReactDOMServer.renderToStaticMarkup(content),
    content,
    options,
  };
}

export function removeNotification(content) {
  return {
    type: REMOVE_NOTIFICATION,
    stringifiedContent: ReactDOMServer.renderToStaticMarkup(content),
  };
}

export function openModal(modalName, modalSegment) {
  // If modalSegment is null then this was dispatched by the class controls
  return {
    type: OPEN_MODAL,
    modalName,
    modalSegment: modalSegment ? modalSegment : 'classControls',
  };
}

export function closeModal() {
  return {
    type: CLOSE_MODAL,
  };
}

export function openDropdown(dropdownName) {
  return {
    type: OPEN_DROPDOWN,
    dropdownName,
  };
}

export function closeDropdown(dropdownName) {
  return {
    type: CLOSE_DROPDOWN,
    dropdownName,
  };
}

export function openFlyout(flyoutName) {
  return {
    type: OPEN_FLYOUT,
    flyoutName: flyoutName,
  };
}

export function stopBrowsingTimeout() {
  return {
    type: BROWSING_TIMEOUT,
    browsingTimeout: false,

  };
}

export function resetBrowsingTimeout() {
  return {
    type: BROWSING_TIMEOUT,
    browsingTimeout: true,
  };
}

export function closeFlyout() {
  return {
    type: CLOSE_FLYOUT,
  };
}

export function resetUi() {
  return {
    type: RESET_UI,
  };
}

export function openSubFlyout(subFlyoutName) {
  return {
    type: OPEN_SUB_FLYOUT,
    subFlyoutName: subFlyoutName,
  };
}

export function closeSubFlyout() {
  return {
    type: CLOSE_SUB_FLYOUT,
  };
}

export function getWebRulesSection(sectionName) {
  return {
    type: GET_WEB_RULES_SECTION,
    sectionName: sectionName,
  };
}

export function getCurrentSegmentGuid(segmentGuid) {
  return {
    type: GET_CURRENT_SEGMENT_GUID,
    segmentGuid: segmentGuid,
  };
}

export function expandNavigation() {
  return {
    type: EXPAND_NAVIGATION,
  };
}

export function collapseNavigation() {
  return {
    type: COLLAPSE_NAVIGATION,
  };
}
