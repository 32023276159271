import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Icon from './Icon';

const LoadingButton = ({
  label,
  classes,
  onClick,
  isLoading,
  isDisabled,
  hasLinkStyles,
  testId,
  warningButton,
  displayTextWhileLoading,
}) => {
  const labelSpan = <span className='m-left--8'>{label}</span>
  const loadingIcon = (
    <div data-testid='loadingButton-icon'>
      <Icon
        name='icon-loading'
        classes='icon-loading--animating'
      />
      {displayTextWhileLoading && labelSpan}
    </div>
  );

  const buttonClasses = classNames(classes, {
    button: !hasLinkStyles && !warningButton,
    'button-link': hasLinkStyles,
    'co-modal-warningButton': warningButton,
  });

  return (
    <button
      data-testid={testId || 'loadingButton-save'}
      className={buttonClasses}
      onClick={onClick}
      disabled={isLoading || isDisabled}
    >
      {isLoading ? loadingIcon : label}
    </button>
  );
};

LoadingButton.propTypes = {
  label: PropTypes.string,
  classes: PropTypes.string,
  onClick: PropTypes.func,
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,
  hasLinkStyles: PropTypes.bool,
  testId: PropTypes.string,
  warningButton: PropTypes.bool,
  displayTextWhileLoading: PropTypes.bool,
};

export default LoadingButton;
