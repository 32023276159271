import React from 'react';
import PropTypes from 'prop-types';
import { CSSTransition, TransitionGroup } from 'react-transition-group';


const PopFadeInOut = ({ children, condition, isQuick, ...props }) => {
  const timing = isQuick ? 400 : 500;
  const classNames = isQuick ? 'slideInOutQuick' : 'slideInOut';

  return (
    <TransitionGroup>
      {condition && (
        <CSSTransition
          {...props}
          timeout={{ enter: timing, exit: timing }}
          classNames={classNames}
        >
          {children}
        </CSSTransition>
      )}
    </TransitionGroup>
  );
};

PopFadeInOut.propTypes = {
  children: PropTypes.object,
  condition: PropTypes.bool.isRequired,
  isQuick: PropTypes.bool,
};

export default PopFadeInOut;
