function checkForTimeInputSupport() {
  const input = document.createElement('input');
  const textValue = 'abc';
  input.setAttribute('type', 'time');
  input.setAttribute('value', textValue);
  return input.value !== textValue;
}

export default function setupBrowserFeatures() {
  window.browserFeatures = {
    timeInput: checkForTimeInputSupport(),
  };
}
