import React, { useReducer } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useDrop } from 'react-dnd';
import { connect } from 'react-redux';

import I18n from '../../utilities/i18n';
import { nameReducer } from '../../utilities/groups';

import DataLoader from '../common/DataLoader';
import Icon from '../common/Icon';
import Options from './Options';
import UpdateStatus from '../common/UpdateStatus';
import UserScreensContainer from './UsersScreensView/UserScreensContainer';
import UsersHeatMap from './UsersHeatMap/UsersHeatMap';
import UsersTable from './UsersTable/UsersTable';

const GroupSegment = ({
  currentGroupNavTab,
  dropMember,
  hasHeatMapViewPolicy,
  hasScreensViewPolicy,
  isDefault,
  isLoading,
  noUsers,
  removeSegment,
  saveName,
  segment,
  toggleViewUserScreen,
  updating,
  usersBeingViewed,
  viewingAllScreens,
}) => {
  const dragType = 'member';
  const [name, dispatchName] = useReducer(nameReducer, {
    active: false,
    text: '',
  });
  const [{ isOver, canDrop }, drop] = useDrop({
    accept: dragType,
    canDrop: (item) => !segment.member_guids.includes(item.guid),
    drop: (item) => dropMember(segment.guid, item.guid),
    collect: (monitor) => ({
      isOver: Boolean(monitor.isOver()),
      canDrop: Boolean(monitor.canDrop()),
    }),
  });
  const canDropMember = isOver && canDrop;
  const updatingSegment = updating.segment === segment.guid;
  const isRemoving = updating.remove === segment.guid;
  const isUpdatingName = updating.name === segment.guid;
  const isScreensView = currentGroupNavTab === 'screensView';
  const isHeatMapView = currentGroupNavTab === 'heatMapView';

  const handleNameSubmit = (e) => {
    e.preventDefault();
    saveName(segment.guid, name.text);
    dispatchName({ type: 'stop' });
  };

  const buildSegmentName = () => {
    const isEditing = !isDefault && name.active;
    if (isEditing || isUpdatingName) {
      return (
        <form
          className='m-top--8'
          onSubmit={handleNameSubmit}
          data-testid='segment-form'
        >
          <UpdateStatus isActive={isUpdatingName}>
            <input
              type='text'
              className='co-groupPage-segment-header-titleInput'
              autoFocus={true}
              value={name.text}
              onChange={(e) =>
                dispatchName({ type: 'update', text: e.target.value })
              }
              onFocus={(e) => e.target.select()}
              onBlur={() => dispatchName({ type: 'stop' })}
              onKeyDown={(e) => {
                if (e.key === 'Escape') {
                  dispatchName({ type: 'stop' });
                }
              }}
              data-testid='segment-name-input'
            />
          </UpdateStatus>
        </form>
      );
    }

    return (
      <>
        <h2 className='co-groupPage-segment-header-title'>
          {segment.name}
        </h2>
        {!isDefault && (
          <button
            className='co-segments-iconButton button-link m-left--16'
            aria-label={I18n.t('change_name')}
            onClick={() => dispatchName({ type: 'start', text: segment.name })}
            data-testid='segment-editName-button'
          >
            <Icon name='icon-edit' />
          </button>
        )}
      </>
    );
  };

  const buildRemoveButton = () => {
    if (isDefault) {
      return null;
    }
    return (
      <button
        className='co-segments-iconButton button-link'
        aria-label={I18n.t('remove_group')}
        onClick={() => removeSegment(segment.guid)}
        data-testid='segment-remove-button'
      >
        <Icon name='icon-trash' />
      </button>
    );
  };

  const buildUsersView = () => {
    if (isLoading) {
      return <DataLoader />;
    }

    if (
      (!hasScreensViewPolicy ||
        (hasHeatMapViewPolicy && hasScreensViewPolicy)) &&
      isHeatMapView
    ) {
      return (
        <UsersHeatMap
          segment={segment}
          viewingAllScreens={viewingAllScreens}
          usersBeingViewed={usersBeingViewed}
          toggleViewUserScreen={toggleViewUserScreen}
          dragType={dragType}
          canDropMember={canDropMember}
          updating={updating}
        />
      );
    }

    if (hasScreensViewPolicy && isScreensView) {
      return (
        <UserScreensContainer
          segment={segment}
          dragType={dragType}
          canDropMember={canDropMember}
          updating={updating}
        />
      );
    }

    return (
      <UsersTable
        segment={segment}
        dragType={dragType}
        canDropMember={canDropMember}
        updating={updating}
      />
    );
  };

  const buildHeader = () => {
    return (
      <header className='co-groupPage-segment-header'>
        <div className='co-groupPage-segment-header-group'>
          {buildSegmentName()}
        </div>
        <div>{buildRemoveButton()}</div>
      </header>
    );
  };

  const buildOptions = () => {
    const noMembers =
      !segment.member_guids || segment.member_guids.length === 0;
    if (isDefault || noMembers) {
      return null;
    }

    return (
      <section className='m-bottom--16'>
        <Options segment={segment} noUsers={noUsers} />
      </section>
    );
  };

  const groupClasses = classNames({
    'co-groupPage-segment': true,
    'co-segments-groupMembers--droppable': canDropMember || updatingSegment,
  });

  return (
    <div className='co-groupPage'>
      <UpdateStatus isActive={isRemoving}>
        <section className={groupClasses} ref={drop}>
          {buildHeader()}
          {buildOptions()}
          {buildUsersView()}
        </section>
      </UpdateStatus>
    </div>
  );
};

GroupSegment.propTypes = {
  currentGroupNavTab: PropTypes.string.isRequired,
  dropMember: PropTypes.func.isRequired,
  hasHeatMapViewPolicy: PropTypes.bool.isRequired,
  hasScreensViewPolicy: PropTypes.bool.isRequired,
  isDefault: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  noUsers: PropTypes.bool.isRequired,
  removeSegment: PropTypes.func,
  saveName: PropTypes.func,
  segment: PropTypes.object.isRequired,
  toggleViewUserScreen: PropTypes.func.isRequired,
  updating: PropTypes.object.isRequired,
  usersBeingViewed: PropTypes.arrayOf(PropTypes.string).isRequired,
  viewingAllScreens: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  classroomGroupBetas: state.betas,
  groupPolicy: state.policy.group,
  userBetas: state.currentUser.betas,
});

export default connect(mapStateToProps, null)(GroupSegment);
