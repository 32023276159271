export function isValidUrl(url) {
  const regex = new RegExp('(^([^.]+\\.)+[^.]+$)|((^\\*)|(\\*$))');
  return regex.test(url) && !url.includes(' ');
}

export function isValidDomain(domain) {
  return Boolean(!domain.match(/[^a-zA-Z0-9.\-_]/g) && domain.match(/\./));
}

export function getValidUrlLength(url) {
  if (url.length > 2048) {
    return url.slice(0, 2048);
  }
  return url;
}

export function getLocationUrl(location) {
  return `${location.pathname}${location.search}${location.hash}`;
}

export function getGroupUrl(group, subdomain = '') {
  if (group?.guid) {
    return `/groups/${group.guid}${subdomain}`;
  }
  return '/';
}

export function parseQueryParams(location) {
  if (!location) {
    return {};
  }

  if (typeof location.search === 'string' && location.search !== '') {
    return parseQueryString(location.search);
  }
  if (typeof location.hash === 'string' && location.hash !== '') {
    return parseQueryString(location.hash);
  }

  return {};
}

export function parseQueryString(str) {
  const pairs = str.replace(/.*\?/, '').split('&');
  return pairs.reduce((result, pair) => {
    const splits = pair.split('=');
    if (splits.length === 2) {
      result[splits[0]] = decodeURIComponent(splits[1]);
    }
    return result;
  }, {});
}

export function filterBrowsingHistory(reports) {
  return reports.filter((report) => {
    return report.url !== 'chrome://newtab/';
  });
}

export function getLinkFromUrl(url) {
  if (!url.startsWith('https://') && !url.startsWith('http://')) {
    return '//' + url;
  }
  return url;
}

export const removeHttpProtocol = (url) => {
  return url.replace(/^https?:\/\//, '');
};

export const removeTrailingSlash = (url) => {
  return url.replace(/\/+$/, '');
};

export const removeWww = (url) => {
  return url.replace(/^www.?/, '');
};

export const formatScreensViewUrl = (url) => {
  return removeTrailingSlash(removeWww(removeHttpProtocol(url)));
};

export function isDevUrl(url) {
  return ['development', 'staging', 'localhost'].some((partialUrl) => {
    return url.includes(partialUrl);
  });
}

export function findActiveTab(tabs, getTab) {
  for (let x = 0; x < tabs.length; ++x) {
    const tab = tabs[x];
    if (tab.active && tab.url) {
      const url = tab.url;
      if (getTab) {
        return tab;
      }
      if (url.startsWith('https://')) {
        return url.slice(8);
      } else if (url.startsWith('http://')) {
        return url.slice(7);
      }
      return tab.title;
    }
  }
  return null;
}

export const isSameArray = (a1, a2) => {
  if (a1.length !== a2.length) {
    return false;
  }
  return a1.reduce((p, c) => a2.includes(c) && p, true);
};

// get environment specific url to be concatenated onto sites array
export const getEnvSpecificUrl = () => {
  if (process.env.REACT_APP_ENV === 'development') {
    return [`https://${process.env.REACT_APP_ENV}-classroom.relay.school`];
  }

  if (process.env.REACT_APP_ENV === 'staging') {
    return [
      `https://${process.env.REACT_APP_ENV}-classroom.relay.school`,
      `https://${process.env.REACT_APP_ENV}-devices.classroom.relay.school`,
      `${process.env.REACT_APP_ENV}-classroom.relay.school`,
      `${process.env.REACT_APP_ENV}-devices.classroom.relay.school`,
    ];
  }

  return [
    'https://devices.classroom.relay.school',
    'devices.classroom.relay.school',
    'https://classroom.relay.school',
    'classroom.relay.school',
  ];
};

export const isNewTab = (tabUrl) => {
  return tabUrl === 'newtab';
};
