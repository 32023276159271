/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const TM = ({ className, onClick, style }) => {
  let clickProps;
  if (onClick) {
    clickProps = { onClick, tabIndex: '0', role: 'button' };
  }
  return (
    <svg
      className={classNames('icon', className)}
      {...clickProps}
      style={style}
      data-testid='CeleritasTM'
      width='7'
      height='4'
      viewBox='0 0 7 4'
      fill='none'
    >
      <path fillRule='evenodd' clipRule='evenodd' d='M1.28378 1.03879H0.447601V0.679932H2.50693V1.03879H1.66159V3.82118H1.28378V1.03879ZM4.32041 3.46233L3.22898 1.33635V3.82118H2.86802V0.679932H3.29966L4.51414 3.03378L5.72959 0.679932H6.1605V3.82118H5.80027V1.33635L4.70811 3.46233H4.32041Z' fill='#0B1F40'/>
    </svg>
  );
};

TM.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  style: PropTypes.object,
};

export default TM;
