import React from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { stopBroadcast } from '../../redux/broadcast/actions';

import { findBroadcast, ownsBroadcast } from '../../utilities/ably';
import { getCurrentGroup } from '../../utilities/groups';
import I18n from '../../utilities/i18n';

import Icon from './Icon';
import SlideInOut from './animations/SlideInOut';


const BroadcastNotification = ({
  currentUser,
  currentGroup,
  ablyData,
  stopBroadcast,
}) => {
  const groupGuid = currentGroup.guid;
  const broadcastingNotificationRoot = document.getElementById('broadcastingNotificationRoot');
  const broadcast = findBroadcast(ablyData, groupGuid);
  const isMyBroadcast = ownsBroadcast(broadcast, currentUser.email);

  const buildLabel = () => {
    const userName = getBroadcastName();
    const groupName = broadcast?.groupName;

    return `${userName} to ${groupName}`;
  };

  const getBroadcastName = () => {
    if (broadcast?.email === currentUser.email) {
      return I18n.t('my_screen');
    }

    return I18n.t('names_screen', { name: broadcast?.userName });
  };

  const buildActions = () => {
    return (
      <div className='flashNotice-buttonContainer'>
        <button
          className='flashNotice-button'
          onClick={() => stopBroadcast(groupGuid, currentUser.email)}
          data-testid='broadcastNotification-close'
        >
          <Icon name='icon-stop' classes='text--red' />
        </button>
      </div>
    );
  };

  const buildContent = () => {
    return (
      <SlideInOut condition={isMyBroadcast}>
        <section className='flashNotice co-flashNotice--broadcastingNotification'>
          <div className='flashNotice-messageContainer' data-testid='broadcastNotification-flash'>
            <div className='flashNotice-message'>
              <div className='text--xsmall text--uppercase text--bold'>
                {I18n.t('now_sharing')}
              </div>
              {buildLabel()}
            </div>
          </div>

          {buildActions()}
        </section>
      </SlideInOut>
    );
  };

  return ReactDOM.createPortal(
    buildContent(),
    broadcastingNotificationRoot,
  );
};

BroadcastNotification.propTypes = {
  currentUser: PropTypes.object.isRequired,
  currentGroup: PropTypes.object.isRequired,
  ablyData: PropTypes.object.isRequired,
  stopBroadcast: PropTypes.func.isRequired,
};

const mapState = (state) => ({
  currentUser: state.currentUser,
  currentGroup: getCurrentGroup(state.groups),
  ablyData: state.ably.data,
});

const mapDispatch = {
  stopBroadcast,
};

export default connect(mapState, mapDispatch)(BroadcastNotification);
