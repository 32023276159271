import React, { useEffect, useState, useCallback } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import * as microsoftTeams from '@microsoft/teams-js';

import {
  loginWithCognitoAccessToken,
  loginWithJwt,
  loginWithLightspeed,
  loginWithSso,
} from '../../redux/currentUser/actions';

import I18n from '../../utilities/i18n';
import { parseQueryParams, getGroupUrl } from '../../utilities/urls';
import { getCognitoCookieValue } from '../../helpers/cookies';
import { saveLocalStorage, loadLocalStorage } from '../../helpers/localStorage';

import { ReactComponent as LoadingLogoQuadrant } from '../../images/loadingLogoQuadrant.svg';

import Icon from '../common/Icon';
import { addError } from '../../redux/errors/actions';
import GenericLogin from './GenericLogin';


const SignIn = ({
  location,
  currentUser,
  allGroups,
  inSession,
  loginWithCognitoAccessToken,
  loginWithJwt,
  loginWithLightspeed,
  loginWithSso,
}) => {
  const [isInTeams, setIsInTeams] = useState(false);

  const loginTeams = useCallback((authType) => {
    microsoftTeams.getContext(() => {
      microsoftTeams.authentication.authenticate({
        url: window.location.origin + '/teams-auth?authType=' + authType,
        width: 600,
        height: 535,
        successCallback: function (result) {
          //this has to be called to close popup
          loginWithSso(result.state, result.code, true);
        },
        failureCallback: function (reason) {
          //popup remains open, shows classroom welcome page
          addError(`Could not log into teams: ${reason}`);
        },
      });
    });
  }, [loginWithSso]);

  const hasCognitoAccessToken = () => {
    return getCognitoCookieValue('access_token') !== null ? true : false;
  };

  useEffect(() => {
    const queryParams = parseQueryParams(location);
    const code = queryParams.code;
    const state = queryParams.state;
    const lightspeedToken = queryParams.token;
    const autoLogin = (loadLocalStorage('ClassroomAutoLogin') !== false);

    if (code) {
      saveLocalStorage('ClassroomAutoLogin', true);
      if (isInTeams) {
        //redirects to successCallback() to close popup
        microsoftTeams.authentication.notifySuccess({
          state,
          code,
        });
      } else {
        loginWithSso(state, code, false);
      }
    } else if (lightspeedToken) {
      loginWithLightspeed(queryParams.c, lightspeedToken, isInTeams);
    } else if (localStorage.jwt) {
      loginWithJwt(isInTeams);
    } else if (hasCognitoAccessToken() && currentUser && !currentUser.hasLoggedOut) {
      loginWithCognitoAccessToken(isInTeams);
    } else if (autoLogin && isInTeams) {
      loginTeams('azure');
    }
  }, [location, loginWithSso, loginWithLightspeed, loginWithJwt, isInTeams, loginTeams, currentUser.hasLoggedOut]);

  microsoftTeams.getContext(() => {
    if (!isInTeams) {
      setIsInTeams(true);
    }
  });

  const buildLoadingScreen = () => {
    return (
      <div className='body--signin minHeight--100vh'>
        <div className='co-centeredFullScreen'>
          <div className='signinLoadingLogo'>
            <Icon name='icon-classroom-logo-32' classes='signinLoadingLogo-classroom' />
            <LoadingLogoQuadrant
              className='signinLoadingLogo-animatedCircle signinLoadingLogo-animatedCircle--fast'
            />
            <LoadingLogoQuadrant
              className='signinLoadingLogo-animatedCircle signinLoadingLogo-animatedCircle--slow'
            />
          </div>
          <div className='signinLoadingLogo-text'>{I18n.t('loading')}...</div>
        </div>
      </div>
    );
  };

  const isLoggedIn = currentUser.loggedIn;
  const isLoggingIn = currentUser.loggingIn;
  const isDataLoaded = currentUser.dataLoaded;

  if (isLoggedIn && isDataLoaded) {
    const redirectUrl = currentUser.redirectUrl;
    const isNewUser = !loadLocalStorage('ClassroomGettingStartedDone');
    const hasGroups = allGroups.length > 0;
    const firstGroup = allGroups[0];
    const firstShownGroup = allGroups.find((group) => !group.hidden);
    const firstInSessionGroup = allGroups.find((group) => inSession.includes(group.guid));
    const isAdmin = currentUser.is_admin;

    if (redirectUrl) {
      return <Redirect to={redirectUrl} />;
    }

    if (isAdmin && (isNewUser || !hasGroups)) {
      return <Redirect to='/getting-started' />;
    }

    if (!isAdmin && !hasGroups) {
      // Teacher that has no classes, but can create classes
      return <Redirect to={'/my-classes'} />;
    }

    if (firstInSessionGroup) {
      return <Redirect to={getGroupUrl(firstInSessionGroup)} />;
    }

    if (firstShownGroup) {
      return <Redirect to={getGroupUrl(firstShownGroup)} />;
    }

    return <Redirect to={getGroupUrl(firstGroup)} />;
  }

  if (isLoggingIn || (isLoggedIn && !isDataLoaded)) {
    return buildLoadingScreen();
  }

  return (
    <div className='co-login minHeight--100vh'>
      <GenericLogin
        loginError={currentUser.loginError}
        isInTeams={isInTeams}
      />
    </div>
  );
};

SignIn.propTypes = {
  location: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  allGroups: PropTypes.arrayOf(PropTypes.object).isRequired,
  inSession: PropTypes.arrayOf(PropTypes.string).isRequired,
  loginWithCognitoAccessToken: PropTypes.func.isRequired,
  loginWithJwt: PropTypes.func.isRequired,
  loginWithSso: PropTypes.func.isRequired,
  loginWithLightspeed: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  currentUser: state.currentUser,
  allGroups: state.groups.groups,
  inSession: state.classes.inSession,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  loginWithCognitoAccessToken,
  loginWithJwt,
  loginWithLightspeed,
  loginWithSso,
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SignIn));
