import { addError } from '../errors/actions';
import { addErrorNotification } from '../ui/actions';

import I18n from '../../utilities/i18n';
import { parseInviteCodeOLD, createInviteCodeOLD, getCustomerIdFromInviteCode } from '../../utilities/invites';

import { ClassroomApi } from '../../helpers/classroomApi';
import { hasBeta } from '../../utilities/users';

export const RECEIVE_INVITES = 'RECEIVE_INVITES';
export const REMOVE_INVITE = 'REMOVE_INVITE';
export const REMOVE_ALL_INVITES = 'REMOVE_ALL_INVITES';
export const CREATE_INVITE = 'CREATE_INVITE';
export const SET_INVITE_CREATED = 'SET_INVITE_CREATED';
export const SET_INVITE_ERROR = 'SET_INVITE_ERROR';
export const SET_INVITE_CODE = 'SET_INVITE_CODE';

export function getInvites(groupGuid) {
  return async function(dispatch, getState) {
    const state = getState();
    const group = state.groups.groups.find((group) => group.guid === groupGuid);
    if (!group) {
      return {};
    }

    const response = await ClassroomApi.get(`/pending_group_invites?group_id=${group.id}&group_guid=${groupGuid}`);

    if (response?.status !== 200) {
      return dispatch(addError('getInvites', response));
    }

    const invites = response.data?.invites ?? [];

    return dispatch({ type: RECEIVE_INVITES, invites });
  };
}

export function acceptInvite(groupGuid, userId, userGuid) {
  return async function(dispatch, getState) {
    const state = getState();
    const group = state.groups.groups.find((group) => group.guid === groupGuid);
    if (!group) {
      return {};
    }

    const data = {
      group_id: group.id,
      group_guid: groupGuid,
      user_id: userId,
      user_guid: userGuid,
    };
    const response = await ClassroomApi.post('/pending_group_invites/accept', data);

    if (response?.status === 403 && response?.data === 'class at max size') {
      dispatch(addErrorNotification(I18n.t('string_could_not_be_added_max_size', { string: 'Student' })));
      return dispatch({ type: REMOVE_INVITE, groupId: group.id, userId, userGuid, groupGuid });
    }

    if (response?.status !== 200) {
      return dispatch(addError('acceptInvite', response));
    }

    return dispatch({ type: REMOVE_INVITE, groupId: group.id, userId, userGuid, groupGuid });
  };
}

export function rejectInvite(groupGuid, userId, userGuid) {
  return async function(dispatch, getState) {
    const state = getState();
    const group = state.groups.groups.find((group) => group.guid === groupGuid);
    if (!group) {
      return {};
    }

    const data = {
      group_guid: groupGuid,
      group_id: group.id,
      user_id: userId,
      user_guid: userGuid,
    };
    const response = await ClassroomApi.post('/pending_group_invites/reject', data);

    if (response?.status !== 200) {
      return dispatch(addError('rejectInvite', response));
    }

    return dispatch({ type: REMOVE_INVITE, groupId: group.id, userId, userGuid, groupGuid });
  };
}

export function acceptAllInvites(groupGuid) {
  return async function(dispatch, getState) {
    const state = getState();
    const group = state.groups.groups.find((group) => group.guid === groupGuid);
    if (!group) {
      return {};
    }

    const data = {
      group_guid: groupGuid,
      group_id: group.id,
    };
    const response = await ClassroomApi.post('/pending_group_invites/accept_all', data);

    if (response?.status === 403 && response?.data === 'class at max size') {
      dispatch(addErrorNotification(I18n.t('string_could_not_be_added_max_size', { string: 'Students' })));
      return dispatch({ type: REMOVE_ALL_INVITES });
    }

    if (response?.status !== 200) {
      return dispatch(addError('acceptAllInvites', response));
    }

    if (response?.data?.notAllAdded) {
      dispatch(addErrorNotification(I18n.t('not_all_students_added_max_size')));
    }

    return dispatch({ type: REMOVE_ALL_INVITES });
  };
}

export function rejectAllInvites(groupGuid) {
  return async function(dispatch, getState) {
    const state = getState();
    const group = state.groups.groups.find((group) => group.guid === groupGuid);
    if (!group) {
      return {};
    }

    const data = {
      group_guid: groupGuid,
      group_id: group.id,
    };
    const response = await ClassroomApi.post('/pending_group_invites/reject_all', data);

    if (response?.status !== 200) {
      return dispatch(addError('rejectAllInvites', response));
    }

    return dispatch({ type: REMOVE_ALL_INVITES });
  };
}

function createInviteOLD(code, email) {
  return async function(dispatch) {
    const inviteData = parseInviteCodeOLD(code);

    if (!inviteData) {
      return dispatch({ type: SET_INVITE_ERROR, error: 'invalid_code' });
    }

    dispatch({ type: CREATE_INVITE, email });

    const { customerId, groupId } = inviteData;
    const data = {
      group_id: groupId,
      email,
    };
    const response = await ClassroomApi.post('/pending_group_invites', data, customerId);

    if (response?.status !== 200 || response.data?.error) {
      const error = response.data?.error ?? 'unknown_error';

      dispatch({ type: SET_INVITE_ERROR, error });
      return dispatch(addError('createInvite', response));
    }

    return dispatch({ type: SET_INVITE_CREATED, email });
  };
}

export function createInvite(code, email) {
  return async function(dispatch) {
    // if code starts with 'g-' then beta_cloud_rostering is enabled 
    if (!code.startsWith('g-')) {
      return dispatch(createInviteOLD(code, email));
    }
    // TODO - remove this substring call when beta_cloud_rostering is in GA
    code = code.substring(2);
    dispatch({ type: CREATE_INVITE, email });

    const response = await ClassroomApi.get(`/pending_group_invites/guid?code=${code}`);
    if (!response?.data) {
      return dispatch({ type: SET_INVITE_ERROR, error: 'invalid_code' });
    }


    const customerId = getCustomerIdFromInviteCode(code);
    const groupGuid = response.data.group_guid;
    const data = {
      group_guid: groupGuid,
      code,
      email,
    };
    const createResponse = await ClassroomApi.post('/pending_group_invites', data, customerId);

    if (createResponse?.status !== 200 || createResponse.data?.error) {
      const error = createResponse.data?.error ?? 'unknown_error';
      dispatch({ type: SET_INVITE_ERROR, error });
      return dispatch(addError('createInvite', createResponse));
    }

    return dispatch({ type: SET_INVITE_CREATED, email });
  };
}

export function createInviteCode(groupId, groupGuid) {
  return async function(dispatch, getState) {
    const state = getState();
    const authCustomerId = state.currentUser.auth_customer_id;
    const hasRosterSync = hasBeta('cloud_rostering', state.district.betas, state.currentUser.betas);

    if (!hasRosterSync) {
      const code = createInviteCodeOLD(authCustomerId, groupId);
      return dispatch({ type: SET_INVITE_CODE, code });
    } 

    const data = {
      group_guid: groupGuid,
    };
    const response = await ClassroomApi.post('/pending_group_invites/short_code', data);
    if (response && response.data && typeof response.data === 'string') {
      // TODO: remove g- in front (and join-group page check for it) when beta_cloud_rostering is in GA. 
      // This a temporary fix to tell join-group page that beta is enabled
      const code = `g-${response.data}`;
      return dispatch({ type: SET_INVITE_CODE, code });
    }

    return dispatch({ 
      type: SET_INVITE_CODE, code: '', 
      error: response.error || 'ERROR fetching invite code',
    });
  };
}
