import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { addNotification, closeModal, openModal } from '../../../../redux/ui/actions';

import I18n from '../../../../utilities/i18n';

import Modal from '../../Modal';
import { Fragment } from 'react';
import { getDefaultSelection, getSelectedSegmentEmails } from '../../../../utilities/groups';
import { startBroadcast, initTeacherStream } from '../../../../redux/broadcast/actions';
import SegmentSelector from '../../SegmentSelector';


const ShareScreen = ({
  currentlyOpenModal,
  currentlyOpenModalSegment,
  closeModal,
  currentSegments,
  allUsers,
  currentUser,
  startBroadcast,
  initTeacherStream,
  openModal,
  addNotification,
}) => {
  const shareScreenModal = 'shareScreen';
  const securityBlockedModal = 'securityBlocked';
  const [selectedSegments, setSelectedSegments] =
    useState(getDefaultSelection(currentSegments, currentlyOpenModalSegment));

  useEffect(() => {
    setSelectedSegments(getDefaultSelection(currentSegments, currentlyOpenModalSegment));
  }, [currentlyOpenModalSegment]);

  const saveHandler = async () => {
    const emails = getSelectedSegmentEmails(allUsers, selectedSegments, currentSegments);
    const teacherEmail = currentUser.email;
    closeModal();

    const promptResponse = await initTeacherStream(teacherEmail);
    switch (promptResponse) {
      case 'accepted':
        startBroadcast(teacherEmail, emails, true);
        break;
      case 'blocked':
        openModal(securityBlockedModal);
        break;
      case 'error':
        addNotification(I18n.t('network_error_please_try_again'), { notificationType: 'error', autoDismiss: true });
        break;
      default:
        break;
    }
  };

  const buildSegmentSelector = () => {
    if (currentlyOpenModalSegment) {
      // navigated from specific segment options
      return null;
    }

    return (
      <SegmentSelector
        currentSegments={currentSegments}
        selectedSegments={selectedSegments}
        setSelectedSegments={setSelectedSegments}
      />
    );
  };

  const buildScreenShareModal = () => {
    let message = 'your_screen_will_be_shared_with_group';
    if (selectedSegments?.length && selectedSegments[0] === 'all') {
      message = 'your_screen_will_be_shared_with_all_students';
    }

    return (
      <Modal
        show={currentlyOpenModal === shareScreenModal}
        title={I18n.t('share_screen')}
        closeModal={closeModal}
        isSmall
        modalRedesign2022Q1
        legacyModalVersion
        saveHandler={saveHandler}
        saveText={I18n.t('continue')}
        closeText={I18n.t('close')}
      >
        <Fragment>
          <div className='' data-testid='openTabs-modal'>
            {!currentlyOpenModalSegment && I18n.t('share_screen_with')}
          </div>
          <p className='alert alert--warning m-top--24' data-testid='openTabs-modal'>
            {I18n.t(message)}
          </p>
          <div data-testid='segment-selector-container'>
            {buildSegmentSelector()}
          </div>
        </Fragment>
      </Modal>
    );
  };

  const buildSecurityBlockedModal = () => {
    const message = 'system_security_settings_may_be_blocking';

    return (
      <Modal
        show={currentlyOpenModal === securityBlockedModal}
        title={I18n.t('share_screen')}
        closeModal={closeModal}
        isSmall
        closeText={I18n.t('close')}
      >
        <Fragment>
          <p className='alert alert--error m-top--24' data-testid='openTabs-modal'>
            {I18n.t(message)}
          </p>
        </Fragment>
      </Modal>
    );
  };

  return (
    <Fragment>
      {buildScreenShareModal()}
      {buildSecurityBlockedModal()}
    </Fragment>
  );
};

ShareScreen.propTypes = {
  closeModal: PropTypes.func.isRequired,
  currentlyOpenModal: PropTypes.string,
  currentlyOpenModalSegment: PropTypes.string,
  currentUser: PropTypes.object.isRequired,
  currentSegments: PropTypes.arrayOf(PropTypes.object).isRequired,
  allUsers: PropTypes.object.isRequired,
  startBroadcast: PropTypes.func.isRequired,
  initTeacherStream: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  addNotification: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  currentlyOpenModal: state.ui.currentlyOpenModal,
  currentlyOpenModalSegment: state.ui.currentlyOpenModalSegment,
  currentSegments: state.groupSegments.segments,
  allUsers: state.users.all,
  currentUser: state.currentUser,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  startBroadcast,
  closeModal,
  initTeacherStream,
  openModal,
  addNotification,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ShareScreen);
