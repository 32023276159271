import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classNames from 'classnames';

import { startBroadcast } from '../../redux/broadcast/actions';
import { openFlyout, openModal } from '../../redux/ui/actions';
import {
  getUserGuids,
  getUserInfos,
  onlineUsersAvailableForBulkCheckIn,
} from '../../utilities/users';
import { findBroadcast } from '../../utilities/ably';
import { getCurrentGroup } from '../../utilities/groups';
import { isOnCampusOnly } from '../../utilities/policy';
import I18n from '../../utilities/i18n';
import { getWebRulesType, isNoBrowsing } from '../../utilities/webzones';
import {
  removeWebzone,
  setCustomWebzone,
  setNoBrowsingWebzone,
  updateNoBrowsing,
} from '../../redux/webzones/actions';
import { publishBrowsingToggleUpdate, updatePolicy } from '../../redux/ably/actions';
import { modifySegment } from '../../redux/groupSegments/actions';
import { getClassroomUsers } from '../../redux/users/actions';

import Icon from '../common/Icon';
import Toggle from '../common/Toggle';
import Tooltip from '../common/Tooltip/Tooltip';
import SendLink from '../Groups/SendLink';
import BetaBadge from '../common/BetaBadge';

const Options = ({
  allUsers,
  districtIps,
  segment,
  currentUser,
  currentGroup,
  ablyData,
  openModal,
  classes,
  currentWebzone,
  updatePolicy,
  openFlyout,
  webzonesAreLoading,
  updateNoBrowsing,
  publishBrowsingToggleUpdate,
  noUsers,
  groupPolicy,
  getClassroomUsers,
}) => {
  const ips = isOnCampusOnly(groupPolicy) ? districtIps : null;
  const shareScreenModal = 'shareScreen';
  const lockScreenModal = 'lockScreens';
  const sendLinkModal = 'sendLinkSegments';
  const hasBroadcast = Boolean(findBroadcast(ablyData, currentGroup.guid));
  const isInSession = classes.inSession.includes(currentGroup.guid);
  const shareScreenDisabled = !isInSession || hasBroadcast || noUsers;

  const startBroadcastingHandler = () => {
    openModal(shareScreenModal, segment && segment.guid);
  };

  const handleToggleBrowsing = async () => {
    if (webzonesAreLoading) {
      return;
    }

    const currentNoBrowsingState = isNoBrowsing(currentWebzone);
    await updateNoBrowsing(currentGroup.guid, currentUser.email, !currentNoBrowsingState);
    await updatePolicy();
    publishBrowsingToggleUpdate(currentGroup.guid, !currentNoBrowsingState);
  };

  const handleOpenFlyout = (openMessage) => {
    if (segment) {
      openMessage += `::${segment.guid}`;
    }
    openFlyout(openMessage);
  };

  const buildButton = (
    label,
    icon,
    disabled,
    testId,
    onClickFunc,
    hasEarlyAccessLabel,
  ) => {
    const buttonClasses = classNames({
      button: true,
      'co-nav-options-link': true,
      'co-nav-options-link--disabled': disabled || noUsers,
      'm-bottom--0': true,
    });

    return (
      <button
        className={buttonClasses}
        onClick={onClickFunc}
        disabled={disabled || noUsers}
        data-testid={testId}
      >
        <span className='co-nav-action-icon'>
          <Icon name={icon} />
        </span>

        <span className='co-nav-option-text'>
          {I18n.t(label)}
        </span>
        {hasEarlyAccessLabel && <BetaBadge classes='m-left--8' />}
      </button>
    );
  };

  const buildBrowsingOption = () => {
    if (segment.guid !== 'classControls') {
      return null;
    }

    return (
      <section className='co-nav-option-browsing'>
        <Toggle
          className='m-right--8'
          onClick={handleToggleBrowsing}
          checked={!isNoBrowsing(currentWebzone)}
          id={'options-toggle'}
          testId={'options-browsing-toggle'}
        />

        <span className='co-nav-option-text'>
          {I18n.t('browsing')}
        </span>
      </section>
    );
  };

  const webRulesType = {
    noWebRules: 'none',
    segmentWebRules: getWebRulesType(segment?.web_rules),
    webRules: getWebRulesType(currentWebzone),
  };

  const getLabel = () => {
    if (isNoBrowsing(currentWebzone)) {
      return webRulesType.noWebRules;
    } else if (segment?.guid !== 'classControls') {
      return webRulesType.segmentWebRules;
    }
    return webRulesType.webRules;
  };

  const ariaLabel = getLabel() ? I18n.t(`${getLabel()}_web_rules`) : '';

  const buildWebRulesOption = () => {
    const label = segment.guid !== 'classControls' ? 'group_web_rules' : 'web_rules';

    const buttonClasses = classNames({
      button: true,
      'co-nav-options-link': true,
    });

    const indicatorClasses = classNames({
      'co-nav-action-indicator': true,
      'co-nav-action-indicator--on': (getLabel() === 'allow') || (getLabel() === 'block'),
    });

    return (
      <button
        className={buttonClasses}
        onClick={() => {
          handleOpenFlyout('webRules');
        }}
        data-testid='options-webRules-button'
      >
        <span className='co-nav-action-icon'>
          <Icon name={'icon-globe'} />
        </span>
        <span className='co-nav-option-text m-right--8' data-testid={'web-rules-label'}>
          {I18n.t(label)}
        </span>
        <span className={indicatorClasses}>
        </span>
      </button>
    );
  };

  const bulkCheckInStudentsOption = () => {
    if (!groupPolicy?.bulk_check_ins || segment.guid !== 'classControls') {
      return null;
    }

    const userGuids = getUserGuids(allUsers);
    const userInfos = getUserInfos(userGuids, allUsers, ablyData, ips);

    if (!onlineUsersAvailableForBulkCheckIn(userInfos, currentGroup.guid).length) {
      return (
        <Tooltip
          content={I18n.t('all_online_students_are_currently_checked_in')}
          aria-label={I18n.t('all_online_students_are_currently_checked_in')}
          position='top'
          size='medium'
          customClassTwo='centerText'
        >
          <li className='co-nav-option'>
            <span>
              {buildButton(
                'bulk_check_in',
                'icon-bulk-check-in-checkmark',
                true,
                'options-bulkCheckIn-button',
                async () => {
                  await getClassroomUsers();
                  handleOpenFlyout('bulkCheckInFlyout');
                })}
            </span>
          </li>
        </Tooltip>
      );
    }

    return (
      <li className='co-nav-option'>
        {buildButton(
          'bulk_check_in',
          'icon-bulk-check-in-checkmark',
          !isInSession,
          'options-bulkCheckIn-button',
          async () => {
            await getClassroomUsers();
            handleOpenFlyout('bulkCheckInFlyout');
          })}
      </li>
    );
  };

  return (
    <Fragment>
      <ul className='co-nav-options'>
        {buildBrowsingOption()}
        <li className='co-nav-option'>
          {buildButton('screen_lock', 'icon-lock', !isInSession, 'options-screenLock-button', () => {
            openModal(lockScreenModal, segment && segment.guid);
          })}
        </li>
        <li className='co-nav-option'>
          {buildButton('send_link', 'icon-link', !isInSession, 'options-sendLink-button', () => {
            openModal(sendLinkModal, segment && segment.guid);
          })}
        </li>

        <Tooltip
          content={ariaLabel}
          aria-label={ariaLabel}
          position='top'
          size='medium'
          customClassTwo='centerText'
        >
          <li className='co-nav-option'>
            {buildWebRulesOption()}
          </li>
        </Tooltip>

        <li className='co-nav-option'>
          {buildButton('share_screen', 'icon-screen', shareScreenDisabled, 'options-shareScreen-button', () => {
            startBroadcastingHandler();
          })}
        </li>
        {bulkCheckInStudentsOption()}
      </ul>
      <SendLink parentGuid={segment.guid} />
    </Fragment>
  );
};

Options.propTypes = {
  allUsers: PropTypes.object.isRequired,
  districtIps: PropTypes.arrayOf(PropTypes.string).isRequired,
  segment: PropTypes.object,
  currentUser: PropTypes.object.isRequired,
  currentGroup: PropTypes.object.isRequired,
  ablyData: PropTypes.object.isRequired,
  openModal: PropTypes.func,
  classes: PropTypes.object.isRequired,
  currentWebzone: PropTypes.object,
  updateNoBrowsing: PropTypes.func.isRequired,
  publishBrowsingToggleUpdate: PropTypes.func,
  updatePolicy: PropTypes.func.isRequired,
  openFlyout: PropTypes.func.isRequired,
  webzonesAreLoading: PropTypes.bool,
  noUsers: PropTypes.bool.isRequired,
  groupPolicy: PropTypes.object.isRequired,
  getClassroomUsers: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  allUsers: state.users.all,
  currentUser: state.currentUser,
  currentGroup: getCurrentGroup(state.groups),
  districtIps: state.district.ips,
  ablyData: state.ably.data,
  classes: state.classes,
  currentWebzone: state.webzones.current,
  webzonesAreLoading: state.webzones.isLoading,
  currentSegments: state.groupSegments.segments,
  groupPolicy: state.policy.group,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  startBroadcast,
  openModal,
  removeWebzone,
  setNoBrowsingWebzone,
  updatePolicy,
  openFlyout,
  setCustomWebzone,
  modifySegment,
  updateNoBrowsing,
  getClassroomUsers,
  publishBrowsingToggleUpdate,
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Options));

