export function insertEvent(event, events) {
  let index = 0;
  while (index < events.length && compareEvents(event, events[index]) > 0) {
    index++;
  }
  return [...events.slice(0, index), event, ...events.slice(index)];
}

export function getTypeValue(type) {
  switch (type) {
    case 'classStop':
      return 0;
    case 'scheduleReset':
      return 1;
    case 'classStart':
      return 2;
    default:
      return Infinity;
  }
}

export function compareEvents(event0, event1) {
  const time0 = event0.date.getTime();
  const time1 = event1.date.getTime();
  if (time0 !== time1) {
    return time0 - time1;
  }

  const type0 = getTypeValue(event0.type);
  const type1 = getTypeValue(event1.type);
  return type0 - type1;
}

export function matchesEvent(event, guid, type, date) {
  if (guid && event.guid !== guid) {
    return false;
  }
  if (type && event.type !== type) {
    return false;
  }
  if (date && event.date.getTime() !== date.getTime()) {
    return false;
  }
  return true;
}
