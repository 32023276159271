import React from 'react';
import PropTypes from 'prop-types';
import format from 'date-fns/format';

import I18n from '../../../utilities/i18n';


const InsightsItem = ({
  userGuid,
  firstName,
  lastName,
  timestamp,
  openFlyout,
  removeInsight,
}) => {
  const firstInitial = firstName ? firstName.substring(0, 1) : '-';
  const lastInitial = lastName ? lastName.substring(0, 1) : '-';
  const fullName = `${firstName} ${lastName}`;

  const onClick = () => {
    openFlyout(userGuid);
    removeInsight(userGuid);
  };

  return (
    <li className='co-insights-item'>
      <button
        className='co-insights-item-content'
        onClick={onClick}
        data-testid='insight-button'>

        <span className='co-insights-item-initials'>
          {firstInitial + lastInitial}
        </span>

        <div className='text--white'>
          <strong>{fullName}</strong>
          <br />
          {format(timestamp, 'h:mma')} - {I18n.t('unusual_browsing_activity')}
        </div>
      </button>
    </li>
  );
};

InsightsItem.propTypes = {
  userGuid: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  timestamp: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string,
  ]).isRequired,
  openFlyout: PropTypes.func,
  removeInsight: PropTypes.func,
};

export default InsightsItem;
