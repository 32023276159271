import axios from 'axios';
import { getCognitoCookieValue } from './cookies';

export const ClassroomApi = {
  async get(url) {
    return await this.request('get', url);
  },

  async post(url, data, customerId) {
    return await this.request('post', url, data, customerId);
  },

  async patch(url, data, customerId) {
    return await this.request('patch', url, data, customerId);
  },

  async put(url, data) {
    return await this.request('put', url, data);
  },

  async delete(url, data) {
    return await this.request('delete', url, data);
  },

  isUnauthorizedError(error) {
    const errorMessage = error?.response?.data?.message || error?.response?.data;
    const errors = [
      'Bad JWT',
      'The incoming token has expired',
      'Unauthorized',
    ];
    return errors.includes(errorMessage);
  },

  async request(method, url, data, customerid) {
    try {
      const headers = {
        'x-api-key': process.env.REACT_APP_RELAY_API_KEY,
        'Content-Type': 'application/json',
      };

      if (customerid) {
        headers.customerid = customerid;
      } else {
        headers.jwt = localStorage.jwt;
      }

      headers.Authorization = getCognitoCookieValue('access_token');

      const response = await axios({
        method,
        url: `${process.env.REACT_APP_RELAY_API_URL}${url}`,
        headers,
        data,
      });

      return response;
    } catch (error) {
      if (this.isUnauthorizedError(error)) {
        delete localStorage.jwt;
        window.location.href = `${process.env.REACT_APP_RELAY_LOGIN_URL}${process.env.REACT_APP_RELAY_SIGN_OUT_PATH}`;
      } else {
        this.checkIssue(method, url, error);
      }

      return error.response;
    }
  },

  checkIssue(method, url, error) {
    const data = this.getIssueData(method, url, error);
    const [action, message] = this.classifyIssue(data);
    this.sendRollbar(action, message, data);
  },

  getIssueData(method, url, error) {
    const hasResponse = error.response !== undefined;
    const controller = url.split(/[/?]/)[1] || '';
    const errorMessage = error.message;
    const responseStatus = hasResponse ? error.response.status : 'NONE';
    const responseData = hasResponse ? error.response.data : 'NONE';
    const online = window.navigator.onLine;
    const apiVersion = hasResponse ? error.response.headers['api-version'] : 'NONE';
    const uiVersion = window.classroomUIVersion;

    return {
      method,
      url,
      controller,
      errorMessage,
      responseStatus,
      responseData,
      online,
      apiVersion,
      uiVersion,
    };
  },

  classifyIssue(data) {
    const infoResponses = [
      'bad roles',
      'jwt expired',
      'no classes',
      'no license',
      'password must not be empty',
      'unauthenticated',
      'unauthorized',
      'username must not be empty',
      'user in different school',
      'user not found',
    ];
    const warningMessages = [
      'Network Error',
      'timeout of 0ms exceeded',
      'Request aborted',
    ];

    if (!data.online) {
      return ['info', 'Offline'];
    }
    if (infoResponses.includes(data.responseData)) {
      return ['info', data.responseData];
    }
    if (warningMessages.includes(data.errorMessage)) {
      return ['warning', data.errorMessage];
    }
    const responseType = this.getResponseType(data.responseStatus);
    return ['error', `${data.controller} ${responseType}`];
  },

  getResponseType(status) {
    if (typeof status === 'number') {
      if (status >= 400 && status < 500) {
        return 'Client Error';
      } else if (status >= 500 && status < 600) {
        return 'Server Error';
      }
    }
    return 'Unknown Error';
  },

  sendRollbar(action, message, data) {
    switch (action) {
      case 'info':
        if (process.env.REACT_APP_INFO_ROLLBARS) {
          window.Rollbar.info(`api: ${message}`, data);
        }
        break;
      case 'warning':
        if (process.env.REACT_APP_WARNING_ROLLBARS) {
          window.Rollbar.warning(`api: ${message}`, data);
        }
        break;
      default:
        window.Rollbar.error(`api: ${message}`, data);
        break;
    }
  },
};
