import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import I18n from '../../../utilities/i18n';

import RadioGroupExpander from './RadioGroupExpander';
import RadioTab from './RadioTab';
import WebsiteImage from '../WebsiteImage';

const HOST_LIMIT = 3;

const TopBrowsingFilterList = ({ hosts, selectedFilter, onTabClick }) => {
  const savedExpandedOption = localStorage.getItem(I18n.t('currently_browsing'));
  const browsingList = useMemo(() => {
    return hosts
      .sort((a, b) => b.count - a.count)
      .slice(0, HOST_LIMIT);
  }, [hosts]);

  return (
    <RadioGroupExpander
      label={I18n.t('top_browsing')}
      defaultExpanded={savedExpandedOption ? savedExpandedOption === 'true' : true}
    >
      {
        browsingList.length
          ? browsingList.map(({ label, count }) => (
            <RadioTab
              key={label}
              value={label}
              count={count}
              isSelected={selectedFilter === label}
              onClick={() => onTabClick(label)}
            >
              <div className='co-topBrowsingFilterList-item' data-testid='currentlyBrowsing-listItem'>
                <WebsiteImage host={label} />
                <span>{label}</span>
              </div>
            </RadioTab>))
          : <div className='co-topBrowsingFilterList--empty'>{I18n.t('nothing_nada_zilch')}</div>
      }
    </RadioGroupExpander>
  );
};

TopBrowsingFilterList.propTypes = {
  hosts: PropTypes.array,
  selectedFilter: PropTypes.string,
  onTabClick: PropTypes.func,
};

export default TopBrowsingFilterList;
