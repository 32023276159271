import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Collapse } from 'react-collapse';

import I18n from '../../utilities/i18n';

export const GettingStartedStep = ({
  icon,
  title,
  titleText,
  children,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  function toggleContentClickHandler() {
    setIsOpen((isOpen) => {
      return !isOpen;
    });
  }

  function buildContent() {
    return (
      <Collapse
        isOpened={isOpen}
        springConfig={{ stiffness: 165, damping: 20, precision: .05 }}
        theme={{ content: 'co-gettingStarted-stepContent' }}
      >
        <span data-testid='gettingStarted-content'>
          {children}
        </span>
      </Collapse>
    );
  }

  return (
    <li className='co-gettingStarted-step'>
      <div className='co-gettingStarted-stepInfo'>
        <div className='l-flex l-flex--vAlignCenter'>
          <img src={icon} className='co-gettingStarted-stepImage' alt='step icon' />

          <div>
            <h3>{title}</h3>
            <p className='text--gray m-top--4 m-bottom--0'>
              {titleText}
            </p>
          </div>
        </div>

        <button
          className='button button--secondaryWhite'
          onClick={toggleContentClickHandler}
          data-testid='gettingStarted-toggle'>
          {I18n.t('details')}
        </button>
      </div>

      {buildContent()}
    </li>
  );
};

GettingStartedStep.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string,
  titleText: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
};

export default GettingStartedStep;
