export async function getPresence(channel, validIds) {
  if (!channel) {
    return {};
  }

  const messages = await channel.presence.get();

  return messages.reduce((data, message) => {
    const useId = Array.isArray(validIds) ? validIds.includes(message.clientId) : true;

    if (useId) {
      return {
        ...data,
        [message.clientId]: message.data,
      };
    }
    return data;
  }, {});
}

export function getPresenceData(ablyData, name, presenceName) {
  const channelData = getChannelData(ablyData, name);
  if (!channelData.presence || !presenceName) {
    return {};
  }

  return channelData.presence[presenceName] ?? {};
}

export function getGroupData(ablyData, name) {
  const channelData = getChannelData(ablyData, name);
  const groupsData = channelData?.groupUpdate ?? {};
  const first = Object.values(groupsData)[0];

  return first ?? {};
}

export function getTabsData(ablyData, name) {
  const channelData = getChannelData(ablyData, name);
  return channelData?.tabs ?? [];
}

export function getChannelData(ablyData, name) {
  if (!ablyData || !name) {
    return {};
  }

  return ablyData[name] ?? {};
}

export function findBroadcast(ablyData, groupGuid) {
  if (!ablyData || !groupGuid) {
    return null;
  }

  const groupPresence = ablyData[groupGuid]?.presence;
  if (!groupPresence) {
    return null;
  }

  const broadcastId = Object.keys(groupPresence).find((key) => groupPresence[key].broadcastEmail);
  if (!broadcastId) {
    return null;
  }

  const broadcastData = groupPresence[broadcastId];
  return {
    clientId: broadcastId,
    email: broadcastData.broadcastEmail,
    userName: broadcastData.broadcastUserName,
    expiresAt: broadcastData.broadcastExpiresAt,
    groupName: broadcastData.broadcastGroupName,
    streamId: broadcastData.broadcastStreamId,
    jwt: broadcastData.broadcastJwt,
  };
}

export function ownsBroadcast(broadcast, email) {
  if (!broadcast?.clientId || !email) {
    return false;
  }
  return broadcast.clientId.startsWith(email) || broadcast.clientId.startsWith(`broadcast:${email}`);
}

export function hasClassroomExtension(ablyData, email) {
  const presenceData = getPresenceData(ablyData, email, email);

  return Boolean(presenceData.state);
}

// grouping users by their checkedInto guid
export const groupUsersByCheckedIntoGuid = (usersList) => {
  const usersGroupedByCheckedIntoGuid = Object.values(usersList).reduce(
    (acc, item) => {
      // Initialize group
      if (!acc[item.checkedInto.guid]) {
        acc[item.checkedInto.guid] = [];
      }

      // Handle grouping
      acc[item.checkedInto.guid].push(item);

      return acc;
    },
    {},
  );

  return usersGroupedByCheckedIntoGuid;
};
