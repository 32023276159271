import React from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as DooStern } from '../../images/dooStern.svg';

const GroupError = ({ title, message }) => {
  return (
    <div className='co-mainContent co-mainContent--centered'>
      <section className='co-error'>
        <DooStern className='co-error-doo'/>
        <div className='co-error-content' data-testid='groupError-content'>
          <p className='co-error-title'>{title}</p>
          <h1 className='co-error-text'>{message}</h1>
        </div>
      </section>
    </div>
  );
};

GroupError.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
};

export default GroupError;
