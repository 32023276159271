import React from 'react';

const DataLoader = () => {
  return (
    <div
      className='m-vertical--40 loader--newtonsCradle'
      data-testid='dataLoader'>
    </div>
  );
};

export default DataLoader;
