import React from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';

import I18n from '../../utilities/i18n';
import { parseQueryParams } from '../../utilities/urls';

import { ReactComponent as StudentSitting } from '../../images/studentSitting.svg';

import Icon from '../common/Icon';
import { clearCurrentUser } from '../../redux/currentUser/actions';
import { bindActionCreators } from 'redux';


const StudentView = ({
  history,
  location,
  browser,
  clearCurrentUser,
}) => {
  const queryParams = parseQueryParams(location);
  const isInTeams = typeof queryParams.teamsId === 'string';
  const browserSize = browser.is;

  if (!isInTeams) {
    return <Redirect to='/' />;
  }

  const handleLogout = () => {
    clearCurrentUser();
    history.push('/');
  };

  const sizeClasses = classNames({
    'studentView-container': !browserSize.s && !browserSize.smed && !browserSize.xs,
    'sv-ex-large': browserSize.xl || browserSize.infinity,
    'sv-lessThan-ex-large': browser.lessThan.xl,
    'sv-large': browserSize.l,
    'sv-medium': browserSize.m,
    'sv-small-medium': browserSize.smed,
    'sv-small': browserSize.s,
    'sv-ex-small': browserSize.xs,
  });

  const buildHeader = () => {
    return (
      <header className='co-header'>
        <Icon name='icon-classroom-logo-32' classes='co-header-home-icon'/>
      </header>
    );
  };

  const buildText = () => {
    if (browserSize.xs) {
      return null;
    }
    return (
      <section className='co-teamsStudent-text'>
        <div>
          <div className='co-teamsStudent-title'>
            {I18n.t('welcome_to_classroom')}
          </div>
          <div className='co-teamsStudent-subtitle'>
            {I18n.t('for_microsoft_teams')}
          </div>
          <div>
            <button
              className='co-teamsStudent-link'
              data-testid='studentView-logout-button'
              onClick={handleLogout}
            >
              Sign in as a Teacher
            </button>
          </div>
        </div>
      </section>
    );
  };

  const buildContent = () => {
    return (
      <div className='co-teamsStudent-container' >
        <main className='co-teamsStudent'>
          {buildText()}
          <section className='co-teamsStudent-image'>
            <StudentSitting className='co-teamsStudent-foreground' />
          </section>
        </main>
      </div>
    );
  };

  return (
    <>
      <div className={sizeClasses} >
        {buildHeader()}
        {buildContent()}
      </div>
    </>
  );
};

StudentView.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  browser: PropTypes.object.isRequired,
  clearCurrentUser: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  browser: state.browser,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  clearCurrentUser,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(StudentView);
