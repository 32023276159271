import {
  FETCH_WEBZONE,
  RECEIVE_WEBZONE,
  CLEAR_WEBZONE,
  UPDATE_WEBZONE_BROWSING,
} from './actions';

export const defaultState = {
  isLoading: false,
  current: null,
};

export const webzonesReducer = (state = defaultState, action) => {
  switch (action.type) {
    case FETCH_WEBZONE:
      return {
        ...state,
        isLoading: true,
        current: null,
      };
    case RECEIVE_WEBZONE:
      return {
        ...state,
        isLoading: false,
        current: action.webzone,
      };
    case UPDATE_WEBZONE_BROWSING:
      return {
        ...state,
        current: { ...state.current, noBrowsing: action.noBrowsing },
      };
    case CLEAR_WEBZONE:
      return defaultState;
    default:
      return state;
  }
};
