import { addError } from '../errors/actions';

import { filterBrowsingHistory } from '../../utilities/urls';

import { ClassroomApi } from '../../helpers/classroomApi';

export const GET_BROWSING_HISTORY = 'GET_BROWSING_HISTORY';
export const RECEIVE_BROWSING_HISTORY = 'RECEIVE_BROWSING_HISTORY';
export const BROWSING_HISTORY_ERROR = 'BROWSING_HISTORY_ERROR';
export const RESET_BROWSING_HISTORY = 'RESET_BROWSING_HISTORY';

export function getBrowsingHistory(guid, startTime, endTime) {
  return async function(dispatch, getState) {
    const state = getState();
    const groupGuid = state?.groups?.loadedGuid;

    dispatch({ type: GET_BROWSING_HISTORY, guid, startTime, endTime });

    const response = await ClassroomApi.get(getBrowsingHistoryUrl(guid, startTime, endTime, groupGuid));

    if (response?.status !== 200) {
      dispatch(browsingHistoryError('Failed getBrowsingHistory'));
      return dispatch(addError('getBrowsingHistory', response));
    }

    const reports = filterBrowsingHistory(response.data);
    return dispatch(receiveBrowsingHistory(reports));
  };
}

function receiveBrowsingHistory(browsingHistory) {
  return {
    type: RECEIVE_BROWSING_HISTORY,
    browsingHistory,
  };
}

function browsingHistoryError(error) {
  return {
    type: BROWSING_HISTORY_ERROR,
    error,
  };
}

export function resetBrowsingHistory() {
  return {
    type: RESET_BROWSING_HISTORY,
  };
}

function getBrowsingHistoryUrl(guid, startTime, endTime, groupGuid) {
  const queryParams = [
    `start=${startTime}`,
    `end=${endTime}`,
  ];

  if (groupGuid) {
    queryParams.push(`group_guid=${groupGuid}`);
  }

  return `/users/${guid}/browsing?` + queryParams.join('&');
}

export function getOldBrowsingHistory(email, startTime, endTime) {
  return async function(dispatch) {
    dispatch({ type: GET_BROWSING_HISTORY, email, startTime, endTime });

    const response = await ClassroomApi.get(getOldBrowsingHistoryUrl(email, startTime, endTime));

    if (response?.status !== 200) {
      dispatch(browsingHistoryError('Failed getBrowsingHistory'));
      return dispatch(addError('getOldBrowsingHistory', response));
    }

    const reports = filterBrowsingHistory(response.data);
    return dispatch(receiveBrowsingHistory(reports));
  };
}

function getOldBrowsingHistoryUrl(email, startTime, endTime) {
  const perPage = 50;
  const page = 1;
  const queryParams = [
    `username=${email}`,
    `start_date=${startTime}`,
    `end_date=${endTime}`,
    `limit=${perPage}`,
    `offset=${(page - 1) * perPage}`,
  ];

  return '/users/hostDetail?' + queryParams.join('&');
}
