import { removeProperty } from '../../utilities/types';

import {
  INIT_ABLY,
  SHUTDOWN_ABLY,
  SET_ABLY_STATUS,
  ADD_CHANNEL,
  REMOVE_CHANNEL,
  UPDATE_CHANNEL_DATA,
} from './actions';

export const defaultState = {
  customerId: '',
  clientId: '',
  client: null,
  status: '',
  channels: {},
  data: {},
};

export function ablyReducer(state = defaultState, action) {
  switch (action.type) {
    case INIT_ABLY:
      return {
        ...state,
        customerId: action.customerId,
        clientId: action.clientId,
        client: action.client,
      };
    case SHUTDOWN_ABLY:
      return {
        ...state,
        customerId: '',
        clientId: '',
        client: null,
      };
    case SET_ABLY_STATUS:
      return {
        ...state,
        status: action.status,
      };
    case ADD_CHANNEL:
      return {
        ...state,
        channels: {
          ...state.channels,
          [action.name]: action.channel,
        },
      };
    case REMOVE_CHANNEL: {
      const channels = removeProperty(state.channels, action.name);
      const data = removeProperty(state.data, action.name);
      return {
        ...state,
        channels,
        data,
      };
    }
    case UPDATE_CHANNEL_DATA: {
      const original = state.data[action.name] ?? {};
      const updated = {
        ...original,
        [action.key]: action.value,
      };

      return {
        ...state,
        data: {
          ...state.data,
          [action.name]: updated,
        },
      };
    }
    default:
      return state;
  }
}
