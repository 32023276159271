import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';


const Errors = ({ errors }) => {
  return (
    <section className='hidden'>
      <div
        data-testid='errors-data'
        data-json={JSON.stringify(errors)}
      >
      </div>
    </section>
  );
};

Errors.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const mapStateToProps = (state) => ({
  errors: state.errors.all,
});

export default connect(mapStateToProps)(Errors);
