export function isClassroomEnabled(policy) {
  if (policy && (typeof policy.enable_classroom !== 'undefined')) {
    return policy.enable_classroom;
  }
  return true;
}

export function isOnCampusOnly(policy) {
  if (policy && (typeof policy.on_campus_only !== 'undefined')) {
    return policy.on_campus_only;
  }
  return false;
}

export function maximumPeriodDuration(policy) {
  if (policy && (typeof policy.max_period_duration !== 'undefined')) {
    return parseInt(policy.max_period_duration, 10);
  }
  return 60;
}

export function allScheduleTypes() {
  return ['bell', 'custom', 'ad_hoc'];
}

export function validScheduleTypes(policy, isAdmin) {
  if (isAdmin) {
    return allScheduleTypes();
  }

  let valid = allScheduleTypes();
  if (policy && (typeof policy.editable_schedules !== 'undefined')) {
    valid = policy.editable_schedules;
  }
  if (policy && (!Array.isArray(policy.bell_schedules) || (policy.bell_schedules.length === 0))) {
    valid = valid.filter((type) => type !== 'bell');
  }
  return valid;
}

export function canEditSchedule(policy, isAdmin) {
  if (isAdmin) {
    return true;
  }

  if (policy && (typeof policy.can_edit_schedule !== 'undefined')) {
    return policy.can_edit_schedule;
  }
  return true;
}

export function canAddStudentsByEmail(policy) {
  if (policy && (typeof policy.add_students_by_email !== 'undefined')) {
    return policy.add_students_by_email;
  }
  return false;
}

export function canCreateClasses(policy) {
  if (policy && (typeof policy.manual_classes !== 'undefined')) {
    return policy.manual_classes;
  }
  return false;
}

export function canViewAllScreens(policy) {
  if (policy && (typeof policy.can_view_all_screens !== 'undefined')) {
    return policy.can_view_all_screens;
  }
  return true;
}

export function canViewBrowsingHistory(policy) {
  if (policy && (typeof policy.view_browsing_history !== 'undefined')) {
    return policy.view_browsing_history;
  }
  return true;
}

export function canRecordScreens(policy) {
  if (policy && (typeof policy.record_screens !== 'undefined')) {
    return policy.record_screens;
  }
  return true;
}

export function canLaunchVideoChat(policy) {
  if (policy && (typeof policy.launch_video_chat !== 'undefined')) {
    return policy.launch_video_chat;
  }
  return false;
}

export function canSendCustomMessage(policy) {
  if (policy && (typeof policy.custom_messages !== 'undefined')) {
    return policy.custom_messages;
  }
  return false;
}

export function videoChatApps(policy) {
  if (policy && policy.video_chat_apps) {
    return policy.video_chat_apps;
  }
  return [];
}
