import React from 'react';
import PropTypes from 'prop-types';

import I18n from '../../utilities/i18n';

import { DrawerEmptyIcon } from 'design-system';

const EmptyState = ({
  message,
  children,
}) => {
  return (
    <div className='co-emptyState'>
      <DrawerEmptyIcon className='co-emptyState-icon'/>
      <span className='co-emptyState-noData'>
        {message ? message : I18n.t('no_data_to_display')}
      </span>
      {children}
    </div>
  );
};

EmptyState.propTypes = {
  message: PropTypes.string,
  children: PropTypes.any,
};

export default EmptyState;
