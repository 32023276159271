import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import I18n from '../../utilities/i18n';
import Selector from './Selector';
import { getDefaultSelection } from '../../utilities/groups';


const SegmentSelector = ({
  currentSegments,
  selectedSegments,
  setSelectedSegments,
}) => {
  const allStudents = 'all';
  const defaultGroup = 'default';

  useEffect(() => {
    setSelectedSegments(getDefaultSelection(currentSegments));
  }, [currentSegments, setSelectedSegments]);

  const defaultItems = [
    {
      label: I18n.t('default_group'),
      id: defaultGroup,
    },
  ];

  if (currentSegments.length > 0) {
    // only show 'All Students' as option if there is at least one segment
    defaultItems.unshift({
      label: I18n.t('all_students'),
      id: allStudents,
    });
  } 

  const items = currentSegments.reduce((items, segment) => {
    return [
      ...items,
      {
        label: segment.name,
        id: segment.guid,
      },
    ];
  }, defaultItems);

  return (
    <Selector
      items={items}
      selectedIds={selectedSegments}
      radioButton={true}
      onSelection={(selection) => setSelectedSegments(selection)}
      containerClasses='co-selector--sendLink'
    />
  );

};

SegmentSelector.propTypes = {
  currentSegments: PropTypes.array.isRequired,
  selectedSegments: PropTypes.array.isRequired,
  setSelectedSegments: PropTypes.func.isRequired,
};

export default SegmentSelector;
