// Copied over from the Classroom Chrome extension
// used to create teacher screen share without the extension

export default class CoRTCConnection {
  constructor(args) {
    let servers = args.iceServers;
    if (typeof(args) === 'object') {
      if (typeof(args.customIce) !== 'undefined') {
        if (!Array.isArray(args.customIce)) {
          throw new Error('custom ice must be an array');
        }
        this.customIce = args.customIce;
        servers = this.customIce;
      }
    } else if (typeof(args) !== 'undefined') {
      throw new Error('args must be an object when provided');
    }
    this.ice = [];
    this.onIceCallbacks = [];
    this.onOfferCallbacks = [];
    this.haveAllIce = false;
    if (typeof(RTCPeerConnection) !== 'undefined') {
      this.rtc = new RTCPeerConnection({
        iceServers: servers,
      });
      this.rtc.onicecandidate = this.onIce.bind(this);
    }
  }

  createOffer() {
    // Passthrough
    return this.rtc.createOffer();
  }

  onIce(event) {
    if (typeof(event) === 'undefined') {
      throw new Error('rtc event required');
    }
    if (event.candidate) {
      this.haveAllIce = false;

      this.ice.push(event.candidate);
      this.onIceCallbacks.forEach((callback) => {
        callback(event.candidate);
      });
    } else {
      this.haveAllIce = true;
      this.onIceCallbacks.forEach((callback) => {
        callback(null);
      });
    }
  }

  setStream(stream) {
    if (typeof(stream) !== 'object') {
      throw new Error('stream must be an object');
    }
    this.stream = stream;
    if (typeof(this.rtc) !== 'undefined') {
      this.rtc.addStream(stream);
    }
  }

  setOffer(offer) {
    if (typeof(offer) !== 'object') {
      throw new Error('offer must be an object');
    }
    if (typeof(this.rtc) !== 'undefined') {
      this.rtc.setLocalDescription(offer);
      this.offer = offer;
      this.onOfferCallbacks.forEach((callback) => {
        callback();
      });
      this.onOfferCallbacks = [];
    }
  }

  setAnswer(answer) {
    if (typeof(answer) === 'undefined') {
      throw new Error('answer required');
    }
    this.rtc.setRemoteDescription(answer);
  }

  addIce(ice) {
    if (typeof(ice) === 'undefined') {
      throw new Error('ice required');
    }
    this.rtc.addIceCandidate(ice);
  }

  addIceCallback(callback) {
    if (typeof(callback) !== 'function') {
      throw new Error('callback function requred');
    }
    this.onIceCallbacks.push(callback);
  }
}
