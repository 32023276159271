import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import validUrl from 'valid-url';
import { addInsightSharedLink } from '../../redux/insights/actions';
import { sendLink } from '../../redux/ably/actions';
import { 
  getCurrentGroup, 
  getDefaultSelection, 
  getSelectedSegmentEmails, 
} from '../../utilities/groups';
import I18n from '../../utilities/i18n';
import Modal from '../common/Modal';
import Fade from '../common/animations/Fade';
import SegmentSelector from '../common/SegmentSelector';
import { closeModal } from '../../redux/ui/actions';


const SendLink = ({
  currentGroup,
  currentSegments,
  currentlyOpenModalSegment,
  allUsers,
  sendLink,
  addInsightSharedLink,
  currentlyOpenModal,
  closeModal,
  parentGuid,
}) => {
  const sendLinkModal = 'sendLinkSegments';
  const [url, setUrl] = useState('');
  const [error, setError] = useState('');
  const [selectedSegments, setSelectedSegments] = 
    useState(getDefaultSelection(currentSegments, currentlyOpenModalSegment));

  useEffect(() => {
    setSelectedSegments(getDefaultSelection(currentSegments, currentlyOpenModalSegment));
  }, [currentlyOpenModalSegment]);

  function saveHandler() {
    let newUrl = url;
    if (!url.match('^https://') && !url.match('^http://')) {
      newUrl = `https://${url}`;
    } 

    if (validUrl.isUri(newUrl)) {
      const emails = getSelectedSegmentEmails(allUsers, selectedSegments, currentSegments);
      for (const email of emails) {
        sendLink(newUrl, email);
      }
      addInsightSharedLink(currentGroup.guid, newUrl);

      setUrl('');
      closeModal();
    } else {
      setError(I18n.t('please_enter_a_valid_url'));
    }
  }

  const buildSegmentSelector = () => {
    if (currentlyOpenModalSegment !== 'classControls') {
      // navigated from specific segment options
      return null;
    }

    return (
      <SegmentSelector
        currentSegments={currentSegments}
        selectedSegments={selectedSegments}
        setSelectedSegments={setSelectedSegments}
      />
    );
  };
  return (
    <Modal
      show={currentlyOpenModal === sendLinkModal && currentlyOpenModalSegment === parentGuid}
      title={I18n.t('send_link')}
      closeModal={closeModal}
      saveHandler={saveHandler}
      saveText={I18n.t('send')}
      modalRedesign2022Q1
      isSmall
      legacyModalVersion
    >
      <section className='co-nav-shareLink'>
        <input
          type='text'
          name='sendLink'
          onChange={(e) => {
            setError('');
            setUrl(e.target.value);
          }}
          onKeyDown={(e) => {
            const eventCode = e.charCode || e.keyCode;
            eventCode === 13 && saveHandler();
          }}
          className='co-nav-shareLink-input co-nav-shareLink-input--wide'
          value={url}
          autoFocus
          data-testid='sendLink-input'
        />

        <p className='text--gray text--small m-bottom--0 m-top--4'>
          {I18n.t('the_link_will_open_in_a_new_tab')}
        </p>
        {buildSegmentSelector()}
        <Fade condition={Boolean(error)}>
          <div className='alert alert--error m-top--8'>
            <span className='alert--error-errorList'>
              {error}
            </span>
          </div>
        </Fade>
      </section>
    </Modal>
  );
};

SendLink.propTypes = {
  currentGroup: PropTypes.object.isRequired,
  currentSegments: PropTypes.arrayOf(PropTypes.object).isRequired,
  allUsers: PropTypes.object.isRequired,
  sendLink: PropTypes.func.isRequired,
  addInsightSharedLink: PropTypes.func.isRequired,
  currentlyOpenModal: PropTypes.string,
  currentlyOpenModalSegment: PropTypes.string,
  parentGuid: PropTypes.string.isRequired,
  closeModal: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  currentGroup: getCurrentGroup(state.groups),
  currentSegments: state.groupSegments.segments,
  currentlyOpenModal: state.ui.currentlyOpenModal,
  currentlyOpenModalSegment: state.ui.currentlyOpenModalSegment,
  allUsers: state.users.all,
  browser: state.browser,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  sendLink,
  addInsightSharedLink,
  closeModal,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SendLink);
