import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import I18n from '../../../utilities/i18n';

import TaskStatusIcon from '../TaskStatusIcon';

import RadioGroupExpander from './RadioGroupExpander';
import RadioTab from './RadioTab';

const TASK_STATUSES = ['working', 'need_help', 'done'];

const TaskStatusFilterList = ({ taskStatus, selectedFilter, onTabClick }) => {
  const savedExpandedOption = localStorage.getItem(I18n.t('status'));
  const filterList = useMemo(() => (
    TASK_STATUSES.map((status) => ({
      status,
      count: taskStatus[status].length,
      isSelected: selectedFilter === status,
      isDisabled: !taskStatus[status].length,
      onClick: () => onTabClick(status),
    }))
  ), [taskStatus, selectedFilter, onTabClick]);

  return (
    <RadioGroupExpander
      label={I18n.t('task_status')}
      defaultExpanded={savedExpandedOption ? savedExpandedOption === 'true' : true}
      optionalBadgeList={filterList}
    >
      {
        filterList.map(({ status, count, isSelected, isDisabled, onClick }) => (
          <RadioTab
            key={status}
            value={status}
            count={count}
            isSelected={isSelected}
            isDisabled={isDisabled}
            onClick={onClick}
          >
            <div className='co-taskStatusFilterList-label' data-testid='statusFilter-container'>
              <TaskStatusIcon status={status} isDisabled={isDisabled} />
              <span>{I18n.t(status)}</span>
            </div>
          </RadioTab>
        ))
      }
    </RadioGroupExpander>
  );
};

TaskStatusFilterList.propTypes = {
  taskStatus: PropTypes.object,
  selectedFilter: PropTypes.string,
  onTabClick: PropTypes.func,
};

export default TaskStatusFilterList;
