/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

import I18n from '../../utilities/i18n';

import { InfoIcon, SuccessIcon, CloseIcon } from 'design-system';



const Notification = ({
  message,
  removeNotification,
}) => {
  const { content, options } = message;
  // TODO: Add case for error notification
  const buildHeader = () => {
    switch (options.notificationType) {
      case 'success':
        return <><SuccessIcon className='CeleritasNotification-heading-icon CeleritasAlert-heading-toast--success' style={{ color: '#15655C' }} /> <span className='CeleritasNotification-heading--toast--success'>Success</span></>;
      case 'info':
      default:
        return <><InfoIcon className='CeleritasNotification-heading-icon' />Info</>;
    }
  };
  const buildControls = () => {
    let controls;

    if (options.autoDismiss) {
      setTimeout(removeNotification.bind(null, content), 5000);
      return null;
    }

    //if customActions is passed in, use those and not the default view/dismiss
    if (options.action && options.actionName) {
      controls = (
        <button
          className='flashNotice-button'
          onClick={options.action}
          data-testid='notification-action'
        >
          {I18n.t(options.actionName)}
        </button>
      );
    } else if (content) {
      controls = (
        <button
          className='CeleritasNotification-closeButton CeleritasNotificationButton CeleritasNotificationButton--plain'
          onClick={removeNotification.bind(null, content)}
          data-testid='notification-content'
        >
          <CloseIcon />
        </button>
      );
    }

    return (options.autoDismiss ? setTimeout(removeNotification.bind(null, content), 5000) : () => {}) && controls && (<div className='flashNotice-buttonContainer'> {controls} </div>);
  };

  return (
    <div className='CeleritasToastNotification'>
      <section className='CeleritasNotification-content'>
        <header className='CeleritasNotification-header'>
          <div className='CeleritasNotification-heading--toast--default'>
            {buildHeader()}
          </div>
          {buildControls()}
        </header>
        <div className='CeleritasNotification-message' data-testid='notification-flash'>
          {content}
        </div>
      </section>
    </div>
  );
};

Notification.propTypes = {
  message: PropTypes.object,
  removeNotification: PropTypes.func,
};

export default Notification;
