import { getPresenceData, getGroupData } from './ably';
import { isOnCampus } from './ips';

export function isSupportUser(user) {
  return user.guid === '00000000-0000-0000-0000-000000000000' ||
         user.guid === 'ffffffff-ffff-ffff-ffff-ffffffffffff';
}

export function getFullName(user) {
  if (!user) {
    return '';
  }

  return `${user.first_name} ${user.last_name}`;
}

export function getUserGuids(allUsers) {
  if (!allUsers) {
    return [];
  }

  return Object.keys(allUsers);
}

export function onlineUsersAvailableForBulkCheckIn(userInfos, groupGuid) {
  const filteredUserInfos = Object.values(userInfos).filter((userInfo) => {
    return userInfo.online && userInfo.checkedInto.guid !== groupGuid;
  });

  const newFilteredUserInfos = [...filteredUserInfos];

  // checkedIntoName and checkedIntoTeacherName are being added to userInfo
  // so the table in the UI can be sorted.
  newFilteredUserInfos.forEach((userInfo) => {
    userInfo.checkedIntoName = userInfo.checkedInto.name;
    userInfo.checkedIntoTeacherName = userInfo.checkedInto.teacher_name;
  });

  return newFilteredUserInfos;
}

export function getUserEmails(allUsers) {
  if (!allUsers) {
    return [];
  }

  const users = Object.values(allUsers);
  return users.map((user) => user.email);
}

export function getOnCampusUserEmails(guids, allUsers, ablyData, ips) {
  let userInfos = getUserInfos(guids, allUsers, ablyData, ips);
  userInfos = userInfos.filter((userInfo) => !userInfo.offCampus);

  return userInfos.map((userInfo) => userInfo.email);
}

export function getUserByEmail(allUsers, email) {
  const users = Object.values(allUsers);

  return users.find((user) => user.email === email);
}

export function getUserHosts(userInfos) {
  const hostsByLabel = userInfos.reduce((hosts, userInfo) => {
    if (userInfo.online && userInfo.guid && userInfo.currentHost) {
      const label = userInfo.currentHost;
      if (!hosts[label]) {
        hosts[label] = { label, count: 0, users: [] };
      }
      hosts[label].count += 1;
      hosts[label].users.push(userInfo.guid);
    }
    return hosts;
  }, []);

  return Object.values(hostsByLabel);
}

export function getEmptyHost(label) {
  return {
    label,
    count: 0,
    users: [],
  };
}

export function getEmptyStatusesCI() {
  return {
    working: [],
    need_help: [],
    done: [],
  };
}

// remove when beta_classroom_check_ins is GA
export function getEmptyStatuses() {
  return {
    present: [],
    need_help: [],
    done: [],
    away: [],
  };
}

export function getEmptyAttendences() {
  return {
    checked_in: [],
    not_checked_in: [],
    offline: [],
  };
}

export function getUserStatusesCI(userInfos) {
  return userInfos.reduce((statuses, userInfo) => {
    if (userInfo && userInfo.guid) {
      if (userInfo.online && userInfo.state) {
        const key = userInfo.state.replace(' ', '_');
        if (statuses[key]) {
          statuses[key].push(userInfo.guid);
        }
      }
    }
    return statuses;
  }, getEmptyStatusesCI());
}

// remove when beta_classroom_check_ins is GA
export function getUserStatuses(userInfos) {
  return userInfos.reduce((statuses, userInfo) => {
    if (userInfo && userInfo.guid) {
      if (userInfo.online && userInfo.state) {
        const key = userInfo.state.replace(' ', '_');
        if (statuses[key]) {
          statuses[key].push(userInfo.guid);
        }
      }

      if (userInfo.online) {
        statuses.present.push(userInfo.guid);
      } else {
        statuses.away.push(userInfo.guid);
      }
    }
    return statuses;
  }, getEmptyStatuses());
}

export function getUserAttendences(userInfos, currentGroupGuid) {
  return userInfos.reduce((attendences, userInfo) => {
    if (userInfo && userInfo.guid) {
      let key;
      if (!userInfo.online) {
        key = 'offline';
      } else if (userInfo.checkedInto?.guid === currentGroupGuid) {
        key = 'checked_in';
      } else {
        key = 'not_checked_in';
      }
      if (attendences[key]) {
        attendences[key].push(userInfo.guid);
      } else {
        attendences[key] = [userInfo.guid];
      }
    }
    return attendences;
  }, getEmptyAttendences());
}

export function getUserInfos(guids, allUsers, ablyData, ips) {
  return guids.map((guid) => getUserInfo(guid, allUsers, ablyData, ips));
}

export function getAttendanceStatus (user, group) {
  if (!user || !group || !user.online) {
    return 'offline';
  }
  if (user.checkedInto.guid === group.guid) {
    return 'checked_in';
  }
  return 'not_checked_in';
}

export function getUserInfosWithStatuses(users, currentGroup) {
  const statuses = users.map((user) => {
    const userStatus = getAttendanceStatus(user, currentGroup);
    const updatedUserInfo = { ...user, checkInStatus: userStatus };
    return updatedUserInfo;
  });

  return statuses;
}

export function getUserInfo(guid, allUsers, ablyData, ips) {
  const user = allUsers[guid] || {};
  const email = user.email || '';

  const checkedInto = user.checkedInto || '';
  const groupData = getGroupData(ablyData, email);
  const presenceData = getPresenceData(ablyData, email, email);

  const firstName = user.first_name || '';
  const lastName = user.last_name || '';
  const fullName = `${firstName} ${lastName}`;
  const initials = getUserInitials(user);
  const firstNameLastInitial = getFirstNameLastInitial(user);

  const ipAddress = presenceData.ip || null;
  const offCampus = !isOnCampus(ipAddress, ips);

  const online = Boolean(presenceData.state) && !offCampus;
  const recording = Boolean(presenceData.recording);
  const state = (online && presenceData.state) || null;
  const currentHost = (online && groupData.currentHost) || null;
  const favico = (online && groupData.favico) || null;
  const top = (online && groupData.top) || null;
  const visits = (online && groupData.visits) || null;

  const activeWindowActiveTabUrl = (online && groupData.activeWindowActiveTabUrl) || null;

  return {
    activeWindowActiveTabUrl,
    checkedInto,
    email,
    guid,
    firstName,
    lastName,
    fullName,
    initials,
    firstNameLastInitial,
    ipAddress,
    offCampus,
    online,
    recording,
    state,
    currentHost,
    favico,
    top,
    visits,
  };
}

export function findHighestVisits(userInfos) {
  return userInfos.reduce((highest, user) => {
    if (user.visits && user.visits > highest) {
      return user.visits;
    }
    return highest;
  }, 0);
}

export function getUserInfoCompare(key, isAscending = true) {
  return (a, b) => {
    if (!a.hasOwnProperty(key) && !b.hasOwnProperty(key)) { // eslint-disable-line no-prototype-builtins
      return 0;
    }

    const keyA = a[key];
    const keyB = b[key];

    // Always sort falsy values last
    if (!keyA && !keyB) {
      return 0;
    }
    if (!keyA) {
      return 1;
    }
    if (!keyB) {
      return -1;
    }

    let comparison = 0;
    if ((typeof keyA === 'string') && (typeof keyB === 'string')) {
      comparison = keyA.localeCompare(keyB);
    } else if ((typeof keyA === 'number') && (typeof keyB === 'number')) {
      comparison = keyA - keyB;
    }

    return isAscending ? comparison : -comparison;
  };
}

export function getUserInitials(user) {
  const firstName = user && user.first_name;
  const lastName = user && user.last_name;
  const firstInitial = firstName ? firstName.substring(0, 1) : '-';
  const lastInitial = lastName ? lastName.substring(0, 1) : '-';
  return `${firstInitial}${lastInitial}`;
}

export function getFirstNameLastInitial(user) {
  if (user && user.first_name && user.last_name) {
    const lastInitial = user.last_name.substring(0, 1);
    return `${user.first_name} ${lastInitial}.`;
  }

  return '-';
}

export function getFirstInitialLastName(user) {
  const name = user;
  if (!name) {
    return '-';
  }
  const [firstName, lastName] = name.split(' ');
  return `${firstName[0]} ${lastName}`;
}

export function isInHomeDistrict(currentUser) {
  if (!currentUser || !currentUser.customer_id || !currentUser.auth_customer_id) {
    return false;
  }
  return currentUser.customer_id === currentUser.auth_customer_id;
}

export function canRemoveGroup(group) {
  const isClassroomGroup = group?.group_type === 'classroom_group';
  const isManualClassroom = group?.type === 'ManualClassroom';

  return isClassroomGroup || isManualClassroom;
}

export function hasBeta(name, districtBetas, userBetas) {
  return hasBetaFeature(name, districtBetas) || hasBetaFeature(name, userBetas);
}

export function hasBetaFeature(name, betaFeatures) {
  const everyoneGets = [
    'test_name',
  ];
  if (everyoneGets.includes(name)) {
    return true;
  }

  const fullName = `beta_${name}`;
  const value = betaFeatures[fullName];

  if (typeof value === 'string' && value === 'true') {
    return true;
  } else if (typeof value === 'boolean' && value) {
    return true;
  }
  return false;
}

export function byKey(key) {
  return (a, b) => {
    const valueA = a && a[key];
    const valueB = b && b[key];
    if (typeof valueA === 'string') {
      return valueA.localeCompare(valueB);
    } else if (typeof valueB === 'string') {
      return -valueB.localeCompare(valueA);
    }
    return 0;
  };
}

export function getEmailsFromGuids(allUsers, guids) {
  return guids.map((guid) => {
    if (guid in allUsers) {
      return allUsers[guid].email;
    }
    return '';
  });
}

export function combineUserData(users, classroomUsers) {
  return Object.values(users).map((user) => {
    const userMatch = classroomUsers.find((classroomUser) => classroomUser.user_guid === user.guid);
    if (userMatch) {
      user.checkedInto = {
        guid: userMatch.checked_in_guid,
        name: userMatch.checked_in_name,
        teacher_name: userMatch.checked_in_teacher,
        expiration: userMatch.checked_in_expire,
      };
    } else {
      user.checkedInto = {};
    }
    return user;
  });
}

export function getGuidsToCheckIn(users, groupGuid, newExpiration) {
  const currentTime = Date.now();
  const oneMinute = 1000 * 60;
  const guids = [];
  const notExpired = (user) => user.checkedInto.expiration !== newExpiration;
  Object.values(users).forEach((user) => {
    // check if users need to refresh expiration for current class
    if (user.checkedInto?.guid === groupGuid && notExpired(user)) {
      guids.push(user.guid);
    }
    if (!user.checkedInto?.guid || user.checkedInto?.expiration - currentTime < oneMinute) {
      // no check in conflict
      guids.push(user.guid);
    }
  });
  return guids;
}

export function getNameWithPossession(name) {
  if (typeof name !== 'string') {
    return '';
  }
  if (name.endsWith('s')) {
    return name + '\'';
  }
  return name + '\'s';
}

export function getNotificationSettings() {
  const showNotifications = JSON.parse(window.localStorage.getItem('showNotifications'));
  if (showNotifications === null) {
    window.localStorage.setItem('showNotifications', true);
    return true;
  }
  return showNotifications;
}
