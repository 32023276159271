import { getNotificationSettings } from '../../utilities/users';
import {
  ADD_NOTIFICATION,
  REMOVE_NOTIFICATION,
  OPEN_MODAL,
  CLOSE_MODAL,
  OPEN_DROPDOWN,
  CLOSE_DROPDOWN,
  OPEN_FLYOUT,
  CLOSE_FLYOUT,
  RESET_UI,
  BROWSING_TIMEOUT,
  OPEN_SUB_FLYOUT,
  CLOSE_SUB_FLYOUT,
  GET_WEB_RULES_SECTION,
  GET_CURRENT_SEGMENT_GUID,
  EXPAND_NAVIGATION,
  COLLAPSE_NAVIGATION,
} from './actions';


export const defaultState = {
  messages: [],
  currentlyOpenModal: null,
  currentlyOpenDropdown: null,
  currentlyOpenFlyout: null,
  currentlyOpenModalSegment: null,
  browsingTimeout: true,
  currentlyOpenSubFlyout: null,
  currentWebRulesSection: null,
  currentSegmentGuid: null,
  navigationCollapsed: false,
};

export function uiReducer(state = defaultState, action) {
  switch (action.type) {
    case ADD_NOTIFICATION: {
      const showNotifications = getNotificationSettings();
      const messageExists = state.messages.some((m) => m.stringifiedContent === action.stringifiedContent);
      if (!showNotifications) {
        return state;
      }
      if (!messageExists) {
        const message = {
          content: action.content,
          stringifiedContent: action.stringifiedContent,
          options: action.options,
        };
        return {
          ...state,
          messages: [...state.messages, message],
        };
      }
      return state;
    }
    case REMOVE_NOTIFICATION: {
      const messages = state.messages.filter((m) => m.stringifiedContent !== action.stringifiedContent);
      return {
        ...state,
        messages,
      };
    }
    case OPEN_MODAL: {
      return {
        ...state,
        currentlyOpenModal: action.modalName,
        currentlyOpenModalSegment: action.modalSegment,
      };
    }
    case CLOSE_MODAL: {
      return {
        ...state,
        currentlyOpenModal: null,
        currentlyOpenModalSegment: null,
      };
    }
    case OPEN_DROPDOWN: {
      return {
        ...state,
        currentlyOpenDropdown: action.dropdownName,
      };
    }
    case CLOSE_DROPDOWN: {
      const name = (action.dropdownName === state.currentlyOpenDropdown) ? null : state.currentlyOpenDropdown;
      return {
        ...state,
        currentlyOpenDropdown: name,
      };
    }
    case OPEN_FLYOUT: {
      return {
        ...state,
        currentlyOpenFlyout: action.flyoutName,
      };
    }
    case CLOSE_FLYOUT: {
      return {
        ...state,
        currentlyOpenFlyout: null,
      };
    }
    case BROWSING_TIMEOUT: {
      return {
        ...state,
        browsingTimeout: action.browsingTimeout,
      };
    }
    case RESET_UI: {
      return defaultState;
    }
    case OPEN_SUB_FLYOUT: {
      return {
        ...state,
        currentlyOpenSubFlyout: action.subFlyoutName,
      };
    }
    case CLOSE_SUB_FLYOUT: {
      return {
        ...state,
        currentlyOpenSubFlyout: null,
      };
    }
    case GET_WEB_RULES_SECTION: {
      return {
        ...state,
        currentWebRulesSection: action.sectionName,
      };
    }
    case GET_CURRENT_SEGMENT_GUID: {
      return {
        ...state,
        currentSegmentGuid: action.segmentGuid,
      };
    }
    case EXPAND_NAVIGATION: {
      return {
        ...state,
        navigationCollapsed: false,
      };
    }
    case COLLAPSE_NAVIGATION: {
      return {
        ...state,
        navigationCollapsed: true,
      };
    }
    default: {
      return state;
    }
  }
}
