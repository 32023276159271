import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { getBellPeriods } from '../../../utilities/schedule';

import Fade from '../../common/animations/Fade';
import Icon from '../../common/Icon';


const BellSchedule = ({
  bellSchedule,
  schedule,
  togglePeriod,
  readOnly,
}) => {
  const daysKey = Object.keys(bellSchedule.schedule)[0];

  const isPeriodSelected = (period, schedule) => {
    if (!period || !schedule) {
      return false;
    }

    const periodTimes = period.schedule ? period.schedule[daysKey] : {};
    const classTimes = schedule[daysKey];

    if (!periodTimes || !classTimes) {
      return false;
    }
    return (periodTimes.s === classTimes.s) && (periodTimes.e === classTimes.e);
  };

  const buildDaysLabel = () => {
    const dayAbbr = {
      0: 'SUN',
      1: 'MON',
      2: 'TUE',
      3: 'WED',
      4: 'THU',
      5: 'FRI',
      6: 'SAT',
    };

    return daysKey.split('').map((day) => dayAbbr[day]).join(', ');
  };

  const buildSelected = () => {
    const indicatorClasses = classNames({
      'co-bellSchedule-timesSelected': true,
      'co-bellSchedule-timesSelected--disabled': readOnly,
    });

    return (
      <div className={indicatorClasses}>
        <span className='co-bellSchedule--timesSelected-iconContainer'>
          <Icon name='icon-checkmark-solid' classes='co-bellSchedule-timesSelected-icon' />
        </span>
      </div>
    );
  };

  const buildPeriods = () => {
    const periods = getBellPeriods(bellSchedule);

    return periods.map((period) => {
      const isSelected = isPeriodSelected(period, schedule);
      const isDismissal = period.schedule === null;
      const clickHandler = (isDismissal || readOnly) ? null : togglePeriod.bind(null, period.schedule);
      const buttonClasses = classNames({
        'button-link': true,
        'co-bellSchedule-timesButton': true,
        'co-bellSchedule-timesButton--selected': isSelected,
        'co-bellSchedule-timesButton--noHover': isDismissal || readOnly,
      });

      return (
        <li key={`${period.name}${period.time}`} className='co-bellSchedule-times'>
          <button
            className={buttonClasses}
            onClick={clickHandler}
            data-testid='bellSchedule-time-button'
          >
            <Fade condition={isSelected}>
              {buildSelected()}
            </Fade>
            <div className='l-flex'>
              <strong className='co-bellSchedule-timesLabel'>
                {period.name}
              </strong>
              <div className='co-bellSchedule-timesValue'>
                {period.time}
              </div>
            </div>
          </button>
        </li>
      );
    });
  };

  return (
    <section key={bellSchedule.name}>
      <h3 className='co-bellSchedule-name'>{bellSchedule.name}</h3>
      <div className='co-bellSchedule-days'>
        {buildDaysLabel()}
      </div>
      <ul>
        {buildPeriods()}
      </ul>
    </section>
  );
};

BellSchedule.propTypes = {
  bellSchedule: PropTypes.object.isRequired,
  schedule: PropTypes.object,
  togglePeriod: PropTypes.func.isRequired,
  readOnly: PropTypes.bool.isRequired,
};

export default BellSchedule;
