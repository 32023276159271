import {
  RECEIVE_INVITES,
  REMOVE_INVITE,
  REMOVE_ALL_INVITES,
  CREATE_INVITE,
  SET_INVITE_CREATED,
  SET_INVITE_ERROR,
  SET_INVITE_CODE,
} from './actions';

export const defaultState = {
  all: [],
  isCreating: false,
  wasCreated: false,
  error: '',
  inviteCode: '',
};

export const invitesReducer = (state = defaultState, action) => {
  switch (action.type) {
    case RECEIVE_INVITES:
      return {
        ...state,
        all: action.invites,
      };
    case REMOVE_INVITE: {
      const all = state.all.filter((invite) => {
        if (invite.group_guid) {
          return invite.group_guid !== action.groupGuid || invite.user_guid !== action.userGuid;
        }
        //TODO: remove id checks when cloud_rostering in GA
        return invite.group_id !== action.groupId || invite.user_id !== action.userId;
      });
      return {
        ...state,
        all,
      };
    }
    case REMOVE_ALL_INVITES:
      return {
        ...state,
        all: [],
      };
    case CREATE_INVITE:
      return {
        ...state,
        isCreating: true,
        wasCreated: false,
        error: '',
      };
    case SET_INVITE_CREATED:
      return {
        ...state,
        isCreating: false,
        wasCreated: true,
      };
    case SET_INVITE_ERROR:
      return {
        ...state,
        isCreating: false,
        error: action.error,
      };
    case SET_INVITE_CODE: 
      return {
        ...state,
        inviteCode: action.code,
        error: action.error,
      };
    default:
      return state;
  }
};
