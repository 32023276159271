import categoryList from '../../resources/default_categories.json';

import { addError } from '../errors/actions';

export const CHANGE_INSIGHT_POINT = 'CHANGE_INSIGHT_POINT';
export const ADD_INSIGHT = 'ADD_INSIGHT';
export const REMOVE_INSIGHT = 'REMOVE_INSIGHT';
export const CLEAR_ALL_INSIGHTS = 'CLEAR_ALL_INSIGHTS';
export const SET_INSIGHT_SHARED_HOSTS = 'SET_INSIGHT_SHARED_HOSTS';

export function addInsight(guid, host, state) {
  const user = state.users.all[guid];
  if (!user) {
    return {};
  }

  const insight = {
    firstName: user.first_name,
    lastName: user.last_name,
    email: user.email,
    guid,
    timestamp: new Date(),
    host,
  };

  if (window.pendo && window.pendo.isReady && window.pendo.isReady()) {
    window.pendo.track('Insight Displayed', {
      type: 'Unusual browsing behavior',
    });
  }

  return {
    type: ADD_INSIGHT,
    insight,
  };
}

export function resetInsightPoints(guid) {
  return {
    type: CHANGE_INSIGHT_POINT,
    guid,
    points: 0,
  };
}

export function addInsightPoint(guid, currentPoints) {
  return {
    type: CHANGE_INSIGHT_POINT,
    guid,
    points: currentPoints + 1,
  };
}

export function clearAllInsights() {
  return {
    type: CLEAR_ALL_INSIGHTS,
  };
}

export function removeInsight(guid) {
  return {
    type: REMOVE_INSIGHT,
    guid,
  };
}

function getWebzoneList(webzone) {
  if (webzone) {
    const blocked = Boolean(webzone?.settings?.blocked);
    const sites = webzone?.settings?.sites;

    if (sites && !blocked) {
      return sites.map((s) => s.replace('www.', ''));
    }
  }
  return [];
}

function getSharedList(groups, insights) {
  const guid = groups.currentGuid;
  const insightsGroup = insights.groups[guid];
  const hosts = insightsGroup && insightsGroup.sharedHosts;
  return (hosts || []);
}

function checkIsSafeListed(userHost, safeList) {
  const host = userHost.toLowerCase();
  return Boolean(safeList.find((safeSite) => {
    return host.endsWith(safeSite.toLowerCase());
  }));
}

export function analyzeInsight(guid, currentHost) {
  return async (dispatch, getState) => {
    const state = getState();
    const isOnNewTab = currentHost === 'newtab' || currentHost === 'about:home';
    const existingInsight = state.insights.active.find((insight) => {
      return insight.guid === guid;
    });

    // Bail if insight already exists, user is on a new tab, or has no current host
    if (existingInsight || isOnNewTab || !currentHost) {
      return null;
    }

    // Bail if current host is on any safe list
    const webzoneList = getWebzoneList(state.webzones.current);
    const sharedList = getSharedList(state.groups, state.insights);

    if (checkIsSafeListed(currentHost, webzoneList) || checkIsSafeListed(currentHost, sharedList)) {
      return null;
    }

    let categoryId;
    try {
      categoryId = await window.relayWebSocket.lookupCategory(currentHost, state.currentUser.customer_id);
    } catch (error) {
      dispatch(addError(`Could not lookup host category: ${error}`));
    }

    // Bail if we couldn't get a category id, it's not on our list, or it's an allowed category
    if (!categoryId || !categoryList[categoryId] || categoryList[categoryId].allowed) {
      return null;
    }

    const points = state.insights[guid] ? state.insights[guid].points : 0;
    if (points >= 4) {
      // If there are 5 points, reset and add an Insight
      dispatch(resetInsightPoints(guid));
      dispatch(addInsight(guid, currentHost, state));
    } else {
      dispatch(addInsightPoint(guid, points));
    }
  };
}

export function clearInsightSharedHosts(groupGuid) {
  return (dispatch) => {
    dispatch(setInsightSharedHosts(groupGuid, []));
  };
}

export function addInsightSharedLink(groupGuid, link) {
  return (dispatch, getState) => {
    const addedHost = (new URL(link)).hostname;
    let hosts = [];
    const state = getState();
    const group = state.insights.groups && state.insights.groups[groupGuid];
    if (group && group.sharedHosts) {
      const maxHosts = 20;
      const filtered = group.sharedHosts.filter((h) => h !== addedHost);
      hosts = filtered.slice(0, maxHosts - 1);
    }
    hosts = [...hosts, addedHost];
    dispatch(setInsightSharedHosts(groupGuid, hosts));
  };
}

function setInsightSharedHosts(groupGuid, hosts) {
  return {
    type: SET_INSIGHT_SHARED_HOSTS,
    groupGuid,
    hosts,
  };
}
