import { addError } from '../errors/actions';

import { ClassroomApi } from '../../helpers/classroomApi';
import { fixAllowedHoursObject } from '../../utilities/schedule';

export const RECEIVE_GROUP_POLICY = 'RECEIVE_GROUP_POLICY';
export const RECEIVE_SCHOOL_POLICY = 'RECEIVE_SCHOOL_POLICY';
export const RECEIVE_DISTRICT_POLICY = 'RECEIVE_DISTRICT_POLICY';
export const CLEAR_POLICIES = 'CLEAR_POLICIES';

export function getPolicy(guid, policyType) {
  return async function(dispatch) {
    const response = await ClassroomApi.get(`/classroom_policy?group_guid=${guid}&policy_request_type=${policyType}`);

    if (response?.status === 200) {
      const policy = response.data?.policy ?? {};

      switch (policyType) {
        case 'group':
          // TODO: remove this after granular allowed hours has been in prod for awhile (~ 2022)
          fixAllowedHoursObject(policy.allowed_hours);
          dispatch({ type: RECEIVE_GROUP_POLICY, policy });
          break;
        // the current user's school
        case 'school':
          dispatch({ type: RECEIVE_SCHOOL_POLICY, policy });
          break;
        default:
          break;
      }
    }

    return dispatch(addError(`getPolicy - ${policyType}`, response));
  };
}

export function saveLastSchedule(groupGuid, scheduleType, schedule) {
  return function(dispatch) {
    const validTypes = ['bell', 'custom'];
    if (!validTypes.includes(scheduleType)) {
      return {};
    }

    //TODO This is a hack since policy can not store null, remove once settings are in megatable
    const alteredSchedule = schedule === null ? {} : schedule;

    dispatch(savePolicySetting(groupGuid, `last_${scheduleType}`, alteredSchedule));
  };
}

export function savePolicySetting(groupGuid, settingName, settingValue) {
  return async function(dispatch, getState) {
    const state = getState();
    const group = state.groups.groups.find((group) => group.guid === groupGuid);
    if (!group) {
      return {};
    }

    const data = {
      group_guid: groupGuid,
      group_id: group.id,
      setting_name: settingName,
      setting_value: settingValue,
      restrictive_level: 0,
      locked: false,
    };
    const response = await ClassroomApi.post('/classroom_policy/update_setting', data);

    if (response?.status !== 200) {
      return dispatch(addError('savePolicySetting', response));
    }

    if (!response.data?.policy) {
      return dispatch(addError('savePolicySetting data', response));
    }
  };
}

export function clearPolicies() {
  return {
    type: CLEAR_POLICIES,
  };
}
