import React from 'react';

import I18n from '../../utilities/i18n';

import { ReactComponent as DooStern } from '../../images/dooStern.svg';

const PermissionDenied = () => {
  return (
    <div className='co-errorContainer'>
      <section className='co-error'>
        <DooStern className='co-error-doo'/>
        <div className='co-error-content' data-testid='permissionDenied-content'>
          <p className='co-error-title'>{I18n.t('not_so_fast')}</p>
          <h1 className='co-error-text'>{I18n.t('permission_denied')}</h1>
        </div>
      </section>
    </div>
  );
};

export default PermissionDenied;
