import { hasBetaFeature } from './users';

export const hasGroupBetaAnyGroupTypes = (betaName, betaState) => {
  const hasBetaName = Boolean(Object.entries(betaState).find((betas) => betas[1][betaName]));

  return hasBetaName;
};

// checks for group and user beta types (all stored GlobalClassroom table)
// this keeps our code DRY since this logic is needed in multiple places
export const hasGroupOrUserBetas = (betaName, userBetas, classroomGroupBetas) => {
  const hasUserBetaType = hasBetaFeature(
    betaName,
    userBetas,
  );
  const hasGroupBetaType = hasGroupBetaAnyGroupTypes(
    betaName,
    classroomGroupBetas,
  );
  const hasBeta =
    hasUserBetaType || hasGroupBetaType;

  return hasBeta;
};
