import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import I18n from '../../utilities/i18n';

import Tooltip from '../common/Tooltip/Tooltip';

const AttendanceStatusIndicator = ({
  ariaLabel, 
  className,
  isDisabled,
  status,
  isAboutToDrag,
}) => {
  const divClasses = classNames([
    className,
    'co-indicator',
    `co-indicator--${status}`,
    {
      'co-indicator--disabled': isDisabled,
    },
  ]);
  const statusClasses = classNames({
    'co-indicator-label': status === 'not_checked_in',
  });

  return (
    <div className={divClasses}>
      {ariaLabel && !isAboutToDrag ? (
        <Tooltip
          content={ariaLabel}
          aria-label={ariaLabel} 
          position='top'
          size='notCheckedIn'
        >
          <span className={statusClasses}>
            {I18n.t(status)}
          </span>
        </Tooltip>
      ) : (
        <span className={statusClasses}>
          {I18n.t(status)}
        </span>
      )}
    </div>
  );
};

AttendanceStatusIndicator.propTypes = {
  className: PropTypes.string,
  status: PropTypes.oneOf([
    'checked_in',
    'not_checked_in',
    'offline',
  ]).isRequired,
  ariaLabel: PropTypes.string,
  isDisabled: PropTypes.bool,
  isAboutToDrag: PropTypes.bool,
};

export default AttendanceStatusIndicator;
