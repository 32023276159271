import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import gravatar from 'gravatar';

import { closeDropdown } from '../../redux/ui/actions';

import I18n from '../../utilities/i18n';

import Dropdown from './Dropdown';


const UserMenu = ({
  currentUser,
  logoutUser,
  closeDropdown,
}) => {
  const dropdownName = 'userMenu';
  const privacyLink = 'https://www.lightspeedsystems.com/privacy/';
  const ideaLink = 'https://lightspeedsystems.canny.io/classroom-teachers';

  const logoutUserHandler = () => {
    closeDropdown(dropdownName);
    logoutUser();
  };

  const buildLabel = () => {
    const firstInitial = currentUser.first_name ? currentUser.first_name.substring(0, 1) : '-';
    const lastInitial = currentUser.last_name ? currentUser.last_name.substring(0, 1) : '-';
    const url = gravatar.url(currentUser.email, { s: '200', r: 'g', d: 'blank' }, true);

    return (
      <div>
        {firstInitial + lastInitial}
        <img
          className='co-header-userGravatarImage'
          src={url}
          alt={I18n.t('gravatar_image')}
        />
      </div>
    );
  };

  return (
    <Dropdown
      initialLabel={buildLabel()}
      buttonLabelClasses='co-header-userMenu'
      containerClasses='co-dropdown--naturalWidth'
      dropdownMenuClasses='co-dropdown-menu--rightAnchored'
      hasArrow={false}
      dropdownName={dropdownName}
    >
      <ul>
        <li className='co-header-userInfo'>
          <div className='co-header-userName'>{I18n.t('hi')}, {currentUser.first_name}</div>
          <span className='co-header-userEmail'>{currentUser.email}</span>
        </li>
        <li>
          <a
            className='co-dropdown-item'
            href={ideaLink}
            title={ideaLink}
            target='_blank'
            rel='noopener noreferrer'
            data-testid='userMenu-idea'
          >
            {I18n.t('submit_an_idea')}
          </a>
        </li>
        <li>
          <a
            className='co-dropdown-item'
            href={privacyLink}
            title={privacyLink}
            target='_blank'
            rel='noopener noreferrer'
          >
            {I18n.t('privacy_policy')}
          </a>
        </li>
        <li>
          <button
            className='co-dropdown-item button-link'
            onClick={logoutUserHandler}
            data-testid='userMenu-logout'
          >
            {I18n.t('sign_out')}
          </button>
        </li>
      </ul>
    </Dropdown>
  );
};

UserMenu.propTypes = {
  currentUser: PropTypes.object,
  logoutUser: PropTypes.func,
  closeDropdown: PropTypes.func,
};

const mapDispatch = {
  closeDropdown,
};

export default connect(null, mapDispatch)(UserMenu);
