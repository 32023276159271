import { ClassroomApi } from '../helpers/classroomApi';
import { loadLocalStorage } from '../helpers/localStorage';
const { getEmailsFromGuids } = require('./users');
const allStudents = 'all';
const defaultGroup = 'default';
const localStorageWebzone = 'ClassroomLastCustom';
const localStorageWebzoneType = 'ClassroomLastWebzoneType';

export function getGroupName(group) {
  return group?.display_name ?? group?.name;
}

export function getCurrentGroup(groupsState) {
  const guid = groupsState.currentGuid;
  const groups = groupsState.groups;

  return selectGroup(guid, groups);
}

export function selectGroup(guid, groups) {
  if (guid && Array.isArray(groups)) {
    const group = groups.find((group) => group.guid === guid);

    if (group) {
      return group;
    }
  }
  return {};
}

export function getCurrentGroupIsLoaded(groupsState) {
  const current = groupsState.currentGuid;
  const loaded = groupsState.loadedGuid;

  if (current && loaded) {
    return groupsState.currentGuid === groupsState.loadedGuid;
  }
  return false;
}

export async function resyncSis(guid) {
  const response = await ClassroomApi.post(`/groups/${guid}/resync`, {});
  if (response?.status !== 200 || !response.data) {
    return false;
  }
  return true;
}

export function getSelectedSegmentEmails(allUsers, selectedSegments, currentSegments) {
  // selectedSegments is an array of selected segment guids
  // or the string 'all' for all students, or 'default' for default group
  // first check for those string values, and if not present, get emails for selected segments

  const selectedId = selectedSegments.length > 0 ? selectedSegments[0] : null;
  if (selectedId === allStudents) {
    return Object.values(allUsers).map((user) => user.email);
  } else if (selectedId === defaultGroup) {
    let emails = Object.values(allUsers).map((user) => user.email);
    for (const segment of currentSegments) {
      const memberEmails = getEmailsFromGuids(allUsers, segment.member_guids);
      emails = emails.filter((email) => !memberEmails.includes(email));
    }
    return emails;
  }

  const emails = [];
  selectedSegments.forEach((segmentGuid) => {
    const segment = currentSegments.find((segment) => segment.guid === segmentGuid);
    if (segment) {
      const newEmails = getEmailsFromGuids(allUsers, segment.member_guids);
      newEmails.forEach((email) => {
        emails.push(email);
      });
    }
  });
  return emails;
}

export function getDefaultSelection(currentSegments, openSegment) {
  if (openSegment === 'classControls') {
    return [allStudents];
  }
  if (openSegment) {
    return [openSegment];
  }

  if (currentSegments && currentSegments.length > 0) {
    return [allStudents];
  }
  return [defaultGroup];
}


export function getSegmentByGuid(guid, currentSegments) {
  return currentSegments.find((segment) => {
    return segment.guid === guid;
  });
}

export const loadSavedSegmentWebzones = (currentSegments, saveCallback) => {
  currentSegments.forEach((segment) => {
    const webzoneType = loadLocalStorage(`${localStorageWebzoneType}::g::${segment.guid}`);
    if (webzoneType === 'none') {
      saveCallback(segment.guid, {});
      return;
    }

    const segmentWebRules = loadLocalStorage(`${localStorageWebzone}::g::${segment.guid}`);
    if (segmentWebRules) {
      saveCallback(segment.guid, segmentWebRules);
    }
  });
};

export const loadSavedWebzone = (saveCallback) => {
  const webzoneType = loadLocalStorage(localStorageWebzoneType);
  if (webzoneType === 'none') {
    return;
  }

  const groupWebRules = loadLocalStorage(localStorageWebzone);
  if (groupWebRules) {
    saveCallback(groupWebRules);
  }
};

export function getSegmentGuid(currentlyOpenFlyout) {
  if (currentlyOpenFlyout?.includes('::')) {
    return currentlyOpenFlyout.split('::')[1];
  }
  return null;
}

export const nameReducer = (state, action) => {
  switch (action.type) {
    case 'start':
      return { ...state, active: true, text: action.text };
    case 'update':
      return { ...state, text: action.text };
    case 'stop':
      return { ...state, active: false };
    default:
      return state;
  }
};

export const getUpdatingDefault = () => ({
  name: '',
  segment: '',
  member: '',
  add: false,
  remove: '',
});

export const updatingReducer = (state, action) => {
  switch (action.type) {
    case 'name':
      return { ...state, name: action.guid };
    case 'member':
      return { ...state, segment: action.guid, member: action.memberGuid };
    case 'add':
      return { ...state, add: true };
    case 'remove':
      return { ...state, remove: action.guid };
    case 'done':
      return getUpdatingDefault();
    default:
      return state;
  }
};
