import React from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import I18n from '../../utilities/i18n';

import BetaBadge from '../common/BetaBadge';
import Icon from '../common/Icon';
import Options from './Options';

const GroupShowControls = ({
  currentGroupNavTab,
  hasHeatMapViewPolicy,
  hasScreensViewPolicy,
  noUsers,
  setCurrentGroupNavTab,
  usersFilter,
}) => {
  const isScreensView = hasScreensViewPolicy && currentGroupNavTab === 'screensView';
  const isHeatMapView = hasHeatMapViewPolicy && currentGroupNavTab === 'heatMapView';
  const isListView = currentGroupNavTab === 'listView' || (!isScreensView && !isHeatMapView);

  const buildTabControls = () => {
    const screensViewTabClasses = classNames('co-groupPage-tabControls', {
      'co-groupPage-tabControls--screensView': isScreensView,
    });
    const heatMapViewTabClasses = classNames('co-groupPage-tabControls', {
      'co-groupPage-tabControls--heatMapView': isHeatMapView,
    });
    const listViewTabClasses = classNames('co-groupPage-tabControls', {
      'co-groupPage-tabControls--listView': isListView,
    });

    return (
      <section>
        {hasScreensViewPolicy &&
          <button
            className={screensViewTabClasses}
            aria-label={I18n.t('screens')}
            type='button'
            data-testid='co-groupPage-tabControls-screensView'
            onClick={() => setCurrentGroupNavTab('screensView')}
          >
            <Icon name='icon-grid' />
            <span className='m-left--12 m-right--12'>
              {I18n.t('screens')}
            </span>
          </button>}
        <button
          className={listViewTabClasses}
          type='button'
          data-testid='co-groupPage-tabControls-listView'
          aria-label={I18n.t('list')}
          onClick={() => setCurrentGroupNavTab('listView')}
        >
          <Icon name='icon-list' />
          <span className='m-left--12'>
            {I18n.t('list')}
          </span>
        </button>
        {hasHeatMapViewPolicy &&
          <button
            className={heatMapViewTabClasses}
            aria-label={I18n.t('heat_map')}
            type='button'
            data-testid='co-groupPage-tabControls-heatMapView'
            onClick={() => setCurrentGroupNavTab('heatMapView')}
          >
            <Icon name='icon-heat-grid' />
            <span className='m-left--12'>
              {I18n.t('heat_map')}
            </span>
          </button>}
      </section>
    );
  };

  const buildOptions = () => {
    return <Options noUsers={noUsers} segment={{ guid: 'classControls' }} />;
  };

  const groupHeaderClasses = classNames('co-groupPage-dashboardHeader');

  return (
    <>
      <section className='co-groupPage co-groupPage-classControls'>
        <h1 className='co-groupPage-controls-header'>
          {I18n.t('class_controls')}
        </h1>
        {buildOptions()}
      </section>
      <section>
        <header className={groupHeaderClasses}>
          <h2 className='font-size--h2'>{usersFilter}</h2>
          {buildTabControls()}
        </header>
      </section>
    </>
  );
};

GroupShowControls.propTypes = {
  currentGroupNavTab: PropTypes.string.isRequired,
  hasHeatMapViewPolicy: PropTypes.bool.isRequired,
  hasScreensViewPolicy: PropTypes.bool.isRequired,
  noUsers: PropTypes.bool.isRequired,
  setCurrentGroupNavTab: PropTypes.func.isRequired,
  usersFilter: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  classroomGroupBetas: state.betas,
  userBetas: state.currentUser.betas,
});

export default connect(mapStateToProps, null)(GroupShowControls);
