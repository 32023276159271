import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import I18n from '../../../../utilities/i18n';
import { addNotification, getCurrentSegmentGuid, closeSubFlyout } from '../../../../redux/ui/actions';
import { deleteWebRules, updateWebRules, getWebRules } from '../../../../redux/webRules/actions';
import { getSegmentGuid } from '../../../../utilities/groups';

import ListForm from './ListForm';

const EditList = ({
  addNotification,
  closeSubFlyout,
  currentlyOpenFlyout,
  deleteWebRules,
  getCurrentSegmentGuid,
  getWebRules,
  updateWebRules,
  getDeletedList,
  getEditedList,
  getCanClose,
}) => {
  const segmentGuid = getSegmentGuid(currentlyOpenFlyout);
  const [error, setError] = useState(null);

  const handleSave = async (newWebRules) => {
    await updateWebRules(newWebRules).then(async () => {
      getCurrentSegmentGuid(segmentGuid);
      getEditedList(newWebRules);
      const successMessage = (
        <div className='co-listForm-notificationMessage-wrapper'>
          <span className='co-listForm-notificationMessage'>{newWebRules.newName}</span> {I18n.t('was_saved')}
        </div>
      );
      addNotification(successMessage, { notificationType: 'success', autoDismiss: true });
      await getWebRules();
      closeSubFlyout();
    }).catch((error) => {
      setError(error);
    });
  };

  const handleDelete = async (deleteList) => {
    await deleteWebRules(deleteList).then(async () => {
      getCurrentSegmentGuid(segmentGuid);
      getDeletedList(deleteList.name);
      const deleteMessage = (
        <div className='co-listForm-notificationMessage-wrapper'>
          <span className='co-listForm-notificationMessage'>{deleteList.name}</span> {I18n.t('was_deleted')}
        </div>
      );
      addNotification(deleteMessage, { notificationType: 'success', autoDismiss: true });
      await getWebRules();
      closeSubFlyout();
    }).catch((error) => {
      setError(error);
    });
  };

  const handleClose = (isEdited, entries) => {
    if (!isEdited || entries.length === 0) {
      getCurrentSegmentGuid(segmentGuid);
      closeSubFlyout();
    }
  };

  return (
    <ListForm
      handleSave={handleSave}
      handleClose={handleClose}
      handleDelete={handleDelete}
      getCanClose={getCanClose}
      error={error}
    />
  );
};

EditList.propTypes = {
  addNotification: PropTypes.func,
  closeSubFlyout: PropTypes.func,
  currentlyOpenFlyout: PropTypes.string,
  deleteWebRules: PropTypes.func.isRequired,
  getCurrentSegmentGuid: PropTypes.func.isRequired,
  getWebRules: PropTypes.func.isRequired,
  updateWebRules: PropTypes.func.isRequired,
  getDeletedList: PropTypes.func,
  getEditedList: PropTypes.func,
  getCanClose: PropTypes.func,
};

const mapState = (state) => ({
  currentlyOpenFlyout: state.ui.currentlyOpenFlyout,
  currentlyOpenSubFlyout: state.ui.currentlyOpenSubFlyout,
  currentSegmentGuid: state.ui.currentSegmentGuid,
  currentWebRulesSection: state.ui.currentWebRulesSection,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  addNotification,
  closeSubFlyout,
  deleteWebRules,
  getCurrentSegmentGuid,
  getWebRules,
  updateWebRules,
}, dispatch);

export default connect(mapState, mapDispatchToProps)(EditList);
