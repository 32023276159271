import cn from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { ReactComponent as OpenBookIcon } from '../../images/openBook.svg';
import { ReactComponent as NeedHelpIcon } from '../../images/needHelp.svg';
import { ReactComponent as SuccessIcon } from '../../images/success.svg';

const ICON_MAP = {
  working: OpenBookIcon,
  need_help: NeedHelpIcon,
  'need help': NeedHelpIcon,
  done: SuccessIcon,
};

const TaskStatusIcon = ({ className, status, isDisabled = false }) => {
  const Icon = ICON_MAP[status];

  if (status === 'none') {
    return (<></>);
  }
  
  return ( 
    <Icon className={cn([
      className,
      'co-taskStatusIcon',
      { 'co-taskStatusIcon--disabled': isDisabled }])}
    />
  );
};

TaskStatusIcon.propTypes = {
  className: PropTypes.string,
  status: PropTypes.oneOf([
    'working',
    'need_help',
    'need help',
    'done',
  ]).isRequired,
  isDisabled: PropTypes.bool,
};

export default TaskStatusIcon;
