import { createResponsiveStateReducer } from 'redux-responsive';

export const browserReducer = createResponsiveStateReducer({
  xs: 500,
  s: 768,
  smed: 940,
  m: 1024,
  l: 1280,
  xl: 1440,
});

