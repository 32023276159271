import { isDevUrl } from '../utilities/urls';

export function handleOneTrust(isInTeams = false) {
  // disable one trust if not in prod, or in Teams
  // adding the following cookie keeps the one trust popup from opening
  const cookieName = 'OptanonAlertBoxClosed=2020-08-26T17:24:02.496Z; path=/';

  if (isInTeams || isDevUrl(window.location.href)) {
    document.cookie = cookieName;
  }
}

function getCognitoCookiePrefix() {
  let prefix = '';

  if (process.env.REACT_APP_ENV === 'development' || process.env.REACT_APP_ENV === 'prototype') {
    prefix = 'develop_';
  } else if (process.env.REACT_APP_ENV === 'staging') {
    prefix = 'staging_';
  }

  return prefix;
}

export function getCognitoCookieValue(name) {
  const cookiePrefix = getCognitoCookiePrefix();
  const cookieName = cookiePrefix + name;
  const cookie = document.cookie.split('; ').find((cookie) => cookie.indexOf(cookieName) === 0);

  return cookie ? cookie.split(`${cookieName}=`)[1] : null;
}
