import { addError } from '../errors/actions';

import { ClassroomApi } from '../../helpers/classroomApi';
import { addNotification } from '../ui/actions';
import { isNoBrowsingOLD } from '../../utilities/webzones';

export const FETCH_WEBZONE = 'FETCH_WEBZONE';
export const RECEIVE_WEBZONE = 'RECEIVE_WEBZONE';
export const CLEAR_WEBZONE = 'CLEAR_WEBZONE';
export const UPDATE_WEBZONE_BROWSING = 'UPDATE_WEBZONE_BROWSING';

export function getWebzone(groupGuid) {
  return async function(dispatch, getState) {
    if (!groupGuid) {
      return {};
    }

    dispatch({ type: FETCH_WEBZONE });

    const response = await ClassroomApi.get(`/web_zones/${groupGuid}`);

    if (response?.status !== 200) {
      return dispatch(addError('getWebzone', response));
    }

    const state = getState();
    // change old no browsing data to new format
    if (isNoBrowsingOLD(response.data)) {
      const userEmail = state.currentUser.email;
      await dispatch(removeWebzone(groupGuid));
      return await dispatch(updateNoBrowsing(groupGuid, userEmail, true));
    }

    return dispatch({ type: RECEIVE_WEBZONE, webzone: response.data });
  };
}

export function removeWebzone(groupGuid, successMessage) {
  return async function(dispatch) {
    if (!groupGuid) {
      return {};
    }

    dispatch({ type: FETCH_WEBZONE });
    const response = await ClassroomApi.delete(`/web_zones/${groupGuid}`);

    if (response?.status !== 200) {
      return dispatch(addError('removeWebzone', response));
    }

    if (successMessage) {
      dispatch(addNotification(successMessage, { autoDismiss: true, notificationType: 'success' }));
    }
    return dispatch({ type: RECEIVE_WEBZONE, webzone: response.data });
  };
}

export function setNoBrowsingWebzone(groupGuid, userEmail) {
  return async function(dispatch) {
    if (!groupGuid || !userEmail) {
      return {};
    }

    const data = {
      sites: [],
      userEmail,
    };
    return await dispatch(updateWebzone(groupGuid, data));
  };
}

export function updateNoBrowsing(groupGuid, userEmail, newBrowsingState) {
  return async function(dispatch) {
    if (!groupGuid || !userEmail) {
      return {};
    }

    const data = {
      noBrowsing: newBrowsingState,
      userEmail,
    };
    return await dispatch(updateWebzone(groupGuid, data));
  };
}

export function setCustomWebzone(groupGuid, userEmail, settings, successMessage) {
  return async function(dispatch) {
    if (!groupGuid || !userEmail || !settings) {
      return {};
    }

    const data = {
      ...settings,
      userEmail,
    };

    await dispatch(updateWebzone(groupGuid, data));

    if (successMessage) {
      dispatch(addNotification(successMessage, { autoDismiss: true, notificationType: 'success' }));
    }
  };
}

function updateWebzone(groupGuid, data) {
  return async function(dispatch) {
    dispatch({ type: FETCH_WEBZONE });

    const response = await ClassroomApi.put(`/web_zones/${groupGuid}`, data);

    if (response?.status !== 200) {
      return dispatch(addError('updateWebzone', response));
    }

    return dispatch({ type: RECEIVE_WEBZONE, webzone: response.data });
  };
}

export function clearWebzone() {
  return { type: CLEAR_WEBZONE };
}

export function updateBrowsingToggle(msg) {
  return { type: UPDATE_WEBZONE_BROWSING, noBrowsing: msg.noBrowsing };
}
