import { insertEvent, matchesEvent } from '../../utilities/events';

import {
  ADD_EVENT,
  REMOVE_EVENTS,
  ADD_IN_SESSION_GROUP,
  REMOVE_IN_SESSION_GROUP,
  ADD_CONNECTED_GROUP,
  REMOVE_CONNECTED_GROUP,
} from './actions';

export const defaultState = {
  events: [],
  inSession: [],
  connected: [],
};

export function classesReducer(state = defaultState, action) {
  switch (action.type) {
    case ADD_EVENT: {
      const event = {
        date: action.date,
        type: action.eventType,
        guid: action.guid,
      };
      return {
        ...state,
        events: insertEvent(event, state.events),
      };
    }
    case REMOVE_EVENTS: {
      const events = state.events.filter((event) => !matchesEvent(event, action.guid, action.eventType, action.date));
      return {
        ...state,
        events,
      };
    }
    case ADD_IN_SESSION_GROUP: {
      const filtered = state.inSession.filter((guid) => guid !== action.guid);
      return {
        ...state,
        inSession: [...filtered, action.guid],
      };
    }
    case REMOVE_IN_SESSION_GROUP: {
      const filtered = state.inSession.filter((guid) => guid !== action.guid);
      return {
        ...state,
        inSession: filtered,
      };
    }
    case ADD_CONNECTED_GROUP: {
      const filtered = state.connected.filter((guid) => guid !== action.guid);
      return {
        ...state,
        connected: [...filtered, action.guid],
      };
    }
    case REMOVE_CONNECTED_GROUP: {
      const filtered = state.connected.filter((guid) => guid !== action.guid);
      return {
        ...state,
        connected: filtered,
      };
    }
    default: {
      return state;
    }
  }
}
