import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import uuidv1 from 'uuid/v1';

import { connectChannel, sendAblyRollbar } from '../../redux/ably/actions';

import {
  setStep,
  setError,
  setExpired,
  openBroadcast,
  closeBroadcast,
} from '../../redux/broadcast/actions';
import { addWebrtc } from '../../redux/webrtcs/actions';

import { getChannelData, findBroadcast } from '../../utilities/ably';
import I18n from '../../utilities/i18n';
import { parseQueryParams } from '../../utilities/urls';

import Loader from '../common/Loader';
import BroadcastError from './BroadcastError';

function getClientId() {
  let clientId = localStorage.ClassroomClientId;

  if (!clientId) {
    clientId = uuidv1();
    localStorage.ClassroomClientId = clientId;
  }

  return clientId;
}

function setViewingPresence(channel, clientId) {
  const data = {
    sessionId: clientId,
    viewingScreen: true,
    viewingBroadcast: true,
  };

  channel.presence.enter(data).catch((e) => sendAblyRollbar('broadcast_enter', e));
}

function clearViewingPresence(channel) {
  if (channel) {
    channel.presence.leave().catch((e) => sendAblyRollbar('broadcast_enter', e));
  }
}

function setVideoSource(videoElement, stream) {
  if (videoElement.current && videoElement.current.srcObject !== stream) {
    videoElement.current.srcObject = stream;
  }
}

const WatchBroadcast = ({
  location,
  match,
  step,
  isLoading,
  isExpired,
  hasError,
  channelName,
  iceServers,
  ablyStatus,
  ablyData,
  channel,
  channelData,
  streams,
  openBroadcast,
  closeBroadcast,
  addWebrtc,
  setStep,
  setError,
  setExpired,
  connectChannel,
}) => {
  const clientId = getClientId();
  const queryParams = parseQueryParams(location);
  const jwt = queryParams.jwt;
  const groupGuid = match.params.groupGuid;
  const broadcast = findBroadcast(ablyData, groupGuid);
  const stream = streams[clientId];

  const videoElement = useRef(null);
  const [startupCheck, setStartupCheck] = useState(false);
  const [expiredTimeout, setExpiredTimeout] = useState(null);

  useEffect(() => {
    if (step === 0) {
      const startupMs = 1000 * 10;

      setTimeout(() => setStartupCheck(true), startupMs);
      openBroadcast(clientId, groupGuid, jwt);
      setStep(1);
    }
  }, [step, clientId, groupGuid, jwt, openBroadcast, setStep]);

  useEffect(() => {
    if (step === 1 && ablyStatus === 'connected') {
      connectChannel(channelName);
      connectChannel(groupGuid, null);
      setStep(2);
    }
  }, [step, ablyStatus, channelName, groupGuid, connectChannel, setStep]);

  useEffect(() => {
    if (step === 2 && channel && channelData?.status === 'attached' && broadcast) {
      setViewingPresence(channel, clientId);
      const streamId = broadcast.streamId;
      addWebrtc(clientId, clientId, channel, iceServers, streamId);
      setStep(3);
    }
  }, [step, channel, channelData, clientId, iceServers, addWebrtc, setStep, broadcast]);

  useEffect(() => {
    if (step === 3 && stream && broadcast?.jwt === jwt) {
      setVideoSource(videoElement, stream);
      setStep(4);
    }
  }, [step, stream, broadcast, jwt, setStep]);

  useEffect(() => {
    if (step < 4 && startupCheck) {
      const isConnected = Boolean(stream);

      if (isConnected) {
        setExpired();
      } else {
        setError();
      }
    }
  }, [step, startupCheck, stream, setExpired, setError]);

  useEffect(() => {
    if (step === 4) {
      const expiredMs = 1000 * 60 * 2;
      const hasBroadcast = broadcast?.jwt === jwt;

      if (!hasBroadcast && !expiredTimeout) {
        setExpiredTimeout(setTimeout(setExpired, expiredMs));
      }
      if (hasBroadcast && expiredTimeout) {
        clearTimeout(expiredTimeout);
        setExpiredTimeout(null);
      }
    }
  }, [step, broadcast, jwt, expiredTimeout, setExpired]);

  useEffect(() => {
    if (step < 5 && (isExpired || hasError)) {
      clearViewingPresence(channel);
      closeBroadcast(clientId);
      setStep(5);
    }
  }, [step, isExpired, hasError, channel, clientId, closeBroadcast, setStep]);

  if (isLoading) {
    return (
      <Loader
        isFullscreen={true}
        text={I18n.t('your_teacher_is_sharing')}
      />
    );
  }

  if (isExpired) {
    return <BroadcastError message={I18n.t('this_broadcast_has_expired')} />;
  }

  if (hasError) {
    return <BroadcastError message={I18n.t('network_error_please_try_again')} />;
  }

  return (
    <section className='fs-block'>
      <div className='minHeight--100vh background--black'>
        <video
          ref={videoElement}
          data-testid='watchBroadcast-video'
          className='video--fill'
          autoPlay
          muted
        />
      </div>
    </section>
  );
};

WatchBroadcast.propTypes = {
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  step: PropTypes.number.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isExpired: PropTypes.bool.isRequired,
  hasError: PropTypes.bool.isRequired,
  channelName: PropTypes.string.isRequired,
  iceServers: PropTypes.arrayOf(PropTypes.object).isRequired,
  ablyStatus: PropTypes.string.isRequired,
  ablyData: PropTypes.object.isRequired,
  channel: PropTypes.object,
  channelData: PropTypes.object,
  streams: PropTypes.object.isRequired,
  openBroadcast: PropTypes.func.isRequired,
  closeBroadcast: PropTypes.func.isRequired,
  addWebrtc: PropTypes.func.isRequired,
  setStep: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  setExpired: PropTypes.func.isRequired,
  connectChannel: PropTypes.func.isRequired,
};

const mapState = (state) => ({
  step: state.broadcast.step,
  isLoading: state.broadcast.isLoading,
  isExpired: state.broadcast.isExpired,
  hasError: state.broadcast.hasError,
  channelName: state.broadcast.channelName,
  iceServers: state.broadcast.iceServers,
  ablyStatus: state.ably.status,
  ablyData: state.ably.data,
  channel: state.ably.channels[state.broadcast.channelName],
  channelData: getChannelData(state.ably.data, state.broadcast.channelName),
  streams: state.webrtcs.streams,
});

const mapDispatch = {
  openBroadcast,
  closeBroadcast,
  addWebrtc,
  setStep,
  setError,
  setExpired,
  connectChannel,
};

export default connect(mapState, mapDispatch)(WatchBroadcast);
