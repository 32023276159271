export function loadLocalStorage(key, oldestValidTime = 0) {
  try {
    const valueStr = localStorage.getItem(key);
    const timestampRaw = parseInt(localStorage.getItem(getTimestampName(key)), 10);
    const timestamp = isNaN(timestampRaw) ? Date.now() : timestampRaw;

    if (valueStr && (timestamp >= oldestValidTime)) {
      return JSON.parse(valueStr);
    }
  } catch (e) {
    localStorage.removeItem(key);
    console.error(`loadLocalStorage: ${e.message}`); // eslint-disable-line no-console
  }

  return null;
}

export function saveLocalStorage(key, value) {
  try {
    const valueStr = JSON.stringify(value);
    localStorage.setItem(key, valueStr);
    localStorage.setItem(getTimestampName(key), `${Date.now()}`);
  } catch (e) {
    console.error(`saveLocalStorage: ${e.message}`); // eslint-disable-line no-console
  }
}

export function getTimestampName(key) {
  return `_${key}_timestamp`;
}
