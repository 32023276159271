import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import I18n from '../../../../utilities/i18n';
import { openSubFlyout } from '../../../../redux/ui/actions';
import { getAllowListLength, getBlockListLength } from '../../../../utilities/webRules';

import EmptyState from '../../EmptyState';
import ListsTable from './ListsTable';
import Tooltip from '../../../common/Tooltip/Tooltip';

const ListsContainer = ({
  currentSection,
  getSelectedWebRulesLists,
  initialCheckedLists,
  initialSection,
  openSubFlyout,
  webRules,
}) => {
  const allow = getAllowListLength(webRules);
  const block = getBlockListLength(webRules);
  const isAllowSection = currentSection === 'allow';

  const listInfo = {
    hasAllowList: allow > 0 && isAllowSection,
    hasBlockList: block > 0 && !isAllowSection,
    emptyAllowList: allow < 1 && isAllowSection,
    emptyBlockList: block < 1 && !isAllowSection,
    maxAllow: isAllowSection && allow >= 20,
    maxBlock: !isAllowSection && block >= 20,
  };

  const handleOpenSubFlyout = () => {
    const subFlyoutName = 'create_list';
    openSubFlyout(subFlyoutName);
  };

  const buildHeader = () => {
    const title = (
      <>
        {I18n.t(`${currentSection}_lists`)}
        <span className='co-listsContainer-titleInfo m-left--8' data-testid='listsContainer-titleInfo'>
          (20 {I18n.t('max')})
        </span>
      </>
    );
    return (
      <h3 className='co-listsContainer-titleContainer' data-testid='listsContainer-titleContainer'>
        <span
          className='co-listsContainer-title'
          data-testid='listsContainer-title'
        >
          {title}
        </span>
        {buildHeaderButton()}
      </h3>
    );
  };

  const buildHeaderButton = () => {
    if (listInfo.hasAllowList || listInfo.hasBlockList) {
      const isListMaximum = (listInfo.maxAllow || listInfo.maxBlock);
      const maxListMessage = I18n.t('max_number_of_x_lists_reached', { string: I18n.t(currentSection) });
      const button = (
        <button
          className='co-listsContainer-quietButton'
          onClick={handleOpenSubFlyout}
          disabled={isListMaximum}
          data-testid='listsContainer-createListButton-quiet'
        >
          {I18n.t('create_list')}
        </button>
      );
      return (
        <div className='co-listsContainer-quietButtonContainer' data-testid='listsContainer-quietButtonContainer'>
          {isListMaximum ? (
            <Tooltip
              content={maxListMessage}
              aria-label={maxListMessage}
              position='left'
              size='small'
            >
              {button}
            </Tooltip>
          ) : (
            button
          )}
        </div>
      );
    }
  };

  const buildEmptyState = () => {
    if (listInfo.emptyAllowList || listInfo.emptyBlockList) {
      return (
        <EmptyState>
          <button
            data-testid='listsContainer-emptyState-createListButton'
            onClick={handleOpenSubFlyout}
            className='button button--secondaryWhite co-listsContainer-secondaryButton'
          >
            {I18n.t('create_list')}
          </button>
        </EmptyState>
      );
    }
  };

  const listsContainerClasses = cn({
    'co-listsContainer': true,
    'display--none': currentSection === 'none',
  });

  return (
    <div className={listsContainerClasses} data-testid='listsContainer-wrapper'>
      {buildHeader()}
      {buildEmptyState()}
      {(listInfo.hasAllowList || listInfo.hasBlockList) &&
        <ListsTable
          currentSection={currentSection}
          initialSection={initialSection}
          initialCheckedLists={initialCheckedLists}
          getSelectedWebRulesLists={getSelectedWebRulesLists} />}
    </div>
  );
};

ListsContainer.propTypes = {
  currentSection: PropTypes.string,
  getSelectedWebRulesLists: PropTypes.func,
  initialCheckedLists: PropTypes.array,
  initialSection: PropTypes.string,
  openSubFlyout: PropTypes.func,
  webRules: PropTypes.object,
};

const mapState = (state) => ({
  webRules: state.webRules,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  openSubFlyout,
}, dispatch);

export default connect(mapState, mapDispatchToProps)(ListsContainer);
