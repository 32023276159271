import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import cn from 'classnames';
import { useDrag } from 'react-dnd';

import { initFlyout, shutdownFlyout } from '../../../redux/ably/actions';
import { openFlyout } from '../../../redux/ui/actions';

import {
  findActiveTab,
  formatScreensViewUrl,
  isNewTab,
  isValidUrl,
} from '../../../utilities/urls';
import { getCurrentGroup } from '../../../utilities/groups';
import { getTabsData } from '../../../utilities/ably';
import I18n from '../../../utilities/i18n';

import UpdateStatus from '../../common/UpdateStatus';
import { ReactComponent as NeedHelpIcon } from '../../../images/needHelpWithBorder.svg';
import { ReactComponent as DoneIcon } from '../../../images/doneIconWithBorder.svg';
import { ReactComponent as ExpandIcon } from '../../../images/expandIcon.svg';
import { ReactComponent as DragHandleIcon } from '../../../images/dragHandle.svg';
import { ReactComponent as NoImageIcon } from '../../../images/noImage.svg';

import DataLoader from '../../common/DataLoader';

const UserScreenCard = ({
  ablyData,
  currentGroup,
  dragType,
  initFlyout,
  inSession,
  isMoving,
  openFlyout,
  userInfo,
  streams,
  shutdownFlyout,
  allWebrtcs,
}) => {
  const webrtc = allWebrtcs[userInfo.email];
  const email = userInfo.email;
  const tabs = getTabsData(ablyData, email);
  const activeTab = findActiveTab(tabs, true);
  const [emptyStreamMessage, setEmptyStreamMessage] = useState(null);
  const userCheckedIn = userInfo.checkInStatus === 'checked_in';
  const userNotCheckedIn = userInfo.checkInStatus === 'not_checked_in';
  const videoElement = useRef(null);
  const stream = streams[email];
  const isInSession = inSession.includes(currentGroup.guid);
  const currentHost = userInfo && userInfo.currentHost;
  const [{ isDragging }, drag, preview] = useDrag({
    item: { type: dragType, guid: userInfo.guid },
    collect: (monitor) => ({
      isDragging: Boolean(monitor.isDragging()),
    }),
  });
  const showContent = !isDragging && !isMoving;

  const restartWebrtcConnection = useCallback(() => {
    shutdownFlyout(email);

    if (userInfo.online) {
      initFlyout(email);
    }
  }, [shutdownFlyout, email, userInfo.online, initFlyout]);

  const webrtcError = webrtc && webrtc.error;

  const monitorWebrtcConnectionStateChange = useCallback(() => {
    // TODO: remove console logs
    // Since we don't know the exact cause of the gray screens issue defined in RC-1373,
    // We are deliberately leaving these console logs in place so we can debug in production
    // for a customer if the gray screens issue persists after these changes are released.
    console.log(`\n\n${email} webrtc connection state changed to ${webrtc.connection.connectionState}`);

    if (webrtc.connection.connectionState === 'disconnected' || webrtc.connection.connectionState === 'failed') {
      console.log(`%c\n\n${email} webrtc is ${webrtc.connection.connectionState}`, 'color: #ff0000');

      restartWebrtcConnection();
    }

    if (webrtc.connection.connectionState === 'connected') {
      console.log(`%c\n\n${email} webrtc is connected`, 'color: #00ff00');
    }
  }, [email, webrtc, restartWebrtcConnection]);

  useEffect(() => {
    // TODO: remove console logs
    // Since we don't know the exact cause of the gray screens issue defined in RC-1373,
    // We are deliberately leaving these console logs in place so we can debug in production
    // for a customer if the gray screens issue persists after these changes are released.
    if (webrtcError) {
      console.log(`%c\n\nwebrtc error for ${email}: ${webrtcError}`, 'color: #ff0000');
    }

    let retryWebrtcConnection = null;

    if (webrtc) {
      console.log(`%c\n\n${email} has a webrtc:`, 'color: #00ff00', webrtc);
      webrtc.connection.addEventListener('connectionstatechange', monitorWebrtcConnectionStateChange);

      // If the webrtc connection state gets hung on "new",
      // or there is a webrtc.error, restart the connection.
      retryWebrtcConnection = setInterval(() => {
        if (webrtc.connection.connectionState === 'new' || webrtc.error) {
          console.log(`\n\nretrying webrtc connection for ${email}`);

          restartWebrtcConnection();
        }
      }, 10000);
    } else {
      console.log(`%c\n\n${email} does not have a webrtc`, 'color: #DAA520');
    }

    return () => {
      if (webrtc) {
        webrtc.connection.removeEventListener('connectionstatechange', monitorWebrtcConnectionStateChange);
        clearInterval(retryWebrtcConnection);
      }
    };
  }, [webrtc, webrtcError, email, initFlyout, shutdownFlyout, userInfo.online,
    monitorWebrtcConnectionStateChange, restartWebrtcConnection]);

  useEffect(() => {
    if (userInfo.online) {
      /*
      init screen connection
      "flyout" corresponds with flyout quality of stream
      in agents "viewingFlyout" === medium quality stream
      */
      initFlyout(email);
    }

    return () => {
      shutdownFlyout(email);
    };
  }, [userInfo.online, email, initFlyout, shutdownFlyout]);

  // These variables are being added for use in the useEffect dependency array
  // because just having the videoElement in the dependency array doesn't cause a
  // render when videoElement.current or videoElement.current.srcObject changes.
  const videoElementCurrent = videoElement && videoElement.current;
  const videoElementSrcObject = videoElement && videoElement.current && videoElement.current.srcObject;

  useEffect(() => {
    if (videoElement.current && videoElement.current.srcObject !== stream) {
      videoElement.current.srcObject = stream;
    }
  }, [videoElement, videoElementCurrent, videoElementSrcObject, stream, activeTab, emptyStreamMessage]);

  useEffect(() => {
    if (currentHost) {
      if (isNewTab(currentHost) && !isValidUrl(currentHost)) {
        setEmptyStreamMessage('new_tab');
      } else if (!isValidUrl(currentHost)) {
        setEmptyStreamMessage('no_image');
      } else {
        setEmptyStreamMessage(null);
      }
    }
  }, [currentHost, activeTab]);

  const checkInStatusClasses = cn(
    'co-userScreensView-userScreen-checkInStatus',
    {
      'co-userScreensView-userScreen-checkInStatus--checkedIn':
        isInSession && userCheckedIn,
      'co-userScreensView-userScreen-checkInStatus--notCheckedIn':
        isInSession && userNotCheckedIn,
    },
  );

  const buildUserTaskStatus = () => {
    if (userInfo.state === 'need help' || userInfo.state === 'done') {
      return (
        <span
          className='co-userScreensView-userScreen-taskSatus'
          data-testid={`userScreen-taskStatus-${userInfo.state}`}
          aria-label={
            userInfo.state === 'need help'
              ? I18n.t('need_help')
              : I18n.t('done')
          }
        >
          {userInfo.state === 'need help' ? <NeedHelpIcon /> : <DoneIcon />}
        </span>
      );
    }

    return null;
  };

  const buildOpenTabUrl = () => {
    if (userInfo && userInfo.activeWindowActiveTabUrl) {
      return userInfo.activeWindowActiveTabUrl;
    }

    return `https://${currentHost}`;
  };

  const buildOpenTabLink = () => {
    if (currentHost && !emptyStreamMessage && isInSession) {
      const formattedUrl = formatScreensViewUrl(currentHost);
      return (
        <a
          href={buildOpenTabUrl()}
          className='truncate'
          target='_blank'
          rel='noopener noreferrer'
          onClick={(e) => e.stopPropagation()}
        >
          {formattedUrl}
        </a>
      );
    }

    return null;
  };

  const buildUserScreen = () => {
    if (userInfo.online && isInSession && webrtcError) {
      return (
        <div>
          <NoImageIcon className='co-userScreensView-userScreen-noImageIcon' />
          <span className='co-userScreensView-userScreen-noImageMessage'>
            {I18n.t('connection_error')}
          </span>
        </div>
      );
    }

    if (webrtc && webrtc.connection.connectionState !== 'connected') {
      return <DataLoader />;
    }

    if (userInfo.online && !emptyStreamMessage && isInSession) {
      return (
        <>
          <video
            ref={videoElement}
            className='co-userScreensView-userScreen-video'
            autoPlay
            muted
          />
          <div className='co-userScreensView-userScreen-expandIcon'>
            <ExpandIcon />
          </div>
        </>
      );
    }

    if (!userInfo.online || emptyStreamMessage || !isInSession) {
      return (
        <div>
          <NoImageIcon className='co-userScreensView-userScreen-noImageIcon' />
          <span className='co-userScreensView-userScreen-noImageMessage'>
            {userInfo.online && isInSession
              ? I18n.t(emptyStreamMessage)
              : I18n.t('offline')}
          </span>
        </div>
      );
    }
  };

  return (
    <UpdateStatus isActive={isMoving}>
      <div
        ref={preview}
        className='co-userScreensView-userScreen'
        onClick={() => openFlyout(userInfo.guid)}
      >
        {showContent &&
          <>
            <div
              className='co-userScreensView-userScreen-header'
              data-testid='co-userScreensView-userScreen-header'
            >
              <h4 className='truncate'>
                {userInfo.firstNameLastInitial}
                <span
                  className={checkInStatusClasses}
                  data-testid={`userScreensView-userScreen-${userInfo.checkInStatus}`}
                />
              </h4>
              {isInSession &&
                userInfo.checkInStatus === 'checked_in' &&
                buildUserTaskStatus()}
            </div>
            <div className='co-userScreensView-userScreen-videoContainer'>
              {buildUserScreen()}
            </div>
            <div className='co-userScreensView-userScreen-footer'>
              {buildOpenTabLink()}
              <button ref={drag} className='co-userScreensView-userScreen-dragIcon'>
                <DragHandleIcon />
              </button>
            </div>
          </>
        }
      </div>
    </UpdateStatus>
  );
};

UserScreenCard.propTypes = {
  ablyData: PropTypes.object.isRequired,
  currentGroup: PropTypes.object.isRequired,
  dragType: PropTypes.string.isRequired,
  initFlyout: PropTypes.func.isRequired,
  inSession: PropTypes.array.isRequired,
  isMoving: PropTypes.bool,
  openFlyout: PropTypes.func.isRequired,
  shutdownFlyout: PropTypes.func.isRequired,
  streams: PropTypes.object.isRequired,
  userInfo: PropTypes.object.isRequired,
  allWebrtcs: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  ablyData: state.ably.data,
  currentGroup: getCurrentGroup(state.groups),
  inSession: state.classes.inSession,
  streams: state.webrtcs.streams,
  allWebrtcs: state.webrtcs.all,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  initFlyout,
  openFlyout,
  shutdownFlyout,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(UserScreenCard);
