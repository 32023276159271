import {
  connectGroupChannels,
  disconnectAllChannelsExceptBroadcast,
  unlockScreen,
  updatePolicy,
} from '../ably/actions';
import { stopBroadcast } from '../broadcast/actions';
import { updateSchedule } from '../groups/actions';
import { clearAllInsights, clearInsightSharedHosts } from '../insights/actions';
import { clearDisplayedUsers } from '../users/actions';
import { resetBrowsingTimeout } from '../ui/actions';

import { createDummySchedule } from '../../utilities/schedule';
import {
  getDayScheduleTimes,
  isValidScheduleTimes,
  isAfterSession,
  isInSession,
} from '../../utilities/scheduleTimes';

export const ADD_EVENT = 'ADD_EVENT';
export const REMOVE_EVENTS = 'REMOVE_EVENTS';
export const ADD_IN_SESSION_GROUP = 'ADD_IN_SESSION_GROUP';
export const REMOVE_IN_SESSION_GROUP = 'REMOVE_IN_SESSION_GROUP';
export const ADD_CONNECTED_GROUP = 'ADD_CONNECTED_GROUP';
export const REMOVE_CONNECTED_GROUP = 'REMOVE_CONNECTED_GROUP';

export function updateEvents(groupOrGroups, now = new Date()) {
  return (dispatch) => {
    const groups = [].concat(groupOrGroups);

    for (const group of groups) {
      if (group && !group.hidden) {
        const isAdHoc = group.schedule_type === 'ad_hoc';
        dispatch(updateClassEvents(group.guid, group.class_schedule, now, isAdHoc));
      }
    }

    return dispatch(processEvents());
  };
}

export function updateClassEvents(guid, schedule, now = new Date(), isAdHoc = false) {
  return (dispatch, getState) => {
    dispatch(removeEvents(guid));

    const times = getDayScheduleTimes(schedule);
    const timesAreValid = isValidScheduleTimes(times);
    const classShouldBeStopped = !timesAreValid || !isInSession(times, now);
    if (classShouldBeStopped) {
      const state = getState();
      const inSession = state.classes.inSession.includes(guid);
      if (inSession) {
        dispatch(addEvent(now, 'classStop', guid));
      }
    }

    if (!timesAreValid || isAfterSession(times, now)) {
      return {};
    }

    if (isAdHoc) {
      dispatch(addEvent(times.end, 'scheduleReset', guid));
    }
    dispatch(addEvent(times.end, 'classStop', guid));
    return dispatch(addEvent(times.start, 'classStart', guid));
  };
}

export function processEvents(now = Date.now()) {
  return (dispatch, getState) => {
    const state = getState();
    const events = state.classes.events;

    let i = 0;
    while (i < events.length && events[i].date.getTime() < now) {
      const event = events[i];
      switch (event.type) {
        case 'classStart':
          dispatch(startClass(event.guid));
          break;
        case 'classStop':
          dispatch(stopClass(event.guid));
          break;
        case 'scheduleReset':
          dispatch(updateSchedule(event.guid, createDummySchedule()));
          break;
        default:
          break;
      }
      dispatch(removeEvents(event.guid, event.type, event.date));
      i++;
    }

    return dispatch(updateGroupConnections());
  };
}


export function startClass(guid) {
  return (dispatch, getState) => {
    const state = getState();
    const inSession = state.classes.inSession.includes(guid);
    if (inSession) {
      return {};
    }

    return dispatch({ type: ADD_IN_SESSION_GROUP, guid });
  };
}

export function stopClass(guid) {
  return (dispatch, getState) => {
    const state = getState();
    const inSession = state.classes.inSession.includes(guid);
    if (!inSession) {
      return {};
    }

    dispatch(resetBrowsingTimeout());
    dispatch(unlockScreen());
    dispatch(clearAllInsights());
    dispatch(clearInsightSharedHosts(guid));
    dispatch(clearDisplayedUsers());
    return dispatch({ type: REMOVE_IN_SESSION_GROUP, guid });
  };
}

export function updateGroupConnections() {
  return (dispatch, getState) => {
    const state = getState();
    const currentGuid = state.groups.currentGuid;
    const loadedGuid = state.groups.loadedGuid;
    const inSession = state.classes.inSession;
    const connected = state.classes.connected;

    for (const guid of connected) {
      const isCurrent = guid === currentGuid;
      const isInSession = inSession.includes(guid);
      if (!isCurrent || !isInSession) {
        dispatch(disconnectGroup(guid));
      }
    }

    const isInSession = inSession.includes(currentGuid);
    const isLoaded = currentGuid === loadedGuid;
    const isConnected = connected.includes(currentGuid);
    if (isInSession && isLoaded && !isConnected) {
      dispatch(connectGroup(currentGuid));
    }
  };
}

export function connectGroup(guid) {
  return (dispatch) => {
    dispatch(connectGroupChannels(guid));
    return dispatch({ type: ADD_CONNECTED_GROUP, guid });
  };
}

export function disconnectGroup(guid) {
  return async (dispatch) => {
    await dispatch(stopBroadcast(guid));
    dispatch({ type: REMOVE_CONNECTED_GROUP, guid });
    return dispatch(disconnectAllChannelsExceptBroadcast());
  };
}

export function updateGroup() {
  return (dispatch, getState) => {
    const state = getState();
    const guid = state.groups.currentGuid;
    const isConnected = state.classes.connected.includes(guid);

    if (isConnected) {
      return dispatch(updatePolicy());
    }
  };
}

function addEvent(date, eventType, guid) {
  return {
    type: ADD_EVENT,
    date,
    eventType,
    guid,
  };
}

function removeEvents(guid, eventType, date) {
  return {
    type: REMOVE_EVENTS,
    guid,
    eventType,
    date,
  };
}
