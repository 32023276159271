import React from 'react';
import ReactDOM from 'react-dom';
import throttle from 'lodash.throttle';

import { store } from './store';
import Root from './Root';
import registerServiceWorker from './registerServiceWorker';

import setupBrowserFeatures from './helpers/browserFeatures';
import ClassroomDebug from './helpers/debug';
import { saveLocalStorage } from './helpers/localStorage';
import * as microsoftTeams from '@microsoft/teams-js';
import { handleOneTrust } from './helpers/cookies';

microsoftTeams.initialize();
microsoftTeams.getContext(() => {
  handleOneTrust(true);
});
handleOneTrust();

store.subscribe(throttle(() => {
  saveLocalStorage('ClassroomReduxState', {
    insights: store.getState().insights,
  });
}));

setupBrowserFeatures();
window.classroomDebug = new ClassroomDebug(store);

ReactDOM.render(<Root store={store} />, document.getElementById('root'));
registerServiceWorker();
