import {
  RECEIVE_GROUP_BETAS,
  RECEIVE_SCHOOL_BETAS,
  RECEIVE_DISTRICT_BETAS,
} from './actions';

export const defaultState = {
  group: {},
  school: {},
  district: {},
};

export const betasReducer = (state = defaultState, action) => {
  switch (action.type) {
    case RECEIVE_GROUP_BETAS: {
      return {
        ...state,
        group: action.betas,
      };
    }
    case RECEIVE_SCHOOL_BETAS: {
      return {
        ...state,
        school: action.betas,
      };
    }
    case RECEIVE_DISTRICT_BETAS: {
      return {
        ...state,
        district: action.betas,
      };
    }
    default:
      return state;
  }
};
