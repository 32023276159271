import React from 'react';
import PropTypes from 'prop-types';
import { CSSTransition, TransitionGroup } from 'react-transition-group';


const PopFadeInOut = ({ children, condition, ...props }) => {
  return (
    <TransitionGroup>
      {condition && (
        <CSSTransition
          {...props}
          timeout={{ enter: 300, exit: 200 }}
          classNames='popFadeInOut'
        >
          {children}
        </CSSTransition>
      )}
    </TransitionGroup>
  );
};

PopFadeInOut.propTypes = {
  children: PropTypes.object,
  condition: PropTypes.bool.isRequired,
};

export default PopFadeInOut;
