import format from 'date-fns/format';
import { isRealNumber } from './types';

import {
  dateToFloatTime,
  parseFloatTime,
  floatTimeToString,
  minutesLater,
} from './time';
import I18n from './i18n';

export function isValidSchedule(schedule) {
  if (schedule === null) {
    return true;
  }
  if (typeof schedule !== 'object') {
    return false;
  }

  const dayKeys = Object.keys(schedule);
  if (dayKeys.length === 0) {
    return false;
  }

  for (const dayKey of dayKeys) {
    const validDays = ['0', '1', '2', '3', '4', '5', '6'];
    const days = dayKey.split('');
    const daysValid = days.every((day) => validDays.includes(day));
    if (!daysValid) {
      return false;
    }

    const times = schedule[dayKey];
    if (isNaN(times.e) || isNaN(times.s)) {
      return false;
    }
    if (times.e < times.s) {
      return false;
    }
  }

  return true;
}

export function parseScheduleDays(schedule, keepAsFloat = false, sundayNumber = 0) {
  if (!schedule) {
    return [];
  }

  const allKeys = Object.keys(schedule);
  const days = {};
  let start = null;
  let end = null;
  const scheduleDays = [];
  const dayIndices = [sundayNumber, 1, 2, 3, 4, 5, 6];
  const dayAbbr = {
    [sundayNumber]: 'su',
    1: 'mo',
    2: 'tu',
    3: 'we',
    4: 'th',
    5: 'fr',
    6: 'sa',
  };

  for (const i in allKeys) {
    allKeys[i].split('').forEach(function(dayNumber) {
      days[dayNumber] = {
        ...schedule[allKeys[i]],
        rowIndex: parseInt(i, 10) + 1,
      };
    });
  }

  for (let i in dayIndices) {
    i = dayIndices[i];
    start = null;
    end = null;

    if (days[i]) {

      if (keepAsFloat) {
        start = days[i].s;
        end = days[i].e;
      } else {
        start = days[i].s ? floatTimeToString(days[i].s) : null;
        end = days[i].e ? floatTimeToString(days[i].e) : null;
      }

      scheduleDays.push({
        day: dayAbbr[i],
        start: start,
        end: end,
        rowIndex: days[i].rowIndex,
      });
    } else {
      scheduleDays.push({
        day: dayAbbr[i],
        start: null,
        end: null,
        rowIndex: null,
      });
    }
  }

  return scheduleDays;
}

export function calculateDuration(start, end) {
  if (isRealNumber(start) && isRealNumber(end)) {
    const [startHours, startMinutes] = parseFloatTime(start);
    const [endHours, endMinutes] = parseFloatTime(end);
    return (60 * (endHours - startHours) + (endMinutes - startMinutes));
  }

  return 0;
}

export function hasValidScheduleDuration(classSchedule, maxDuration) {
  const scheduleDays = parseScheduleDays(classSchedule);
  return !scheduleDays.some((day) => {
    if (day.start && day.end) {
      const start = parseFloat(day.start.replace(/:/, '.'));
      const end = parseFloat(day.end.replace(/:/, '.'));
      return calculateDuration(start, end) > maxDuration;
    }
    return false;
  });
}

export function filterBellSet(bells) {
  return bells.filter((bell) => isValidBell(bell));
}

export function isValidBellSet(bells) {
  if (!Array.isArray(bells)) {
    return false;
  }

  return bells.every((bell) => isValidBell(bell));
}

export function isValidBell(bell) {
  const periods = getBellPeriods(bell);
  if (periods.length < 2) {
    return false;
  }

  if (periods.some((period) => period === null)) {
    return false;
  }

  const timePeriods = periods.slice(0, -1);
  for (const period of timePeriods) {
    if (period.schedule === null || !isValidSchedule(period.schedule)) {
      return false;
    }
  }

  return true;
}

export function getBellPeriods(bell) {
  if (!bell || typeof bell !== 'object' || !bell.schedule) {
    return [];
  }

  const daysKey = Object.keys(bell.schedule)[0];
  if (!daysKey) {
    return [];
  }

  const times = bell.schedule[daysKey];
  return times.map((_, index) => (
    getBellPeriod(index, bell.schedule)
  ));
}

export function getBellPeriod(index, bellSchedule) {
  const daysKey = Object.keys(bellSchedule)[0];
  const times = bellSchedule[daysKey];

  if (index >= times.length) {
    return null;
  }

  const startSlot = times[index];
  const isDismissal = Boolean(startSlot.e);
  let name;
  let startTime;
  let schedule = null;

  if (isDismissal) {
    name = I18n.t('dismissal');
    startTime = startSlot.e;
  } else if (index + 1 < times.length) {
    const endSlot = times[index + 1];
    name = startSlot.n;
    startTime = startSlot.s;
    const endTime = endSlot.s || endSlot.e;
    const s = parseFloat(startTime);
    const e = endTime && parseFloat(endTime);
    schedule = { [daysKey]: { s, e } };
  } else {
    return null;
  }

  const [hour, minutes] = parseFloatTime(startTime);
  const date = new Date(0, 0, 0, hour, minutes);
  const time = format(date, 'h:mma');

  return {
    name,
    time,
    schedule,
  };
}

export function isSameBellTimes(period0, period1) {
  if (!period0 || !period1) {
    return false;
  }

  return (period0.s === period1.s) && (period0.e === period1.e);
}

export function alignScheduleToBells(schedule, bellSchedules) {
  if (!schedule) {
    return null;
  }

  return bellSchedules.reduce((aligned, bell) => {
    const periods = getBellPeriods(bell);
    const match = periods.find((period) => {
      if (period.schedule) {
        const periodDaysKey = Object.keys(period.schedule)[0];
        const periodTimes = period.schedule[periodDaysKey];
        const scheduleTimes = schedule[periodDaysKey];
        return isSameBellTimes(periodTimes, scheduleTimes);
      }
      return false;
    });

    if (match) {
      return {
        ...aligned,
        ...match.schedule,
      };
    }
    return aligned;
  }, {});
}

export function createAdHocSchedule(start, minutes) {
  const end = minutesLater(start, minutes);
  const startTime = dateToFloatTime(start);
  const endTime = dateToFloatTime(end);
  const day = start.getDay();

  return {
    [day]: {
      s: startTime,
      e: endTime,
    },
  };
}

export function createDummySchedule() {
  return {
    0: {
      s: .01,
      e: .02,
    },
  };
}

function isDummySchedule(schedule) {
  return Object.keys(schedule).length === 1 &&
  ((
    schedule[0] &&
    schedule[0].s === 0.01 &&
    schedule[0].e === 0.02
  ) ||
  (
    schedule[1] &&
    schedule[1].s === 0.01 &&
    schedule[1].e === 0.02
  ));
}

export function isScheduleTimeAllowed(schedule, allowedHours, scheduleType = '') {
  if (scheduleType === 'bell') {
    return true;
  }

  if (!schedule || !allowedHours || Object.keys(allowedHours).length === 0) {
    //all times allowed
    return true;
  }

  if (isDummySchedule(schedule)) {
    //ignore dummy schedule
    return true;
  }

  if (getDisabledDays(schedule, allowedHours).length > 0) {
    return false;
  }

  const expandedSchedule = expandScheduleObject(schedule);
  const expandedAllowedHours = expandScheduleObject(allowedHours);

  // check if schedule has times outside allowed times
  for (const allowedDay of Object.keys(expandedAllowedHours)) {
    for (const scheduledDay of Object.keys(expandedSchedule)) {
      const allowedTimes = expandedAllowedHours[allowedDay];
      const scheduledTimes = expandedSchedule[scheduledDay];
      if (
        allowedDay === scheduledDay &&
        scheduledTimes !== null && allowedTimes !== null &&
        (scheduledTimes.s < allowedTimes.s || scheduledTimes.e > allowedTimes.e)
      ) {
        return false;
      }
    }
  }
  return true;
}

export function getDisabledDays(schedule, allowedHours) {
  const expandedSchedule = expandScheduleObject(schedule, false);
  const disabledDays = [];
  Object.keys(expandedSchedule).forEach((scheduleDay) => {
    if (isDayDisabled(allowedHours, scheduleDay)) {
      disabledDays.push(scheduleDay);
    }
  });
  return disabledDays;
}

export function convertArrayToSchedule(scheduleArray) {
  //convert from array to object, for db store
  if (!Array.isArray(scheduleArray)) {
    return null;
  }
  const schedule = {};
  scheduleArray.forEach((row) => {
    if (row.days !== '') {
      schedule[row.days] = row.times;
    }
  });
  return schedule;
}

export function convertScheduleToArray(schedule) {
  //convert schedule obj into array for easier use
  if (typeof schedule !== 'object' || Array.isArray(schedule)) {
    return null;
  }
  const startingRow = { days: '', times: { s: '', e: '' } };
  if (!schedule || Object.keys(schedule).length === 0) {
    return [startingRow];
  }
  return Object.keys(schedule).map((i) => {
    return { days: i, times: schedule[i] };
  });
}

export function isDayDisabled(allowedHours, dayValue) {
  if (!allowedHours || Object.keys(allowedHours).length === 0) {
    return false;
  }

  const expandedAllowedHours = expandScheduleObject(allowedHours);
  if ((dayValue in expandedAllowedHours) && expandedAllowedHours[dayValue] === null) {
    return true;
  }
  return false;
}

export function expandScheduleObject(schedule, addDisabled = true) {
  if (!schedule) {
    return;
  }
  const times = {};

  Object.keys(schedule).forEach((hoursKey) => {
    const timeValues = schedule[hoursKey];
    const hoursKeyArray = hoursKey.toString().split('');

    hoursKeyArray.forEach((dayValue) => {
      times[dayValue] = timeValues;
    });
  });

  if (addDisabled && Object.keys(schedule).length > 0) {
    // indicate which days are disabled with null value
    [1, 2, 3, 4, 5, 6, 0].forEach((dayValue) => {
      if (
        !(dayValue in times) ||
        (typeof times[dayValue] === 'object' && times[dayValue] !== null && Object.keys(times[dayValue]).length === 0)
      ) {
        times[dayValue] = null;
      }
    });

  }
  return times;
}

export function removeDisabledDays(schedule, allowedHours) {
  const disabledScheduleDays = getDisabledDays(schedule, allowedHours);

  Object.keys(schedule).forEach((daysKey) => {
    const daysArray = daysKey.toString().split('');
    disabledScheduleDays.forEach((disabledDay) => {
      if (daysArray.includes(disabledDay)) {
        const oldDaysKey = daysKey;
        daysKey = daysKey.replace(disabledDay, '');
        if (daysKey !== '') {
          schedule[daysKey] = { ...schedule[oldDaysKey] };
        }
        delete schedule[oldDaysKey];
      }
    });
  });

  return schedule;
}

export function fixAllowedHoursObject(allowedHours) {
  //modifies the object passed in if it is wrong
  if (!allowedHours) {
    return;
  }
  const invalidKey = '67';
  const correctKey = '06';
  if (invalidKey in allowedHours) {
    //outdated allowed hours object incorrectly identifies Sunday as 7 instead of 0
    allowedHours[correctKey] = allowedHours[invalidKey];
    delete allowedHours[invalidKey];
  }
}
