import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { logoutUser } from '../../redux/currentUser/actions';
import I18n from '../../utilities/i18n';

import UserMenu from './UserMenu';
import { isInHomeDistrict, isSupportUser } from '../../utilities/users';
import LightspeedLogoTextOneLine from '../SignIn/LightspeedLogoTextOneLine';
import { QuestionMarkIcon } from 'design-system';
import Tooltip from './Tooltip/Tooltip';

const Header = ({
  logoutUser,
  currentUser,
}) => {
  const hasCompletedGettingStarted = localStorage.getItem('hasCompletedGettingStarted');
  const userIsInHomeDistrict = isInHomeDistrict(currentUser);

  const buildHelpButton = () => {
    let helpButtonUrl =
      'https://help.lightspeedsystems.com/s/datacatarticles?language=en_US&cat=RC%3FSubC%3DClassroom_for_Teachers';
    if (currentUser.is_admin) {
      helpButtonUrl = 'https://help.lightspeedsystems.com/s/product?language=en_US&cat=RC';
    }

    return (
      <a href={helpButtonUrl} className='button-link co-header-help' target='_blank' rel='noopener noreferrer'>
        <QuestionMarkIcon className='co-header-help-icon' />
      </a>
    );
  };

  const buildOutOfDistrictMessage = () => {
    if (userIsInHomeDistrict) {
      return null;
    }

    if (isSupportUser(currentUser)) {
      return (
        <p className='alert outOfDistrict-message'>
          {I18n.t('you_are_viewing_this_class_as_a_support_user')}
        </p>
      );
    }

    return (
      <p
        data-testid='shareScreenModal-error'
        className='alert outOfDistrict-message'
      >
        {I18n.t('you_are_viewing_a_district_other_than_your_own')}
      </p>
    );
  };

  return (
    <header className='co-header'>
      <Link to='/'>
        <LightspeedLogoTextOneLine className='co-header-home-link'/>
      </Link>

      {buildOutOfDistrictMessage()}
      <div className='co-header-actions'>
        {!hasCompletedGettingStarted && currentUser.is_admin && (
          <Link to='/getting-started' className='button button--yellow'>
            {I18n.t('getting_started')}
          </Link>
        )}

        <UserMenu logoutUser={logoutUser} currentUser={currentUser} />

        <Tooltip
          className='co-header-help-tooltip'
          content={I18n.t('help_page')}
          aria-label={I18n.t('help_page')}
          position='left'
          size='small'
          customClassTwo='centerText'
        >
          {buildHelpButton()}
        </Tooltip>
      </div>
    </header>
  );
};

Header.propTypes = {
  currentUser: PropTypes.object.isRequired,
  logoutUser: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  currentUser: state.currentUser,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  logoutUser,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Header);
