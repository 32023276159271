import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classNames from 'classnames';

import { saveLastSchedule, savePolicySetting } from '../../redux/policy/actions';
import { addStudent, updateSchedule } from '../../redux/groups/actions';
import { addNotification, openModal, closeModal } from '../../redux/ui/actions';
import { createInviteCode } from '../../redux/invites/actions';

import {
  isClassroomEnabled,
  maximumPeriodDuration,
  canEditSchedule,
  allScheduleTypes,
  validScheduleTypes,
} from '../../utilities/policy';
import { getCurrentGroup, getCurrentGroupIsLoaded } from '../../utilities/groups';
import I18n from '../../utilities/i18n';
import { getGroupUrl } from '../../utilities/urls';
import { isScheduleTimeAllowed } from '../../utilities/schedule';
import { getNotificationSettings } from '../../utilities/users';

import AddStudents from './AddStudents';
import AdHocSchedule from './Schedules/AdHocSchedule';
import BellSchedules from './Schedules/BellSchedules';
import CustomSchedule from './Schedules/CustomSchedule';
import Dropdown from '../common/Dropdown';
import GroupError from './GroupError';
import Icon from '../common/Icon';
import Loader from '../common/Loader';
import LoadingButton from '../common/LoadingButton';
import Modal from '../common/Modal';
import SISSync from './SISSync';
import Toggle from '../common/Toggle';

const GroupSettingsPage = ({
  history,
  currentUser,
  groupPolicy,
  currentGroup,
  currentGroupIsLoaded,
  currentlyOpenModal,
  addStudent,
  addNotification,
  openModal,
  closeModal,
  saveLastSchedule,
  savePolicySetting,
  updateSchedule,
  inviteCode,
  createInviteCode,
}) => {
  const modalName = 'resetSchedule';
  const currentScheduleType = currentGroup.schedule_type;
  const hasBulkCheckInsPolicy = groupPolicy?.bulk_check_ins;

  const [scheduleTypes, setScheduleTypes] = useState([]);
  const [selectedScheduleType, setSelectedScheduleType] = useState('ad_hoc');
  const [isSaving, setIsSaving] = useState(false);
  const [showNotifications, setShowNotifications] = useState(getNotificationSettings());

  useEffect(() => {
    if (inviteCode === '' && currentGroupIsLoaded) {
      createInviteCode(currentGroup.id, currentGroup.guid);
    }
  }, [inviteCode, currentGroupIsLoaded, createInviteCode, currentGroup.guid, currentGroup.id]);

  useEffect(() => {
    if (currentGroupIsLoaded) {
      let types = validScheduleTypes(groupPolicy, currentUser.is_admin);
      const firstValidType = allScheduleTypes().find((type) => types.includes(type));
      const settingIsValid = types.includes(currentScheduleType);
      const canEdit = canEditSchedule(groupPolicy, currentUser.is_admin);

      let type;
      if (canEdit) {
        if (settingIsValid) {
          type = currentScheduleType;
        } else {
          type = firstValidType;
        }
      } else {
        if (settingIsValid && (currentScheduleType !== 'ad_hoc')) {
          types = [currentScheduleType];
          type = currentScheduleType;
        } else {
          types = [];
        }
      }

      setScheduleTypes(types);
      setSelectedScheduleType(type);
    }
  }, [currentGroup, currentGroupIsLoaded, currentScheduleType, currentUser, groupPolicy]);


  const getSchedule = (type) => {
    if (type && (type !== currentScheduleType)) {
      const lastTypeSchedule = groupPolicy[`last_${type}`];
      const lastIsValid = lastTypeSchedule &&
        Object.keys(lastTypeSchedule).length > 0 &&
        isScheduleTimeAllowed(lastTypeSchedule, groupPolicy.allowed_hours, groupPolicy.schedule_type);

      if (lastIsValid) {
        return lastTypeSchedule;
      }
      return null;
    }
    if (type === 'custom' && currentGroup.invalid_schedule) {
      return currentGroup.invalid_schedule;
    }

    return currentGroup.class_schedule;
  };

  const saveSchedule = async (schedule) => {
    await saveLastSchedule(currentGroup.guid, selectedScheduleType, schedule);
    await savePolicySetting(currentGroup.guid, 'schedule_type', selectedScheduleType);
    await updateSchedule(currentGroup.guid, schedule);
    closeSettings();
  };

  const confirmResetSchedule = () => {
    openModal(modalName);
  };

  const handleToggle = () => {
    window.localStorage.setItem('showNotifications', !showNotifications);
    setShowNotifications(!showNotifications);
  };

  const resetSchedule = async () => {
    setIsSaving(true);
    await saveLastSchedule(currentGroup.guid, selectedScheduleType, null);
    await updateSchedule(currentGroup.guid, null);
    closeModal();
    setIsSaving(false);
    addNotification(I18n.t('your_schedule_has_been_reset'), { autoDismiss: true });
  };

  const closeSettings = () => {
    history.push(getGroupUrl(currentGroup));
  };

  const buildTypeDropdown = () => {
    if (scheduleTypes.length <= 1) {
      return null;
    }

    const canEdit = canEditSchedule(groupPolicy, currentUser.is_admin);
    const dropdownName = 'scheduleType';

    return (
      <section className='co-settings-section co-settings-section--flex'>
        <strong className='co-settings-section-title'>{I18n.t('type')}</strong>

        <Dropdown
          initialLabel={I18n.t(selectedScheduleType)}
          dropdownName={dropdownName}
          containerClasses='co-dropdown--scheduleType'
          dropdownMenuClasses='co-dropdown-menu--fullWidth'
          buttonLabelClasses='co-dropdown--scheduleType-button'
          showAsButton={canEdit}
          hasTriangleArrow={canEdit}
          hasArrow={false}
        >
          <ul>
            {buildTypeItems()}
          </ul>
        </Dropdown>
      </section>
    );
  };

  const buildTypeItems = () => {
    const buttonClasses = classNames({
      'co-dropdown-item': true,
      'button-link': true,
    });

    return scheduleTypes.map((type) => {
      const label = I18n.t(type);

      return (
        <li key={type}>
          <button
            className={buttonClasses}
            onClick={() => setSelectedScheduleType(type)}
            data-testid='GroupSettingsPage-dropdown-button'
          >
            {label}
          </button>
        </li>
      );
    });
  };

  const buildMessage = () => {
    const noScheduleTypes = scheduleTypes.length === 0;
    const readOnly = !canEditSchedule(groupPolicy, currentUser.is_admin);
    if (noScheduleTypes || readOnly) {
      return (
        <div className='alert m-bottom--16'>
          {noScheduleTypes && I18n.t('no_schedule_has_been_configured')}
          {!noScheduleTypes && readOnly && I18n.t('the_schedule_can_only_be_modified_by_an_administrator')}
        </div>
      );
    }

    return null;
  };

  const buildModal = () => {
    const scheduleType = selectedScheduleType ? I18n.t(selectedScheduleType) : '';

    const customSaveButton = (
      <LoadingButton
        classes='co-modal-warningButton'
        onClick={resetSchedule}
        label={I18n.t('reset_schedule')}
        isDisabled={isSaving}
        isLoading={isSaving}
        warningButton
        displayTextWhileLoading
      />
    );

    return (
      <Modal
        show={currentlyOpenModal === modalName}
        title={I18n.t('reset_schedule')}
        customSaveButton={customSaveButton}
        closeModal={closeModal}
        isSmall
        legacyModalVersion
        modalRedesign2022Q1
      >
        <p className='alert alert--warning'>
          {I18n.t('are_you_sure_you_want_to_reset_your', { scheduleType })}
        </p>
      </Modal>
    );
  };

  const buildScheduleType = () => {
    const maxDuration = maximumPeriodDuration(groupPolicy);
    const readOnly = !canEditSchedule(groupPolicy, currentUser.is_admin);
    const schedule = getSchedule(selectedScheduleType);
    const isCurrentType = selectedScheduleType === currentGroup.schedule_type;
    const bellSchedules = groupPolicy.bell_schedules || [];
    const allowedHours = groupPolicy.allowed_hours || {};

    switch (selectedScheduleType) {
      case 'bell':
        return (
          <BellSchedules
            schedule={schedule}
            isCurrentType={isCurrentType}
            readOnly={readOnly}
            bellSchedules={bellSchedules}
            saveSchedule={saveSchedule}
            resetSchedule={confirmResetSchedule}
            closeSettings={closeSettings}
          />
        );
      case 'custom':
        return (
          <CustomSchedule
            schedule={schedule}
            allowedHours={allowedHours}
            isCurrentType={isCurrentType}
            readOnly={readOnly}
            maxDuration={maxDuration}
            saveSchedule={saveSchedule}
            resetSchedule={confirmResetSchedule}
            closeSettings={closeSettings}
          />
        );
      case 'ad_hoc':
        return (
          <AdHocSchedule
            readOnly={readOnly}
            allowedHours={allowedHours}
            maxDuration={maxDuration}
            saveSchedule={saveSchedule}
          />
        );
      default:
        return null;
    }
  };

  const buildScheduleSection = () => {
    return (
      <div>
        <h3 className='co-schedules-title m-top--48'>{I18n.t('schedule')}</h3>
        {buildModal()}
        {buildTypeDropdown()}
        {buildMessage()}
        {buildScheduleType()}
      </div>
    );
  };

  if (!currentGroupIsLoaded) {
    return (
      <Loader />
    );
  }

  if (!isClassroomEnabled(groupPolicy)) {
    return (
      <GroupError
        title={I18n.t('group_unavailable')}
        message={I18n.t('your_administrator_has_not_enabled_monitoring_for_this_group')}
      />
    );
  }

  const buildNotificationToggle = () => {
    return (
      <section className='co-settings-invitesContainer'>
        <h3 className='m-top--48'>{I18n.t('toggle_notifications')}</h3>
        <section className='co-settings-notifications'>
          <Toggle
            className='m-right--8'
            onClick={handleToggle}
            checked={showNotifications}
            id={'settings-notification-toggle'}
          />
          <span className='co-settings-notifications-text'>
            {hasBulkCheckInsPolicy
              ? I18n.t(
                'receive_notifications_when_any_of_your_students_gets_checked_into_another_class',
              )
              : I18n.t(
                'receive_notifications_when_one_of_your_students_gets_checked_into_a_new_class',
              )}
          </span>
        </section>
      </section>
    );
  };

  return (
    <section className='co-settings' data-testid='GroupSettingsPage-content'>
      <div className='width--100pct co-settings-content'>
        <button
          data-testid='groupSettings-back'
          className='button-link link--underlineHover text--orange m-bottom--8 inlineBlock'
          onClick={closeSettings}
        >
          <Icon name='icon-left-2' classes='p-right--8 text--big' />
          {I18n.t('back')}
        </button>

        <h1 className='m-bottom--24'>{I18n.t('class_settings')}</h1>
        {buildScheduleSection()}
        <SISSync
          authCustomerId={currentUser.auth_customer_id}
          currentGroup={currentGroup}
          groupPolicy={groupPolicy}
        />

        <AddStudents
          authCustomerId={currentUser.auth_customer_id}
          groupPolicy={groupPolicy}
          groupId={currentGroup.id}
          addStudent={addStudent}
          inviteCode={inviteCode}
          currentGroupGuid={currentGroup.guid}
        />

        {buildNotificationToggle()}
      </div>
    </section>
  );
};

GroupSettingsPage.defaultProps = {
  groupPolicy: {},
  currentGroup: {},
};

GroupSettingsPage.propTypes = {
  currentUser: PropTypes.object.isRequired,
  groupPolicy: PropTypes.object.isRequired,
  currentGroup: PropTypes.object.isRequired,
  currentGroupIsLoaded: PropTypes.bool.isRequired,
  currentlyOpenModal: PropTypes.string,
  addStudent: PropTypes.func.isRequired,
  addNotification: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  saveLastSchedule: PropTypes.func.isRequired,
  savePolicySetting: PropTypes.func.isRequired,
  updateSchedule: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  inviteCode: PropTypes.string,
  createInviteCode: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  currentUser: state.currentUser,
  groupPolicy: state.policy.group,
  currentGroup: getCurrentGroup(state.groups),
  currentGroupIsLoaded: getCurrentGroupIsLoaded(state.groups),
  currentlyOpenModal: state.ui.currentlyOpenModal,
  inviteCode: state.invites.inviteCode,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  addStudent,
  addNotification,
  openModal,
  closeModal,
  saveLastSchedule,
  savePolicySetting,
  updateSchedule,
  createInviteCode,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(GroupSettingsPage);
