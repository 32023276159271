import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  updatePolicy,
} from '../../../redux/ably/actions';
import {
  closeFlyout,
  openModal,
  closeModal,
  getWebRulesSection,
  getCurrentSegmentGuid,
} from '../../../redux/ui/actions';
import { getCurrentGroup, getSegmentByGuid } from '../../../utilities/groups';
import I18n from '../../../utilities/i18n';
import { webRulesInit } from '../../../utilities/webRules';
import {
  getAllowWhenDone,
  getAllowSSO,
  getWebRulesCustomAllowSites,
  getWebRulesCustomBlockSites,
  getWebRulesCheckedListNames,
  getWebRulesType,
  isCustom,
  isNoBrowsing,
} from '../../../utilities/webzones';
import { updateNoBrowsing, removeWebzone, setCustomWebzone } from '../../../redux/webzones/actions';
import { getWebRules } from '../../../redux/webRules/actions';
import { loadLocalStorage, saveLocalStorage } from '../../../helpers/localStorage';
import { modifySegment } from '../../../redux/groupSegments/actions';
import { getEnvSpecificUrl } from '../../../utilities/urls';

import Modal from '../Modal';
import UrlChecker from '../UrlChecker';
import Icon from '../Icon';
import ListsContainer from './WebRuleLists/ListsContainer';
import ErrorMessage from '../ErrorMessage/ErrorMessage';

const WebRulesFlyout = ({
  segmentGuid,
  currentGroup,
  currentSegments,
  currentUser,
  currentlyOpenModal,
  closeFlyout,
  openModal,
  closeModal,
  currentWebzone,
  updatePolicy,
  setCustomWebzone,
  removeWebzone,
  modifySegment,
  updateNoBrowsing,
  getWebRules,
  getCanClose,
  getWebRulesSection,
  currentWebRulesSection,
  currentSegmentGuid,
  getCurrentSegmentGuid,
  deletedListName,
  editedList,
  currentlyOpenFlyout,
  webRulesFromRedux,
}) => {
  const [errors, setErrors] = useState(0);
  const [entries, setEntries] = useState([]);
  const [initialCustomSites, setInitialCustomSites] = useState([]);
  const [initialCustomAllowSites, setInitialCustomAllowSites] = useState([]);
  const [initialCustomBlockSites, setInitialCustomBlockSites] = useState([]);
  const [initialCheckedLists, setInitialCheckedLists] = useState([]);
  const [isEdited, setIsEdited] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const modalName = 'webRulesCancel';
  const segment = getSegmentByGuid(segmentGuid, currentSegments);
  const webRules = webRulesInit(segment, currentWebzone);
  const [currentSection, setCurrentSection] = useState(getWebRulesType(webRules));
  const [allowWhenDone, setAllowWhenDone] = useState(getAllowWhenDone(webRules));
  const [allowSSO, setAllowSSO] = useState(getAllowSSO(webRules));
  const localStorageWebzone = segment ? `ClassroomLastCustom::g::${segment.guid}` : 'ClassroomLastCustom';
  const localStorageWebzoneType = segment ? `ClassroomLastWebzoneType::g::${segment.guid}` : 'ClassroomLastWebzoneType';
  const hasInvalidEntries = errors > 0 || entries.length > 100;
  const [initialSection, setInitialSection] = useState('');
  const [selectedWebListsNames, setSelectedWebListsNames] = useState([]);
  const [selectedWebListsUrls, setSelectedWebListsUrls] = useState([]);
  const [listChange, setListChange] = useState(false);
  const [listNameToDelete, setListNameToDelete] = useState(null);
  const [editedListToUpdate, setEditedListToUpdate] = useState(null);
  const [list, setList] = useState([]);
  const [allowWhenDoneChanged, setAllowWhenDoneChanged] = useState(false);
  const [allowSSOChanged, setAllowSSOChanged] = useState(false);
  const editedRef = useRef();
  const modalNeededBeforeClose = isEdited || listChange || allowSSOChanged || allowWhenDoneChanged;

  useEffect(() => {
    if (currentSection === 'allow') {
      setList(Object.entries(webRulesFromRedux.allowList));
      setInitialCustomSites(initialCustomAllowSites);
    }
    if (currentSection === 'block') {
      setList(Object.entries(webRulesFromRedux.blockList));
      setInitialCustomSites(initialCustomBlockSites);
    }
  }, [webRulesFromRedux, currentSection, initialCustomAllowSites, initialCustomBlockSites]);

  const getSelectedWebRulesLists = (selectedLists, selectedListsUrls, listChanged) => {
    setSelectedWebListsNames(selectedLists);
    setSelectedWebListsUrls(selectedListsUrls);
    setListChange(listChanged);
  };

  useEffect(() => {
    if (currentlyOpenFlyout === null) {
      getCurrentSegmentGuid(null);
    }
  }, [currentlyOpenFlyout, getCurrentSegmentGuid]);

  useEffect(() => {
    if (currentSection !== 'none') {
      getCanClose(modalNeededBeforeClose);
    }
  }, [modalNeededBeforeClose, isEdited, getCanClose]);

  const sections = [
    { name: 'none', text: 'no_web_rules_selected' },
    { name: 'allow', text: 'students_can_only_visit_websites_you_list' },
    { name: 'block', text: 'most_websites_can_be_visited_except_those_you_specifically_block' },
  ];

  useEffect(() => {
    getWebRulesSection(currentSection);
    getWebRules();
  }, [currentSection, getWebRulesSection, getWebRules]);

  useEffect(() => {
    if (deletedListName) {
      setListNameToDelete(deletedListName);
    }
    if (editedList) {
      setEditedListToUpdate(editedList);
    }
  }, [editedList, deletedListName]);

  useEffect(() => {
    if (listNameToDelete) {
      handleListChange(listNameToDelete);
    }
    if (editedListToUpdate) {
      handleListChange(editedListToUpdate.name);
    }
  }, [editedListToUpdate, listNameToDelete]);

  const handleListChange = async (listNameToFilter) => {
    if (editedListToUpdate) {
    // if the edited list was a selected list (checked list), then we need to update the webzone
    // otherwise, no webzone update is needed
      const isEditedListChecked = initialCheckedLists.filter((x) => x === listNameToFilter);
      if (isEditedListChecked.length === 0) {
        return;
      }
    }
    setIsSaving(true);
    saveLocalStorage(localStorageWebzoneType, currentSection);
    const prunedNames = initialCheckedLists.filter((x) => x !== listNameToFilter);
    const newNames = editedListToUpdate ? [...prunedNames, editedListToUpdate.newName] : prunedNames;
    const getUpdatedEntries = list.filter((listEntry) => newNames.includes(listEntry[0]));
    const newUrls = getUpdatedEntries.map((x) => x[1].urls).flat(Infinity);
    const customArray = {
      allow: initialCustomAllowSites,
      block: initialCustomBlockSites,
    };
    setInitialCheckedLists(newNames);
    const sitesArray = [customArray[currentSection], ...newUrls].flat(Infinity);
    const blockedCheckboxesOnly = currentSection === 'block' && !allowWhenDone;
    const allowedCheckboxesOnly = currentSection === 'allow' && !allowWhenDone && !allowSSO;
    if (sitesArray.length === 0 && (allowedCheckboxesOnly || blockedCheckboxesOnly)) {
      // removing the applied sites array with applied lists and custom urls,
      // but preserving the custom urls
      await handleSaveCustomWebzone([], []);
      await handleSaveNoneWebzone();
    } else {
      await handleSaveCustomWebzone(sitesArray, newNames, customArray[currentSection]);
    }
    updatePolicy();
    setListNameToDelete(null);
    setEditedListToUpdate(null);
    setIsSaving(false);
  };

  useEffect(() => {
    if (isSaving) {
      return;
    }
    if (isCustom(webRules)) {
      const section = getWebRulesType(webRules);
      loadFromSettings(webRules.settings, section);
    } else {
      loadFromSettings(loadLocalStorage(localStorageWebzone), 'none');
    }
  }, [currentWebzone, segmentGuid, localStorageWebzone, isSaving]);

  const loadFromSettings = (settings, section) => {
    const customAllowSites = getWebRulesCustomAllowSites(settings, segment);
    const customBlockSites = getWebRulesCustomBlockSites(settings, segment);
    const checkedLists = getWebRulesCheckedListNames(settings, segment);
    setInitialCustomAllowSites(customAllowSites);
    setInitialCustomBlockSites(customBlockSites);
    setInitialCheckedLists(checkedLists);
    // to ensure that after the sub flyout is opened, the user is returned to their previous allow / block section
    if (currentSegmentGuid && currentSegmentGuid === segmentGuid &&
        currentWebRulesSection && currentWebRulesSection !== section) {
      setCurrentSection(currentWebRulesSection);
    } else {
      setInitialSection(section);
      setCurrentSection(section);
    }
  };

  const getNotificationSuccessMessage = (setNone) => {
    let successMessage = {
      none: 'changes_saved_no_web_rules',
      allow: 'changes_saved_allow_web_rules',
      block: 'changes_saved_block_web_rules',
    }[currentSection];

    if (segment) {
      successMessage = setNone ? 'changes_saved_no_web_rules_group' : `${successMessage}_group`;
      return I18n.t(successMessage, { groupName: segment.name });
    }
    if (setNone) {
      return I18n.t('changes_saved_no_web_rules');
    }
    return I18n.t(successMessage);
  };

  const verifySaveNone = () => {
    const setToNoneModal = 'setToNoneModal';
    openModal(setToNoneModal);
  };

  const buildSetToNoneModal = () => {
    const confirmButton = (
      <button
        data-testid='webRulesFlyout-setToNoneModal-confirmButton'
        className='button button--blue'
        onClick={async () => {
          saveLocalStorage(localStorageWebzoneType, currentSection);
          const successMessage = getNotificationSuccessMessage(true);
          closeModal();
          closeFlyout();
          await handleSaveCustomWebzone([], []);
          await handleSaveNoneWebzone(successMessage);
        }}
      >
        {I18n.t('confirm')}
      </button>
    );

    return (
      <Modal
        data-testid='webRulesFlyout-setToNoneModal'
        show={currentlyOpenModal === 'setToNoneModal'}
        title={I18n.t('set_web_rules_to_none')}
        customSaveButton={confirmButton}
        closeModal={closeModal}
        modalRedesign2022Q1
      >
        <div>
          {I18n.t('no_lists_selected_and_no_custom_urls_added',
            { listType: currentSection, customType: currentSection })}
        </div>
      </Modal>
    );
  };

  const handleSave = async () => {
    setIsSaving(true);
    let entryTexts = [];
    if (currentSection !== 'none') {
      const errorsFound = entries.filter((entry) => entry.error === true);
      if (errorsFound.length > 0) {
        setErrors(errorsFound.length);
        return;
      }
      setErrors(0);
      entryTexts = entries.map((entry) => entry.text);
    }

    const successMessage = getNotificationSuccessMessage();

    if (isNoBrowsing(currentWebzone)) {
      await updateNoBrowsing(currentGroup.guid, currentUser.email, false);
    }

    if (currentSection === 'none') {
      saveLocalStorage(localStorageWebzoneType, currentSection);
      closeFlyout();
      await handleSaveCustomWebzone([], []);
      await handleSaveNoneWebzone(successMessage);
    } else {
      const sitesArray = [entryTexts, ...selectedWebListsUrls].flat(Infinity);
      if (sitesArray.length === 0 && allowWhenDone === false && allowSSO === false) {
        verifySaveNone();
      } else {
        saveLocalStorage(localStorageWebzoneType, currentSection);
        closeFlyout();
        await handleSaveCustomWebzone(sitesArray, selectedWebListsNames, entryTexts, successMessage);
      }
    }
    updatePolicy();
  };

  const handleSaveCustomWebzone = async (sitesArray, selectedWebListsNames, entryTexts, successMessage) => {
    const blocked = currentSection === 'block';
    const sso = currentSection === 'allow' ? allowSSO : false;
    // the url returned from getEnvSpecificUrl() allows students to access screen shares without seeing block page
    // previously, teachers had to manually add the share screen url in custom urls or web rule list
    if (currentSection === 'allow' && sitesArray.length > 0) {
      sitesArray.push(...getEnvSpecificUrl());
    }
    const customAllowUrls = {
      none: initialCustomAllowSites,
      allow: entryTexts,
      block: initialCustomAllowSites,
    };
    const customBlockUrls = {
      none: initialCustomBlockSites,
      allow: initialCustomBlockSites,
      block: entryTexts,
    };
    const newWebRules = {
      sites: sitesArray,
      listNames: selectedWebListsNames.flat(Infinity),
      customAllowUrls: customAllowUrls[currentSection],
      customBlockUrls: customBlockUrls[currentSection],
      blocked,
      allowWhenDone,
      allowSSO: sso,
    };

    if (segment) {
      await modifySegment(currentGroup.guid, segment.guid, { web_rules: newWebRules }, successMessage);
    } else {
      await setCustomWebzone(currentGroup.guid, currentUser.email, newWebRules, successMessage);
    }
    saveLocalStorage(localStorageWebzone, newWebRules);
  };

  const handleSaveNoneWebzone = async (successMessage) => {
    if (segment) {
      await modifySegment(currentGroup.guid, segment.guid, { web_rules: {} }, successMessage);
      return;
    }
    await removeWebzone(currentGroup.guid, successMessage);
  };

  const handleCloseFlyout = () => {
    if (!modalNeededBeforeClose) {
      closeFlyout();
      return;
    }

    openModal(modalName);
  };

  const buildWebRulesCancelModal = () => {
    const leaveButton = (
      <button
        data-testid='webRulesFlyout-leavePageModal-modalLeaveButton'
        className='button button--blue'
        onClick={() => {
          closeFlyout();
          closeModal();
        }}
      >
        {I18n.t('leave')}
      </button>
    );

    return (
      <Modal
        data-testid='webRulesFlyout-leavePageModal'
        show={currentlyOpenModal === modalName}
        title={I18n.t('leave_page')}
        customSaveButton={leaveButton}
        closeModal={closeModal}
        modalRedesign2022Q1
      >
        <div>
          {I18n.t('are_you_sure_you_want_to_leave_your_changes_wont_be_saved')}
        </div>
      </Modal>
    );
  };

  const buildGroupSpecificWarning = () => {
    if (!segment) {
      return null;
    }

    return (
      <div className='co-webRulesFlyout-warning'>
        <div className='co-webRulesFlyout-warning-icon'>
          <Icon name='icon-error-triangle' classes='p-bottom--4 text--brownRed'/>
          <h3 className='co-webRulesFlyout-warning-header'>{I18n.t('caution')}</h3>
        </div>
        <div className='co-webRulesFlyout-warning-text'>
          {I18n.t('any_web_rules_applied_to_group')}
        </div>
      </div>
    );
  };

  const buildHeader = () => {
    const header = segment ? 'group_web_rules' : 'web_rules';

    return (
      <header className='co-webRulesFlyout-header'>
        <div className='l-flex l-flex--vAlignCenter m-bottom--24'>
          <h1>{I18n.t(header)}</h1>
        </div>
        {segment && <div className='l-flex l-flex--vAlignCenter co-webRulesFlyout-segmentName'>
          {segment.name}
        </div>}
        {buildGroupSpecificWarning()}
        <nav className='co-webRulesFlyout-types'>
          {buildSectionButtons()}
        </nav>
      </header>
    );
  };

  const buildSectionButtons = () => {
    return sections.map((section) => {
      const isSelected = section.name === currentSection;
      const buttonClasses = cn({
        'button-link': true,
        'co-webRulesFlyout-type': true,
        'co-webRulesFlyout-type--selected': isSelected,
      });

      return (
        <div
          key={section.name}
          className={buttonClasses}
          onClick={() => setCurrentSection(section.name)}
          data-testid={`webRulesFlyout-${section.name}-tab`}
        >
          <div className='co-webRulesFlyout-group'>
            <input
              className='co-webRulesFlyout-type-radio'
              checked={isSelected}
              type='radio'
              onChange={() => setCurrentSection(section.name)}
            />
            <h3 className='co-webRulesFlyout-header-text'>{I18n.t(section.name)}</h3>
          </div>
          <div className='co-webRulesFlyout-group'>
            <div className='co-webRulesFlyout-type-text'>
              <p className='m-vertical--0 text--gray'>
                {I18n.t(section.text)}
              </p>
            </div>
          </div>
        </div>
      );
    });
  };

  const isButtonDisabled = () => {
    const initialSection = getWebRulesType(webRules);
    const invalidEntries = entries.length === 0 || errors > 0;
    const removeAll = initialCustomSites.length > 0 && errors === 0 && entries.length === 0 && isEdited;
    const listOrCheckboxChanges = listChange || allowWhenDoneChanged || allowSSOChanged;

    if (entries.length > 100 || (initialSection === 'none' && currentSection === 'none')) {
      return true;
    }
    if (
      (initialSection === 'none' && currentSection !== 'none') &&
      (removeAll || listOrCheckboxChanges || allowWhenDone)
    ) {
      return false;
    }
    if (
      (initialSection !== currentSection) &&
      ((!invalidEntries && isEdited) || (!isEdited && entries.length > 0) || listOrCheckboxChanges || allowWhenDone)
    ) {
      return false;
    }
    if (
      (isNoBrowsing(currentWebzone)) || (currentSection === 'none' && initialSection !== 'none') ||
      listOrCheckboxChanges || removeAll || (!invalidEntries && isEdited)
    ) {
      return false;
    }

    return true;
  };

  const buildFooter = () => {
    const buttonDisabled = isButtonDisabled();

    return (
      <footer className='m-top--48 co-webRulesFlyout-footer'>
        <button
          data-testid='webRulesFlyout-close-button'
          className='button button--secondaryWhite co-modal-cancel'
          onClick={handleCloseFlyout}
        >
          {I18n.t('close')}
        </button>
        <button
          className='button button--blue co-flyout-applyChanges'
          onClick={handleSave}
          data-testid='webRulesFlyout-save-button'
          disabled={buttonDisabled}>
          {I18n.t('apply_changes')}
        </button>
      </footer>
    );
  };

  useEffect(() => {
    if (editedRef && editedRef.current) {
      editedRef.current.addEventListener('keydown', () => setIsEdited(true));
    }
  }, [entries, setEntries, errors, initialCustomSites, initialSection]);

  const buildURLCheckerSection = () => {
    const checkerClassnames = cn({
      'co-webRulesFlyout-section': true,
      'display--none': currentSection === 'none',
    });

    return (
      <div className={checkerClassnames} data-testid='urlChecker-content-container'>
        <h3 className='co-webRulesFlyout-section-title'>
          {currentSection === 'allow' ? I18n.t('allow_custom_urls') : I18n.t('block_custom_urls')}
        </h3>
        <p className='co-webRulesFlyout-section-subtitle'>
          {I18n.t('add_websites')}
        </p>
        <div ref={editedRef}>
          {/* TODO: refactor URL Checker again to allow for more easily reusable and DRY code */}
          {currentSection === 'allow' &&
            <UrlChecker
              initialSites={initialCustomAllowSites || []}
              showEntries={currentSection !== 'none'}
              updateEntries={(entries) => {
                setEntries(entries);
                const errorsFound = entries.filter((entry) => entry.error);
                setErrors(errorsFound.length);
              }}
              hasError={hasInvalidEntries}
            />}
          {currentSection === 'block' &&
            <UrlChecker
              initialSites={initialCustomBlockSites || []}
              showEntries={currentSection !== 'none'}
              updateEntries={(entries) => {
                setEntries(entries);
                const errorsFound = entries.filter((entry) => entry.error);
                setErrors(errorsFound.length);
              }}
              hasError={hasInvalidEntries}
            />}
        </div>
        <ErrorMessage hasError={errors > 0 || entries.length > 100}>
          {errors > 0 && entries.length <= 100 && I18n.t('invalid_urls', { count: errors })}
          {entries.length > 100 &&
            (I18n.t('max_of_x_websites_exceeded_please_correct_before_proceeding', { count: '100' }))}
        </ErrorMessage>
      </div>
    );
  };

  const buildAllowWhenDone = () => {
    if (currentSection === 'none') {
      return null;
    }

    return (
      <div className='l-flex m-top--24 p-top--4 cursorPointer co-webRulesFlyout-allowWhenDone'>
        <input
          type='checkbox'
          checked={allowWhenDone}
          value={allowWhenDone}
          id='checkbox-allowWhenDone'
          className='co-webRulesFlyout-checkbox'
          onChange={(e) => setAllowWhenDone(e.target.checked)}
          onClick={() => setAllowWhenDoneChanged(true)}
          data-testid='customWebzoneModal-checkbox'
        />
        <label htmlFor='checkbox-allowWhenDone' />

        <div className='m-top--0 co-webRulesFlyout-allowWhenDoneLabel'>
          {I18n.t('allow_full_internet_when_done')}
        </div>
      </div>
    );
  };

  const buildAllowSSO = () => {
    if (currentSection !== 'allow') {
      return null;
    }

    return (
      <div className='co-webRulesFlyout-ssoContainer'>
        <h3 className='co-webRulesFlyout-ssoTitle'>
          {I18n.t('single_sign_on_sso')}
        </h3>
        <div
          className='l-flex cursorPointer co-webRulesFlyout-ssoCheckbox-checkboxWrapper'
          data-testid='webRulesFlyout-ssoCheckboxLabel'
        >
          <input
            type='checkbox'
            checked={allowSSO}
            value={allowSSO}
            id='checkbox-allowSSO'
            data-testid='webRulesFlyout-ssoCheckbox'
            className='co-webRulesFlyout-checkbox'
            onChange={(e) => setAllowSSO(e.target.checked)}
            onClick={() => setAllowSSOChanged(true)}
          />
          <label htmlFor='checkbox-allowSSO' />

          <div className='m-top--0 co-webRulesFlyout-ssoCheckboxLabelText'>
            {I18n.t('allow_single_sign_on_sso')}
          </div>
        </div>
        <div className='co-webRulesFlyout-ssoInfo' data-testid='webRulesFlyout-ssoInfo'>
          {I18n.t('approved_cipa_compliant_education_companies')}
          Google, Microsoft Office 365, Clever, ClassLink, Savvas.
        </div>
      </div>
    );
  };

  return (
    <section className='co-flyout' data-testid='webRulesFlyout'>
      <div className='co-flyout-close'>
        <button
          onClick={handleCloseFlyout}
          className='button-link tooltipped tooltipped--left tooltipped--small tooltipped--opaque'
          aria-label={I18n.t('close')}
        >
          <span className='co-icon-x'>&times;</span>
        </button>
      </div>
      {buildHeader()}
      {currentSection !== 'none' &&
        <ListsContainer
          currentSection={currentSection}
          initialCheckedLists={initialCheckedLists}
          initialSection={initialSection}
          getSelectedWebRulesLists={getSelectedWebRulesLists} />}
      {buildURLCheckerSection()}
      {buildAllowWhenDone()}
      {buildAllowSSO()}
      {buildFooter()}
      {buildWebRulesCancelModal()}
      {buildSetToNoneModal()}
    </section>
  );
};

WebRulesFlyout.propTypes = {
  currentGroup: PropTypes.object.isRequired,
  currentlyOpenModal: PropTypes.string,
  closeFlyout: PropTypes.func.isRequired,
  updateNoBrowsing: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  currentWebzone: PropTypes.object,
  currentSegments: PropTypes.array,
  updatePolicy: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
  setCustomWebzone: PropTypes.func.isRequired,
  removeWebzone: PropTypes.func.isRequired,
  segmentGuid: PropTypes.string,
  modifySegment: PropTypes.func.isRequired,
  getWebRulesSection: PropTypes.func.isRequired,
  getWebRules: PropTypes.func.isRequired,
  getCanClose: PropTypes.func,
  currentWebRulesSection: PropTypes.string,
  getCurrentSegmentGuid: PropTypes.func,
  currentSegmentGuid: PropTypes.string,
  editedList: PropTypes.object,
  deletedListName: PropTypes.string,
  currentlyOpenFlyout: PropTypes.string,
  webRulesFromRedux: PropTypes.object.isRequired,
};

const mapState = (state) => ({
  currentGroup: getCurrentGroup(state.groups),
  currentlyOpenModal: state.ui.currentlyOpenModal,
  currentlyOpenFlyout: state.ui.currentlyOpenFlyout,
  currentWebRulesSection: state.ui.currentWebRulesSection,
  currentWebzone: state.webzones.current,
  currentUser: state.currentUser,
  currentSegmentGuid: state.ui.currentSegmentGuid,
  currentSegments: state.groupSegments.segments,
  webRulesFromRedux: state.webRules,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  removeWebzone,
  closeFlyout,
  openModal,
  closeModal,
  updatePolicy,
  setCustomWebzone,
  modifySegment,
  updateNoBrowsing,
  getWebRules,
  getWebRulesSection,
  getCurrentSegmentGuid,
}, dispatch);

export default connect(mapState, mapDispatchToProps)(WebRulesFlyout);
