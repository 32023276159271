import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cn from 'classnames';
import { bindActionCreators } from 'redux';

import {
  checkOutNotification,
  initFlyout,
  sendMessage,
  setStudentState,
  shutdownFlyout,
} from '../../../redux/ably/actions';
import { removeStudent, setCurrentGroup } from '../../../redux/groups/actions';
import { checkInStudent, getClassroomUsers } from '../../../redux/users/actions';
import { closeFlyout, openModal, closeModal } from '../../../redux/ui/actions';
import { getAttendanceStatus } from '../../../utilities/users';
import { canViewBrowsingHistory, canSendCustomMessage, isOnCampusOnly } from '../../../utilities/policy';
import { getTabsData } from '../../../utilities/ably';
import { getCurrentGroup } from '../../../utilities/groups';
import I18n from '../../../utilities/i18n';
import { getNameWithPossession, getUserInfo } from '../../../utilities/users';
import { disableCheckInNotification } from '../../../redux/currentUser/actions';

import BrowsingHistory from './BrowsingHistory';
import Dropdown from '../Dropdown';
import Icon from '../Icon';
import Modal from '../Modal';
import OpenTabs from './OpenTabs';
import Recordings from './Recordings';
import SendLinkModal from '../SendLinkModal';
import AttendanceStatusIndicator from '../AttendanceStatusIndicator';
import Tag from '../Tag';
import Tooltip from '../Tooltip/Tooltip';
import LoadingButton from '../LoadingButton';
import { removeStudentFromSegmentOnDelete } from '../../../redux/groupSegments/actions';

function getValidSections(online, canViewBrowsing) {
  const sections = [];
  if (online) {
    sections.push('open_tabs');
  }
  if (canViewBrowsing) {
    sections.push('browsing_history');
  }
  sections.push('recordings');

  return sections;
}

function getMessageTypes(useCustom) {
  const types = [
    {
      key: 'need_help',
      id: 2,
      analytics: 'messageDropdown-needHelp',
    },
    {
      key: 'are_you_done',
      id: 3,
      analytics: 'messageDropdown-areYouDone',
    },
    {
      key: 'great_job',
      id: 1,
      analytics: 'messageDropdown-greatJob',
    },
  ];

  if (useCustom) {
    types.push({
      key: 'custom_type',
      id: 0,
      analytics: 'messageDropdown-custom',
    });
  }

  return types;
}

function generateCustomId() {
  const idOffset = 100;
  const now = new Date();

  return 3600 * now.getHours() + 60 * now.getMinutes() + now.getSeconds() + idOffset;
}

const Flyout = ({
  userGuid,
  currentSegments,
  currentGroup,
  currentUser,
  allUsers,
  ablyData,
  groupPolicy,
  districtIps,
  currentlyOpenModal,
  initFlyout,
  shutdownFlyout,
  setStudentState,
  sendMessage,
  removeStudent,
  closeFlyout,
  openModal,
  closeModal,
  checkInStudent,
  disableCheckInNotification,
  removeStudentFromSegmentOnDelete,
  checkOutNotification,
  getClassroomUsers,
  setCurrentGroup,
}) => {
  const removeStudentModalName = 'removeStudent';
  const customMessageModalName = 'customMessage';
  const checkInModalName = 'checkIn';
  const customMessageId = 0;
  const customMessageCharMax = 150;
  const ips = isOnCampusOnly(groupPolicy) ? districtIps : null;
  const userInfo = getUserInfo(userGuid, allUsers, ablyData, ips);
  const email = userInfo.email;
  const tabs = getTabsData(ablyData, email);
  const fullName = `${userInfo.firstName} ${userInfo.lastName}`;
  const canViewBrowsing = canViewBrowsingHistory(groupPolicy);
  const validSections = getValidSections(userInfo.online, canViewBrowsing);
  const status = getAttendanceStatus(userInfo, currentGroup);
  const isCheckedIn = status === 'checked_in';
  const [currentSection, setCurrentSection] = useState(validSections[0]);
  const [customMessage, setCustomMessage] = useState('');
  const [disableNotificationSelected, setDisableNotificationSelected] = useState(false);
  const [isRemoving, setIsRemoving] = useState(false);
  const isScreensViewTab =
    JSON.parse(localStorage.getItem('CurrentGroupContainerTab')) ===
    'screensView';

  useEffect(() => {
    function handleKeyDown(e) {
      if (e.key === 'Escape') {
        closeFlyout();
      }
    }

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [closeFlyout]);

  useEffect(() => {
    // re-fetch users from the GlobalClassroom table to ensure student checkedInto status is up-to-date
    getClassroomUsers();
  }, [getClassroomUsers]);

  useEffect(() => {
    if (userInfo.online) {
      initFlyout(email);
    }

    return () => {
      /*
      if the current group tab is set to screensView, then we do NOT want to dispatch removePresence
      withing the shutdownWebrtc function that gets called from shutdownFlyout
      */
      shutdownFlyout(email, isScreensViewTab);
    };
  }, [userInfo.online, email, userInfo.guid, isScreensViewTab, initFlyout, shutdownFlyout]);

  useEffect(() => {
    setCurrentSection((section) => {
      if (validSections.includes(section)) {
        return section;
      }
      return validSections[0];
    });
  }, [validSections]);

  const verifyRemoveStudent = () => {
    openModal(removeStudentModalName);
  };

  const confirmRemoveStudent = async () => {
    const segment = currentSegments.find((segment) => {
      return segment.member_guids.includes(userGuid);
    });
    if (segment) {
      await removeStudentFromSegmentOnDelete(currentGroup.guid, userGuid, segment);
    }

    setIsRemoving(true);
    await removeStudent(userGuid);
    setCurrentGroup(currentGroup.guid);
    setIsRemoving(false);
    closeModal();
    closeFlyout();
  };

  const clearUserState = () => {
    setStudentState('working', email);
  };

  const handleSendMessage = (message, messageId) => {
    const isCustom = messageId === customMessageId;

    if (isCustom) {
      openModal(customMessageModalName);
    } else {
      sendMessage(messageId, message, email);
    }
  };

  const handleCheckIn = async () => {
    if (disableNotificationSelected) {
      // passing the teacher guid and the name of the setting
      disableCheckInNotification(currentUser.guid, 'check_in_notification_disabled');
      setDisableNotificationSelected(false);
    }
    checkInStudent(currentGroup, userGuid, userInfo);
    await getClassroomUsers();
    // passing group to notify guid, checkOutData (student name for single student check out), notificationType
    checkOutNotification(userInfo.checkedInto.guid, userInfo.fullName, 'singleStudentCheckOut');
    closeModal();
    closeFlyout();
  };

  const updateCustomMessage = (message) => {
    const clipped = message.slice(0, customMessageCharMax);
    setCustomMessage(clipped);
  };

  const sendCustomMessage = () => {
    closeModal();
    const id = generateCustomId();
    const message = customMessage.replace('\n', ' ');
    sendMessage(id, message, email, true);
    setCustomMessage('');
  };

  const buildRemoveStudentButton = () => {
    const manualGuids = currentGroup.manual_user_guids || [];
    const wasAddedByTeacher = manualGuids.includes(userInfo.guid);
    if (!wasAddedByTeacher) {
      return null;
    }

    return (
      <>
        <button
          data-testid='flyout-removeStudent-button'
          className='button-link tooltipped tooltipped--left tooltipped--small tooltipped--opaque m-right--4'
          aria-label={I18n.t('remove_student').toUpperCase()}
          onClick={verifyRemoveStudent}
        >
          <div className='co-flyout-remove-button-wrapper'>
            <Tooltip
              className='co-header-help-tooltip'
              content={I18n.t('removes_student_from_this_class')}
              aria-label={I18n.t('removes_student_from_this_class')}
              position='right'
              size='large'
            >
              <div className='button--danger' >
                {I18n.t('remove_student')}
              </div>
            </Tooltip>
          </div>
        </button>
        {buildRemoveStudentModal()}
      </>
    );
  };

  const buildRemoveStudentModal = () => {
    const removeButton = (
      <LoadingButton
        data-testid='flyout-removeStudent-modalButton'
        classes='co-modal-warningButton'
        onClick={() => confirmRemoveStudent()}
        label={I18n.t('remove')}
        isDisabled={isRemoving}
        isLoading={isRemoving}
        warningButton
        displayTextWhileLoading
      />
    );

    return (
      <Modal
        data-testid='flyout-removeStudentModal'
        show={currentlyOpenModal === removeStudentModalName}
        title={I18n.t('remove_student')}
        customSaveButton={removeButton}
        closeModal={closeModal}
        modalRedesign2022Q1
        isSmall
      >
        <div>
          {
            I18n.t('you_are_about_to_remove_string_from_string2_continue', {
              string: fullName,
              string2: currentGroup.display_name || currentGroup.name,
            })
          }
        </div>
      </Modal>
    );
  };

  const buildCheckInInfo = () => {
    if (isCheckedIn || !userInfo.online) {
      return null;
    }

    const className = userInfo.checkedInto.name;
    const teacher = getNameWithPossession(userInfo.checkedInto.teacher_name);

    return (
      <div className='co-flyout-checkIn'>
        <div className='co-flyout-checkIn-title'>
          {I18n.t('student_check_in')}
        </div>
        <div>
          {I18n.t('this_student_is_currently_in', { teacher, className })}
        </div>
        <div>
          {I18n.t('check_in_this_student_to_enable')}
        </div>
        <div>
          <button
            className='co-flyout-checkIn-button'
            onClick={() => {
              currentUser.check_in_notification_disabled ? handleCheckIn() : openModal(checkInModalName);
            }}
          >
            {I18n.t('check_in')}
          </button>
        </div>
      </div>
    );
  };

  const buildCheckIn = () => {
    const taskStatus = isCheckedIn ? userInfo.state === 'need help' ? 'need_help' : userInfo.state : null;

    return (
      <>
        <div className='co-flyout-tags'>
          <AttendanceStatusIndicator
            className='co-flyout-tag co-indicator-border'
            status={getAttendanceStatus(userInfo, currentGroup)}
          />
          {taskStatus && (
            <Tag
              status={taskStatus}
              showIcon
              onClick={taskStatus !== 'working' ? clearUserState : null } />
          )}
        </div>
        <div>{buildCheckInInfo()}</div>
      </>
    );
  };

  const buildHeader = () => {
    return (
      <header className='co-flyout-header'>
        <div className='l-flex l-flex--vAlignCenter'>
          <h1 className={ 'co-flyout-title' }>{fullName}</h1>
          {buildStatus()}
        </div>

        {buildCheckIn()}

        <nav className='co-flyout-buttons'>
          <div className='buttonGroup co-flyout-tabs'>
            {buildSectionButtons()}
          </div>

          {buildSendMessage()}
        </nav>
      </header>
    );
  };

  const buildStatus = () => {
    return null;
  };

  const buildSectionButtons = () => {
    return validSections.map((section) => {
      const isSelected = section === currentSection;
      const buttonClasses = cn({
        button: true,
        'l-flex-1': true,
        'button--secondaryWhite': !isSelected,
        'button--blue': isSelected,
      });

      return (
        <button
          key={section}
          data-testid={`flyout-${section}-button`}
          className={buttonClasses}
          onClick={() => setCurrentSection(section)}
        >
          {I18n.t(section)}
          {buildSectionCount(section, isSelected)}
        </button>
      );
    });
  };

  const buildSectionCount = (section, isSelected) => {
    if (section !== 'open_tabs') {
      return null;
    }

    const count = `${tabs.length}`;
    const buttonLabelClasses = cn({
      label: true,
      'm-left--8': true,
      'label--whiteTransparent': isSelected,
      'label--lightGray': !isSelected,
    });

    return (
      <span className={buttonLabelClasses}>{count}</span>
    );
  };

  const buildListHeader = (label) => {
    return (
      <li className='co-dropdown--header'>{I18n.t(label)}</li>
    );
  };

  const buildSendLinkButton = () => {
    const analyticsClass = 'analytics-sendDropdown-link';
    const buttonClasses = cn(analyticsClass, {
      'co-dropdown-item': true,
      'button-link': true,
    });

    return (
      <li>
        <button
          data-testid='flyout-sendLink-button'
          className={buttonClasses}
          onClick={() => openModal('sendLink')}
        >
          {I18n.t('link')}
        </button>
      </li>
    );
  };

  const buildSendMessage = () => {
    const dropdownName = 'sendMessage';

    return (
      <Fragment>
        <Dropdown
          initialLabel={buildSendMessageLabel()}
          dropdownName={dropdownName}
          hasArrow={false}
          buttonLabelClasses='co-flyout-sendMessage'
          containerClasses='co-dropdown--sendMessage'
          showAsButton={true}
          hasTriangleArrow={true}
          isRightAligned={true}
          isDisabled={!isCheckedIn}
        >
          <ul>
            {buildListHeader('message')}
            {buildSendMessageItems()}
            {buildListHeader('other')}
            {buildSendLinkButton()}
          </ul>
        </Dropdown>
        {buildCustomMessageModal()}
        {buildSendLinkModal()}
      </Fragment>
    );
  };

  const buildSendMessageLabel = () => {
    return (
      <div>
        <span className='co-flyout-sendMessageIcon'>
          <Icon name='icon-send' classes='text--mediumGray' />
        </span>
        {I18n.t('send')}
      </div>
    );
  };

  const buildSendMessageItems = () => {
    const useCustom = canSendCustomMessage(groupPolicy);

    return getMessageTypes(useCustom).map((type) => {
      const isCustom = type.id === customMessageId;
      const message = I18n.t(type.key);
      const analyticsClass = `analytics-${type.analytics}`;
      const buttonClasses = cn(analyticsClass, {
        'co-dropdown-item': true,
        'button-link': true,
      });

      return (
        <Fragment key={type.id}>
          <li>
            <button
              data-testid={`flyout-message${type.id}-button`}
              className={buttonClasses}
              onClick={() => handleSendMessage(message, type.id)}
            >
              {isCustom ? message : `"${message}"`}
            </button>
          </li>
        </Fragment>
      );
    });
  };

  const buildSendLinkModal = () => {
    return (
      <SendLinkModal
        emails={[email]}
        sendToName={fullName}
      />
    );
  };

  const buildCustomMessageModal = () => {
    const title = I18n.t('send_message_to_name', { name: fullName });

    return (
      <Modal
        show={currentlyOpenModal === customMessageModalName}
        title={title}
        closeModal={closeModal}
        isSmall
        modalRedesign2022Q1
        legacyModalVersion
        saveHandler={sendCustomMessage}
        saveText={I18n.t('send')}
        closeText={I18n.t('cancel')}
      >
        <div>
          <textarea
            data-testid='flyout-customMessage-textarea'
            value={customMessage}
            onChange={(e) => updateCustomMessage(e.target.value)}
            placeholder={I18n.t('custom_message')}
          >
          </textarea>
          <p className='m-vertical--0 text--gray'>
            {I18n.t('max_length_of_size_characters', { size: customMessageCharMax })}
          </p>
        </div>
      </Modal>
    );
  };

  const buildCheckInModal = () => {
    return (
      <Modal
        show={currentlyOpenModal === checkInModalName}
        title={I18n.t('student_check_in')}
        closeModal={closeModal}
        isSmall
        modalRedesign2022Q1
        legacyModalVersion
        saveHandler={handleCheckIn}
        saveText={I18n.t('check_in')}
        closeText={I18n.t('cancel')}
      >
        <div>
          {I18n.t('by_checking_studentName_into_your_class', { studentName: fullName })}
          <ul className='co-modal-list'>
            <li>{I18n.t('screen_lock')}</li>
            <li>{I18n.t('send_links')}</li>
            <li>{I18n.t('apply_web_rules')}</li>
            <li>{I18n.t('share_screen')}</li>
            <li>{I18n.t('send_messages')}</li>
          </ul>
          <label className='co-modal-checkbox-label cursorPointer'>
            <input
              type='checkbox'
              className='co-modal-checkbox'
              checked={disableNotificationSelected}
              onChange={(e) => setDisableNotificationSelected(e.target.checked)}
              data-testid='flyout-doNotShow-checkbox'
            />
            {I18n.t('dont_show_this_message_again')}
          </label>
        </div>
      </Modal>
    );
  };

  const buildCurrentSection = () => {
    switch (currentSection) {
      case 'browsing_history':
        return <BrowsingHistory email={email} userGuid={userGuid} />;
      case 'recordings':
        return <Recordings userGuid={userGuid} />;
      default:
        return <OpenTabs isCheckedIn={isCheckedIn} userInfo={userInfo} tabs={tabs} />;
    }
  };

  return (
    <section className='co-flyout' data-testid='flyout'>
      <div className='co-flyout-contentWrapper'>
        <div className='co-flyout-close'>
          <button
            onClick={closeFlyout}
            className='button-link tooltipped tooltipped--left tooltipped--small tooltipped--opaque'
            aria-label={I18n.t('close')}
          >
            <span className='co-icon-x'>&times;</span>
          </button>
        </div>
        {buildHeader()}
        {buildCurrentSection()}
        {buildCheckInModal()}
      </div>
      {buildRemoveStudentButton()}
    </section>
  );
};

Flyout.propTypes = {
  userGuid: PropTypes.string,
  currentGroup: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  allUsers: PropTypes.object.isRequired,
  ablyData: PropTypes.object.isRequired,
  groupPolicy: PropTypes.object.isRequired,
  districtIps: PropTypes.arrayOf(PropTypes.string).isRequired,
  currentlyOpenModal: PropTypes.string,
  initFlyout: PropTypes.func.isRequired,
  shutdownFlyout: PropTypes.func.isRequired,
  setStudentState: PropTypes.func.isRequired,
  sendMessage: PropTypes.func.isRequired,
  removeStudent: PropTypes.func.isRequired,
  closeFlyout: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  checkInStudent: PropTypes.func.isRequired,
  disableCheckInNotification: PropTypes.func.isRequired,
  currentSegments: PropTypes.array,
  removeStudentFromSegmentOnDelete: PropTypes.func.isRequired,
  checkOutNotification: PropTypes.func.isRequired,
  getClassroomUsers: PropTypes.func.isRequired,
  setCurrentGroup: PropTypes.func.isRequired,
};

const mapState = (state) => ({
  currentGroup: getCurrentGroup(state.groups),
  currentUser: state.currentUser,
  allUsers: state.users.all,
  ablyData: state.ably.data,
  groupPolicy: state.policy.group,
  districtIps: state.district.ips,
  currentlyOpenModal: state.ui.currentlyOpenModal,
  currentSegments: state.groupSegments.segments,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  initFlyout,
  shutdownFlyout,
  setStudentState,
  sendMessage,
  removeStudent,
  closeFlyout,
  openModal,
  closeModal,
  checkInStudent,
  disableCheckInNotification,
  removeStudentFromSegmentOnDelete,
  checkOutNotification,
  getClassroomUsers,
  setCurrentGroup,
}, dispatch);

export default connect(mapState, mapDispatchToProps)(Flyout);
