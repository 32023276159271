import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { createInvite } from '../../redux/invites/actions';

import I18n from '../../utilities/i18n';

import Icon from '../common/Icon';
import Loader from '../common/Loader';


const JoinGroup = ({
  match,
  createInvite,
  inviteError,
  inviteIsCreating,
  inviteWasCreated,
}) => {

  const [email, setEmail] = useState('');
  const [classCode, setClassCode] = useState(match.params.code || '');
  const [error, setError] = useState(null);

  useEffect(() => {
    let errorFound = null;

    if (inviteError === 'user_not_found') {
      errorFound = { message: I18n.t('user_not_found'), type: 'email' };
    } else if (inviteError === 'invalid_code') {
      errorFound = { message: I18n.t('oops_thats_not_a_valid_class_code'), type: 'class code' };
    } else if (inviteError === 'already_exists') {
      errorFound = { message: I18n.t('you_have_already_submitted_a_request_to_join'), type: 'generic' };
    } else if (inviteError === 'already_member') {
      errorFound = { message: I18n.t('you_are_already_a_member'), type: 'generic' };
    } else if (inviteError) {
      errorFound = { message: I18n.t('an_unknown_error_occured_please_contact_admin'), type: 'generic' };
    }

    if (errorFound) {
      setError(errorFound);
    }
  }, [inviteError]);

  const joinGroupHandler = () => {
    if (!email) {
      setError({ message: I18n.t('please_fill_out_required_fields'), type: 'email' });
      return false;
    }

    if (!classCode) {
      setError({ message: I18n.t('please_fill_out_required_fields'), type: 'class code' });
      return false;
    }

    createInvite(classCode, email);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      joinGroupHandler();
    }
  };

  const buildPageHeader = () => {
    return (
      <header className='signinHeader signinHeader--groupInvite'>
        <div className='signinLogo'>
          <Icon name='icon-classroom-logo-32' classes='signinLogo-productLogo' />
          <svg className='signinLogo-border' height='80' width='80'>
            <defs>
              <linearGradient id='gradient' x2='100%' y2='100%'>
                <stop offset='0%' stopColor='#00D3D6'></stop>
                <stop offset='100%' stopColor='#00afd7'></stop>
              </linearGradient>
            </defs>
            <circle
              className='logo-border'
              cx='40' cy='40'
              fill='transparent'
              r='38' strokeWidth='2'
              stroke='url(#gradient)'>
            </circle>
          </svg>
        </div>
        <h1>{I18n.t('classroom')}</h1>
        <div className='co-signin-introText'>{I18n.t('join_your_class')}</div>
      </header>
    );
  };

  const buildErrorMessage = () => {
    return (
      <div
        className='co-signin-statusMessage co-signin-statusMessage--error'
        data-testid='joinGroup-error'
      >
        {error.message}
      </div>
    );
  };

  const buildInputErrorIndicator = () => {
    return (
      <span className='co-signin-inputErrorIndicator' data-testid='joinGroup-errorIndicator'></span>
    );
  };

  const buildSuccessMessage = () => {
    return (
      <div data-testid='joinGroup-success' className='co-signin-statusMessage'>
        {I18n.t('your_request_was_successfully_submitted')}
      </div>
    );
  };

  const buildContent = () => {
    return (
      <section className='signinForm'>
        {(error) && buildErrorMessage()}

        <div className='inputElement move-2nd'>
          {error && error.type === 'email' && buildInputErrorIndicator()}
          <Icon name='icon-mail-stroke' classes='co-signin-inputIcon' />
          <input
            type='text'
            id='email'
            name='email'
            placeholder={I18n.t('email_address')}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            onKeyDown={handleKeyDown}
            data-testid='joinGroup-email-input'
          />
        </div>

        <div className='inputElement move-3rd'>
          {error && error.type === 'class code' && buildInputErrorIndicator()}
          <Icon name='icon-unlock-stroke' classes='co-signin-inputIcon' />
          <input
            type='text'
            id='classCode'
            name='classCode'
            placeholder={I18n.t('class_code')}
            value={classCode}
            onChange={(e) => setClassCode(e.target.value)}
            onKeyDown={handleKeyDown}
            data-testid='joinGroup-classCode-input'
          />
        </div>

        <div className='move-4th'>
          <button
            className='button signinForm-submit'
            onClick={joinGroupHandler}
            data-testid='joinGroup-button'
          >
            {I18n.t('submit_request')}
          </button>
        </div>
      </section>
    );
  };


  if (inviteIsCreating) {
    return (
      <div className='body--signin minHeight--100vh' data-testid='joinGroup-loading'>
        <Loader isFullscreen={true} useRelayLogo={true} />
      </div>
    );
  }

  const content = inviteWasCreated ? buildSuccessMessage() : buildContent();

  return (
    <div className='body--signin minHeight--100vh fs-block'>
      <div className='signinContainer'>
        {buildPageHeader()}
        {content}
      </div>
    </div>
  );
};

JoinGroup.propTypes = {
  createInvite: PropTypes.func.isRequired,
  inviteError: PropTypes.string.isRequired,
  inviteIsCreating: PropTypes.bool.isRequired,
  inviteWasCreated: PropTypes.bool.isRequired,
  match: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  inviteError: state.invites.error,
  inviteIsCreating: state.invites.isCreating,
  inviteWasCreated: state.invites.wasCreated,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  createInvite,
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(JoinGroup));
