import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classNames from 'classnames';

import {
  collapseNavigation,
  expandNavigation,
} from '../../../redux/ui/actions';

// import { hasGroupOrUserBetas } from '../../../utilities/betas';

import { ChevronThickLeftIcon, ChevronThickRightIcon } from 'design-system';

const NavCollapseTab = ({
  classes,
  // classroomGroupBetas,
  collapseNavigation,
  expandNavigation,
  navigationCollapsed,
  // userBetas,
}) => {
  // const hasScreensViewBeta = hasGroupOrUserBetas(
  //   'enhanced_screens_view',
  //   userBetas,
  //   classroomGroupBetas,
  // );
  const buttonClasses = classNames(classes, {
    'co-nav-collapse-button': true,
    'co-nav-collapse-button--collapsed': navigationCollapsed,
  });

  return (
    <div className='co-nav-collapse-wrapper'>
      <button
        className={buttonClasses}
        data-testid='co-nav-collapse-iconButton'
        onClick={() => {
          navigationCollapsed ? expandNavigation() : collapseNavigation();
        }}
      >
        {navigationCollapsed ? (
          <ChevronThickRightIcon />
        ) : (
          <ChevronThickLeftIcon />
        )}
      </button>
    </div>
  );
};

NavCollapseTab.propTypes = {
  classes: PropTypes.string,
  // classroomGroupBetas: PropTypes.object,
  collapseNavigation: PropTypes.func.isRequired,
  expandNavigation: PropTypes.func.isRequired,
  navigationCollapsed: PropTypes.bool.isRequired,
  // userBetas: PropTypes.object.isRequired,
};

const mapState = (state) => ({
  classroomGroupBetas: state.betas,
  navigationCollapsed: state.ui.navigationCollapsed,
  userBetas: state.currentUser.betas,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  collapseNavigation,
  expandNavigation,
}, dispatch);

export default connect(mapState, mapDispatchToProps)(NavCollapseTab);
