import cn from 'classnames';
import { useUniqueId } from 'design-system';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';

import Tooltip from '../../common/Tooltip/Tooltip';
import I18n from '../../../utilities/i18n';

const RadioTab = ({ value, count, isSelected, isDisabled = false, onClick, children }) => {
  const id = useUniqueId('RadioTab');
  const labelRef = useRef();

  const checkValue = {
    checked_in: 'student_is_online_and_checked_into_your_class_class_controls_can_be_applied',
    not_checked_in: 'student_is_online_but_not_checked_into_your_class_class_controls_cannot_be_applied',
    offline: 'student_is_offline_and_class_controls_cannot_be_applied',
  };

  const radioList = (
    <li
      className={cn(['co-radioTab', { 'co-radioTab--selected': isSelected, 'co-radioTab--disabled': isDisabled }])}
      onClick={isDisabled ? null : onClick}>
      <div>
        <input
          className='co-radioTab-input'
          id={id}
          type='radio'
          value={value}
          disabled={isDisabled}
          checked={isSelected}
          onChange={() => {}}
        />
        <label
          className='co-radioTab-label'
          ref={labelRef}
          htmlFor={id}
        >
          <span className='co-radioTab-label-circle' />
          <div className='co-radioTab-label-text'>
            { children }
          </div>
        </label>
      </div>
      <div className='co-radioTab-count'>
        {count}
      </div>
    </li>
  );

  const buildTooltip = () => {
    if (value in checkValue) {
      return (
        <Tooltip 
          content={I18n.t(`${checkValue[value]}`)} 
          aria-label={I18n.t(`${checkValue[value]}`)} 
          position='right'
          size='large'
        >
          {radioList}
        </Tooltip>
      );
    }
    return radioList;
  };

  return (
    <>
      {buildTooltip()}
    </>
  );
};

RadioTab.propTypes = {
  value: PropTypes.string,
  count: PropTypes.number,
  isSelected: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.any,
};

export default RadioTab;
