import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import I18n from '../../utilities/i18n';

const BetaBadge = ({
  classes,
  isInHeader,
  largeStyle,
  label = 'early_access',
}) => {
  const badgeClasses = classNames(classes, {
    'co-betaBadge': true,
    'co-betaBadge--header': isInHeader,
    'co-betaBadge--largeStyle': largeStyle,
  });

  return (
    <div className={badgeClasses}>
      {I18n.t(label)}
    </div>
  );
};

BetaBadge.propTypes = {
  classes: PropTypes.string,
  largeStyle: PropTypes.bool,
  isInHeader: PropTypes.bool,
  label: PropTypes.string,
};

export default BetaBadge;
