import {
  FETCH_SEGMENTS,
  RECEIVE_SEGMENTS,
  PRUNE_SEGMENT,
  SET_SEGMENTS_ERROR,
  CLEAR_SEGMENTS,
  RECEIVE_SEGMENT,
  UPDATE_SEGMENT,
} from './actions';

export const defaultState = {
  segments: [],
  fetchedAt: 0,
  updatedAt: 0,
  segmentsLoaded: false,
  errorAt: 0,
  error: '',
};

export function groupSegmentsReducer(state = defaultState, action) {
  switch (action.type) {
    case FETCH_SEGMENTS:
      return {
        ...state,
        fetchedAt: action.time,
        error: '',
      };
    case RECEIVE_SEGMENTS: {
      return {
        ...state,
        segments: fixSegmentsData(action.segments),
        updatedAt: action.time,
        segmentsLoaded: true,
      };
    }
    case RECEIVE_SEGMENT: {
      const guid = action.segment.guid;
      const index = state.segments.findIndex((segment) => segment.guid === guid);
      let segments;
      if (index === -1) {
        segments = [...state.segments, action.segment];
      } else {
        segments = [...state.segments.slice(0, index), action.segment, ...state.segments.slice(index + 1)];
      }
      return {
        ...state,
        segments: fixSegmentsData(segments),
      };
    }
    case UPDATE_SEGMENT: { 
      const newSegments = [...state.segments];
      const indexOfMemberGuid = action.segment.member_guids.indexOf(action.userGuid);  
      const indexOfSegment = newSegments.map((segment) => {
        return segment.guid;
      }).indexOf(action.segment.guid);
      if (indexOfSegment > -1 && indexOfMemberGuid > -1) {
        newSegments[indexOfSegment].member_guids.splice(indexOfMemberGuid, 1);
      }
      return {
        ...state,
        segments: newSegments,
      };
    }
    case PRUNE_SEGMENT: {
      const removed = state.segments.filter((segment) => {
        return segment.guid !== action.guid;
      });
      return {
        ...state,
        segments: removed,
        updatedAt: action.time,
      };
    }
    case SET_SEGMENTS_ERROR:
      return {
        ...state,
        fetchedAt: 0,
        errorAt: action.time,
        error: action.error,
      };
    case CLEAR_SEGMENTS:
      return defaultState;
    default:
      return state;
  }
}

function fixSegmentsData(segments) {
  // member_guids field needs to be array type
  // at least one group in production had it missing
  return segments.map((segment) => {
    if (!segment.member_guids) {
      segment.member_guids = [];
    }
    if (segment.web_rules) {
      segment.web_rules.allowWhenDone = segment.web_rules.allow_when_done;
      delete segment.web_rules.allow_when_done;
    }
    return segment;
  });
}
