/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const GoogleLogoIcon = ({ className, onClick, style }) => {
  let clickProps;
  if (onClick) {
    clickProps = { onClick, tabIndex: '0', role: 'button' };
  }
  return (
    <svg
      width='39.473'
      height='40.282'
      viewBox='0 0 39.473 40.282'
      className={classNames('icon', className)}
      {...clickProps}
      style={style}
      data-testid='CeleritasGoogleLogoIcon'
    >
      <path
        fill='#fff'
        d='M31.207,15.131A20.255,20.255,0,0,0,19.8,25.124a19.939,19.939,0,0,0-1.754,5.067,20.153,20.153,0,0,0,14.53,23.44,21.552,21.552,0,0,0,10.307.108A17.773,17.773,0,0,0,56.228,41.094a23.65,23.65,0,0,0,.509-10.7H37.79v7.86H48.768a9.485,9.485,0,0,1-4.028,6.225A11.536,11.536,0,0,1,40.3,46.237a13.285,13.285,0,0,1-4.807-.011,11.923,11.923,0,0,1-4.482-1.938A12.452,12.452,0,0,1,26.4,38.106a12.22,12.22,0,0,1,0-7.839,12.578,12.578,0,0,1,2.923-4.753,11.842,11.842,0,0,1,11.931-3.107A10.842,10.842,0,0,1,45.6,24.962l3.7-3.7c.65-.66,1.332-1.3,1.96-1.981a19.638,19.638,0,0,0-6.485-4.017A20.326,20.326,0,0,0,31.207,15.131Z'
        transform='translate(-17.646 -14.029)'
      />
      <path
        fill='#ea4335'
        d='M48.911,15.121a20.385,20.385,0,0,1,13.565.119,19.567,19.567,0,0,1,6.485,4.018c-.628.682-1.31,1.321-1.96,1.982l-3.7,3.7a10.966,10.966,0,0,0-4.341-2.556,11.884,11.884,0,0,0-5.911-.238A12.032,12.032,0,0,0,47.027,25.5,12.5,12.5,0,0,0,44.1,30.251c-2.2-1.711-4.4-3.411-6.6-5.123A20.235,20.235,0,0,1,48.911,15.121Z'
        transform='translate(-35.35 -14.019)'
      />
      <path
        fill='#fbbc05'
        d='M18.146,121.468A20.09,20.09,0,0,1,19.9,116.4c2.2,1.711,4.4,3.411,6.606,5.123a12.152,12.152,0,0,0,0,7.841q-3.3,2.567-6.6,5.123A20.044,20.044,0,0,1,18.146,121.468Z'
        transform='translate(-17.74 -105.318)'
      />
      <path
        fill='#4285f4'
        d='M203.611,165.1h18.952a23.824,23.824,0,0,1-.509,10.7,18.3,18.3,0,0,1-5.1,8.361l-6.4-4.971a9.488,9.488,0,0,0,4.029-6.227H203.6Q203.616,169.031,203.611,165.1Z'
        transform='translate(-183.473 -148.75)'
      />
      <path
        fill='#34a853'
        d='M37.5,241.323q3.3-2.551,6.6-5.123a12.519,12.519,0,0,0,4.614,6.184,12,12,0,0,0,4.484,1.939,13.053,13.053,0,0,0,4.809.011,11.541,11.541,0,0,0,4.44-1.754l6.4,4.971a18.176,18.176,0,0,1-8.252,4.289,21.558,21.558,0,0,1-10.31-.108,19.906,19.906,0,0,1-7.321-3.693A20.281,20.281,0,0,1,37.5,241.323Z'
        transform='translate(-35.35 -212.15)'
      />
    </svg>
  );
};


GoogleLogoIcon.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  style: PropTypes.object,
};

export default GoogleLogoIcon;
