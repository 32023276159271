import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import I18n from '../../../../utilities/i18n';
import { addNotification, getCurrentSegmentGuid, closeSubFlyout } from '../../../../redux/ui/actions';
import { createWebRules, getWebRules } from '../../../../redux/webRules/actions';
import { getSegmentGuid } from '../../../../utilities/groups';

import ListForm from './ListForm';

const CreateList = ({
  addNotification,
  createWebRules,
  closeSubFlyout,
  currentlyOpenFlyout,
  getCurrentSegmentGuid,
  getWebRules,
  getCanClose,
}) => {
  const segmentGuid = getSegmentGuid(currentlyOpenFlyout);
  const [error, setError] = useState(null);

  const handleSave = async (newWebRules) => {
    await createWebRules(newWebRules).then(async () => {
      getCurrentSegmentGuid(segmentGuid);
      const successMessage = (
        <div className='co-listForm-notificationMessage-wrapper'>
          <span className='co-listForm-notificationMessage'>{newWebRules.name}</span> {I18n.t('was_saved')}
        </div>
      );
      addNotification(successMessage, { notificationType: 'success', autoDismiss: true });
      await getWebRules();
      closeSubFlyout();
    }).catch((error) => {
      setError(error);
    });
  };

  const handleClose = (isEdited, entries) => {
    if (!isEdited || entries.length === 0) {
      getCurrentSegmentGuid(segmentGuid);
      closeSubFlyout();
    }
  };

  return (
    <ListForm
      handleSave={handleSave}
      handleClose={handleClose}
      error={error}
      getCanClose={getCanClose}
    />
  );

};

CreateList.propTypes = {
  addNotification: PropTypes.func,
  closeSubFlyout: PropTypes.func,
  createWebRules: PropTypes.func,
  currentlyOpenFlyout: PropTypes.string,
  getCurrentSegmentGuid: PropTypes.func.isRequired,
  getWebRules: PropTypes.func.isRequired,
  getCanClose: PropTypes.func,
};

const mapState = (state) => ({
  currentlyOpenFlyout: state.ui.currentlyOpenFlyout,
  currentlyOpenSubFlyout: state.ui.currentlyOpenSubFlyout,
  currentSegmentGuid: state.ui.currentSegmentGuid,
  currentWebRulesSection: state.ui.currentWebRulesSection,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  addNotification,
  closeSubFlyout,
  createWebRules,
  getCurrentSegmentGuid,
  getWebRules,
}, dispatch);

export default connect(mapState, mapDispatchToProps)(CreateList);
