import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getUsers } from '../../../redux/users/actions';
import { openModal, closeModal } from '../../../redux/ui/actions';
import {
  acceptInvite,
  rejectInvite,
  acceptAllInvites,
  rejectAllInvites,
} from '../../../redux/invites/actions';

import { getCurrentGroup } from '../../../utilities/groups';
import I18n from '../../../utilities/i18n';

import Modal from '../Modal';
import Icon from '../Icon';
import UpdateStatus from '../UpdateStatus';


const Invites = ({
  openModal,
  closeModal,
  acceptInvite,
  rejectInvite,
  acceptAllInvites,
  getUsers,
  rejectAllInvites,
  allInvites,
  currentGroup,
  currentlyOpenModal,
}) => {
  const modalName = 'invites';
  const modalIsOpen = currentlyOpenModal === modalName;
  const invitesCount = allInvites.length;
  const [usersAdded, setUsersAdded] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  useEffect(() => {
    if (modalIsOpen && invitesCount === 0) {
      closeModal();
    }
  }, [modalIsOpen, invitesCount, closeModal]);

  useEffect(() => {
    if (!modalIsOpen && usersAdded) {
      setUsersAdded(false);
      getUsers(currentGroup.guid);
    }
  }, [modalIsOpen, usersAdded, currentGroup.guid, getUsers]);

  const acceptInviteHandler = async (userId, userGuid) => {
    setIsUpdating(true);
    await acceptInvite(currentGroup.guid, userId, userGuid);
    setIsUpdating(false);
    setUsersAdded(true);
  };

  const rejectInviteHandler = async (userId, userGuid) => {
    setIsUpdating(true);
    await rejectInvite(currentGroup.guid, userId, userGuid);
    setIsUpdating(false);
  };

  const acceptAllInvitesHandler = async () => {
    setIsUpdating(true);
    await acceptAllInvites(currentGroup.guid);
    setIsUpdating(false);
    setUsersAdded(true);
  };

  const rejectAllInvitesHandler = () => {
    setIsUpdating(true);
    rejectAllInvites(currentGroup.guid);
    setIsUpdating(false);
  };

  const buildInvite = (label, handleReject, handleAccept) => {
    return (
      <div className='l-flex l-flex--hSpaceBetween l-flex--vAlignCenter'>
        <span className='p-left--8'>{label}</span>

        <section className='l-flex'>
          <button className='button-link' onClick={handleReject} data-testid='invites-deny'>
            <span className='co-icon-x'>&times;</span>
          </button>

          <button className='button-link' onClick={handleAccept} data-testid='invites-confirm'>
            <Icon name='icon-checkmark-solid' classes='co-invitesModal-accept' />
          </button>
        </section>
      </div>
    );
  };

  const buildListItems = () => {
    return allInvites.map((item) => {
      const label = `${item.first_name} ${item.last_name}`;
      return (
        <li
          data-testid={`invites-invite-${item.user_id}`}
          key={item.email}
          className='invites-content'
        >
          {buildInvite(
            label,
            () => rejectInviteHandler(item.user_id, item.user_guid),
            () => acceptInviteHandler(item.user_id, item.user_guid),
          )}
        </li>
      );
    });
  };

  const buildModalContent = () => {
    return (
      <Fragment>
        <p>
          {I18n.t('following_individuals_would_like_to_join')}
          <br/>
          <span className='text--gray'>{I18n.t('important_if_browsing_activity_does_not')}</span>
        </p>

        <UpdateStatus isActive={isUpdating}>
          <div>
            <ul>
              <li data-testid='invites-invite-all' className='co-invitesModal-item'>
                {buildInvite(I18n.t('all_requests'), rejectAllInvitesHandler, acceptAllInvitesHandler)}
              </li>
              {buildListItems()}
            </ul>
          </div>
        </UpdateStatus>
      </Fragment>
    );
  };

  return (invitesCount > 0) && (
    <Fragment>
      <button
        className='co-nav-invites button button--secondaryWhite'
        onClick={() => openModal(modalName)}
        data-testid='invites-open'
      >
        {I18n.t('view_requests_to_join_class')}
        <span className='co-nav-invites-count badge badge--red'>{invitesCount}</span>
      </button>

      <Modal
        show={modalIsOpen}
        title={I18n.t('requests_to_join')}
        closeText={I18n.t('close')}
        closeModal={closeModal}
      >
        {buildModalContent()}
      </Modal>
    </Fragment>
  );
};

Invites.propTypes = {
  openModal: PropTypes.func,
  closeModal: PropTypes.func.isRequired,
  acceptInvite: PropTypes.func.isRequired,
  rejectInvite: PropTypes.func.isRequired,
  acceptAllInvites: PropTypes.func.isRequired,
  getUsers: PropTypes.func.isRequired,
  rejectAllInvites: PropTypes.func.isRequired,
  allInvites: PropTypes.arrayOf(PropTypes.object).isRequired,
  currentGroup: PropTypes.object.isRequired,
  currentlyOpenModal: PropTypes.string,
};

const mapStateToProps = (state) => ({
  currentlyOpenModal: state.ui.currentlyOpenModal,
  currentGroup: getCurrentGroup(state.groups),
  allInvites: state.invites.all,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  closeModal,
  openModal,
  acceptInvite,
  rejectInvite,
  acceptAllInvites,
  rejectAllInvites,
  getUsers,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Invites);
