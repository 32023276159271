import {
  ADD_ERROR,
} from './actions';

export const defaultState = {
  all: [],
};

export function errorsReducer(state = defaultState, action) {
  switch (action.type) {
    case ADD_ERROR: {
      const oneDay = 1000 * 60 * 60 * 24;
      const tooOld = action.error.at - (7 * oneDay);
      const filtered = state.all.filter((error) => error.at > tooOld);
      const all = [...filtered, action.error];

      return {
        ...state,
        all,
      };
    }
    default:
      return state;
  }
}
