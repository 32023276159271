import React from 'react';
import PropTypes from 'prop-types';
import { CSSTransition, TransitionGroup } from 'react-transition-group';


const PushUpFade = ({ children, condition, ...props }) => {
  return (
    <TransitionGroup>
      {condition && (
        <CSSTransition
          {...props}
          timeout={180}
          classNames='pushUpFade'
        >
          {children}
        </CSSTransition>
      )}
    </TransitionGroup>
  );
};

PushUpFade.propTypes = {
  children: PropTypes.object,
  condition: PropTypes.bool.isRequired,
};

export default PushUpFade;
