import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import * as microsoftTeams from '@microsoft/teams-js';

import I18n from '../../utilities/i18n';
import { parseQueryParams } from '../../utilities/urls';

import { ReactComponent as StudentSitting } from '../../images/studentSitting.svg';

function buildAzureSSOLink(context) {
  return 'https://login.microsoftonline.com/common/oauth2/v2.0/authorize?' +
    'client_id=5f10720f-e441-4b2c-9943-f00153df3a4e&response_type=code&' +
    `state=azure&redirect_uri=${process.env.REACT_APP_CLASSROOM_URL}&` +
    `login_hint=${context.loginHint}&` +
    'scope=user.read';
}

function buildGoogleSSOLink() {
  return 'https://accounts.google.com/o/oauth2/v2/auth?' +
  'client_id=250992307284-m88p8nub2ormiak916etcu4ke3o850it&' +
  `response_type=code&redirect_uri=${process.env.REACT_APP_CLASSROOM_URL}&` +
  'prompt=login&' +
  'scope=email&state=google&';
}

function buildAuthLink(authType, context) {
  if (authType === 'azure') {
    return buildAzureSSOLink(context);
  }
  return buildGoogleSSOLink();
}

const TeamsAuth = ({
  location,
}) => {
  const queryParams = parseQueryParams(location);

  useEffect(() => {
    microsoftTeams.getContext(function (context) {
      const authorizeEndpoint = buildAuthLink(queryParams.authType, context);
      window.location.assign(authorizeEndpoint);
    });
  }, [queryParams.authType]);

  return (
    <div className='co-teamsConfig-container'>
      <main className='co-teamsConfig'>
        <section className='co-teamsConfig-text'>
          <div>
            <div className='co-teamsConfig-title'>
              {I18n.t('welcome_to_classroom')}
            </div>
            <div className='co-teamsConfig-subtitle'>
              {I18n.t('for_microsoft_teams')}
            </div>
          </div>
        </section>
        <section className='co-teamsConfig-image'>
          <StudentSitting className='co-teamsConfig-foreground' />
        </section>
      </main>
    </div>
  );
};

TeamsAuth.propTypes = {
  location: PropTypes.object.isRequired,
};

export default TeamsAuth;
