import cn from 'classnames';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDrag } from 'react-dnd';
import { connect } from 'react-redux';

import I18n from '../../../utilities/i18n';
import {
  getNameWithPossession,
  getFirstInitialLastName,
  getAttendanceStatus,
} from '../../../utilities/users';

import PushUpFade from '../../common/animations/PushUpFade';
import AttendanceStatusIndicator from '../../common/AttendanceStatusIndicator';
import Icon from '../../common/Icon';
import TaskStatusIcon from '../../common/TaskStatusIcon';
import WebsiteImage from '../../common/WebsiteImage';
import classNames from 'classnames';

const UsersTableRow = ({
  currentSegments,
  userInfo,
  isMoving,
  openFlyout,
  dragType,
  currentGroup,
  classes,
  browsingTimeout,
}) => {
  const [showIPTooltip, setShowIPTooltip] = useState(false);
  const [flyoutEnabled, setFlyoutEnabled] = useState(true);
  const buildNDash = <span className='text--mediumGray'>&ndash;</span>;
  const isInSession = classes.inSession.includes(currentGroup.guid);
  const status = getAttendanceStatus(userInfo, currentGroup);
  const isCheckedIn = status === 'checked_in';

  const [isAboutToDrag, setIsAboutToDrag] = useState(false);
  const [{ isDragging }, drag, preview] = useDrag({
    item: { type: dragType, guid: userInfo.guid },
    collect: (monitor) => ({
      isDragging: Boolean(monitor.isDragging()),
    }),
  });
  const showContent = !isDragging && !isMoving;

  const userClickHandler = () => {
    if (flyoutEnabled) {
      openFlyout(userInfo.guid, true);
    }
  };

  const WebsiteWithIcon = ({ srcUrl, faviconUrl }) => {
    if (!isInSession || !isCheckedIn || !userInfo.online || !srcUrl) {
      return null;
    }

    return (
      <div className='co-usersTable-sites'>
        <WebsiteImage host={srcUrl} faviconUrl={faviconUrl} />
        <span className='m-left--8 truncate'>{srcUrl}</span>
      </div>
    );
  };

  WebsiteWithIcon.propTypes = {
    srcUrl: PropTypes.string,
    faviconUrl: PropTypes.string,
  };

  const buildEmptyRow = () => {
    return (
      <tr className='co-table-users-tr--empty'>
        <td className='co-table-td' colSpan='6'>
          {isMoving &&
            <div className='co-updateStatus-tr-icon' data-testid='updateStatus-icon'>
              <Icon name='icon-loading' classes='icon-loading--animating' />
            </div>
          }
        </td>
      </tr>
    );
  };

  const buildDragHandle = () => {
    if (currentSegments.length === 0) {
      return;
    }

    return (
      <button
        className='button-link text--mediumGray m-right--16'
        aria-label={I18n.t('drag_segment_member')}
        data-testid='segmentMember-drag-button'
      >
        <Icon name='icon-move-handle' />
      </button>
    );
  };

  const buildStatus = () => {
    if (!isInSession || !userInfo || !currentGroup) {
      return null;
    }

    return (
      <AttendanceStatusIndicator
        isAboutToDrag={isAboutToDrag}
        status={status}
        ariaLabel={getTooltipMessage()}
        className='co-indicator-border'
      />
    );
  };

  const buildWebsiteAndIcon = (src, favicon) => {

    if (!userInfo.online || typeof src === 'undefined' || src === '') {
      return buildNDash;
    }
    return (
      <div className='co-usersTable-sites'>
        <WebsiteImage host={src} faviconUrl={favicon} />
        <span className='m-left--8 truncate'>{src}</span>
      </div>
    );
  };

  const buildCurrentlyBrowsing = () => {
    const currentHost = userInfo && userInfo.currentHost;
    if (!isCheckedIn) {
      return singleDash;
    }
    if (isCheckedIn && !currentHost) {
      return (
        <span className='co-table-users-notBrowsing'>{I18n.t('not_browsing')}</span>
      );
    }

    return buildWebsiteAndIcon(currentHost, userInfo.favicon);
  };

  const getTooltipMessage = () => {
    if (status !== 'not_checked_in') {
      return null;
    }

    const teacher = getNameWithPossession(getFirstInitialLastName(userInfo.checkedInto.teacher_name));
    if (userInfo.checkedInto.name && userInfo.checkedInto.teacher_name) {
      return I18n.t('currently_in_teacher_class', {
        class: userInfo.checkedInto.name, teacher,
      });
    }
    return I18n.t('currently_in_another_teachers_class');
  };

  if (!showContent) {
    return buildEmptyRow();
  }

  const visitsClasses = classNames({
    'text--gray': !userInfo.visits,
    'co-table-users-td--center': true,
  });

  const singleDash = <span className='text--gray'>-</span>;

  return (
    <tr
      className='co-table-users-tr'
      ref={preview}
      onClick={userClickHandler}>
      <td
        className='co-table-users-td'
        onMouseEnter={() => setIsAboutToDrag(true)}
        onMouseLeave={() => setIsAboutToDrag(false)}
        ref={drag}>
        {buildDragHandle()}
      </td>
      <td className='co-table-users-td'>
        <span className='co-table-users-td--center'>
          {userInfo.state && (
            <TaskStatusIcon status={userInfo.state} />
          )}
        </span>
      </td>
      <td className={cn([
        'co-table-users-name',
        'co-table-users-td',
        'co-table-td',
        'co-table-td-overflow-visible',
        'truncate',
        {
          'co-table-users-name--disabled': !isInSession || !userInfo.online,
        },
      ])}>
        <span
          data-testid='usersTableRow-names'>
          {userInfo.firstName} {userInfo.lastName}
        </span>

        <br />

        {userInfo.offCampus && (
          <div
            className='label'
            data-testid='usersTableRow-offCampus'
            onMouseEnter={() => {
              setFlyoutEnabled(false);
              setShowIPTooltip(true);
            }}
            onMouseLeave={() => {
              setFlyoutEnabled(true);
              setShowIPTooltip(false);
            }}
          >
            <PushUpFade condition={showIPTooltip}>
              <span className='co-usersTable-tooltip'>{userInfo.ipAddress}</span>
            </PushUpFade>

            {I18n.t('off_campus_network')}
          </div>
        )}
      </td>
      <td className='co-table-users-td'>
        {buildStatus() || singleDash}
      </td>
      <td className='co-table-td'>
        {(isCheckedIn && browsingTimeout) ? singleDash : buildCurrentlyBrowsing()}
      </td>

      <td className='co-table-td'>
        {(userInfo.top && buildWebsiteAndIcon(userInfo.top)) || singleDash}
      </td>
      <td className='co-table-users-td'>
        <span className={visitsClasses}>
          {userInfo.visits || '--'}
        </span>
      </td>
    </tr>
  );
};

UsersTableRow.propTypes = {
  currentSegments: PropTypes.array.isRequired,
  userInfo: PropTypes.object,
  isMoving: PropTypes.bool,
  openFlyout: PropTypes.func.isRequired,
  dragType: PropTypes.string.isRequired,
  currentGroup: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  browsingTimeout: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  currentSegments: state.groupSegments.segments,
  classes: state.classes,
});

export default connect(mapStateToProps, null)(UsersTableRow);
