import {
  GET_BROWSING_HISTORY,
  RECEIVE_BROWSING_HISTORY,
  BROWSING_HISTORY_ERROR,
  RESET_BROWSING_HISTORY,
} from './actions';

export const defaultState = {
  startTime: null,
  endTime: null,
  browsingHistoryError: '',
  browsingHistoryLoading: false,
  browsingHistoryLoaded: false,
  browsingHistory: [],
  email: null,
  guid: null,
};

export const browsingHistoryReducer = (state = defaultState, action) => {
  switch (action.type) {
    case GET_BROWSING_HISTORY:
      return {
        ...defaultState,
        browsingHistoryLoading: true,
        browsingHistoryError: '',
        startTime: action.startTime,
        endTime: action.endTime,
        email: action.email || null,
        guid: action.guid || null,
      };
    case RECEIVE_BROWSING_HISTORY:
      return {
        ...state,
        browsingHistory: action.browsingHistory,
        browsingHistoryLoaded: true,
        browsingHistoryLoading: false,
        browsingHistoryError: '',
      };
    case BROWSING_HISTORY_ERROR:
      return {
        ...state,
        browsingHistory: [],
        browsingHistoryLoaded: false,
        browsingHistoryLoading: false,
        browsingHistoryError: action.error,
      };
    case RESET_BROWSING_HISTORY:
      return defaultState;
    default:
      return state;
  }
};
