import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  getBrowsingHistory,
  getOldBrowsingHistory,
  resetBrowsingHistory,
} from '../../../redux/browsingHistory/actions';

import { getCurrentGroup } from '../../../utilities/groups';
import I18n from '../../../utilities/i18n';
import { getDisplayTime, getDayTime } from '../../../utilities/time';

import DataLoader from '../DataLoader';
import DayPickerDropdown from '../DayPickerDropdown';
import WebsiteImage from '../WebsiteImage';


const BrowsingHistory = ({
  email,
  userGuid,
  browsingHistory,
  getBrowsingHistory,
  resetBrowsingHistory,
}) => {

  const [pickedDate, setPickedDate] = useState(new Date());

  useEffect(() => {
    if (email && userGuid) {
      const start = getDayTime(pickedDate);
      const end = getDayTime(pickedDate, 23, 59);
      getBrowsingHistory(userGuid, start, end);
    }
  }, [
    email,
    userGuid,
    pickedDate,
    getBrowsingHistory,
  ]);

  useEffect(() => {
    return resetBrowsingHistory;
  }, [resetBrowsingHistory]);

  const buildContent = () => {
    if (browsingHistory.browsingHistoryLoading) {
      return <DataLoader />;
    }

    if (browsingHistory.browsingHistory.length === 0) {
      return (
        <div
          data-testid='browsingHistory-nothing'
          className='text--gray text--alignCenter m-vertical--40'
        >
          {I18n.t('nothing_nada_zilch')}
        </div>
      );
    }

    return (
      <table className='table'>
        <tbody>
          {buildRows()}
        </tbody>
      </table>
    );
  };

  const buildRows = () => {
    return browsingHistory.browsingHistory.map((item) => {
      const displayTime = getDisplayTime(item.at);

      const url = item.url || item.host;
      const host = item.host;

      return (
        <tr key={item.at}>
          <td className='co-flyout-history-times text--gray'>{displayTime}</td>
          <td>
            <div className='truncate l-flex l-flex--vAlignCenter' data-testid='browsingHistory-row'>
              <WebsiteImage host={host} classes='m-right--8' />

              <a
                href={url}
                className='link--underlineHover'
                target='_blank'
                rel='noopener noreferrer'
                title={host}
              >
                {url}
              </a>
            </div>
          </td>
        </tr>
      );
    });
  };

  return (
    <section className='co-flyout-content'>
      <section className='co-flyout-contentHeading'>
        <h3 className='co-flyout-contentHeader'>{I18n.t('browsing_history')}</h3>
        <DayPickerDropdown
          dayPickerValue={pickedDate}
          onDayClickHandler={(date) => setPickedDate(date)}
          onTodayClickHandler={() => setPickedDate(new Date())}
        />
      </section>

      {buildContent()}
    </section>
  );
};

BrowsingHistory.propTypes = {
  email: PropTypes.string,
  userGuid: PropTypes.string,
  browsingHistory: PropTypes.object.isRequired,
  getBrowsingHistory: PropTypes.func.isRequired,
  resetBrowsingHistory: PropTypes.func.isRequired,
};

const mapState = (state) => ({
  userBetas: state.currentUser.betas,
  currentGroup: getCurrentGroup(state.groups),
  browsingHistory: state.browsingHistory,
  districtBetas: state.district.betas,
});

const mapDispatch = {
  getBrowsingHistory,
  getOldBrowsingHistory,
  resetBrowsingHistory,
};

export default connect(mapState, mapDispatch)(BrowsingHistory);
