import {
  SET_BROADCAST_STEP,
  SET_BROADCAST_ERROR,
  SET_BROADCAST_EXPIRED,
  RECEIVE_BROADCAST_DATA,
} from './actions';

export const defaultState = {
  step: 0,
  isLoading: true,
  isExpired: false,
  hasError: false,
  channelName: '',
  groupGuid: '',
  iceServers: [],
};

export function broadcastReducer(state = defaultState, action) {
  switch (action.type) {
    case SET_BROADCAST_STEP: {
      const isLoading = action.step === 3 ? false : state.isLoading;
      return {
        ...state,
        step: action.step,
        isLoading,
      };
    }
    case SET_BROADCAST_ERROR:
      return {
        ...state,
        isLoading: false,
        hasError: true,
      };
    case SET_BROADCAST_EXPIRED:
      return {
        ...state,
        isLoading: false,
        isExpired: true,
      };
    case RECEIVE_BROADCAST_DATA:
      return {
        ...state,
        channelName: action.channelName,
        groupGuid: action.groupGuid,
        iceServers: action.iceServers,
      };
    default:
      return state;
  }
}
