import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';


import LoadingButton from '../common/LoadingButton';
import { connect } from 'react-redux';
import { getUsers } from '../../redux/users/actions';
import { bindActionCreators } from 'redux';

import { resyncSis } from '../../utilities/groups';
import { hasBeta } from '../../utilities/users';
import I18n from '../../utilities/i18n';
import { saveLocalStorage, loadLocalStorage } from '../../helpers/localStorage';

import BetaBadge from '../common/BetaBadge';
import { addErrorNotification } from '../../redux/ui/actions';

import { hasGroupOrUserBetas } from '../../utilities/betas';

const SISSync = ({
  currentGroup,
  getUsers,
  districtBetas,
  classroomGroupBetas,
  userBetas,
  addErrorNotification,
}) => {
  const [isSaving, setIsSaving] = useState(false);
  const resyncLocalStorage = 'last_class_resync';
  const hasGoogleClassroomSyncBeta = hasGroupOrUserBetas(
    'google_classroom_sync',
    userBetas,
    classroomGroupBetas,
  );

  useEffect(() => {
    setIsSaving(false);
  }, []);

  const resync = async () => {
    if (isSaving) {
      return;
    }

    setIsSaving(true);

    if (await resyncSis(currentGroup.guid)) {
      saveLocalStorage(resyncLocalStorage, Date.now());
      setTimeout(async () => {
        await getUsers(currentGroup.guid);
        setIsSaving(false);
      }, 3000);

      return;
    }
    addErrorNotification(I18n.t('sync_failed'));
    setIsSaving(false);
  };

  const buildResyncButton = () => {
    const oneHour = 60 * 60 * 1000;
    const oneHourAgo = Date.now() - oneHour;
    const lastResync = loadLocalStorage(resyncLocalStorage);
    const isDisabled = lastResync && lastResync > oneHourAgo;

    return (
      <LoadingButton
        classes='button button--blue m-left--8 analytics-resyncClass'
        onClick={resync}
        isDisabled={isDisabled}
        label={I18n.t('resync')}
        isLoading={isSaving}
      />
    );
  };

  const buildMessage = () => {
    if (!currentGroup) {
      return null;
    }

    return (
      <p className='text--gray m-top--0'>
        {I18n.t('pull_the_latest_roster', { name: currentGroup.name })}
      </p>
    );
  };

  const displaySISSyncComponent = () => {
    const isGoogleClassroom = hasBeta('cloud_rostering', districtBetas, userBetas) &&
                              hasGoogleClassroomSyncBeta &&
                              currentGroup?.group_type === 'google_classroom';

    if (isGoogleClassroom) {
      return true;
    }

    if (!hasBeta('cloud_rostering', districtBetas, userBetas) && currentGroup && currentGroup.sis_id) {
      return true;
    }

    return false;
  };

  if (displaySISSyncComponent()) {
    return (
      <div>
        <h3 className='co-schedules-title m-top--48'>
          {I18n.t('resync_from_sis')}
          <BetaBadge classes='m-left--8' isInHeader={true}/>
        </h3>
        {buildMessage()}
        <section className='co-settings-section co-settings-section--flex'>
          <strong className='co-settings-section-title'>{I18n.t('resync_class')}</strong>

          <div
            className='co-settings-resync-button tooltipped tooltipped--medium'
            aria-label={I18n.t('one_resync_per_hour')}
          >
            {buildResyncButton()}
          </div>
        </section>
      </div>
    );
  }

  return null;
};

SISSync.propTypes = {
  classroomGroupBetas: PropTypes.object.isRequired,
  currentGroup: PropTypes.object,
  getUsers: PropTypes.func,
  districtBetas: PropTypes.object.isRequired,
  userBetas: PropTypes.object.isRequired,
  addErrorNotification: PropTypes.func,
};

const mapStateToProps = (state) => ({
  classroomGroupBetas: state.betas,
  districtBetas: state.district.betas,
  userBetas: state.currentUser.betas,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  getUsers,
  addErrorNotification,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SISSync);
