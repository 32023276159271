import * as Ably from 'ably/promises';
import uuidv1 from 'uuid/v1';

import { addError } from '../errors/actions';
import { analyzeInsight } from '../insights/actions';
import {
  ablyGroupChannelUiUpdateFunctions,
  addErrorNotification,
  addNotification,
  ABLY_GROUP_CHANNEL_UI_UPDATE_EVENTS,
  BROWSING_TOGGLE_UPDATE,
} from '../ui/actions';
import {
  addWebrtc,
  removeWebrtc,
  addSecondViewer,
  removeSecondViewer,
  removeHandler,
} from '../webrtcs/actions';

import { getPresence, getPresenceData } from '../../utilities/ably';
import { getCurrentGroup, selectGroup } from '../../utilities/groups';
import I18n from '../../utilities/i18n';
import { removeProperties } from '../../utilities/types';
import { getUserEmails, getUserByEmail, getOnCampusUserEmails, getNameWithPossession } from '../../utilities/users';
import { isOnCampusOnly } from '../../utilities/policy';
import { handleCheckInChange, setPendingCheckOutUpdate } from '../users/actions';

import { ClassroomApi } from '../../helpers/classroomApi';
import { getCognitoCookieValue } from '../../helpers/cookies';

import { addWebrtcError } from '../webrtcs/actions';

export const INIT_ABLY = 'INIT_ABLY';
export const SHUTDOWN_ABLY = 'SHUTDOWN_ABLY';
export const SET_ABLY_STATUS = 'SET_ABLY_STATUS';
export const ADD_CHANNEL = 'ADD_CHANNEL';
export const REMOVE_CHANNEL = 'REMOVE_CHANNEL';
export const UPDATE_CHANNEL_DATA = 'UPDATE_CHANNEL_DATA';

const baseOptions = {
  echoMessages: false,
  environment: 'lightspeed',
  fallbackHosts: [
    'a-fallback-lightspeed.ably.io',
    'b-fallback-lightspeed.ably.io',
    'c-fallback-lightspeed.ably.io',
  ],
  log: { level: 0 },
  queryTime: true,
  recover: (_, shouldRecover) => shouldRecover(true),
};
const rampUpTime = 3 * 1000;
let rampUpTimeout = null;

export function initAblyWithAuth(customerId, email) {
  const clientId = `${email}-ui`;
  const options = {
    ...baseOptions,
    authHeaders: {
      'x-api-key': process.env.REACT_APP_RELAY_API_KEY,
      'Content-Type': 'application/json',
      jwt: localStorage.jwt,
      Authorization: getCognitoCookieValue('access_token'),
    },
    authParams: {
      clientId,
    },
    authUrl: `${process.env.REACT_APP_RELAY_API_URL}/auth/ably_token_request`,
    clientId,
  };

  return initAbly(customerId, clientId, options);
}

export function initAblyWithToken(customerId, clientId, token) {
  const options = {
    ...baseOptions,
    clientId,
    token,
  };

  return initAbly(customerId, clientId, options);
}

function initAbly(customerId, clientId, options) {
  return function(dispatch) {
    const client = new Ably.Realtime.Promise(options);

    client.connection.on((state) => {
      const status = state.current;
      if (status !== 'update') {
        dispatch({ type: SET_ABLY_STATUS, status });
      }
    });

    return dispatch({ type: INIT_ABLY, customerId, clientId, client });
  };
}

export function shutdownAbly() {
  return function(dispatch, getState) {
    const state = getState();
    const channelNames = Object.keys(state.ably.channels);

    for (const name of channelNames) {
      dispatch(disconnectChannel(name));
    }

    if (state.ably.client?.connection) {
      state.ably.client.connection.off();
      state.ably.client.connection.close();
    }
    dispatch(removeHandler());

    return dispatch({ type: SHUTDOWN_ABLY });
  };
}

export function connectGroupChannels(groupGuid) {
  return function(dispatch, getState) {
    const state = getState();
    const teacherEmail = state.currentUser.email;
    const students = Object.values(state.users.all);
    const teacherBroadcastChannel = `broadcast:${teacherEmail}`;

    let groupMessages = ['bulkCheckOut', 'singleStudentCheckOut'];
    groupMessages = groupMessages.concat(ABLY_GROUP_CHANNEL_UI_UPDATE_EVENTS);

    dispatch(connectChannel(groupGuid, null, groupMessages));
    dispatch(connectChannel(teacherBroadcastChannel, [teacherBroadcastChannel]));
    dispatch(connectChannel(teacherEmail, [teacherEmail]));
    dispatch(connectStudentChannels(groupGuid, students));
  };
}

export function connectStudentChannels(groupGuid, students) {
  return function(dispatch, getState) {
    const state = getState();
    const group = selectGroup(groupGuid, state.groups.groups);
    const groupId = group.id;
    const clientId = state.ably.clientId;
    const studentMessages = ['tabs', 'groupUpdate'];

    for (const student of students) {
      const email = student.email;
      const channel = dispatch(connectChannel(email, [email, clientId], studentMessages));
      const groupData = { groupId, groupGuid };

      channel.presence.update(groupData).catch((e) => sendAblyRollbar('group_update', e));
      channel.publish('sendGroupUpdate', groupData);
      channel.subscribe('groupUpdate', (msg) => {
        const currentHost = msg.data[groupGuid]?.currentHost ?? msg.data[groupId]?.currentHost;
        dispatch(analyzeInsight(student.guid, currentHost));
        dispatch(handleCheckInChange(msg, student.guid));
      });
      channel.subscribe('webrtc_error', (msg) => {
        dispatch(addWebrtcError(msg.clientId, msg.data));
      });
    }
  };
}

export function disconnectAllChannelsExceptBroadcast() {
  return function(dispatch, getState) {
    const state = getState();
    const names = Object.keys(state.ably.channels);
    const teacherEmail = state.currentUser.email;

    for (const name of names) {
      // we want to disconnect all channels except the broadcast (screen share) channel
      // keeping the broadcast connection open prevents braodcast errors on the student end (see RC-858 for more info)
      if (name !== `broadcast:${teacherEmail}`) {
        dispatch(disconnectChannel(name));
      }
    }
  };
}

export function connectChannel(name, presenceIds = [], messageTypes = []) {
  return function(dispatch, getState) {
    const state = getState();
    const customerId = state.ably.customerId;
    const client = state.ably.client;

    if (!customerId || !client) {
      return {};
    }

    const fullName = `${customerId}:${name}`;
    const channel = client.channels.get(fullName);

    channel.on((state) => {
      const key = 'status';
      const value = state.current;

      if (value !== 'update') {
        dispatch({ type: UPDATE_CHANNEL_DATA, name, key, value });
      }
    });

    for (const type of messageTypes) {
      channel.subscribe(type, (msg) => {
        const key = type;
        const value = msg.data;

        if (ABLY_GROUP_CHANNEL_UI_UPDATE_EVENTS.includes(key)) {
          dispatch(ablyGroupChannelUiUpdateFunctions[key](value));
        }

        if (type === 'bulkCheckOut') {
          dispatch(setPendingCheckOutUpdate(true));

          const checkOutMessage =
          value.studentInfo === 1
            ? I18n.t(
              'one_student_has_been_checked_into_teachers_class',
              {
                studentCount: value.studentInfo,
                teacherName: value.teacherName,
                className: value.className,
              },
            )
            : I18n.t(
              'number_students_have_been_checked_into_teachers_class',
              {
                studentCount: value.studentInfo,
                teacherName: value.teacherName,
                className: value.className,
              },
            );

          dispatch(
            addNotification(checkOutMessage, {
              autoDismiss: true,
              notificationType: 'info',
            }),
          );
        }

        if (type === 'singleStudentCheckOut') {
          dispatch(setPendingCheckOutUpdate(true));

          const checkOutMessage = I18n.t('student_has_been_checked_into', {
            studentName: value.studentInfo,
            teacherName: value.teacherName,
            className: value.className,
          });

          dispatch(
            addNotification(checkOutMessage, {
              autoDismiss: true,
              notificationType: 'info',
            }),
          );
        }

        dispatch({ type: UPDATE_CHANNEL_DATA, name, key, value });
      });
    }

    channel.presence.subscribe(async () => {
      const key = 'presence';
      const value = await getPresence(channel, presenceIds);

      dispatch({ type: UPDATE_CHANNEL_DATA, name, key, value });
    });

    channel.presence.enter({}).catch((e) => sendAblyRollbar('connect_enter', e));
    dispatch({ type: ADD_CHANNEL, name, channel });

    return channel;
  };
}

export function disconnectChannel(name) {
  return function(dispatch, getState) {
    const state = getState();
    const channel = state.ably.channels[name];

    if (channel) {
      channel.presence.unsubscribe();
      channel.unsubscribe();
      channel.off();
      channel.detach();

      return dispatch({ type: REMOVE_CHANNEL, name });
    }
  };
}

export function initFlyout(email) {
  return function(dispatch) {
    const presenceData = { viewingTabs: true, viewingFlyout: true };
    return dispatch(initWebrtc(email, presenceData));
  };
}

export function shutdownFlyout(email, isScreensViewTab) {
  return function(dispatch) {
    const presenceKeys = ['viewingTabs', 'viewingFlyout'];
    if (rampUpTimeout) {
      presenceKeys.push('viewingThumbnail');
      clearTimeout(rampUpTimeout);
      rampUpTimeout = null;
    }
    return dispatch(shutdownWebrtc(email, presenceKeys, isScreensViewTab));
  };
}

export function initThumbnail(email) {
  return function(dispatch) {
    return dispatch(initWebrtc(email, { viewingThumbnail: true }));
  };
}

export function shutdownThumbnail(email) {
  return function(dispatch) {
    return dispatch(shutdownWebrtc(email, ['viewingThumbnail']));
  };
}

export function initWebrtc(email, presenceData) {
  return function(dispatch, getState) {
    const state = getState();
    const hasExistingWebrtc = state.webrtcs.all[email];

    if (hasExistingWebrtc) {
      dispatch(addPresence(email, presenceData));
      return dispatch(addSecondViewer(email));
    }

    const channel = state.ably.channels[email];

    if (channel) {
      const sessionId = uuidv1();
      const iceServers = state.currentUser.iceServers;
      const data = { ...presenceData, sessionId };

      if (presenceData.viewingFlyout) {
        rampUpQuality(email, data, dispatch);
      } else {
        dispatch(addPresence(email, data));
      }
      return dispatch(addWebrtc(email, sessionId, channel, iceServers));
    }
  };
}

function rampUpQuality(email, data, dispatch) {
  //start at low quality for easier transition
  //TODO: remove once Chrome agent is capturing entire screen
  const initialData = { viewingThumbnail: true, sessionId: data.sessionId };
  dispatch(addPresence(email, initialData));

  rampUpTimeout = setTimeout(() => {
    rampUpTimeout = null;
    dispatch(addPresence(email, data, true));
  }, rampUpTime);
}

export function shutdownWebrtc(email, presenceKeys, isScreensViewTab) {
  return function(dispatch, getState) {
    const state = getState();
    const hasSecondViewer = state.webrtcs.secondViewers[email];

    // if the current group tab is set to screensView, then we do NOT want to dispatch removePresence
    if (isScreensViewTab) {
      return dispatch(removeSecondViewer(email));
    }

    if (hasSecondViewer) {
      dispatch(removePresence(email, presenceKeys));
      return dispatch(removeSecondViewer(email));
    }

    const keys = [...presenceKeys, 'sessionId'];

    dispatch(removePresence(email, keys));
    return dispatch(removeWebrtc(email));
  };
}

export function addPresence(email, data, overwriteThumbnail = false) {
  return function(_, getState) {
    const state = getState();
    const presence = getPresenceData(state.ably.data, email, state.ably.clientId);
    const channel = state.ably.channels[email];
    if (overwriteThumbnail) {
      delete presence.viewingThumbnail;
    }
    const updated = { ...presence, ...data };

    // TODO after all agents have been updated to use viewingBroadcast/Flyout/Thumbnail, remove the 4 lines below
    const all = Object.keys(updated);
    if (all.includes('viewingFlyout') || all.includes('viewingThumbnail')) {
      updated.viewingScreen = true;
    }

    // groupGuid is being added to "fix" the issue with the Chrome extension (and other agents?)
    // not publishing a groupUpdate to Ably due to the Ably data not having a groupGuid key
    if (!Object.prototype.hasOwnProperty.call(updated, 'groupGuid')) {
      updated.groupGuid = state.groups.currentGuid;
    }

    if (channel?.state === 'attached') {
      channel.presence.update(updated).catch((e) => sendAblyRollbar('user_add_update', e));
    }
  };
}

export function removePresence(email, keys) {
  return function(_, getState) {
    const state = getState();
    const presence = getPresenceData(state.ably.data, email, state.ably.clientId);
    const channel = state.ably.channels[email];
    const updated = removeProperties(presence, keys);

    // TODO after all agents have been updated to use viewingBroadcast/Flyout/Thumbnail, remove the 4 lines below
    const all = Object.keys(updated);
    if (!all.includes('viewingFlyout') && !all.includes('viewingThumbnail')) {
      delete updated.viewingScreen;
    }

    if (channel?.state === 'attached') {
      channel.presence.update(updated).catch((e) => sendAblyRollbar('user_remove_update', e));
    }
  };
}

export function sendLink(url, email) {
  return function(dispatch) {
    dispatch(send('url', url, email));
  };
}

export function closeUrl(url, email) {
  return function(dispatch) {
    dispatch(send('closeTab', { url }, email));
  };
}

export function closeTab(tabId, email) {
  return function(dispatch) {
    dispatch(send('closeTab', { tabId }, email));
  };
}

export function focusTab(windowId, tabId, email) {
  return function(dispatch) {
    dispatch(send('focusTab', { windowId, tabId }, email));
  };
}

export function startRecording(email) {
  const tenMinutes = 10 * 60;

  return function(dispatch) {
    dispatch(send('startRecording', { duration: tenMinutes }, email));
  };
}

export function setStudentState(state, email) {
  return function(dispatch) {
    dispatch(send('setState', state, email));
  };
}

export function lockScreen(email) {
  return function(dispatch, getState) {
    const state = getState();
    const firstInitial = state.currentUser.first_name ? state.currentUser.first_name.substring(0, 1) : '-';
    const lastName = state.currentUser.last_name ? state.currentUser.last_name : '-';
    const teacherName = `${firstInitial}. ${lastName}`;
    const lockMessage = I18n.t('locked_by_teacher', { teacherName });

    dispatch(send('lock', { lockMessage }, email));
  };
}

export function unlockScreen(email) {
  return function(dispatch) {
    dispatch(send('unlock', {}, email));
  };
}

export function sendMessage(messageId, message, email, useLog = false) {
  return async function(dispatch) {
    if (useLog) {
      if (!await dispatch(logMessage(email, message))) {
        return dispatch(addErrorNotification(I18n.t('message_could_not_be_sent')));
      }
    }

    return dispatch(send('tm', { m: message, mId: messageId }, email));
  };
}

function logMessage(email, message) {
  return async function(dispatch, getState) {
    const state = getState();
    const currentUser = state.currentUser;
    const currentGroup = getCurrentGroup(state.groups);
    const user = getUserByEmail(state.users.all, email);

    const params = {
      group_guid: currentGroup.guid,
      to_guid: `u::${user?.guid}`,
      message,
    };
    const response = await ClassroomApi.post(`/users/${currentUser.guid}/message_logs`, params);

    if (response?.status !== 200) {
      dispatch(addError('logMessage', response));
      return false;
    }

    return true;
  };
}

export function updatePolicy(email) {
  return function(dispatch) {
    dispatch(sendViaRest('policyUpdate', { trigger: 'classroom' }, email));
  };
}

export function send(type, data, email) {
  return function(_, getState) {
    const state = getState();
    const groupPolicy = state.policy.group;
    const allUsers = state.users.all;

    if (email) {
      const currentGroup = getCurrentGroup(state.groups);
      const user = Object.values(allUsers).find((userInfo) => userInfo.email === email);
      if (user?.checkedInto?.guid !== currentGroup.guid) {
        return;
      }
    }

    let allowedEmails = getUserEmails(allUsers);
    let sendToEmails = [];

    if (isOnCampusOnly(groupPolicy)) {
      const districtIps = state.district.ips;
      allowedEmails = getOnCampusUserEmails(Object.keys(allUsers), allUsers, state.ably.data, districtIps);
    }

    if (email && allowedEmails.includes(email)) {
      sendToEmails = [email];
    } else if (!email) {
      sendToEmails = allowedEmails;
    }

    const channels = sendToEmails.map((email) => state.ably.channels[email]);

    for (const channel of channels) {
      if (channel?.state === 'attached') {
        channel.publish(type, data);
      }
    }
  };
}

export function sendViaRest(type, data, email) {
  return async function(dispatch, getState) {
    const state = getState();
    const clientId = state.ably.clientId;

    // TODO remove useUi once teacher does not use agent for broadcast
    const response = await ClassroomApi.get('/auth/ably_jwt?useUi=true');

    if (response?.status !== 200) {
      return dispatch(addError('updatePolicy', response));
    }

    const token = response.data;
    const options = {
      ...baseOptions,
      clientId,
      token,
    };
    const ablyRest = new Ably.Rest(options);

    const customerId = state.ably.customerId;
    const emails = email ? [email] : getUserEmails(state.users.all);
    const channels = emails.map((email) => ablyRest.channels.get(`${customerId}:${email}`));

    for (const channel of channels) {
      if (channel) {
        channel.publish(type, data);
      }
    }
  };
}

export function sendAblyRollbar(operation, error) {
  window.Rollbar.error(`ably: ${operation}`, {
    reportRatio: 0.01,
    ...error,
  });
}

export function checkOutNotification(groupToNotifyGuid, checkOutData, notificationType) {
  return function (dispatch, getState) {
    const state = getState();
    const customerId = state.ably.customerId;
    const groupChannel = `${customerId}:${groupToNotifyGuid}`;
    const client = state.ably.client;
    const groupToNotifyOfCheckOut = client.channels.get(groupChannel);
    const className = getCurrentGroup(state.groups).name;
    const teacherName = getNameWithPossession(
      `${state.currentUser.first_name} ${state.currentUser.last_name}`,
    );

    // notification types: singleStudentCheckOut || bulkCheckOut
    groupToNotifyOfCheckOut.publish(`${notificationType}`, {
      studentInfo: checkOutData,
      teacherName: teacherName,
      className: className,
    });

    return dispatch(setPendingCheckOutUpdate(true));
  };
}

export function publishBrowsingToggleUpdate(groupGuid, noBrowsing) {
  return function(_, getState) {
    const state = getState();
    const client = state.ably.client;
    const channelName = `${state.ably.customerId}:${groupGuid}`;
    const channel = client.channels.get(channelName);
    channel.publish(BROWSING_TOGGLE_UPDATE, { noBrowsing: noBrowsing });
  };
}
