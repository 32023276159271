export function isRealNumber(value) {
  return value !== null &&
    value !== undefined &&
    typeof value === 'number' &&
    !isNaN(value) &&
    isFinite(value);
}

export function isCustomerId(value) {
  if (typeof value !== 'string') {
    return false;
  }

  const [first, second, third] = value.split('-');

  if (!first || first.length !== 2) {
    return false;
  }
  // TODO remove length 3 when Darin and Phil's staging districts are fixed
  if (!second || !(second.length === 3 || second.length === 4)) {
    return false;
  }
  if (!third || third.length !== 4) {
    return false;
  }

  const firstValue = Number(first);
  const secondValue = Number(second);
  const thirdLetter = third.slice(0, 1);
  const thirdValue = Number(third.slice(1));

  if (!Number.isInteger(firstValue)) {
    return false;
  }
  if (!Number.isInteger(secondValue)) {
    return false;
  }
  if (!/^[A-Z]$/.test(thirdLetter)) {
    return false;
  }
  if (!Number.isInteger(thirdValue)) {
    return false;
  }

  return true;
}

export function removeProperties(object, names) {
  if (Array.isArray(names)) {
    return Object.keys(object).reduce((filtered, key) => {
      if (names.includes(key)) {
        return filtered;
      }
      return {
        ...filtered,
        [key]: object[key],
      };
    }, {});
  }
}

export function removeProperty(object, name) {
  return Object.keys(object).reduce((filtered, key) => {
    if (key === name) {
      return filtered;
    }
    return {
      ...filtered,
      [key]: object[key],
    };
  }, {});
}

export function handleArrayWithTypeNames(data, typeName) {
  if (!Array.isArray(data)) {
    return data;
  }

  return data.map((entry) => {
    return entry[typeName];
  });
}
