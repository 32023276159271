import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import classNames from 'classnames';

import { openModal, closeModal, closeDropdown } from '../../redux/ui/actions';

import I18n from '../../utilities/i18n';
import { getGroupUrl } from '../../utilities/urls';

import Dropdown from '../common/Dropdown';
import Icon from '../common/Icon';
import Modal from '../common/Modal';


const RosterItem = ({
  group,
  isSending,
  isDragged,
  canRemove,
  showGroup,
  hideGroup,
  removeGroup,
  renameGroup,
  currentlyOpenModal,
  openModal,
  closeModal,
  closeDropdown,
}) => {
  const modalName = `rosterItem${group.guid}`;
  const [newName, setNewName] = useState('');
  const settingsDropdownName = 'settings' + group.guid;

  function openNewName() {
    closeDropdown(settingsDropdownName);
    setNewName(group.display_name || group.name);
    openModal(modalName);
  }

  function saveNewName(name) {
    setNewName('');
    closeModal();
    renameGroup(group.guid, name);
  }

  function buildName() {
    return (
      <div className='grid--span8 l-flex l-flex--vAlignCenter'>
        <button
          className='button-link text--lightGray m-right--12'
          data-movable-handle
          tabIndex={-1}
          style={{ cursor: isDragged ? 'grabbing' : 'grab' }}
        >
          <Icon name='icon-move-handle' classes='textHeading--small' />
        </button>

        <div>
          <strong data-guid={group.guid}>
            {group.display_name || group.name}
          </strong>
          <div className='text--gray'>
            {group.name}
          </div>
        </div>
      </div>
    );
  }

  function buildSettings() {
    const label = (
      <div className='co-rosterManagement-infoSettings'>
        <span className='co-rosterManagement-dots'>...</span>
      </div>
    );

    let action = hideGroup;
    let actionLabel = I18n.t('hide');
    if (canRemove) {
      action = removeGroup;
      actionLabel = I18n.t('remove');
    } else if (group.hidden) {
      action = showGroup;
      actionLabel = I18n.t('show');
    }

    return (
      <div className='grid--selfCentered'>
        <Dropdown
          staticLabel={label}
          truncateLabel={false}
          buttonLabelClasses='co-rosterManagement-classDropdown'
          containerClasses='co-dropdown--naturalWidth'
          dropdownMenuClasses='co-dropdown-menu--rightAnchored p-vertical--0'
          dropdownName={settingsDropdownName}
          hasArrow={false}
        >
          <ul>
            <li>
              <Link className='co-dropdown-item button-link' to={getGroupUrl(group)}>
                {I18n.t('view_class')}
              </Link>
            </li>
            <li>
              <Link className='co-dropdown-item button-link' to={getGroupUrl(group, '/settings')}>
                {I18n.t('settings')}
              </Link>
            </li>
            <li>
              <button className='co-dropdown-item button-link' onClick={() => openNewName()}>
                {I18n.t('rename')}
              </button>
            </li>
            <li>
              <button className='co-dropdown-item button-link' onClick={() => {
                closeDropdown(settingsDropdownName);
                action(group.guid);
              }}>
                {actionLabel}
              </button>
            </li>
          </ul>
        </Dropdown>
      </div>
    );
  }

  function buildScheduleType() {
    let type = 'none';
    if (group.schedule_type === 'custom') {
      type = 'custom';
    } else if (group.schedule_type === 'bell') {
      type = 'bell';
    } else if (group.schedule_type === 'ad_hoc') {
      type = 'ad_hoc';
    }
    return <span className='grid--selfCentered'>{I18n.t(type)}</span>;
  }

  const containerClasses = classNames({
    'co-rosterManagement-infoGrid': true,
    'co-rosterManagement-infoGrid--sending': isSending,
  });

  return (
    <Fragment>
      <section className={containerClasses}>
        {buildName()}
        {buildScheduleType()}
        {buildSettings()}

        {isSending && (
          <div className='co-rosterManagement-rosterItem-sending'>
            <Icon name='icon-loading' classes='icon-loading--animating' />
          </div>
        )}
      </section>

      <Modal
        show={currentlyOpenModal === modalName}
        title={I18n.t('class_name')}
        saveHandler={() => saveNewName(newName)}
        saveText={I18n.t('save')}
        closeModal={closeModal}
        isSmall
        modalRedesign2022Q1
        legacyModalVersion
      >
        <section>
          <input
            className='co-rosterManagement-rosterItem-modal'
            data-testid='rosterItem-modal-input'
            type='text'
            value={newName}
            onChange={(e) => setNewName(e.target.value)}
          />

          <div className='m-top--16'>
            <div className='text--gray text--uppercase text--xsmall'>{I18n.t('sis_name')}</div>
            {group.name}
          </div>
        </section>
      </Modal>
    </Fragment>
  );
};

RosterItem.propTypes = {
  group: PropTypes.object,
  isSending: PropTypes.bool,
  isDragged: PropTypes.bool,
  canRemove: PropTypes.bool,
  showGroup: PropTypes.func,
  hideGroup: PropTypes.func,
  removeGroup: PropTypes.func,
  renameGroup: PropTypes.func,
  currentlyOpenModal: PropTypes.string,
  openModal: PropTypes.func,
  closeModal: PropTypes.func,
  closeDropdown: PropTypes.func,
};

const mapStateToProps = (state) => ({
  currentlyOpenModal: state.ui.currentlyOpenModal,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  openModal,
  closeModal,
  closeDropdown,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(RosterItem);
