import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classNames from 'classnames';

import { openDropdown, closeDropdown } from '../../redux/ui/actions';

import Fade from './animations/Fade';
import Icon from './Icon';
import I18n from '../../utilities/i18n';


const Dropdown = ({
  arrowClasses,
  buttonLabelClasses,
  containerClasses,
  dropdownItems,
  dropdownMenuClasses,
  dropdownName,
  hasArrow = true,
  hasTriangleArrow,
  initialLabel,
  showAsButton = false,
  staticLabel,
  truncateLabel = true,
  currentlyOpenDropdown,
  openDropdown,
  closeDropdown,
  children,
  isDisabled,
  disabledLabel,
  isRightAligned,
  labelOnContainer,
}) => {
  const isOpen = currentlyOpenDropdown === dropdownName;
  const ariaLabel = disabledLabel ? I18n.t(disabledLabel) : '';

  // NOTE: labelOnContainer is used to get the aria-label to work on the web rules dropdown
  // The two different dropdown styles don't work well together
  // One aria-label needs to be on the div container, and the other on the button

  useEffect(() => {
    function handleOutsideClick() {
      if (isOpen) {
        closeDropdown(dropdownName);
      }
    }

    document.addEventListener('click', handleOutsideClick);
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [dropdownName, isOpen, closeDropdown]);

  const toggleDropdown = () => {
    if (isOpen) {
      closeDropdown(dropdownName);
    } else {
      openDropdown(dropdownName);
    }
  };

  const handleItemClick = (action) => {
    if (action) {
      closeDropdown(dropdownName);
      action();
    }
  };

  const buildButton = () => {
    const buttonClasses = classNames(buttonLabelClasses, {
      button: showAsButton,
      truncate: truncateLabel,
      'co-dropdown-select': true,
      'button-link': !showAsButton,
      'button--secondaryWhite': showAsButton,
      'has-dropdownArrow': hasTriangleArrow,
      'co-dropdown-triangle--open': isOpen && hasTriangleArrow,
      'tooltipped tooltipped--large--noCase': isDisabled && disabledLabel && !labelOnContainer,
    });

    return (
      <button
        className={buttonClasses}
        onClick={isDisabled ? () => {} : toggleDropdown}
        data-testid={`dropdown-button-${dropdownName}`}
        aria-label={ariaLabel}
        disabled={isDisabled}
      >
        {buildButtonContent()}
        {buildArrow()}
      </button>
    );
  };

  const buildButtonContent = () => {
    if (staticLabel) {
      return staticLabel;
    }

    if (typeof initialLabel === 'function') {
      return initialLabel();
    }

    return (
      <span className='truncate'>{initialLabel}</span>
    );
  };

  const buildArrow = () => {
    if (!hasArrow) {
      return null;
    }

    const iconName = isOpen ? 'icon-up' : 'icon-down';
    const iconClasses = classNames(arrowClasses, {
      'co-dropdown-arrow': true,
    });

    return (
      <span
        data-testid={`dropdown-arrow-${dropdownName}`}
        className={iconClasses}
      >
        <Icon name={iconName} classes='co-dropdown-arrow-icon' />
      </span>
    );
  };

  const buildContent = () => {
    if (!dropdownItems) {
      return children;
    }

    return (
      <ul>
        {buildItems(dropdownItems)}
      </ul>
    );
  };

  const buildItems = (items) => {
    return items.map((item) => {
      if (!item) {
        return null;
      }

      const buttonClasses = classNames(item.classNames, {
        'co-dropdown-item': true,
        'button-link': true,
      });

      return (
        <li
          key={item.label}
        >
          <button
            className={buttonClasses}
            onClick={() => handleItemClick(item.action)}
          >
            {item.label}
          </button>
        </li>
      );
    });
  };

  const divClasses = classNames(containerClasses, { 'co-dropdown': true });
  const menuClasses = classNames(dropdownMenuClasses, {
    dropdown: true,
    'co-dropdown-menu': true,
    'co-dropdown-menu--rightAlign': isRightAligned,
  });

  return (
    <div className={divClasses} aria-label={ariaLabel}>
      {buildButton()}
      <Fade condition={isOpen}>
        <nav
          data-testid={`dropdown-items-${dropdownName}`}
          className={menuClasses}
        >
          {buildContent()}
        </nav>
      </Fade>
    </div>
  );
};

Dropdown.propTypes = {
  arrowClasses: PropTypes.string,
  buttonLabelClasses: PropTypes.string,
  containerClasses: PropTypes.string,
  dropdownItems: PropTypes.array,
  dropdownMenuClasses: PropTypes.string,
  dropdownName: PropTypes.string,
  hasArrow: PropTypes.bool,
  hasTriangleArrow: PropTypes.bool,
  initialLabel: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.func,
  ]),
  showAsButton: PropTypes.bool,
  staticLabel: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  truncateLabel: PropTypes.bool,
  currentlyOpenDropdown: PropTypes.string,
  openDropdown: PropTypes.func,
  closeDropdown: PropTypes.func,
  children: PropTypes.object,
  isDisabled: PropTypes.bool,
  disabledLabel: PropTypes.string,
  isRightAligned: PropTypes.bool,
  labelOnContainer: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  currentlyOpenDropdown: state.ui.currentlyOpenDropdown,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  openDropdown,
  closeDropdown,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Dropdown);
