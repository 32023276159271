import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { sha256 } from 'js-sha256';

import I18n from '../../utilities/i18n';

import { saveLocalStorage } from '../../helpers/localStorage';

import step1Image from '../../images/onboarding1.png';
import step2Image from '../../images/onboarding2.png';
import step3Image from '../../images/onboarding3.png';
import step4Image from '../../images/onboarding4.png';

import Header from '../common/Header';
import GettingStartedStep from './GettingStartedStep';

const GettingStarted = ({
  currentUser,
  groups,
}) => {

  const completeClickHandler = () => {
    saveLocalStorage('ClassroomGettingStartedDone', true);
  };

  const buildStep1Content = () => {
    return (
      <Fragment>
        <p className='m-top--0'>
          {I18n.t('first_step_in_setting_up_classroom')}
        </p>

        <p>
          {I18n.t('if_you_need_some_help_setting_up_your')}
        </p>

        <p>
          {I18n.t('setting_up_your_user_and_group_information')}
        </p>
      </Fragment>
    );
  };

  const buildStep2Content = () => {
    const codeId = 'kkbmdgjggcdajckdlbngdjonpchpaiea';
    const shaValue = currentUser.auth_customer_id ? sha256(currentUser.auth_customer_id) : null;
    const codeUrl = 'https://lsrelay-extensions-' +
      process.env.REACT_APP_ENV +
      '.s3.amazonaws.com/classroom/' +
      shaValue +
      '/Classroom.xml';

    return (
      <Fragment>
        <p className='text--gray m-top--0'>
          {I18n.t('upon_completing_the_following_steps_google')}
        </p>

        <ol className='m-horizontal--16 list--upperAlpha'>
          <li className='m-vertical--16'>
            {I18n.t('open_the_google_admin_console')}
          </li>

          <li className='m-vertical--16'>
            {I18n.t('select_an_organization_then_find')}
          </li>

          <li className='m-vertical--16'>
            {I18n.t('paste_in_the_following_values')}

            <ul className='m-horizontal--16 m-vertical--8'>
              <li>
                ID: <code>{codeId}</code>
              </li>

              <li className='text--wrap'>
                URL:
                <code className='wordBreakAll'>{codeUrl}</code>
              </li>
            </ul>
          </li>

          <li className='m-vertical--16'>
            {I18n.t('click_save_then_click_save')}
          </li>
        </ol>

        <p>{I18n.t('extension_has_been_deployed')}</p>

        <hr className='background--lightGray m-vertical--24' />

        <p className='text--gray'>{I18n.t('we_also_recommend_the_following')}</p>

        <ol className='m-horizontal--16 list--upperAlpha' start='5'>
          <li className='m-vertical--16'>{I18n.t('under_apps_and_extensions')}</li>
          <li className='m-vertical--16'>{I18n.t('under_security_incognito')}</li>
          <li className='m-vertical--16'>{I18n.t('under_user_experience_developer_tools')}</li>
          <li className='m-vertical--16'>{I18n.t('save_changes')}</li>
        </ol>

        <hr className='background--lightGray m-vertical--24' />
        <p className='text--gray'>
          {I18n.t('you_can_find_detailed_documentation')}
        </p>
      </Fragment>
    );
  };

  const buildStep3Content = () => {
    return (
      <Fragment>
        <p className='m-top--0'>{I18n.t('before_going_live_make_sure')}</p>
        <ol className='m-left--32'>
          <li>{I18n.t('log_into_classroom_as_a_group_owner')}</li>
          <li>{I18n.t('set_the_schedule_for_your_group')}</li>
          <li>{I18n.t('log_into_a_chromebook_or_chrome')}</li>
          <li>{I18n.t('verify_that_the_students_browsing')}</li>
        </ol>
      </Fragment>
    );
  };

  const buildStep4Content = () => {
    return (
      <Fragment>
        <p className='m-top--0'>{I18n.t('classroom_provides_teachers_with_numerous')}</p>
        <p>{I18n.t('we_have_all_the_resources_your_teachers')}</p>
      </Fragment>
    );
  };

  const buildContinueButton = () => {
    if (groups.groups.length > 0) {
      return (
        <Link to='/'
          className='co-gettingStarted-completed'
          onClick={completeClickHandler}
          data-testid='gettingStarted-continue'>
          {I18n.t('completed_all_the_steps')}
        </Link>
      );
    }

    return null;
  };

  const buildSteps = () => {
    return (
      <section className='co-gettingStarted-steps'>
        <ul>
          <GettingStartedStep
            icon={step1Image}
            title={`1. ${I18n.t('add_class_to_be_monitored')}`}
            titleText={I18n.t('import_classes_or_set_them_up')}
          >
            {buildStep1Content()}
          </GettingStartedStep>

          <GettingStartedStep
            icon={step2Image}
            title={`2. ${I18n.t('deploy_the_monitoring_software')}`}
          >
            {buildStep2Content()}
          </GettingStartedStep>

          <GettingStartedStep
            icon={step3Image}
            title={`3. ${I18n.t('view_real_time_browsing_activity')}`}
            titleText={I18n.t('visit_a_class_to_verify_everything')}
          >
            {buildStep3Content()}
          </GettingStartedStep>

          <GettingStartedStep
            icon={step4Image}
            title={`4. ${I18n.t('announce_to_your_staff_that_classroom')}`}
            titleText={I18n.t('empower_teachers_to_get_the_most')}
          >
            {buildStep4Content()}
          </GettingStartedStep>
        </ul>

        {buildContinueButton()}
      </section>
    );
  };

  if (!currentUser.is_admin) {
    return <Redirect to='/' />;
  }

  return (
    <Fragment>
      <Header />
      <div className='co-gettingStartedContainer' data-testid='gettingStarted-container'>
        <div className='co-gettingStarted'>
          <section className='co-gettingStarted-intro'>
            <h1>{I18n.t('four_steps_to_get_you_off_to')}</h1>
            <p>{I18n.t('welcome_to_classroom_were_glad_to')}</p>
          </section>

          {buildSteps()}
        </div>
      </div>
    </Fragment>
  );
};

GettingStarted.propTypes = {
  currentUser: PropTypes.object.isRequired,
  groups: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  currentUser: state.currentUser,
  groups: state.groups,
});

export default connect(mapStateToProps, null)(GettingStarted);
