import { isCustomerId } from './types';

const INVITE_CODE_BASE = 36;
const LETTER_A_CODE = 65;

export function createInviteCodeOLD(customerId, groupId) {
  if (!customerId || typeof customerId !== 'string') {
    return;
  }
  if (!Number.isInteger(groupId)) {
    return;
  }

  const noDashes = customerId.replace(/-/g, '');

  const districtLetter = noDashes.slice(-4, -3);
  const substitution = `${districtLetter.charCodeAt(0) - LETTER_A_CODE}`.padStart(2, '0');
  const substituted = noDashes.replace(districtLetter, substitution);

  const customerInteger = parseInt(substituted, 10);
  const customerCode = customerInteger.toString(INVITE_CODE_BASE);
  const groupCode = groupId.toString(INVITE_CODE_BASE);

  return `${customerCode}-${groupCode}`;
}

export function parseInviteCodeOLD(code) {
  if (!code || typeof code !== 'string') {
    return;
  }

  const [customerCode, groupCode] = code.split('-');

  const customerInteger = parseInt(customerCode, INVITE_CODE_BASE);
  const substituted = `${customerInteger}`;
  const subsitution = substituted.slice(-5, -3);
  const districtLetter = String.fromCharCode(parseInt(subsitution, 10) + LETTER_A_CODE);

  const customer0 = substituted.slice(0, 2);
  const customer1 = substituted.slice(2, -5);
  const customer2 = substituted.slice(-3);
  const customerId = `${customer0}-${customer1}-${districtLetter}${customer2}`;

  const groupId = parseInt(groupCode, INVITE_CODE_BASE);

  if (!isCustomerId(customerId) || !Number.isInteger(groupId)) {
    return;
  }

  return {
    customerId,
    groupId,
  };
}

export function getCustomerIdFromInviteCode(code) {
  if (!code || typeof code !== 'string') {
    return;
  }
  const customerCode = code.split('-')[0];

  const customerInteger = parseInt(customerCode, INVITE_CODE_BASE);
  const substituted = `${customerInteger}`;
  const subsitution = substituted.slice(-5, -3);
  const districtLetter = String.fromCharCode(parseInt(subsitution, 10) + LETTER_A_CODE);

  const customer0 = substituted.slice(0, 2);
  const customer1 = substituted.slice(2, -5);
  const customer2 = substituted.slice(-3);
  const customerId = `${customer0}-${customer1}-${districtLetter}${customer2}`;

  if (!isCustomerId(customerId)) {
    return;
  }

  return customerId;
}
