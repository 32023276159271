import cn from 'classnames';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { CSSTransition } from 'react-transition-group';

import { ReactComponent as ArrowDownIcon } from '../../../images/arrowDown.svg';

import Badge from '../Badge';

const RadioGroupExpander = ({ label, defaultExpanded = true, optionalBadgeList, children }) => {
  const [isExpanded, setExpanded] = useState(defaultExpanded);

  return (
    <section className='co-radioGroupExpander'>
      <div
        className='co-radioGroupExpander-labelBlock'
        onClick={() => setExpanded(!isExpanded)}
      >
        <div className='co-radioGroupExpander-label'>
          <ArrowDownIcon className={cn([
            'co-radioGroupExpander-label-icon',
            { 'co-radioGroupExpander-label-icon--collapsed': !isExpanded },
          ])} />
          <span className='co-radioGroupExpander-label-text'>{label}</span>
        </div>
        {!isExpanded && optionalBadgeList && (
          <div className='co-radioGroupExpander-badgeGroup'>
            {optionalBadgeList.map(
              ({ status, count, isDisabled }) => !isDisabled && <Badge key={status} status={status} count={count} />
            )}
          </div>)
        }
      </div>
      <CSSTransition
        in={isExpanded}
        timeout={300}
        classNames='co-radioGroupExpander-list'
        unmountOnExit
      >
        <ul>{children}</ul>
      </CSSTransition>
    </section>
  );
};

RadioGroupExpander.propTypes = {
  label: PropTypes.any.isRequired,
  defaultExpanded: PropTypes.bool,
  optionalBadgeList: PropTypes.arrayOf(PropTypes.shape({
    status: PropTypes.string,
    count: PropTypes.number,
    isDisabled: PropTypes.bool,
  })),
  children: PropTypes.any,
};

export default RadioGroupExpander;
