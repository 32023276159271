import { setupFullStory, resetFullStory } from './fullStory';
import { setupIntercom, resetIntercom } from './intercom';
import { setupPendo } from './pendo';

export function initAnalytics(user, isInTeams = false) {
  let role = 'Teacher';
  if (user.is_lightspeed_admin) {
    role = 'Support';
  } else if (user.is_district_owner) {
    role = 'Owner';
  } else if (user.is_classroom_admin) {
    role = 'Admin';
  }

  const fullUser = {
    ...user,
    role,
  };

  setupIntercom(fullUser);
  setupPendo(fullUser, isInTeams);
  setupFullStory(fullUser);
}

export function shutdownAnalytics() {
  resetIntercom();
  resetFullStory();
}
