export function isOnCampus(ip, ipRanges) {
  if (!ip || !ipRanges) {
    return true;
  }
  return ipRanges.some((ipRange) => matchesIpRange(ip, ipRange));
}

export function matchesIpRange(ip0, ipRange) {
  const [ip1, cidr] = ipRange.split('/');

  if (cidr) {
    const cidrBits = parseInt(cidr, 10);
    return matchesIpBits(ip0, ip1, cidrBits);
  }

  return ip0 === ip1;
}

function matchesIpBits(ip0, ip1, bits) {
  const ignoreBits = 32 - bits;
  const value0 = getIpAsNumber(ip0);
  const value1 = getIpAsNumber(ip1);

  return (value0 >>> ignoreBits) === (value1 >>> ignoreBits);
}

function getIpAsNumber(ip) {
  const octets = ip.split('.').map((octet) => parseInt(octet, 10));
  return (octets[0] << 24) + (octets[1] << 16) + (octets[2] << 8) + octets[3];
}
