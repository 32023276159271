import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';

import { checkOutNotification } from '../../../redux/ably/actions';
import { bulkCheckInStudents, getClassroomUsers } from '../../../redux/users/actions';
import { getCurrentGroup } from '../../../utilities/groups';
import {
  getUserInfoCompare,
  getUserInfos,
  getUserGuids,
  onlineUsersAvailableForBulkCheckIn,
} from '../../../utilities/users';
import { isOnCampusOnly } from '../../../utilities/policy';
import I18n from '../../../utilities/i18n';
import { closeFlyout, closeModal, openModal } from '../../../redux/ui/actions';
import SegmentSelector from '../SegmentSelector';
import { disableCheckInNotification } from '../../../redux/currentUser/actions';

import Modal from '../Modal';
import Icon from '../Icon';
import { groupUsersByCheckedIntoGuid } from '../../../utilities/ably';

const BulkCheckInFlyout = ({
  ablyData,
  allUsers,
  checkOutNotification,
  bulkCheckInStudents,
  closeFlyout,
  closeModal,
  currentGroup,
  currentlyOpenModal,
  currentSegments,
  currentUser,
  disableCheckInNotification,
  districtIps,
  getCanClose,
  getClassroomUsers,
  groupPolicy,
  openModal,
}) => {
  const allUsersSegmentFilter = 'all';
  const defaultGroupSegmentFilter = 'default';

  const [selectedSegments, setSelectedSegments] = useState([allUsersSegmentFilter]);
  const [checkboxesChanged, setCheckboxesChanged] = useState(false);
  const [selectedUserGuids, setSelectedUserGuids] = useState(0);
  const [selectedUserInfos, setSelectedUserInfos] = useState({});
  const modalNameCancel = 'bulkStudentCheckInCancel';
  const modalNameBulkCheckIn = 'bulkStudentCheckIn';
  const [disableBulkCheckInNotificationSelected, setDisableBulkCheckInNotificationSelected] = useState(false);

  const sortByName = 'BulkCheckInSortBy';
  const sortOrderName = 'BulkCheckInSortOrder';
  const savedSortby = localStorage.getItem(sortByName) ? localStorage.getItem(sortByName) : 'fullName';
  const savedSortOrder = localStorage.getItem(sortOrderName) ? localStorage.getItem(sortOrderName) : 'asc';
  const [sortBy, setSortBy] = useState(savedSortby);
  const [sortOrder, setSortOrder] = useState(savedSortOrder);

  const userGuids = getUserGuids(allUsers);
  const ips = isOnCampusOnly(groupPolicy) ? districtIps : null;
  const userInfos = getUserInfos(userGuids, allUsers, ablyData, ips);
  const onlineUserInfos = onlineUsersAvailableForBulkCheckIn(userInfos, currentGroup.guid);

  const buildUserCheckboxesState = () => {
    const obj = {};
    onlineUserInfos.map((userInfo) => {
      obj[userInfo.guid] = { ...userInfo, checked: true };
      return obj;
    });

    return obj;
  };

  const [userCheckboxes, setUserCheckboxes] = useState(buildUserCheckboxesState());

  const sortColumn = (userInfos) => {
    const isAscending = sortOrder === 'asc';
    return userInfos.sort(getUserInfoCompare(sortBy, isAscending));
  };

  const updateSortBy = (type) => {
    const newSortOrder = Boolean(sortBy === type) && sortOrder === 'asc' ? 'desc' : 'asc';
    setSortBy(type);
    setSortOrder(newSortOrder);
    localStorage.setItem(sortByName, type);
    localStorage.setItem(sortOrderName, newSortOrder);
  };

  const sortIconName = sortOrder === 'asc' ? 'icon-up-2' : 'icon-down-2';
  const SortIcon = () => <Icon name={sortIconName} classes='co-usersTable-sortIcon' />;

  const buildHeader = () => {
    return (
      <header className='co-flyout-header co-flyout-header--bulkCheckIn'>
        <div className='l-flex l-flex--vAlignCenter m-bottom--24'>
          <h1>{I18n.t('bulk_check_in')}</h1>
        </div>
        {I18n.t('only_students_online_shown')}
      </header>
    );
  };

  const handleCloseFlyout = () => {
    if (!checkboxesChanged) {
      closeFlyout();
      return;
    }
    openModal(modalNameCancel);
  };

  const handleCheckIn = async () => {
    if (disableBulkCheckInNotificationSelected) {
      // passing the teacher guid and the name of the setting
      disableCheckInNotification(currentUser.guid, 'bulk_check_in_notification_disabled');
      setDisableBulkCheckInNotificationSelected(false);
    }
    // check in selected users
    bulkCheckInStudents(currentGroup, selectedUserGuids);
    await getClassroomUsers();
    // Group users by by guids
    const groups = Object.entries(groupUsersByCheckedIntoGuid(selectedUserInfos));
    // map over the groups and dispatch a check out notification to each group
    groups.map((group) => {
      const groupToNotifyGuid = group[0];
      // passing group to notify guid, checkOutData (number of students for bulk check out), notificationType
      return checkOutNotification(groupToNotifyGuid, group[1].length, 'bulkCheckOut');
    });
  };

  const buildFooter = () => {
    return (
      <footer className='co-flyout-footer'>
        <button
          data-testid='bulkCheckInFlyout-close-button'
          className='button button--secondaryWhite co-modal-cancel'
          onClick={handleCloseFlyout}
        >
          {I18n.t('close')}
        </button>
        <button
          className='button button--blue co-flyout-applyChanges'
          disabled={selectedUserGuids.length === 0}
          onClick={() => {
            currentUser.bulk_check_in_notification_disabled ? handleCheckIn() : openModal(modalNameBulkCheckIn);
          }}
        >
          {I18n.t('check_in_noDash_capitalized')}
        </button>
      </footer>
    );
  };

  const buildBulkCheckInModal = () => {
    const checkInButton = (
      <button
        className='co-modal-save co-modal-save--bulkCheckIn button button--blue'
        data-testid='bulkCheckInModal-checkInButton'
        onClick={() => {
          handleCheckIn();
          closeFlyout();
          closeModal();
        }}
      >
        {I18n.t('check_in_noDash_capitalized')}
      </button>
    );

    const modalMessageText = selectedUserGuids.length === 1 ? (
      I18n.t('by_checking_student_into_your_class', { studentCount: selectedUserGuids.length })
    ) : (
      I18n.t('by_checking_students_into_your_class', { studentCount: selectedUserGuids.length })
    );

    return (
      <Modal
        show={currentlyOpenModal === modalNameBulkCheckIn}
        title={I18n.t('check_students_in')}
        closeModal={closeModal}
        isSmall
        modalRedesign2022Q1
        legacyModalVersion
        closeText={I18n.t('cancel')}
        customSaveButton={checkInButton}
        data-testid='bulkCheckInModal-confirmationModal'
      >
        <div>
          {modalMessageText}
          <ul className='co-modal-list'>
            <li>{I18n.t('screen_lock')}</li>
            <li>{I18n.t('send_links')}</li>
            <li>{I18n.t('apply_web_rules')}</li>
            <li>{I18n.t('share_screen')}</li>
            <li>{I18n.t('send_messages')}</li>
          </ul>
          <div className='co-modal-checkbox-label cursorPointer l-flex'>
            <input
              type='checkbox'
              className='co-listsTable-listName-checkbox co-listsTable-listName-checkbox--doNotShowModal'
              checked={disableBulkCheckInNotificationSelected}
              onChange={(e) => setDisableBulkCheckInNotificationSelected(e.target.checked)}
              data-testid='bulkCheckInFlyout-doNotShow-checkbox'
              id='bulkCheckInFlyout-doNotShow-checkbox'
            />
            <label htmlFor='bulkCheckInFlyout-doNotShow-checkbox' />
            {I18n.t('dont_show_this_message_again')}
          </div>
        </div>
      </Modal>
    );
  };

  const buildCancelModal = () => {
    const leaveButton = (
      <button
        className='button button--blue'
        data-testid='bulkCheckInFlyout-leavePageModal-modalLeaveButton'
        onClick={() => {
          closeFlyout();
          closeModal();
        }}
      >
        {I18n.t('leave')}
      </button>
    );

    return (
      <Modal
        data-testid='bulkCheckInFlyout-leavePageModal'
        show={currentlyOpenModal === modalNameCancel}
        title={I18n.t('leave_page')}
        customSaveButton={leaveButton}
        closeModal={closeModal}
        modalRedesign2022Q1
      >
        <div>
          {I18n.t('are_you_sure_you_want_to_leave_your_changes_wont_be_saved')}
        </div>
      </Modal>
    );
  };

  const handleUserCheckboxChange = (event) => {
    const userGuid = event.target.value;
    const newUserCheckboxes = { ...userCheckboxes };
    newUserCheckboxes[userGuid].checked = !newUserCheckboxes[userGuid].checked;

    toggleAllUsersCheckboxIsChecked();

    setUserCheckboxes(newUserCheckboxes);
    setCheckboxesChanged(true);
    getSelectedUserGuids();
  };

  const getSelectedUserGuids = () => {
    const guids = [];
    Object.keys(userCheckboxes).forEach((key) => {
      if (userCheckboxes[key].checked) {
        guids.push(key);
      }
    });

    let infos = {};
    Object.values(userCheckboxes).forEach((key) => {
      if (key.checked) {
        infos = { ...infos, [key.guid]: key };
      }
    });

    setSelectedUserInfos(infos);
    setSelectedUserGuids(guids);
  };

  const filterUserInfos = (onlineUserInfos) => {
    if (selectedSegments[0] === allUsersSegmentFilter) {
      return onlineUserInfos;
    }

    if (selectedSegments[0] === defaultGroupSegmentFilter) {
      const allSegmentsUserGuids = currentSegments.map((segment) => segment.member_guids).flat();
      return onlineUserInfos.filter((userInfo) => {
        return !allSegmentsUserGuids.includes(userInfo.guid);
      });
    }

    const segment = currentSegments.find((segment) => segment.guid === selectedSegments[0]);
    const segmentMemberGuids = segment.member_guids;
    return onlineUserInfos.filter((userInfo) => {
      return segmentMemberGuids.includes(userInfo.guid);
    });
  };

  const showUsersAvailableForCheckin = () => {
    const filteredUserInfos = filterUserInfos(onlineUserInfos);
    const sortedUserInfos = sortColumn(filteredUserInfos);

    if (!sortedUserInfos.length) {
      return (
        <tbody>
          <tr className='co-table-users-tr'>
            <td
              className='co-table-users-td--empty'
              colSpan={4}
              data-testid='usersTable-empty'
            >
              {I18n.t('all_students_in_this_group_are_already_checked_in_or_offline')}
            </td>
          </tr>
        </tbody>
      );
    }

    return (
      <tbody>
        {sortedUserInfos.map((user) => {
          return (
            <tr
              className='co-table-users-tr'
              key={`user-${user.guid}-table-row`}
            >
              <td className='co-table-users-td'>
                <input
                  checked={userCheckboxes[user.guid].checked}
                  className='user-checkbox co-listsTable-listName-checkbox co-listsTable-listName-checkbox--noLabel'
                  id={`checkbox-${user.guid}`}
                  onChange={handleUserCheckboxChange}
                  type='checkbox'
                  value={user.guid}
                />
                <label htmlFor={`checkbox-${user.guid}`} />
              </td>
              <td className='co-table-users-td'>
                {user.fullName}
              </td>
              <td className='co-table-users-td'>
                {user.checkedIntoName}
              </td>
              <td className='co-table-users-td'>
                {user.checkedIntoTeacherName}
              </td>
            </tr>
          );
        })}
      </tbody>
    );
  };

  const showNumberOfUsersSelected = () => {
    if (selectedUserGuids.length === 1) {
      return I18n.t('students_selected_singular');
    }
    return I18n.t('students_selected_plural', { number: selectedUserGuids.length });
  };

  const toggleAllUsersCheckboxIsChecked = () => {
    // with the 'all' filter selected:
    if (selectedSegments[0] === allUsersSegmentFilter) {
      return Object.keys(userCheckboxes).find((key) => !userCheckboxes[key].checked) === undefined;
    }

    // with the 'default group' filter selected:
    if (selectedSegments[0] === defaultGroupSegmentFilter) {
      const allSegmentsUserGuids = currentSegments.map((segment) => segment.member_guids).flat();
      const defaultGroupUserCheckboxes = {};

      // Build an object with the checkboxes in the 'default group'
      // so we can check only those checkboxes.
      Object.keys(userCheckboxes).forEach((key) => {
        if (!allSegmentsUserGuids.includes(key)) {
          defaultGroupUserCheckboxes[key] = userCheckboxes[key];
        }
      });

      return Object.keys(defaultGroupUserCheckboxes).find((key) => {
        return !defaultGroupUserCheckboxes[key].checked;
      }) === undefined;
    }

    // with a segment filter selected:
    const segmentUserGuids = currentSegments.find((segment) => selectedSegments[0] === segment.guid).member_guids;
    if (!segmentUserGuids.length) {
      return false;
    }
    const segmentUserCheckboxes = {};

    // Build an object with the checkboxes in the selected
    // segment so we can check only those checkboxes.
    segmentUserGuids.forEach((guid) => {
      // If the user in the segment isn't online, they won't be in the userCheckboxes object
      // so we'll set a { checked: false } object instead.
      segmentUserCheckboxes[guid] = userCheckboxes[guid] || { checked: false };
    });

    return Object.keys(segmentUserCheckboxes).find((key) => !segmentUserCheckboxes[key].checked) === undefined;
  };

  const handleToggleAllUserCheckboxes = (event) => {
    const newUserCheckboxes = { ...userCheckboxes };

    // with the 'all' filter selected:
    if (selectedSegments[0] === allUsersSegmentFilter) {
      Object.keys(newUserCheckboxes).forEach((key) => {
        newUserCheckboxes[key].checked = event.target.checked;
      });
    }

    // with the 'default group' filter selected:
    if (selectedSegments[0] === defaultGroupSegmentFilter) {
      const allSegmentsUserGuids = currentSegments.map((segment) => segment.member_guids).flat();
      Object.keys(newUserCheckboxes).forEach((key) => {
        if (!allSegmentsUserGuids.includes(key)) {
          newUserCheckboxes[key].checked = event.target.checked;
        }
      });
    }

    // with a segment filter selected:
    if (selectedSegments[0] !== allUsersSegmentFilter && selectedSegments[0] !== defaultGroupSegmentFilter) {
      const segmentUserGuids = currentSegments.find((segment) => selectedSegments[0] === segment.guid).member_guids;
      Object.keys(newUserCheckboxes).forEach((key) => {
        if (segmentUserGuids.includes(key)) {
          newUserCheckboxes[key].checked = event.target.checked;
        }
      });
    }

    setUserCheckboxes(newUserCheckboxes);
    setCheckboxesChanged(true);
    getSelectedUserGuids();
  };

  useEffect(() => {
    getSelectedUserGuids();
  }, [allUsers]);

  useEffect(() => {
    getCanClose(checkboxesChanged);
  }, [checkboxesChanged, getCanClose]);

  return (
    <section className='co-flyout co-bulk-check-in-flyout' data-testid='studentCheckInFlyout'>
      <div className='co-flyout-close'>
        <button
          onClick={handleCloseFlyout}
          className='button-link tooltipped tooltipped--left tooltipped--small tooltipped--opaque'
          aria-label={I18n.t('close')}
        >
          <span className='co-icon-x'>&times;</span>
        </button>
      </div>
      {buildHeader()}

      <div className='co-flyout-body'>
        <SegmentSelector
          currentSegments={currentSegments}
          selectedSegments={selectedSegments}
          setSelectedSegments={setSelectedSegments}
        />
        <table className='co-table-users m-top--8'>
          <thead className='co-table-users-thead'>
            <tr>
              <th className='co-table-users-th co-table-users-checkbox'>
                <span className='l-flex l-flex--vAlignCenter'>
                  <input
                    checked={toggleAllUsersCheckboxIsChecked()}
                    className='co-listsTable-listName-checkbox co-listsTable-listName-checkbox--noLabel'
                    id='toggle-all-user-checkboxes'
                    onChange={handleToggleAllUserCheckboxes}
                    type='checkbox'
                  />
                  <label htmlFor='toggle-all-user-checkboxes' />
                </span>
              </th>
              <th
                className='co-table-users-th co-table-users-th--hasPointer'
                onClick={() => updateSortBy('fullName')}
              >
                <span className='l-flex l-flex--vAlignCenter'>
                  {I18n.t('name')}
                  {sortBy === 'fullName' && <SortIcon />}
                </span>
              </th>
              <th
                className='co-table-users-th co-table-users-th--hasPointer'
                onClick={() => updateSortBy('checkedIntoName')}
              >
                <span className='l-flex l-flex--vAlignCenter'>
                  {I18n.t('current_class')}
                  {sortBy === 'checkedIntoName' && <SortIcon />}
                </span>
              </th>
              <th
                className='co-table-users-th co-table-users-th--hasPointer'
                onClick={() => updateSortBy('checkedIntoTeacherName')}
              >
                <span className='l-flex l-flex--vAlignCenter'>
                  {I18n.t('current_teacher')}
                  {sortBy === 'checkedIntoTeacherName' && <SortIcon />}
                </span>
              </th>
            </tr>
          </thead>
          {showUsersAvailableForCheckin()}
        </table>

        <p>
          {showNumberOfUsersSelected()}
        </p>
      </div>

      {buildFooter()}
      {buildCancelModal()}
      {buildBulkCheckInModal()}
    </section>
  );
};


BulkCheckInFlyout.propTypes = {
  ablyData: PropTypes.object.isRequired,
  allUsers: PropTypes.object.isRequired,
  checkOutNotification: PropTypes.func.isRequired,
  bulkCheckInStudents: PropTypes.func.isRequired,
  closeFlyout: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  currentGroup: PropTypes.object.isRequired,
  currentlyOpenModal: PropTypes.string,
  currentSegments: PropTypes.arrayOf(PropTypes.object).isRequired,
  currentUser: PropTypes.object.isRequired,
  disableCheckInNotification: PropTypes.func.isRequired,
  districtIps: PropTypes.arrayOf(PropTypes.string).isRequired,
  getCanClose: PropTypes.func,
  getClassroomUsers: PropTypes.func.isRequired,
  groupPolicy: PropTypes.object.isRequired,
  openModal: PropTypes.func.isRequired,
};

const mapState = (state) => ({
  ablyData: state.ably.data,
  allUsers: state.users.all,
  currentGroup: getCurrentGroup(state.groups),
  currentlyOpenModal: state.ui.currentlyOpenModal,
  currentUser: state.currentUser,
  districtIps: state.district.ips,
  groupPolicy: state.policy.group,
  currentSegments: state.groupSegments.segments,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  checkOutNotification,
  bulkCheckInStudents,
  getClassroomUsers,
  closeFlyout,
  closeModal,
  disableCheckInNotification,
  openModal,
}, dispatch);

export default connect(mapState, mapDispatchToProps)(BulkCheckInFlyout);
