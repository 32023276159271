import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cn from 'classnames';

import { getUserInfoCompare, getUserInfosWithStatuses } from '../../../utilities/users';
import { getCurrentGroup } from '../../../utilities/groups';
import I18n from '../../../utilities/i18n';

import UpdateStatus from '../../common/UpdateStatus';
import UserScreenCard from './UserScreenCard';

const UsersScreensContainer = ({
  canDropMember,
  currentGroup,
  dragType,
  segment,
  updating,
}) => {
  const [gridColumnCount, setGridColumnCount] = useState(null);
  const gridRef = useRef();
  const segmentInfosWithStatuses = getUserInfosWithStatuses(
    segment.infos,
    currentGroup,
  );
  const defaultSortOrder = (userInfos) => {
    return userInfos.sort(getUserInfoCompare('fullName'));
  };
  const updatingSegment = updating.segment === segment.guid;

  useEffect(() => {
    const getGridCount = () => {
      if (gridRef.current) {
        const gridComputedStyle = window
          .getComputedStyle(gridRef.current)
          .getPropertyValue('grid-template-columns')
          .split(' ').length;
        setGridColumnCount(gridComputedStyle);
      }
    };

    window.addEventListener('resize', getGridCount);
    return () => window.removeEventListener('resize', getGridCount);
  }, []);

  const buildUserScreens = () => {
    const containerClasses = cn(
      'co-userScreensView-container',
      {
        'co-userScreensView-container--threeColumn': gridColumnCount === 3,
      },
    );

    const emptyContainerClasses = cn(
      'co-userScreensView-emptySegment',
      {
        'co-userScreensView-container': canDropMember || updatingSegment,
        'co-userScreensView-emptySegment--droppable': canDropMember || updatingSegment,
      },
    );

    if (segmentInfosWithStatuses.length === 0) {
      return (
        <div
          className={emptyContainerClasses}
          data-testid='userScreensContainer-empty'
        >
          {canDropMember || updatingSegment ? (
            <UpdateStatus isActive={updatingSegment}>
              <div
                className='co-userScreensView-userScreen co-userScreensView-userScreen--droppable'
                data-testid='usersScreensView-userScreen'
              />
            </UpdateStatus>
          ) : (
            I18n.t('no_students')
          )}
        </div>
      );
    }

    const userScreens = defaultSortOrder(segmentInfosWithStatuses).map((userInfo) => {
      return (
        <UserScreenCard
          key={userInfo.guid}
          userInfo={userInfo}
          dragType={dragType}
          isMoving={userInfo.guid === updating.member}
          data-testid='usersScreensView-userScreen'
        />
      );
    });

    return (
      <div
        ref={gridRef}
        className={containerClasses}
        data-testid='userScreensContainer'
      >
        {userScreens}
        {buildDroppable()}
      </div>
    );
  };

  const buildDroppable = () => {
    if (!canDropMember && !updatingSegment) {
      return null;
    }

    return (
      <UpdateStatus isActive={updatingSegment}>
        <div
          className='co-userScreensView-userScreen co-userScreensView-userScreen--droppable'
          data-testid='usersScreensView-userScreen'
        />
      </UpdateStatus>
    );
  };

  return buildUserScreens();
};

UsersScreensContainer.propTypes = {
  canDropMember: PropTypes.bool.isRequired,
  currentGroup: PropTypes.object.isRequired,
  dragType: PropTypes.string.isRequired,
  segment: PropTypes.object.isRequired,
  updating: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  currentGroup: getCurrentGroup(state.groups),
});

export default connect(mapStateToProps)(UsersScreensContainer);
