import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';


const Icon = ({ name, classes, onClick }) => {
  const iconClasses = classNames(name, classes, 'icon');
  const accessbiliityAttrs = onClick && {
    role: 'button',
    tabIndex: '0',
  };

  return (
    <svg
      className={iconClasses}
      onClick={onClick}
      {...accessbiliityAttrs}
      data-testid={name}
    >
      <use xlinkHref={`#${name}`}></use>
    </svg>
  );
};

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  classes: PropTypes.string,
  onClick: PropTypes.func,
};

export default Icon;
