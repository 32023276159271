export const ADD_ERROR = 'ADD_ERROR';

export function addError(message, request, at = Date.now()) {
  const date = `${new Date(at)}`;

  return {
    type: ADD_ERROR,
    error: {
      message,
      data: request?.data,
      method: request?.config?.method,
      status: request?.status,
      url: request?.config?.url,
      at,
      date,
    },
  };
}
