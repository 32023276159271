import {
  RECEIVE_SELECTABLE_DAYS,
  RECEIVE_RECORDINGS,
  RECEIVE_TABS,
  CLEAR_RECORDINGS,
} from './actions';

export const defaultState = {
  selectableDays: [],
  loading: true,
  tabUrls: [],
  imageUrls: [],
  tabsByIndex: {},
};

export const recordingsReducer = (state = defaultState, action) => {
  switch (action.type) {
    case RECEIVE_SELECTABLE_DAYS:
      return {
        ...state,
        selectableDays: action.selectableDays,
      };
    case RECEIVE_RECORDINGS:
      return {
        ...state,
        loading: false,
        tabUrls: action.tabUrls,
        imageUrls: action.imageUrls,
        tabsByIndex: {},
      };
    case RECEIVE_TABS:
      return {
        ...state,
        tabsByIndex: {
          ...state.tabsByIndex,
          [action.index]: action.tabs,
        },
      };
    case CLEAR_RECORDINGS:
      return defaultState;
    default:
      return state;
  }
};
