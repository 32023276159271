/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const OfficeLogoIcon = ({ className, onClick, style }) => {
  let clickProps;
  if (onClick) {
    clickProps = { onClick, tabIndex: '0', role: 'button' };
  }
  return (
    <svg
      width='21'
      height='24'
      viewBox='0 0 21 24'
      className={classNames('icon', className)}
      {...clickProps}
      style={style}
      data-testid='CeleritasOfficeLogoIcon'
    >
      <path
        fill='#EB3C00'
        d='M0.539551 19.36V4.769L13.4466 0L20.4606 2.244V21.885L13.4466 24L0.539551 19.36L13.4466 20.913V3.928L5.02855 5.893V17.4L0.539551 19.36Z' />
    </svg>
  );
};


OfficeLogoIcon.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  style: PropTypes.object,
};

export default OfficeLogoIcon;
