import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';
import styles from './Tooltip.module.scss';

const Tooltip = ({ 
  children,
  content,
  customClassOne,
  customClassTwo,
  position, 
  size,
}) => {

  return (
    <div
      className={styles.tooltipWrapper}>
      <div className={styles.tooltipHoverContainer}>
        {children}
        <div 
          className={cn([styles.tooltip, `${styles[position]}`, `${styles[size]}`, `${styles[customClassOne]}`])}
          role='tooltip'
        >
          <span className={`${styles[customClassTwo]}`}>
            {content}
          </span>
        </div>
      </div>
    </div>
  );
};

Tooltip.propTypes = {
  children: PropTypes.any,
  content: PropTypes.string,
  customClassOne: PropTypes.string,
  customClassTwo: PropTypes.string,
  position: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
};

export default Tooltip;
