import {
  CLEAR_ALL_INSIGHTS,
  ADD_INSIGHT,
  REMOVE_INSIGHT,
  CHANGE_INSIGHT_POINT,
  SET_INSIGHT_SHARED_HOSTS,
} from './actions';

export const defaultState = {
  active: [],
  groups: {},
};

export const insightsReducer = (state = defaultState, action) => {
  switch (action.type) {
    case CHANGE_INSIGHT_POINT:
      return {
        ...state,
        [action.guid]: {
          points: action.points,
        },
      };
    case ADD_INSIGHT: {
      const filtered = state.active.filter((active) => active.guid !== action.insight.guid);
      return {
        ...state,
        active: [
          ...filtered,
          action.insight,
        ],
      };
    }
    case REMOVE_INSIGHT: {
      const filtered = state.active.filter((active) => active.guid !== action.guid);
      return {
        ...state,
        active: filtered,
      };
    }
    case CLEAR_ALL_INSIGHTS:
      return {
        ...state,
        active: [],
      };
    case SET_INSIGHT_SHARED_HOSTS:
      return {
        ...state,
        groups: {
          ...state.groups,
          [action.groupGuid]: {
            ...(state.groups && state.groups[action.groupGuid]),
            sharedHosts: action.hosts,
          },
        },
      };
    default: {
      return state;
    }
  }
};
