import {
  initAblyWithToken,
  shutdownAbly,
  sendLink,
  closeUrl,
} from '../ably/actions';
import { addError } from '../errors/actions';
import { addStreamToHandler, ADD_STREAM, removeWebrtc, REMOVE_STREAM } from '../webrtcs/actions';
import { findBroadcast } from '../../utilities/ably';
import { getCurrentGroup, getGroupName } from '../../utilities/groups';
import { getEndAt } from '../../utilities/scheduleTimes';
import { getFullName, getUserEmails, getUserByEmail } from '../../utilities/users';
import { ClassroomApi } from '../../helpers/classroomApi';

export const SET_BROADCAST_STEP = 'SET_BROADCAST_STEP';
export const SET_BROADCAST_ERROR = 'SET_BROADCAST_ERROR';
export const SET_BROADCAST_EXPIRED = 'SET_BROADCAST_EXPIRED';
export const RECEIVE_BROADCAST_DATA = 'RECEIVE_BROADCAST_DATA';

export function initTeacherStream(email) {
  return async function(dispatch, getState) {
    const state = getState();
    const currentGroup = getCurrentGroup(state.groups);
    const teacherBroadcastChannel = `broadcast:${email}`;
    const teacherChannel = state.ably.channels[teacherBroadcastChannel];
    try {
      const stream = await navigator.mediaDevices.getDisplayMedia();
      dispatch(addStreamToHandler(stream, teacherChannel));
      dispatch({ type: ADD_STREAM, name: email, stream });

      // handle chrome popup 'Stop Recording' event
      const track = stream.getTracks()[0];
      track.addEventListener('ended', () => {
        dispatch(stopBroadcast(currentGroup.guid, email, true));
      });
      return 'accepted';
    } catch (error) {
      if (error.message === 'Permission denied') {
        return 'denied';
      }
      window.Rollbar.error(`teacher broadcast: ${error.message}`, email);
      if (error.message === 'Permission denied by system') {
        // blocked by OS security
        return 'blocked';
      }

      console.error({ error });
      return 'error';
    }
  };
}

export function startBroadcast(email, broadcastToEmails, isTeacher) {
  return async function(dispatch, getState) {
    const state = getState();
    const currentGroup = getCurrentGroup(state.groups);
    const groupGuid = currentGroup.guid;
    const expiresAt = getEndAt(currentGroup.class_schedule);
    const channelName = isTeacher ? `broadcast:${email}` : email;
    const teacherStream = email in state.webrtcs.streams ? state.webrtcs.streams[email] : null;
    const streamId = teacherStream ? teacherStream.id : null;

    const jwtParams = {
      channel_name: channelName,
      expires_at: expiresAt,
    };
    const response = await ClassroomApi.post(`/groups/${groupGuid}/broadcasts`, jwtParams);

    if (response?.status !== 200) {
      return dispatch(addError('startBroadcast', response));
    }
    const jwt = response.data;
    const user = getUserByEmail(state.users.all, email);
    const userName = getFullName(user);
    const groupName = getGroupName(currentGroup);

    const groupChannel = state.ably.channels[currentGroup.guid];
    if (!groupChannel) {
      return {};
    }

    const data = {
      broadcastEmail: email,
      broadcastUserName: userName,
      broadcastExpiresAt: expiresAt,
      broadcastGroupName: groupName,
      broadcastJwt: jwt,
      broadcastStreamId: streamId, 
    };
    await groupChannel.presence.update(data);

    const url = getWatchBroadcastUrl(groupGuid, jwt);

    const userEmails = broadcastToEmails || getUserEmails(state.users.all);
    const filteredEmails = userEmails.filter((userEmail) => userEmail !== email);
    for (const userEmail of filteredEmails) {
      dispatch(sendLink(url, userEmail));
    }
  };
}

export function stopBroadcast(groupGuid, teacherEmail, alreadyStoppedTrack) {
  return async function(dispatch, getState) {
    const state = getState();
    const broadcast = findBroadcast(state.ably.data, groupGuid);
    const teacherStream = teacherEmail in state.webrtcs.streams ? state.webrtcs.streams[teacherEmail] : null;
    if (teacherStream) {
      try {
        if (!alreadyStoppedTrack) {
          teacherStream.getVideoTracks()[0].stop();
        }
      } catch (err) {
        console.error(err);
      }
    }
    dispatch({ type: REMOVE_STREAM, name: teacherEmail });

    if (broadcast) {
      const groupChannel = state.ably.channels[groupGuid];
      if (groupChannel) {
        await groupChannel.presence.update({});
      }

      const url = getWatchBroadcastUrl(groupGuid, broadcast.jwt);
      dispatch(closeUrl(url));
    }
  };
}

export function openBroadcast(clientId, groupGuid, jwt) {
  return async function(dispatch) {
    if (!clientId || !groupGuid || !jwt) {
      return dispatch(setError());
    }

    const url = `/groups/${groupGuid}/broadcasts?client_id=${clientId}&jwt=${jwt}`;
    const response = await ClassroomApi.get(url);

    if (response?.status === 404 && response?.data === 'expired') {
      return dispatch(setExpired());
    }

    if (response?.status !== 200) {
      dispatch(addError('openBroadcast', response));
      return dispatch(setError());
    }

    dispatch({
      type: RECEIVE_BROADCAST_DATA,
      channelName: response?.data?.channel_name,
      groupGuid: response?.data?.group_guid,
      iceServers: response?.data?.ice_servers,
    });

    const customerId = response?.data?.customer_id;
    const ablyJwt = response?.data?.ably_jwt;
    return dispatch(initAblyWithToken(customerId, clientId, ablyJwt));
  };
}

export function closeBroadcast(sessionId, albyTimeoutMs = 2000) {
  return async function(dispatch) {
    dispatch(removeWebrtc(sessionId));

    //TODO check channel status instead of a timeout
    await new Promise((resolve) => setTimeout(resolve, albyTimeoutMs));
    return dispatch(shutdownAbly());
  };
}

export function setStep(step) {
  return { type: SET_BROADCAST_STEP, step };
}

export function setError() {
  return { type: SET_BROADCAST_ERROR };
}

export function setExpired() {
  return { type: SET_BROADCAST_EXPIRED };
}

function getWatchBroadcastUrl(guid, jwt) {
  return `${process.env.REACT_APP_CLASSROOM_URL}watch-broadcast/${guid}?jwt=${jwt}`;
}

