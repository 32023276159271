import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Icon from './Icon';


const UpdateStatus = ({ children, isActive }) => {
  const containerClasses = classNames({
    'co-updateStatus--active': isActive,
  });

  return (
    <div className={containerClasses} data-testid='updateStatus'>
      { isActive &&
        <div className='co-updateStatus-icon' data-testid='updateStatus-icon'>
          <Icon name='icon-loading' classes='icon-loading--animating' />
        </div>
      }
      {children}
    </div>
  );
};

UpdateStatus.propTypes = {
  children: PropTypes.object,
  isActive: PropTypes.bool.isRequired,
};

export default UpdateStatus;
