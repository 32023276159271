import React from 'react';
import PropTypes from 'prop-types';
import { CSSTransition, TransitionGroup } from 'react-transition-group';


const Fade = ({ children, condition, ...props }) => {
  return (
    <TransitionGroup>
      {condition && (
        <CSSTransition
          {...props}
          timeout={200}
          classNames='fade'
        >
          {children}
        </CSSTransition>
      )}
    </TransitionGroup>
  );
};

Fade.propTypes = {
  children: PropTypes.object,
  condition: PropTypes.bool.isRequired,
};

Fade.propTypes = {
  children: PropTypes.object,
};

export default Fade;
