/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const LightspeedClassroomText = ({ className, onClick, style }) => {
  let clickProps;
  if (onClick) {
    clickProps = { onClick, tabIndex: '0', role: 'button' };
  }
  return (
    <svg
      className={classNames('icon', className)}
      {...clickProps}
      style={style}
      data-testid='CeleritasLightspeedClassroomText'
      width='270'
      height='56'
      viewBox='0 0 204 42'
      fill='none'
    >
      <path d='M54.7437 31.592L58.5677 22.999H60.9877V34.7703H59.177V25.6559L55.6405 33.7276H53.8467L50.3105 25.6559V34.7703H48.5V22.999H50.9198L54.7437 31.592Z' fill='#0B1F40'/>
      <path fillRule='evenodd' clipRule='evenodd' d='M65.6065 29.1708C66.6217 29.1708 67.3833 29.5074 67.7216 29.8099V28.9862C67.7216 27.977 67.011 27.3718 65.8431 27.3718C64.8622 27.3718 64.0159 27.7079 63.373 28.061L62.6624 26.783C63.373 26.3291 64.5573 25.7739 66.0294 25.7739C68.6522 25.7739 69.5491 27.1865 69.5491 29.1036V34.7707H67.9416L67.7893 34.115C67.3323 34.6193 66.6217 34.9893 65.5222 34.9893C63.6606 34.9893 62.1885 33.829 62.1885 32.0633C62.1885 30.3648 63.576 29.1708 65.6065 29.1708V29.1708ZM65.9111 33.5262C66.994 33.5262 67.7722 32.9209 67.7722 32.0128C67.7722 31.0881 67.011 30.4991 65.8941 30.4991C64.7776 30.4991 64.033 31.1386 64.033 32.0128C64.033 32.8873 64.7943 33.5262 65.9111 33.5262H65.9111Z' fill='#0B1F40'/>
      <path d='M78.3636 29.4905V34.7707H76.5363V29.3389C76.5363 28.1282 75.7748 27.422 74.7596 27.422C73.5921 27.422 72.8305 28.2796 72.8305 29.4736V34.7707H70.9863V25.9925H72.7968V27.1363C73.1862 26.4467 74.0993 25.7739 75.2842 25.7739C77.5684 25.7739 78.3637 27.5735 78.3637 29.4905L78.3636 29.4905Z' fill='#0B1F40'/>
      <path fillRule='evenodd' clipRule='evenodd' d='M82.7286 29.1708C83.7438 29.1708 84.5054 29.5074 84.8437 29.8099V28.9862C84.8437 27.977 84.1327 27.3718 82.9655 27.3718C81.9839 27.3718 81.1377 27.7079 80.495 28.061L79.7845 26.783C80.495 26.3291 81.6796 25.7739 83.1511 25.7739C85.7742 25.7739 86.6712 27.1865 86.6712 29.1036V34.7707H85.0637L84.9113 34.115C84.4543 34.6193 83.7438 34.9893 82.6436 34.9893C80.7828 34.9893 79.3105 33.829 79.3105 32.0633C79.3105 30.3648 80.6981 29.1708 82.7286 29.1708L82.7286 29.1708ZM83.0332 33.5262C84.1158 33.5262 84.894 32.9209 84.894 32.0128C84.894 31.0881 84.1327 30.4991 83.0162 30.4991C81.8996 30.4991 81.155 31.1386 81.155 32.0128C81.155 32.8873 81.9166 33.5262 83.0332 33.5262H83.0332Z' fill='#0B1F40'/>
      <path fillRule='evenodd' clipRule='evenodd' d='M89.2605 35.7462C89.5818 35.9311 90.6143 36.4185 91.8493 36.4185C93.3215 36.4185 94.6241 35.8806 94.6241 34.2157V33.6271C94.4214 33.9131 93.5414 34.9893 91.7986 34.9893C89.2606 34.9893 87.5684 33.089 87.5684 30.3984C87.5684 27.6912 89.3282 25.7739 91.8323 25.7739C93.4908 25.7739 94.4384 26.7662 94.709 27.1696V25.9925H96.4518V33.6104C96.4518 36.9063 94.4384 37.9994 91.8666 37.9994C90.6821 37.9994 89.4465 37.6125 88.6685 37.1588L89.2605 35.7462ZM92.0352 27.4051C90.5127 27.4051 89.4128 28.616 89.4128 30.3817C89.4128 32.1812 90.5464 33.3581 92.0523 33.3581C93.6431 33.3581 94.6754 32.1136 94.6754 30.3817C94.6754 28.5822 93.5584 27.4051 92.0352 27.4051Z' fill='#0B1F40'/>
      <path fillRule='evenodd' clipRule='evenodd' d='M101.713 25.7739C104.386 25.7739 105.96 27.607 105.96 30.3648C105.96 30.5162 105.96 30.6676 105.943 30.8188H99.2935C99.3273 32.3828 100.461 33.3919 102.035 33.3919C103.371 33.3919 104.132 32.6518 104.471 32.1642L105.689 33.0724C105.266 33.7955 104.099 34.9893 102.001 34.9893C99.2429 34.9893 97.415 33.0724 97.415 30.4153C97.415 27.6912 99.2935 25.7739 101.713 25.7739L101.713 25.7739ZM104.082 29.5576C104.014 28.1117 102.999 27.3043 101.73 27.3043C100.461 27.3043 99.4795 28.1788 99.3612 29.5576H104.082Z' fill='#0B1F40'/>
      <path d='M119.412 34.7707H117.584V29.5074C117.584 28.1451 116.992 27.422 115.893 27.422C114.86 27.422 114.098 28.1787 114.098 29.5243V34.7707H112.254V29.3389C112.254 28.1451 111.611 27.422 110.562 27.422C109.564 27.422 108.785 28.2293 108.785 29.4905V34.7707H106.941V25.9925H108.752V27.1363C109.073 26.4636 109.936 25.7739 111.137 25.7739C112.372 25.7739 113.253 26.3458 113.675 27.2372C114.2 26.312 115.181 25.7739 116.4 25.7739C118.396 25.7739 119.412 27.1363 119.412 29.0364L119.412 34.7707Z' fill='#0B1F40'/>
      <path fillRule='evenodd' clipRule='evenodd' d='M124.672 25.7739C127.346 25.7739 128.919 27.607 128.919 30.3648C128.919 30.5162 128.919 30.6676 128.903 30.8188H122.253C122.287 32.3828 123.42 33.3919 124.994 33.3919C126.33 33.3919 127.092 32.6518 127.43 32.1642L128.649 33.0724C128.226 33.7955 127.058 34.9893 124.96 34.9893C122.202 34.9893 120.375 33.0724 120.375 30.4153C120.375 27.6912 122.253 25.7739 124.672 25.7739L124.672 25.7739ZM127.042 29.5576C126.974 28.1117 125.958 27.3043 124.689 27.3043C123.42 27.3043 122.439 28.1788 122.321 29.5576H127.042Z' fill='#0B1F40'/>
      <path d='M137.278 29.4905V34.7707H135.452V29.3389C135.452 28.1282 134.69 27.422 133.675 27.422C132.507 27.422 131.746 28.2796 131.746 29.4736V34.7707H129.901V25.9925H131.712V27.1363C132.101 26.4467 133.015 25.7739 134.199 25.7739C136.484 25.7739 137.278 27.5735 137.278 29.4905V29.4905Z' fill='#0B1F40'/>
      <path d='M138.92 24.1589H140.748V25.9919H142.541V27.5054H140.748V31.5918C140.748 32.9539 141.272 33.2566 142.253 33.2566C142.406 33.2566 142.609 33.2397 142.609 33.2397V34.8204C142.609 34.8204 142.287 34.8878 141.712 34.8878C139.562 34.8878 138.92 33.4584 138.92 31.306V27.5054H137.938V25.9919H138.92V24.1589Z' fill='#0B1F40'/>
      <path fillRule='evenodd' clipRule='evenodd' d='M143.623 24.0068H142.787V23.6479H144.846V24.0068H144.001V26.7892H143.623V24.0068ZM146.66 26.4303L145.568 24.3044V26.7892H145.208V23.6479H145.639L146.854 26.0018L148.069 23.6479H148.5V26.7892H148.14V24.3044L147.048 26.4303H146.66Z' fill='#0B1F40'/>
      <path d='M140.118 15.9207C139.525 16.3655 138.22 17.1232 136.389 17.1232C132.797 17.1232 130.288 14.537 130.288 11.144C130.288 7.75111 132.898 5.16504 136.373 5.16504C138.05 5.16504 139.27 5.77437 139.966 6.28517L139.406 7.89923C138.898 7.52047 137.813 6.8781 136.423 6.8781C133.949 6.8781 132.237 8.75576 132.237 11.144C132.237 13.5325 133.898 15.4101 136.44 15.4101C137.983 15.4101 139.084 14.669 139.559 14.323L140.118 15.9207Z' fill='#0B1F40'/>
      <path d='M141.186 5H143.033V16.9087H141.186V5Z' fill='#0B1F40'/>
      <path fillRule='evenodd' clipRule='evenodd' d='M147.472 11.4233C148.489 11.4233 149.252 11.7528 149.592 12.0493V11.2422C149.592 10.2537 148.88 9.66101 147.71 9.66101C146.727 9.66101 145.879 9.99014 145.236 10.3361L144.523 9.08439C145.236 8.63964 146.422 8.09595 147.896 8.09595C150.523 8.09595 151.422 9.47958 151.422 11.3572V16.9083H149.812L149.659 16.2659C149.202 16.7599 148.489 17.1224 147.388 17.1224C145.524 17.1224 144.05 15.9858 144.05 14.2563C144.05 12.5928 145.439 11.4233 147.472 11.4233H147.472ZM147.778 15.6893C148.863 15.6893 149.642 15.0964 149.642 14.2069C149.642 13.3009 148.88 12.7243 147.761 12.7243C146.642 12.7243 145.897 13.3505 145.897 14.2069C145.897 15.0633 146.659 15.6893 147.778 15.6893H147.778Z' fill='#0B1F40'/>
      <path d='M153.386 14.5198C153.793 15.0964 154.403 15.6399 155.505 15.6399C156.284 15.6399 156.86 15.2609 156.86 14.5693C156.86 12.9223 152.556 13.5152 152.556 10.5338C152.556 9.01864 153.877 8.09595 155.59 8.09595C157.251 8.09595 158.131 9.03479 158.47 9.69371L157.199 10.5173C156.946 10.122 156.471 9.56191 155.488 9.56191C154.81 9.56191 154.302 9.90786 154.302 10.501C154.302 12.148 158.623 11.4561 158.623 14.487C158.623 16.0847 157.233 17.1224 155.42 17.1224C153.623 17.1224 152.674 16.1836 152.234 15.4092L153.386 14.5198Z' fill='#0B1F40'/>
      <path d='M160.098 14.5198C160.504 15.0964 161.115 15.6399 162.216 15.6399C162.996 15.6399 163.572 15.2609 163.572 14.5693C163.572 12.9223 159.268 13.5152 159.268 10.5338C159.268 9.01864 160.589 8.09595 162.301 8.09595C163.962 8.09595 164.843 9.03479 165.182 9.69371L163.911 10.5173C163.657 10.122 163.182 9.56191 162.2 9.56191C161.522 9.56191 161.013 9.90786 161.013 10.501C161.013 12.148 165.335 11.4561 165.335 14.487C165.335 16.0847 163.945 17.1224 162.132 17.1224C160.335 17.1224 159.386 16.1836 158.945 15.4092L160.098 14.5198Z' fill='#0B1F40'/>
      <path d='M170.623 9.90786C168.691 9.90786 168.132 11.341 168.132 13.2022V16.9083H166.284V8.31008H168.098V9.66101C168.437 8.80434 169.369 8.09595 170.623 8.09595V9.90786Z' fill='#0B1F40'/>
      <path fillRule='evenodd' clipRule='evenodd' d='M175.453 8.09595C178.182 8.09595 180.148 10.0232 180.148 12.6093C180.148 15.1951 178.147 17.1224 175.453 17.1224C172.741 17.1224 170.775 15.2282 170.775 12.6256C170.775 10.0232 172.792 8.09596 175.453 8.09596V8.09595ZM175.453 15.5247C177.097 15.5247 178.3 14.2894 178.3 12.6093C178.3 10.929 177.097 9.69371 175.453 9.69371C173.792 9.69371 172.64 10.9455 172.64 12.6256C172.64 14.3221 173.809 15.5247 175.453 15.5247Z' fill='#0B1F40'/>
      <path fillRule='evenodd' clipRule='evenodd' d='M185.299 8.09595C188.028 8.09595 189.993 10.0232 189.993 12.6093C189.993 15.1951 187.994 17.1224 185.299 17.1224C182.587 17.1224 180.621 15.2282 180.621 12.6256C180.621 10.0232 182.638 8.09596 185.299 8.09596V8.09595ZM185.299 15.5247C186.942 15.5247 188.146 14.2894 188.146 12.6093C188.146 10.929 186.942 9.69371 185.299 9.69371C183.638 9.69371 182.485 10.9455 182.485 12.6256C182.485 14.3221 183.654 15.5247 185.299 15.5247Z' fill='#0B1F40'/>
      <path d='M203.5 16.9083H201.67V11.7528C201.67 10.4185 201.076 9.71027 199.975 9.71027C198.942 9.71027 198.179 10.4515 198.179 11.7694V16.9083H196.332V11.5879C196.332 10.4185 195.687 9.71027 194.636 9.71027C193.636 9.71027 192.857 10.501 192.857 11.7363V16.9083H191.01V8.31009H192.823V9.43034C193.145 8.77141 194.009 8.09595 195.212 8.09595C196.45 8.09595 197.331 8.65618 197.755 9.52909C198.28 8.62306 199.264 8.09595 200.483 8.09595C202.483 8.09595 203.5 9.43034 203.5 11.2914L203.5 16.9083Z' fill='#0B1F40'/>
      <path d='M48.5 5.58618H50.3457V15.2818H54.4193V16.8977H48.5V5.58618Z' fill='#0B1F40'/>
      <path fillRule='evenodd' clipRule='evenodd' d='M56.2465 5.27905C56.8785 5.27905 57.3774 5.76391 57.3774 6.37798C57.3774 6.99195 56.8785 7.4768 56.2465 7.4768C55.6147 7.4768 55.1162 6.99194 55.1162 6.37798C55.1162 5.76391 55.6147 5.27905 56.2465 5.27905ZM55.3491 8.46253H57.1612V16.8977H55.3491V8.46253Z' fill='#0B1F40'/>
      <path fillRule='evenodd' clipRule='evenodd' d='M59.9378 17.8347C60.2539 18.0125 61.2682 18.4811 62.482 18.4811C63.9283 18.4811 65.2086 17.964 65.2086 16.3642V15.7986C65.0095 16.0734 64.1447 17.1075 62.4319 17.1075C59.9378 17.1075 58.2754 15.2815 58.2754 12.6961C58.2754 10.0943 60.0047 8.2522 62.4653 8.2522C64.0947 8.2522 65.0261 9.20559 65.2917 9.59345V8.46231H67.0043V15.7824C67.0043 18.9498 65.0261 20.0001 62.4984 20.0001C61.3347 20.0001 60.121 19.6284 59.3562 19.1921L59.9378 17.8347ZM62.6648 9.81971C61.1683 9.81971 60.0879 10.983 60.0879 12.6799C60.0879 14.409 61.2014 15.5401 62.6815 15.5401C64.2444 15.5401 65.2587 14.3443 65.2587 12.6799C65.2587 10.9509 64.1615 9.81971 62.6648 9.81971H62.6648Z' fill='#0B1F40'/>
      <path d='M75.6496 11.8233V16.8973H73.8538V11.6779C73.8538 10.5144 73.1055 9.83575 72.1078 9.83575C70.961 9.83575 70.1959 10.6598 70.1959 11.8071V16.8973H68.3838V5.21411H70.1959V9.56097C70.5784 8.89838 71.4594 8.2521 72.6234 8.2521C74.8682 8.2521 75.6496 9.98115 75.6496 11.8233L75.6496 11.8233Z' fill='#0B1F40'/>
      <path d='M77.3929 6.70117H79.189V8.46258H80.9514V9.91691H79.189V13.8437C79.189 15.1526 79.7039 15.4434 80.6685 15.4434C80.8181 15.4434 81.0179 15.4273 81.0179 15.4273V16.9462C81.0179 16.9462 80.7018 17.0109 80.1365 17.0109C78.0251 17.0109 77.3929 15.6374 77.3929 13.5689V9.91692H76.4287V8.46259H77.3929V6.70117Z' fill='#0B1F40'/>
      <path d='M82.7771 14.5544C83.176 15.12 83.7747 15.6532 84.8555 15.6532C85.6203 15.6532 86.1852 15.2815 86.1852 14.6028C86.1852 12.9869 81.9622 13.5687 81.9622 10.6438C81.9622 9.15714 83.2592 8.2522 84.9384 8.2522C86.5681 8.2522 87.4327 9.17329 87.7648 9.81972L86.5179 10.6276C86.2687 10.2397 85.8033 9.69033 84.8389 9.69033C84.1736 9.69033 83.6748 10.0296 83.6748 10.6114C83.6748 12.2274 87.9148 11.5486 87.9148 14.5221C87.9148 16.0896 86.5514 17.1075 84.772 17.1075C83.0096 17.1075 82.0786 16.1865 81.6465 15.427L82.7771 14.5544Z' fill='#0B1F40'/>
      <path fillRule='evenodd' clipRule='evenodd' d='M88.7803 8.46231H90.4925V9.5773C90.7257 9.27029 91.6068 8.2522 93.2862 8.2522C95.7635 8.2522 97.5259 10.0943 97.5259 12.6638C97.5259 15.2815 95.8801 17.1075 93.4025 17.1075C91.7568 17.1075 90.8253 16.1379 90.5928 15.7824V19.9194H88.7803L88.7803 8.46231ZM93.1199 15.5401C94.6326 15.5401 95.6974 14.3605 95.6974 12.6638C95.6974 10.9347 94.5831 9.81972 93.1031 9.81972C91.5071 9.81972 90.5259 11.1124 90.5259 12.6638C90.5259 14.4897 91.6735 15.5401 93.1199 15.5401H93.1199Z' fill='#0B1F40'/>
      <path fillRule='evenodd' clipRule='evenodd' d='M102.514 8.2522C105.141 8.2522 106.687 10.0136 106.687 12.6638C106.687 12.8092 106.687 12.9545 106.671 13.1H100.136C100.17 14.6028 101.284 15.5723 102.83 15.5723C104.144 15.5723 104.892 14.8614 105.224 14.3928L106.422 15.2654C106.006 15.9602 104.859 17.1075 102.797 17.1075C100.087 17.1075 98.291 15.2654 98.291 12.7121C98.291 10.0943 100.136 8.2522 102.514 8.2522ZM104.842 11.8881C104.775 10.4983 103.777 9.72269 102.531 9.72269C101.284 9.72269 100.319 10.563 100.203 11.8881H104.842Z' fill='#0B1F40'/>
      <path fillRule='evenodd' clipRule='evenodd' d='M111.646 8.2522C114.273 8.2522 115.819 10.0136 115.819 12.6638C115.819 12.8092 115.819 12.9545 115.803 13.1H109.268C109.302 14.6028 110.416 15.5723 111.963 15.5723C113.275 15.5723 114.024 14.8614 114.356 14.3928L115.553 15.2654C115.138 15.9602 113.99 17.1075 111.929 17.1075C109.219 17.1075 107.423 15.2654 107.423 12.7121C107.423 10.0943 109.268 8.2522 111.646 8.2522H111.646ZM113.974 11.8881C113.908 10.4983 112.909 9.72269 111.662 9.72269C110.416 9.72269 109.451 10.563 109.335 11.8881H113.974Z' fill='#0B1F40'/>
      <path fillRule='evenodd' clipRule='evenodd' d='M125.292 16.8973H123.579V15.7985C123.346 16.0894 122.465 17.1074 120.786 17.1074C118.308 17.1074 116.546 15.2814 116.546 12.696C116.546 10.0942 118.192 8.25211 120.669 8.25211C122.316 8.25211 123.247 9.22168 123.48 9.59332V5.21411H125.292L125.292 16.8973ZM120.952 9.81958C119.439 9.81958 118.375 11.0153 118.375 12.712C118.375 14.4411 119.489 15.5562 120.969 15.5562C122.565 15.5562 123.546 14.2633 123.546 12.712C123.546 10.8699 122.399 9.81958 120.952 9.81958Z' fill='#0B1F40'/>
      <path d='M21.5 0C33.0982 0 42.5 9.40216 42.5 21.0006C42.5 32.5978 33.0982 42 21.5 42C9.90178 42 0.5 32.5978 0.5 21.0006C0.5 9.40216 9.90178 0 21.5 0' fill='#F89E3E'/>
      <path d='M18.0448 29.275L12.6019 22.7877C12.6009 22.7865 12.5993 22.7857 12.5977 22.7857H8.45944C7.80479 22.7857 7.23126 22.2814 7.21427 21.6268C7.19693 20.955 7.73638 20.4048 8.40425 20.4048H13.7079C13.7096 20.4048 13.7112 20.4055 13.7123 20.4068L17.853 25.3424C17.8557 25.3456 17.8607 25.345 17.8624 25.3412L23.7981 12.5809C23.9304 12.2965 24.3271 12.2761 24.4879 12.5455L29.1778 20.402C29.1788 20.4038 29.1806 20.4048 29.1826 20.4048H34.5395C35.1943 20.4048 35.7679 20.9092 35.7848 21.5637C35.8022 22.2356 35.2627 22.7857 34.5947 22.7857H27.8311C27.8291 22.7857 27.8274 22.7847 27.8264 22.7829L24.3762 17.0035C24.374 16.9995 24.3683 16.9998 24.3663 17.004L18.6983 29.1888C18.5777 29.4481 18.2285 29.4941 18.0448 29.275V29.275Z' fill='white'/>
    </svg>
  );
};

LightspeedClassroomText.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  style: PropTypes.object,
};

export default LightspeedClassroomText;
