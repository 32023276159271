import { isOnCampusOnly } from '../utilities/policy';
import { floatTimeToString, timeUntilString } from '../utilities/time';
import { getUserInfos, getUserInfoCompare } from '../utilities/users';

export default class ClassroomDebug {
  constructor(store) {
    this.store = store;
  }

  getGroupName(guid, groups) {
    const group = groups.find((group) => group.guid === guid);
    return group ? group.name : '';
  }

  getArrayString(values) {
    return values.reduce((out, value, index) => {
      out += `${index > 0 ? ', ' : ''}`;
      out += value;
      return out;
    }, '');
  }

  getScheduleString(schedule) {
    if (!schedule) {
      return JSON.stringify(schedule);
    }
    return Object.keys(schedule).map((key) => {
      return `${this.getDaysString(key)} ${this.getTimesString(schedule[key])}`;
    }).join(', ');
  }

  getScheduleTypeString(type) {
    if (!type) {
      return JSON.stringify(type);
    }
    switch (type) {
      case 'ad_hoc':
        return 'Ad Hoc';
      case 'bell':
        return 'Bell';
      case 'custom':
        return 'Custom';
      default:
        return 'Unknown';
    }
  }

  getDaysString(key) {
    const days = ['N', 'M', 'T', 'W', 'H', 'F', 'S', '?'];
    const values = `${key}`.split('');
    return values.map((value) => days[parseInt(value, 10)]).join('');
  }

  getTimesString(times) {
    return `(${floatTimeToString(times.s)} - ${floatTimeToString(times.e)})`;
  }

  hasInsight(userInfo, insights) {
    const index = insights.active.findIndex((insight) => insight.email === userInfo.email);
    return (index !== -1);
  }

  getInsightPoints(userInfo, insights) {
    const found = insights[userInfo.email];
    return found ? `${found.points}` : '';
  }

  getPolicyValueString(key, value) {
    switch (key) {
      case 'bell_schedules':
        return this.getBellsString(value);
      case 'last_bell':
      case 'last_custom':
        return this.getScheduleString(value);
      default:
        return this.getValueString(value);
    }
  }

  getBellsString(bellSet) {
    let out = '\n';
    for (const bell of bellSet) {
      const name = this.getValueString(bell.name);
      const schedule = bell.schedule;
      const daysKey = Object.keys(schedule)[0];
      const periods = schedule[daysKey];
      const periodNames = periods.map((period) => (period.n ? period.n : 'Dismissal'));
      const periodNamesMax = this.getMaxLength(periodNames);
      const periodTimes = periods.map((period) => (period.s ? period.s : period.e));

      out += `  ${name} ${this.getDaysString(daysKey)}\n`;
      for (let i = 0; i < periods.length; i++) {
        const name = periodNames[i].padEnd(periodNamesMax, ' ');
        out += `    ${name} ${floatTimeToString(periodTimes[i])}\n`;
      }
    }
    return out.slice(0, -1);
  }

  getValueString(value) {
    const type = typeof value;
    switch (type) {
      case 'number':
      case 'string':
        return value;
      default:
        return JSON.stringify(value).replace(/"/g, "'");
    }
  }

  getMaxLength(strings) {
    return Math.max(...strings.map((name) => name?.length));
  }

  classes() {
    const state = this.store.getState();
    const classes = state.classes;
    const groups = state.groups.groups;
    const connectedNames = classes.connected.map((guid) => this.getGroupName(guid, groups));
    const inSessionNames = classes.inSession.map((guid) => this.getGroupName(guid, groups));
    const eventNames = classes.events.map((event) => this.getGroupName(event.guid, groups));
    const eventTypes = classes.events.map((event) => `'${event.type}'`);
    const now = new Date();
    const eventUntils = classes.events.map((event) => timeUntilString(event.date, now));
    const namesMax = this.getMaxLength(eventNames);
    const typesMax = this.getMaxLength(eventTypes);
    const untilsMax = this.getMaxLength(eventUntils);

    let out = `${now.toLocaleTimeString()}\n`;
    out += `Connected: ${this.getArrayString(connectedNames)}\n`;
    out += `InSession: ${this.getArrayString(inSessionNames)}\nEvents:\n`;
    for (let i = 0; i < classes.events.length; i++) {
      const name = eventNames[i]?.padEnd(namesMax, ' ');
      const type = eventTypes[i]?.padEnd(typesMax, ' ');
      const until = eventUntils[i]?.padEnd(untilsMax, ' ');
      out += ` ${name} ${type} in ${until}\n`;
    }

    console.log(out); // eslint-disable-line no-console
  }

  schedules() {
    const state = this.store.getState();
    const groups = state.groups.groups;
    const names = groups.map((group) => group.name);
    const types = groups.map((group) => this.getScheduleTypeString(group.schedule_type));
    const schedules = groups.map((group) => this.getScheduleString(group.class_schedule));
    const namesMax = this.getMaxLength(names);
    const typesMax = this.getMaxLength(types);

    let out = '';
    for (let i = 0; i < groups.length; i++) {
      const name = names[i]?.padEnd(namesMax, ' ');
      const type = types[i]?.padEnd(typesMax, ' ');
      out += `${name} ${type} ${schedules[i]}\n`;
    }

    console.log(out); // eslint-disable-line no-console
  }

  users(sortKey = 'firstName', isAscending = true) {
    const state = this.store.getState();
    const users = state.users.all;
    const ablyData = state.ably.data;
    const groupPolicy = state.policy.group;
    const districtIps = state.district.ips;
    const emails = Object.keys(users);
    const ips = isOnCampusOnly(groupPolicy) ? districtIps : null;
    const infos = getUserInfos(emails, users, ablyData, ips);
    const insights = state.insights;

    infos.sort(getUserInfoCompare(sortKey, isAscending));
    const none = ' -- ';
    const names = infos.map((info) => `${info.firstName} ${info.lastName}`);
    const onlines = infos.map((info) => `${info.online ? 'online' : none}`);
    const offCampuses = infos.map((info) => `${info.offCampus ? 'offcampus' : none}`);
    const states = infos.map((info) => `${info.state ? info.state : none}`);
    const hosts = infos.map((info) => `${info.currentHost ? info.currentHost : none}`);
    const tops = infos.map((info) => `${info.top ? info.top : none}`);
    const visits = infos.map((info) => `${info.visits ? info.visits : none}`);
    const addresses = infos.map((info) => `${info.ipAddress ? info.ipAddress : none}`);
    const hasInsights = infos.map((info) => `${this.hasInsight(info, insights) ? 'insight' : none}`);
    const counts = infos.map((info) => `${this.getInsightPoints(info, insights) || none}`);

    const namesMax = this.getMaxLength(names);
    const onlinesMax = this.getMaxLength(onlines);
    const offCampusesMax = this.getMaxLength(offCampuses);
    const statesMax = this.getMaxLength(states);
    const hostsMax = this.getMaxLength(hosts);
    const topsMax = this.getMaxLength(tops);
    const visitsMax = this.getMaxLength(visits);
    const addressesMax = this.getMaxLength(addresses);
    const countsMax = this.getMaxLength(counts);
    const hasInsightsMax = this.getMaxLength(hasInsights);

    let out = '';
    for (let i = 0; i < infos.length; i++) {
      const name = names[i].padEnd(namesMax, ' ');
      const online = onlines[i].padEnd(onlinesMax, ' ');
      const offCampus = offCampuses[i].padEnd(offCampusesMax, ' ');
      const state = states[i].padEnd(statesMax, ' ');
      const host = hosts[i].padEnd(hostsMax, ' ');
      const top = tops[i].padEnd(topsMax, ' ');
      const visit = visits[i].padEnd(visitsMax, ' ');
      const address = addresses[i].padEnd(addressesMax, ' ');
      const count = counts[i].padEnd(countsMax, ' ');
      const hasInsight = hasInsights[i].padEnd(hasInsightsMax, ' ');
      out += `${name} ${online} ${offCampus} ${state} ${host} ${top} ${visit} ${address}`;
      out += ` ${count} ${hasInsight}\n`;
    }

    console.log(out); // eslint-disable-line no-console
  }

  policy(type = 'group') {
    const state = this.store.getState();
    const policy = type === 'school' ? state.policy.school : state.policy.group;
    const keys = Object.keys(policy).sort((a, b) => a.localeCompare(b));
    const keysMax = this.getMaxLength(keys);

    let out = '';
    for (const key of keys) {
      const keyStr = key.padEnd(keysMax, ' ');
      const valueStr = this.getPolicyValueString(key, policy[key]);
      out += `${keyStr} ${valueStr}\n`;
    }

    console.log(out); // eslint-disable-line no-console
  }

  errors() {
    const state = this.store.getState();
    const errors = state.errors.all;

    let out = `Errors (${errors.length})\n`;
    for (const error of errors) {
      const time = (new Date(error.at)).toLocaleTimeString();
      const method = error.method && error.method.toUpperCase();
      out += ` ${time} ${error.message}\n`;
      out += `  ${method} ${error.url}\n`;
      out += `  ${error.status} ${error.data}\n`;
    }

    console.log(out); // eslint-disable-line no-console
  }
}
