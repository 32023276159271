export const policySettings = {
  ENHANCED_SCREENS_VIEW: { name: 'enhanced_screens_view', defaultValue: true },
  HEAT_MAP_VIEW: { name: 'heat_map_view', defaultValue: true },
};

export function policySettingChecker(policy, setting) {
  // Toggles on the Relay > Classroom Rules page that have never been toggled don't have
  // their default values from config/classroom_settings.yml added to the policy.
  // This function checks to see if the policy contains a key for the setting and
  // if it does, returns its value. If not, then it returns a defualt value which
  // should be the same default value as in config/classroom_settings.yml.

  if (Object.prototype.hasOwnProperty.call(policy, setting.name)) {
    return policy[setting.name];
  }

  return setting.defaultValue;
}
