import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';


const TextInput = ({
  testId = 'text-input',
  disabled = false,
  classes = '',
  overrideClasses,
  placeholder = '',
  value,
  onChange,
  onSubmit,
  onLeave,
  maxLength,
  minLength,
}) => {
  const inputClasses = classNames(classes, 'co-textInput');

  const handleBlur = () => {
    if (typeof onLeave === 'function') {
      onLeave();
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && typeof onSubmit === 'function') {
      e.preventDefault();
      e.stopPropagation();
      onSubmit();
    }
    if (e.key === 'Escape' && typeof onLeave === 'function') {
      e.stopPropagation();
      onLeave();
    }
  };

  return (
    <input
      data-testid={testId}
      type='text'
      disabled={disabled}
      className={overrideClasses ? overrideClasses : inputClasses}
      placeholder={placeholder}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      onFocus={(e) => e.target.select()}
      onBlur={handleBlur}
      onKeyDown={handleKeyDown}
      maxLength={maxLength}
      minLength={minLength}
    />
  );
};

TextInput.propTypes = {
  testId: PropTypes.string,
  disabled: PropTypes.bool,
  classes: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  onLeave: PropTypes.func,
  overrideClasses: PropTypes.string,
  maxLength: PropTypes.number,
  minLength: PropTypes.number,
};

export default TextInput;
