import {
  FETCH_DISTRICT,
  RECEIVE_DISTRICT,
  DISTRICT_ERROR,
  RESET_DISTRICT,
} from './actions';

export const defaultState = {
  loading: true,
  loaded: false,
  betas: {},
  ips: [],
  minimumUIVersion: null,
  error: '',
};

export const districtReducer = (state = defaultState, action) => {
  switch (action.type) {
    case FETCH_DISTRICT:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: '',
      };
    case RECEIVE_DISTRICT:
      return {
        ...state,
        loading: false,
        loaded: true,
        betas: action.betas,
        ips: action.ips,
        minimumUIVersion: action.minimumUIVersion,
      };
    case DISTRICT_ERROR:
      return {
        ...defaultState,
        loading: false,
        error: action.error,
      };
    case RESET_DISTRICT:
      return defaultState;
    default: {
      return state;
    }
  }
};
