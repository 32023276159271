import React, { useEffect, useRef, useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import {
  isValidUrl,
  getValidUrlLength,
  removeHttpProtocol,
  removeTrailingSlash,
} from '../../utilities/urls';

import Icon from './Icon';

const UrlChecker = ({
  updateEntries,
  initialSites,
  showEntries = true,
  hasError,
}) => {
  const divRef = useRef();
  const [entries, setEntries] = useState([]);
  const numOfEntries = useRef(initialSites.length);
  const showEntriesRef = useRef(showEntries);
  let timeout = null;
  const maxLines = 100;

  const resetTimer = () => {
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }
  };

  useEffect(() => {
    updateEntries(entries);
  }, [entries, updateEntries]);

  const handleKeyDown = () => {
    resetTimer();

    const timeoutLength = 300;

    timeout = setTimeout(() => {
      getEntries();
      timeout = null;
    }, timeoutLength);
  };

  useEffect(() => {
    document.addEventListener('keydown', () => handleKeyDown());

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useEffect(() => {
    // to load initial icons after populating div contenteditable with initial sites or saved sites
    showEntriesRef.current = showEntries;
    if (showEntries) {
      setTimeout(() => {
        getEntries();
      }, 10);
    }
  }, [showEntries]);

  useEffect(() => {
    // paste into content editable doesn't work quite right unless controlled
    const handlePasteEvent = (e) => {
      e.preventDefault();
      if (!e.clipboardData) {
        return;
      }

      const text = (e.originalEvent || e).clipboardData.getData('text/plain');
      if (document.queryCommandSupported('insertText')) {
        document.execCommand('insertText', false, text);
        getEntries();
      }
    };

    if (divRef.current) {
      divRef.current.addEventListener('paste', handlePasteEvent);
    }
    return () => {
      document.removeEventListener('paste', handlePasteEvent);
    };
  }, []);

  const handleTextFromElement = (text) => {
    if (!text || text === '' || text === '\n') {
      return null;
    }
    return text.trim();
  };

  const getEntries = (shouldSetEntries = true) => {
    // loop through content editable children elements to gather offset and text info
    if (divRef.current && divRef.current.children) {
      const parentTop = divRef.current.getBoundingClientRect().top;
      const newEntries = [];
      if (divRef.current.childNodes && divRef.current.childNodes.length) {
        // check first element, which is handled differently
        const firstOffset = 8; //padding-top
        const text = handleTextFromElement(divRef.current.childNodes[0].data);

        if (text) {
          const isValidFirst = isValidUrl(text);
          const modifiedFirstText = getValidUrlLength(removeTrailingSlash(removeHttpProtocol(text)));
          // check that after truncating an extremely long url (over 2048 chars), we still have a valid url to save
          // this is an extreme edge case and should very rarely be encountered
          const isModifiedFirstValid = isValidUrl(modifiedFirstText);
          const invalidUrl = !isValidFirst || !isModifiedFirstValid;
          newEntries.push({ top: firstOffset, error: invalidUrl, text: modifiedFirstText });
        }
      }

      // check rest of child elements
      [].slice.call(divRef.current.children).forEach((child) => {
        const text = handleTextFromElement(child.innerText);
        if (text) {
          const childRectangle = child.getBoundingClientRect();
          const isValid = isValidUrl(text);
          const offset = childRectangle.top - parentTop;
          const modifiedText = getValidUrlLength(removeTrailingSlash(removeHttpProtocol(text)));
          // checking that after truncating an extremely long url (over 2048 chars), we still have a valid url to save
          // this is an extreme edge case and should very rarely be encountered
          const isModifiedValid = isValidUrl(modifiedText);
          const invalidUrl = !isValid || !isModifiedValid;
          newEntries.push({ top: offset, error: invalidUrl, text: modifiedText });
        }
      });

      if (shouldSetEntries && showEntriesRef.current) {
        setEntries(newEntries);
        updateEntries(newEntries);
        numOfEntries.current = newEntries.length;
      }
      return newEntries;
    }
  };

  const buildIcons = () => {
    let items = [...entries];
    if (items.length === 0) {
      //get icons for initial sites
      items = getEntries(false);
    }

    return entries.map((offset, index) => {
      const isError = offset.error;
      const isOverLimit = index >= 100;
      const iconClasses = classNames({
        'text--green': !isError && !isOverLimit,
        'text--error': isError || isOverLimit,
      });
      const iconName = offset.error ? 'icon-error' : 'icon-checkmark-solid';

      return (
        // eslint-disable-next-line react/no-array-index-key
        <span key={index} className='co-webRulesFlyout-urls-icon' style={{ top: `${offset.top}px` }}>
          <Icon name={iconName} classes={iconClasses} />
        </span>
      );
    });
  };

  const buildInitialSites = () => {
    return (
      initialSites.map((entry, index) => {
        return (
          // eslint-disable-next-line react/no-array-index-key
          <div key={index}>{entry}</div>
        );
      })
    );
  };

  const buildCounter = () => {
    return (
      <span className='co-webRulesFlyout-urls-counter'>
        {`${entries.length}/${maxLines}`}
      </span>
    );
  };

  const focusClasses = classNames(
    'co-webRulesFlyout-urls-wrapper',
    'co-webRulesFlyout-urls-wrapper-webRuleLists',
    {
      hasError: hasError,
    },
  );


  return (
    <Fragment>
      {buildCounter()}
      <div className={focusClasses}>
        <div
          contentEditable='true'
          aria-label={entries.map((e) => e.text).join(' ')}
          className='co-webRulesFlyout-urls'
          ref={divRef}
          suppressContentEditableWarning={true}
        >
          {buildInitialSites()}
        </div>
        {buildIcons()}
      </div>
    </Fragment>
  );
};

UrlChecker.propTypes = {
  updateEntries: PropTypes.func.isRequired,
  initialSites: PropTypes.array.isRequired,
  showEntries: PropTypes.bool,
  hasError: PropTypes.bool,
};

export default UrlChecker;
