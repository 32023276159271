import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';


const Selector = ({
  items,
  selectedIds,
  radioButton,
  onSelection,
  containerClasses = '',
}) => {
  const selectorClasses = classNames('co-selector', containerClasses);

  const toggleItem = (id) => {
    let updated = [];

    if (radioButton) {
      updated = [id];
    } else {
      if (selectedIds.includes(id)) {
        updated = selectedIds.filter((selectedId) => selectedId !== id);
      } else {
        updated = [...selectedIds, id];
      }
    }

    onSelection(updated);
  };

  const buildItem = (item) => {
    const itemClasses = classNames('button-link co-selector-item', {
      'co-selector-item--selected': selectedIds.includes(item.id),
    });

    return (
      <li key={item.id}>
        <button
          className={itemClasses}
          onClick={() => toggleItem(item.id)}
          data-testid='selector-item-button'
        >
          {item.label}
        </button>
      </li>
    );
  };

  return (
    <ul className={selectorClasses}>
      {items.map((item) => buildItem(item))}
    </ul>
  );
};

Selector.propTypes = {
  items: PropTypes.arrayOf(PropTypes.exact({
    label: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  })).isRequired,
  selectedIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  radioButton: PropTypes.bool,
  onSelection: PropTypes.func.isRequired,
  containerClasses: PropTypes.string,
};

export default Selector;
