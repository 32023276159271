import {
  RECEIVE_GROUP_POLICY,
  RECEIVE_SCHOOL_POLICY,
  CLEAR_POLICIES,
} from './actions';

export const defaultState = {
  group: {},
  school: {},
};

export const policyReducer = (state = defaultState, action) => {
  switch (action.type) {
    case RECEIVE_GROUP_POLICY: {
      return {
        ...state,
        group: action.policy,
      };
    }
    case RECEIVE_SCHOOL_POLICY: {
      return {
        ...state,
        school: action.policy,
      };
    }
    case CLEAR_POLICIES:
      return defaultState;
    default:
      return state;
  }
};
