import React, { Fragment, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { List, arrayMove } from 'react-movable';
import classNames from 'classnames';

import { updateUserSetting } from '../../redux/currentUserSettings/actions';
import { addGroup, removeGroup, clearGroup, getGroups } from '../../redux/groups/actions';
import { openModal, closeModal, addErrorNotification } from '../../redux/ui/actions';

import I18n from '../../utilities/i18n';
import { canCreateClasses } from '../../utilities/policy';
import { getCurrentGroup } from '../../utilities/groups';
import { getGroupUrl } from '../../utilities/urls';
import { isInHomeDistrict, canRemoveGroup } from '../../utilities/users';

import Header from '../common/Header';
import Dropdown from '../common/Dropdown';
import Icon from '../common/Icon';
import Modal from '../common/Modal';
import RosterItem from './RosterItem';
import DataLoader from '../common/DataLoader';


function filterGroupsByDisplay(groups, showHidden) {
  if (showHidden) {
    return groups.filter((group) => group.hidden);
  }
  return groups.filter((group) => !group.hidden);
}

function getDisplayNamesByGuid(groups) {
  return groups.reduce((byGuid, group) => {
    if (group && group.guid && group.display_name) {
      return {
        ...byGuid,
        [group.guid]: group.display_name,
      };
    }
    return byGuid;
  }, {});
}

const RosterManagement = ({
  showHiddenDefault = false,
  currentUser,
  schoolPolicy,
  groups,
  currentGroup,
  currentlyOpenModal,
  updateUserSetting,
  addGroup,
  removeGroup,
  clearGroup,
  openModal,
  closeModal,
  getGroups,
  addErrorNotification,
}) => {
  const addModalName = 'addClass';
  const removeModalName = 'removeClass';
  const dropdownName = 'currentClasses';
  const backUrl = getGroupUrl(currentGroup);

  const [showHidden, setShowHidden] = useState(showHiddenDefault);
  const [displayedGroups, setDisplayedGroups] = useState(filterGroupsByDisplay(groups, showHidden));
  const [updatingGuid, setUpdatingGuid] = useState(null);
  const [addClassName, setAddClassName] = useState('');
  const [groupToRemove, setGroupToRemove] = useState(null);
  const [isLoadingGroups, setIsLoadingGroups] = useState(false);
  const groupsLengthRef = useRef(0);

  useEffect(() => {
    setUpdatingGuid(null);
    groupsLengthRef.current = groups.length;
  }, [groups]);

  useEffect(() => {
    setDisplayedGroups(filterGroupsByDisplay(groups, showHidden));
  }, [groups, showHidden]);

  const addGroupHandler = (name) => {
    closeModal();
    setAddClassName('');

    const numOfGroupsExpected = groups.length + 1;
    addGroup(name);
    setIsLoadingGroups(true);
    fetchGroupsTimeout(numOfGroupsExpected, 5);
  };

  const fetchGroupsTimeout = (numOfGroupsExpected, numOfAttempts) => {
    //This is done to wait for Elastic search to load new group
    const callback = async () => {
      await getGroups();
      if (groupsLengthRef.current === numOfGroupsExpected) {
        setIsLoadingGroups(false);
      } else if (numOfAttempts === 1) {
        // last attempt and didn't recieve group, so show error and display groups list
        setIsLoadingGroups(false);
        addErrorNotification(I18n.t('network_error_please_try_again'));
      } else {
        fetchGroupsTimeout(numOfGroupsExpected, numOfAttempts - 1);
      }
    };

    if (numOfAttempts > 0) {
      setTimeout(callback, 3000);
    }
  };

  const verifyRemoveGroup = (guid) => {
    const group = groups.find((group) => group.guid === guid);
    setGroupToRemove(group);
    openModal(removeModalName);
  };

  const removeGroupHandler = (group) => {
    const guid = group && group.guid;
    closeModal();
    setUpdatingGuid(guid);

    removeGroup(guid);
    if (guid === currentGroup.guid) {
      clearGroup();
    }
  };

  const renameGroup = (guid, value) => {
    setUpdatingGuid(guid);

    const displayNamesByGuid = getDisplayNamesByGuid(groups);
    updateUserSetting({
      name: 'class_display_names',
      value: {
        ...displayNamesByGuid,
        [guid]: value,
      },
    });
  };

  const changeOrder = (oldIndex, newIndex) => {
    const movedGroups = arrayMove(displayedGroups, oldIndex, newIndex);
    setDisplayedGroups(movedGroups);

    const sortedGuids = movedGroups.map((item) => item.guid);
    updateUserSetting({
      name: 'class_sort_order',
      value: sortedGuids,
    });
  };

  const hideGroup = (guid) => {
    setUpdatingGuid(guid);

    const hiddenItems = groups.filter((item) => item.hidden);
    const hiddenGuids = hiddenItems.map((item) => item.guid);
    updateUserSetting({
      name: 'hidden_classes',
      value: [...hiddenGuids, guid],
    });
  };

  const showGroup = (guid) => {
    setUpdatingGuid(guid);

    const hiddenItems = groups.filter((item) => item.hidden && item.guid !== guid);
    const hiddenGuids = hiddenItems.map((item) => item.guid);
    updateUserSetting({
      name: 'hidden_classes',
      value: hiddenGuids,
    });
  };

  const buildCurrentClassesDropdown = () => {
    const dropdownItems = [
      {
        label: I18n.t('current_classes'),
        action: () => setShowHidden(false),
      },
      {
        label: I18n.t('hidden_classes'),
        action: () => setShowHidden(true),
      },
    ];

    return (
      <Dropdown
        initialLabel={showHidden ? I18n.t('hidden_classes') : I18n.t('current_classes')}
        buttonLabelClasses='co-rosterManagement-classDropdown'
        containerClasses='co-dropdown--naturalWidth'
        dropdownName={dropdownName}
        dropdownItems={dropdownItems}
      />
    );
  };

  const buildAddButton = () => {
    if (!isInHomeDistrict(currentUser) || !canCreateClasses(schoolPolicy)) {
      return null;
    }

    return (
      <span
        data-testid='rosterManagement-add'
        className='co-rosterManagement-add'
        onClick={() => openModal(addModalName)}
      >
        <Icon name='icon-plus' />
      </span>
    );
  };

  const buildList = () => {
    if (isLoadingGroups) {
      return (
        <DataLoader />
      );
    }

    if (displayedGroups.length < 1) {
      const currentType = showHidden ? I18n.t('hidden') : I18n.t('current');

      return (
        <p className='text--gray text--alignCenter'>
          {I18n.t('there_are_no_classes', { type: currentType })}
        </p>
      );
    }

    return (
      <List
        values={displayedGroups}
        onChange={({ oldIndex, newIndex }) => changeOrder(oldIndex, newIndex)}
        renderList={({ children, props }) => (
          <ul data-testid='rosterManagement-list' className='list--styleNone' {...props}>{children}</ul>
        )}
        renderItem={({ value, props, isDragged }) => {
          const classes = classNames({
            'co-rosterManagement-rosterItem': !isDragged,
          });

          return (
            <li {...props} className={classes}>
              <RosterItem
                key={value}
                group={value}
                isSending={value.guid === updatingGuid}
                isDragged={isDragged}
                canRemove={canRemoveGroup(value)}
                showGroup={showGroup}
                hideGroup={hideGroup}
                removeGroup={verifyRemoveGroup}
                renameGroup={renameGroup}
              />
            </li>
          );
        }}
      />
    );
  };

  const buildAddModal = () => {
    const nameIsBlank = !addClassName;
    const saveButton = (
      <button
        data-testid='rosterManagement-addModal-save'
        className='button button--blue analytics-addClass'
        onClick={() => addGroupHandler(addClassName)}
        disabled={nameIsBlank}
      >
        {I18n.t('save')}
      </button>
    );

    return (
      <Modal
        show={currentlyOpenModal === addModalName}
        title={I18n.t('class_name')}
        closeModal={closeModal}
        isSmall
        modalRedesign2022Q1
        legacyModalVersion
        customSaveButton={saveButton}
      >
        <input
          className='co-rosterManagement-rosterItem-modal'
          data-testid='rosterManagement-addModal-input'
          type='text'
          value={addClassName}
          onChange={(e) => setAddClassName(e.target.value)}
        />
      </Modal>
    );
  };

  const buildRemoveModal = () => {
    const removeButton = (
      <button
        data-testid='rosterManagement-removeModal-continue'
        className='co-modal-warningButton'
        onClick={() => removeGroupHandler(groupToRemove)}
      >
        {I18n.t('continue')}
      </button>
    );

    return (
      <Modal
        show={currentlyOpenModal === removeModalName}
        title={I18n.t('remove_class')}
        closeModal={closeModal}
        isSmall
        modalRedesign2022Q1
        legacyModalVersion
        customSaveButton={removeButton}
      >
        <p className='alert alert--error m-vertical--0'>
          {I18n.t('remove_class_continue', { name: (groupToRemove && groupToRemove.name) })}
        </p>
      </Modal>
    );
  };

  return (
    <Fragment>
      <Header />

      <main className='co-rosterManagementContainer'>
        <header className='co-rosterManagement'>
          <Link className='co-rosterManagement-back' to={backUrl}>
            <Icon name='icon-left-2' classes='m-right--4' />
            {I18n.t('back')}
          </Link>
          <h1 className='m-bottom--4'>{I18n.t('my_classes')}</h1>

          <div className='l-flex l-flex--hSpaceBetween m-bottom--16'>
            <p className='text--gray m-vertical--0'>{I18n.t('drag_and_drop_to_reorder')}</p>
            {buildCurrentClassesDropdown()}
          </div>
        </header>

        <section className='co-rosterManagement'>
          <header className='co-rosterManagement-infoHeader co-rosterManagement-infoGrid'>
            <span className='grid--span8'>{I18n.t('name')}</span>
            <span className='text--alignCenter'>{I18n.t('schedule')}</span>
            <span className='position--relative'>
              {buildAddButton()}
            </span>
          </header>
          {buildList()}
        </section>
      </main>

      {buildAddModal()}
      {buildRemoveModal()}
    </Fragment>
  );
};

RosterManagement.propTypes = {
  showHiddenDefault: PropTypes.bool,
  currentUser: PropTypes.object,
  schoolPolicy: PropTypes.object.isRequired,
  groups: PropTypes.array.isRequired,
  currentGroup: PropTypes.object.isRequired,
  currentlyOpenModal: PropTypes.string,
  updateUserSetting: PropTypes.func.isRequired,
  addGroup: PropTypes.func.isRequired,
  getGroups: PropTypes.func.isRequired,
  removeGroup: PropTypes.func.isRequired,
  clearGroup: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  addErrorNotification: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  currentUser: state.currentUser,
  schoolPolicy: state.policy.school,
  groups: state.groups.groups,
  currentGroup: getCurrentGroup(state.groups),
  currentlyOpenModal: state.ui.currentlyOpenModal,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  updateUserSetting,
  addGroup,
  removeGroup,
  clearGroup,
  openModal,
  closeModal,
  getGroups,
  addErrorNotification,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(RosterManagement);
