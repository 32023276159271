import CoRTCConnectionHandler from '../../helpers/rtcConnectionHandler';
import I18n from '../../utilities/i18n';
import { addNotification } from '../ui/actions';

export const ADD_WEBRTC = 'ADD_WEBRTC';
export const REMOVE_WEBRTC = 'REMOVE_WEBRTC';
export const ADD_SECOND_VIEWER = 'ADD_SECOND_VIEWER';
export const REMOVE_SECOND_VIEWER = 'REMOVE_SECOND_VIEWER';
export const ADD_STREAM = 'ADD_STREAM';
export const REMOVE_STREAM = 'REMOVE_STREAM';
export const ADD_HANDLER = 'ADD_HANDLER';
export const REMOVE_HANDLER = 'REMOVE_HANDLER';
export const ADD_WEBRTC_ERROR = 'ADD_WEBRTC_ERROR';

export function addWebrtc(name, sessionId, channel, iceServers, streamId) {
  return function(dispatch) {
    function streamCallback(stream) {
      dispatch({ type: ADD_STREAM, name, stream });
    }

    return dispatch({ type: ADD_WEBRTC, name, sessionId, channel, iceServers, streamCallback, streamId });
  };
}

export function addStreamToHandler(stream, teacherChannel) {
  return function(dispatch, getState) {
    const state = getState();
    const iceServers = state.broadcast.iceServers;
    let connectionHandler = state.webrtcs.handler;
    if (connectionHandler) {
      connectionHandler.setNewStream(stream);
      return;
    }
    const addError = (error) => {
      console.error({ error });
      dispatch(addNotification(I18n.t('network_error_please_try_again'), { notificationType: 'error', autoDismiss: true }));
    };
    // initialize connection handler
    connectionHandler = new CoRTCConnectionHandler(stream, teacherChannel, iceServers, addError);
    dispatch({ type: ADD_HANDLER, handler: connectionHandler });
  };
}

export function removeWebrtc(name) {
  return { type: REMOVE_WEBRTC, name };
}

export function addSecondViewer(name) {
  return { type: ADD_SECOND_VIEWER, name };
}

export function removeSecondViewer(name) {
  return { type: REMOVE_SECOND_VIEWER, name };
}

export function removeHandler() {
  return { type: REMOVE_HANDLER };
}

export function addWebrtcError(name, error) {
  return function(dispatch) {
    return dispatch({ type: ADD_WEBRTC_ERROR, name, error });
  };
}
