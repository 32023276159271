import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cn from 'classnames';
import uuid from 'uuid';

import I18n from '../../../../utilities/i18n';
import { openSubFlyout } from '../../../../redux/ui/actions';

import { ReactComponent as PencilEditIcon } from '../../../../images/pencilEditIcon.svg';
import { getEditList } from '../../../../redux/webRules/actions';
import { bindActionCreators } from 'redux';

const ListsTable = ({
  currentSection,
  getEditList,
  getSelectedWebRulesLists,
  initialCheckedLists,
  initialSection,
  openSubFlyout,
  webRules,
}) => {
  const [list, setList] = useState(Object.entries(webRules[`${currentSection}List`]));
  const [checkedItems, setCheckedItems] = useState([]);
  const [checkedItemsUrls, setCheckedItemsUrls] = useState([]);
  const [listChange, setListChange] = useState(false);

  useEffect(() => {
    if (initialSection === currentSection) {
      setCheckedItems(initialCheckedLists);
      const getCheckedEntries = list.filter((listEntry) => initialCheckedLists.includes(listEntry[0]));
      const getInitialUrls = getCheckedEntries.map((x) => x[1].urls).flat(Infinity);
      setCheckedItemsUrls(getInitialUrls);
    }
    if (initialSection !== currentSection) {
      setCheckedItems([]);
      setCheckedItemsUrls([]);
    }
  }, [initialSection, currentSection, initialCheckedLists, list]);

  const checkedItemHandler = (value, urls) => {
    setListChange(true);
    if (checkedItems.includes(value)) {
      const newItems = checkedItems.filter((item) => item !== value);
      const urlsToApply = [list.map((l) => {
        if (newItems.includes(l[0])) {
          return l[1].urls;
        }
        return [];
      })];
      const newUrls = urlsToApply.flat(Infinity);
      setCheckedItems(newItems);
      setCheckedItemsUrls(newUrls);
    } else {
      setCheckedItems([...checkedItems, value]);
      setCheckedItemsUrls([...checkedItemsUrls, urls]);
    }
  };
  
  useEffect(() => {
    getSelectedWebRulesLists(checkedItems, checkedItemsUrls, listChange);
  }, [checkedItems, checkedItemsUrls, listChange, getSelectedWebRulesLists]);

  useEffect(() => {
    setList(Object.entries(webRules[`${currentSection}List`]));
  }, [webRules, currentSection]);

  const sortList = list.sort((x, y) => {  
    return y[1].createdAt - x[1].createdAt;
  });

  const getFirstSelected = sortList.length ? sortList[0][0] : null;
  const [currentSelection, setCurrentSelection] = useState(getFirstSelected);
  const [focus, setFocus] = useState(getFirstSelected);

  useEffect(() => {
    setCurrentSelection(getFirstSelected);
    setFocus(getFirstSelected);
  }, [getFirstSelected, webRules]);

  const handleOpenSubFlyout = (listName, urls, createdAt, updatedAt) => {
    const subFlyoutName = 'edit_list';
    getEditList(listName, urls, createdAt, updatedAt);
    openSubFlyout(subFlyoutName);
  };

  const handleSetSelected = (listName) => {
    setCurrentSelection(listName);
    const selectedListName = listName ? listName : null;
    setFocus(selectedListName);
  };
  
  const getListNames = (sortList) => {
    return sortList.map((x) => {
      const listName = x[0];
      const urls = x[1].urls;
      const createdAt = x[1].createdAt;
      const updatedAt = x[1].updatedAt;

      const getCheckedVal = checkedItems.length > 0 && checkedItems.find((x) => x === listName) ? (
        checkedItems.find((x) => x === listName)
      ) : false;

      const listNameClasses = cn({
        'co-listsTable-listName-wrapper': true,
        isSelected: listName === focus,
      });

      return (
        <div
          // lists names are always unique 
          key={listName} 
          onClick={() => handleSetSelected(listName)} 
          className={listNameClasses}
          data-testid='listsTable-listName-wrapper'
        >
          <div className='co-listsTable-listName' data-testid='listsTable-listName'>
            <input
              data-testid='listsTable-listName-checkbox'
              className='co-listsTable-listName-checkbox' 
              id={`checkbox-${listName}`} 
              type='checkbox' 
              value={listName}
              checked={getCheckedVal}
              onChange={(e) => checkedItemHandler(e.target.value, urls)}
            />
            <label htmlFor={`checkbox-${listName}`} />
            <div
              className='co-listsTable-listName-textContainer truncate'
              data-testid='listsTable-listName-textContainer'
            >
              {listName}
            </div>
            <PencilEditIcon
              className='co-listsTable-editIcon'
              onClick={() => handleOpenSubFlyout(listName, urls, createdAt, updatedAt)}
            />
          </div>
        </div>
      );
    });
  };

  const getListItems = (sortList, listName) => {
    const listItems = sortList.find((list) => list[0] === listName);
    const listUrls = listItems?.[1].urls;
    if (listUrls === null || listUrls === undefined) {
      return;
    }
    return listUrls.map((x) => { 
      return (
        <div
          key={uuid()} 
          className='co-listsTable-urls truncate'
          data-testid='listsTable-urls'
        >
          {x}
        </div>
      );
    });
  };

  const countClasses = cn({
    'co-listsTable-headerInfo': true,
    maxReached: list.length >= 20,
  });

  return (
    <div className='co-listsTable-container' data-testid='listsTable-container'>
      <div className='co-listsTable' data-testid='listsTable'>
        <div className='co-listsTable-header' data-testid='listsTable-header'>
          <div className='co-listsTable-header-section rightBorder' data-testid='listsTable-header-section-left'>
            {I18n.t('lists')}
            <span data-testid='listsTable-listCount' className={countClasses}>{list.length}/20</span>
          </div>
          <div className='co-listsTable-header-section truncate' data-testid='listsTable-header-section-right'>
            {currentSelection}
          </div>
        </div>
        <div className='co-listsTable-content' data-testid='listsTable-header-section-left'>
          <div className='co-listsTable-column rightBorder' data-testid='listsTable-column-listNames'>
            {getListNames(sortList)}
          </div>
          <div className='co-listsTable-column' data-testid='listsTable-column-urls'>
            {currentSelection !== null && getListItems(sortList, currentSelection)}
          </div>
        </div>
      </div>
    </div>
  );
};

ListsTable.propTypes = {
  currentSection: PropTypes.string,
  getEditList: PropTypes.func,
  getSelectedWebRulesLists: PropTypes.func,
  initialCheckedLists: PropTypes.array,
  initialSection: PropTypes.string,
  openSubFlyout: PropTypes.func,
  webRules: PropTypes.object.isRequired,
};

const mapState = (state) => ({
  currentlyOpenSubFlyout: state.ui.currentlyOpenSubFlyout,
  urls: state.webRules.urls,
  webRules: state.webRules,
});


const mapDispatchToProps = (dispatch) => bindActionCreators({
  getEditList,
  openSubFlyout,
}, dispatch);

export default connect(mapState, mapDispatchToProps)(ListsTable);
