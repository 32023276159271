import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

function convertToTimeString(hours, mins, meridiem) {
  if (hours === '12' && meridiem === 'AM') {
    hours = '00';
  }
  if (hours !== '12' && meridiem === 'PM') {
    let hoursInt = parseInt(hours, 10);
    hoursInt = (hoursInt + 12) % 24;
    hours = hoursInt.toString();
  }
  return `${hours}:${mins}`;
}

function getInitialTimes(initialValue) {
  if (!initialValue) {
    return { hours: '', mins: '', meridiem: '' };
  }

  const splitTimes = initialValue.split(':');
  const mins = splitTimes[1];
  let hoursInt = parseInt(splitTimes[0], 10);
  const meridiem = hoursInt >= 12 ? 'PM' : 'AM';
  let hours = splitTimes[0];
  if (hoursInt > 12) {
    hoursInt -= 12;
    hours = hoursInt.toString();
    if (hours.length === 1) {
      hours = '0' + hours;
    }
  } else {
    if (hours === '00') {
      hours = '12';
    }
  }
  return { hours, mins, meridiem };
}

function convertHoursOrMins (value, max) {
  value = value.replace(/^0+/, '');
  let newValue;
  const valueInt = parseInt(value, 10);
  if (valueInt <= max) {
    newValue = value;
  } else {
    newValue = value.substring(0, 1); //first char
  }
  if (newValue.length === 1) {
    newValue = '0' + newValue;
  }
  return newValue;
}

function convertMeridiem (value) {
  const acceptableVals = ['p', 'a', 'P', 'A'];
  if (acceptableVals.includes(value)) {
    const newValue = value.toUpperCase() + 'M';
    return newValue;
  }
}

function keyIsDisallowed (key) {
  return ['-', 'e'].includes(key);
}

const TimeInput = ({
  onChange,
  classes,
  initialValue,
  disabled,
}) => {
  const initialTimes = useMemo(() => getInitialTimes(initialValue), [initialValue]);
  const browserSupportsTimeInput = window.browserFeatures.timeInput;
  const containerClasses = classNames(classes, {
    timeInput: true,
    'timeInput--disabled': disabled,
  });

  const [time, setTime] = useState(initialValue === '' ? undefined : initialValue);
  const [mins, setMins] = useState(initialTimes ? initialTimes.mins : null);
  const [hours, setHours] = useState(initialTimes ? initialTimes.hours : null);
  const [meridiem, setMeridiem] = useState(initialTimes ? initialTimes.meridiem : null);

  useEffect(() => {
    setTime(initialValue === '' ? undefined : initialValue);
    setMins(initialTimes ? initialTimes.mins : null);
    setHours(initialTimes ? initialTimes.hours : null);
    setMeridiem(initialTimes ? initialTimes.meridiem : null);
  }, [initialValue, initialTimes]);

  function handleHoursChange(newVal) {
    const newHours = convertHoursOrMins(newVal, 12);
    setHours(newHours);
    const newTime = convertToTimeString(newHours, mins, meridiem);
    onChange(newTime);
  }

  function handleMinsChange(newVal) {
    let newMins = convertHoursOrMins(newVal, 60);
    if (newMins === '') {
      newMins = '00';
    }
    setMins(newMins);
    const newTime = convertToTimeString(hours, newMins, meridiem);
    onChange(newTime);
  }

  function handleMeridiemChange(newVal) {
    const newMeridiem = convertMeridiem(newVal);
    if (newMeridiem) {
      setMeridiem(newMeridiem);
      const newTime = convertToTimeString(hours, mins, newMeridiem);
      onChange(newTime);
    }
  }

  // if the browser supports time inputs, use those.
  if (browserSupportsTimeInput) {
    // set default value if none is given.
    return (
      <input
        data-testid='timeInput-input'
        className={containerClasses}
        disabled={disabled}
        type='time'
        value={disabled || !time ? '' : time}
        onChange={(e) => {
          setTime(e.target.value);
          onChange(e.target.value);
        }}
      />
    );
  }

  return (
    <section className={containerClasses} data-testid='timeInput-polyfill'>
      <input
        data-testid='timeInput-hours'
        type='number'
        className='timeInput-input timeInput-input--number'
        disabled={disabled}
        value={disabled ? '' : hours}
        placeholder='--'
        mins='1'
        max='12'
        onKeyDown={(e) => keyIsDisallowed(e.key) && e.preventDefault()}
        onClick={(e) => e.target.select()}
        onChange={(e) => handleHoursChange(e.target.value)}
      />

      <span className='p--0'>:</span>

      <input
        data-testid='timeInput-minutes'
        type='number'
        className='timeInput-input timeInput-input--number'
        disabled={disabled}
        value={disabled ? '' : mins}
        placeholder='--'
        mins='0'
        max='59'
        onKeyDown={(e) => keyIsDisallowed(e.key) && e.preventDefault()}
        onClick={(e) => e.target.select()}
        onChange={(e) => handleMinsChange(e.target.value)}
      />

      <input
        data-testid='timeInput-period'
        type='text'
        className='timeInput-input timeInput-input--text'
        disabled={disabled}
        value={disabled ? '' : meridiem}
        placeholder='--'
        onKeyDown={(e) => keyIsDisallowed(e.key) && e.preventDefault()}
        onClick={(e) => e.target.select()}
        onChange={(e) => handleMeridiemChange(e.target.value)}
      />
    </section>
  );
};

TimeInput.propTypes = {
  onChange: PropTypes.func,
  classes: PropTypes.string,
  initialValue: PropTypes.string,
  disabled: PropTypes.bool,
};

export default TimeInput;
