import { connectStudentChannels, disconnectChannel, updatePolicy } from '../ably/actions';
import { updateEvents, updateGroupConnections } from '../classes/actions';
import { addError } from '../errors/actions';
import { getSegments } from '../groupSegments/actions';
import { getInvites } from '../invites/actions';
import { getPolicy, saveLastSchedule } from '../policy/actions';
import { addErrorNotification, addNotification } from '../ui/actions';
import { checkInClass, getUsers } from '../users/actions';
import { getWebzone } from '../webzones/actions';
import { SET_INVITE_CODE } from '../invites/actions';

import { validScheduleTypes } from '../../utilities/policy';
import { getCurrentGroup } from '../../utilities/groups';
import I18n from '../../utilities/i18n';
import { isScheduleTimeAllowed, isValidSchedule, removeDisabledDays } from '../../utilities/schedule';
import { getUserByEmail, hasBeta } from '../../utilities/users';

import { ClassroomApi } from '../../helpers/classroomApi';

export const RECEIVE_GROUPS = 'RECEIVE_GROUPS';
export const CLEAR_GROUPS = 'CLEAR_GROUPS';
export const RECEIVE_GROUP = 'RECEIVE_GROUP';
export const CLEAR_GROUP = 'CLEAR_GROUP';
export const SET_CURRENT_GROUP = 'SET_CURRENT_GROUP';
export const SET_LOADED_GROUP = 'SET_LOADED_GROUP';
export const SET_INVALID_SCHEDULE = 'SET_INVALID_HOURS_SCHEDULE';
export const PRUNE_GROUP = 'PRUNE_GROUP';
// TODO remove pinned users/group settings once 'beta_class_segments' is general release
export const RECEIVE_GROUP_SETTINGS = 'RECEIVE_GROUP_SETTINGS';

export function addGroup(name) {
  return async function(dispatch) {
    const response = await ClassroomApi.post('/groups', { name });

    if (response?.status !== 200) {
      dispatch(addErrorNotification(I18n.t('network_error_please_try_again')));
      return dispatch(addError('addGroup', response));
    }

    return dispatch(getGroups());
  };
}

export function removeGroup(guid) {
  return async function(dispatch, getState) {
    const state = getState();
    const response = await ClassroomApi.delete(`/groups/${guid}`);

    if (response?.status !== 200) {
      dispatch(addErrorNotification(I18n.t('network_error_please_try_again')));
      return dispatch(addError('removeGroup', response));
    }

    if (hasBeta('cloud_rostering', state.district.betas, state.currentUser.betas)) {
      return dispatch({ type: PRUNE_GROUP, guid });
    }

    return dispatch(getGroups());
  };
}

export function getGroups() {
  return async function(dispatch) {
    const response = await ClassroomApi.get('/groups');

    if (response?.status !== 200) {
      return dispatch(addError('getGroups', response));
    }

    const groups = response.data.groups;
    return dispatch({ type: RECEIVE_GROUPS, groups });
  };
}

export function setCurrentGroup(guid) {
  return async function(dispatch, getState) {
    dispatch(setGroup(guid));
    await dispatch(getGroup(guid));

    const state = getState();
    const group = state.groups.groups.find((group) => group.guid === guid);
    if (!group) {
      return {};
    }

    dispatch(updateEvents(group));

    // Update all group data
    const groupLoads = [
      dispatch(getPolicy(guid, 'group')),
      await dispatch(getUsers(guid)),
      dispatch(getGroupSettings(guid)),
      dispatch(getWebzone(guid)),
      dispatch(getInvites(guid)),
      dispatch({ type: SET_INVITE_CODE, code: '' }),
      await dispatch(getSegments(guid)),
    ];

    await Promise.all(groupLoads);

    await dispatch(validateGroupSchedule(guid));

    dispatch({ type: SET_LOADED_GROUP, guid });
    return dispatch(updateGroupConnections());
  };
}



export function getGroup(guid) {
  return async function(dispatch) {
    const response = await ClassroomApi.get(`/groups/${guid}`);

    if (response?.status !== 200) {
      return dispatch(addError('getGroup', response));
    }

    const group = response.data;
    return dispatch({ type: RECEIVE_GROUP, group });
  };
}

export function setGroup(guid) {
  return function(dispatch, getState) {
    const state = getState();
    const group = state.groups.groups.find((group) => group.guid === guid);

    if (!group) {
      return dispatch(clearGroup());
    }

    return dispatch({ type: SET_CURRENT_GROUP, guid: group.guid });
  };
}

export function validateGroupSchedule(groupGuid) {
  return async function(dispatch, getState) {
    const state = getState();
    const group = state.groups.groups.find((group) => group.guid === groupGuid);
    const groupPolicy = state.policy.group;
    const currentUser = state.currentUser;

    if (!group) {
      return {};
    }

    if (groupPolicy &&
      !isScheduleTimeAllowed(group.class_schedule, groupPolicy.allowed_hours, groupPolicy.schedule_type)) {
      const modifiedSchedule = removeDisabledDays(group.class_schedule, groupPolicy.allowed_hours);
      await dispatch(setInvalidHoursSchedule(group, modifiedSchedule));
      await dispatch(updateEvents(group));
      return;
    }

    const validTypes = validScheduleTypes(groupPolicy, currentUser.is_admin);
    if (!isValidSchedule(group.class_schedule)) {
      await dispatch(saveLastSchedule(groupGuid, group.schedule_type, null));
      await dispatch(updateSchedule(groupGuid, null));

      window.Rollbar.critical('invalid schedule', {
        customerId: currentUser.customer_id,
        schoolGuid: group.school_guid,
        groupGuid,
        groupName: group.name,
        schedule: group.class_schedule,
        scheduleType: group.schedule_type,
      });
    } else if (!validTypes.includes(group.schedule_type)) {
      await dispatch(saveLastSchedule(groupGuid, group.schedule_type, null));
      await dispatch(updateSchedule(groupGuid, null));
    }
  };
}

export function updateSchedule(groupGuid, schedule) {
  return async function(dispatch, getState) {
    let state = getState();
    let group = state.groups.groups.find((group) => group.guid === groupGuid);

    if (!group) {
      return {};
    }

    await dispatch(saveSchedule(groupGuid, schedule));
    dispatch(updatePolicy());
    dispatch(getPolicy(groupGuid, 'group'));
    await dispatch(getGroup(groupGuid));

    state = getState();
    group = state.groups.groups.find((group) => group.guid === groupGuid);
    await dispatch(setInvalidHoursSchedule(group));
    await dispatch(checkInClass(group));
    return dispatch(updateEvents(group));
  };
}

export function saveSchedule(groupGuid, schedule) {
  return async function(dispatch) {
    const data = { class_schedule: schedule };
    const response = await ClassroomApi.post(`/groups/${groupGuid}`, data);

    if (response?.status !== 200) {
      dispatch(addErrorNotification(I18n.t('network_error_please_try_again')));
      return dispatch(addError('saveSchedule', response));
    }

    const group = response.data;
    return dispatch({ type: RECEIVE_GROUP, group });
  };
}

export function getGroupSettings(groupGuid) {
  return function(dispatch, getState) {
    const state = getState();
    const group = state.groups.groups.find((group) => group.guid === groupGuid);
    const allUsers = state.users.all;
    if (!group) {
      return {};
    }

    let groupSettings = {};

    try {
      groupSettings = localStorage.groupSettings ? JSON.parse(localStorage.groupSettings) : {};
      groupSettings = groupSettings[groupGuid] || migrateOldSettings(allUsers, groupSettings, group);
    } catch (error) {
      groupSettings = {};
    }

    dispatch({ type: RECEIVE_GROUP_SETTINGS, settings: groupSettings });
  };
}

function migrateOldSettings(allUsers, groupSettings, group) {
  const oldSettings = groupSettings[group.id];

  if (oldSettings) {
    const pinnedEmails = oldSettings.pinnedUsers ?? [];
    const pinnedGuids = pinnedEmails.map((email) => getUserByEmail(allUsers, email).guid);
    const pinnedUsers = pinnedGuids.filter((guid) => Boolean(guid));

    return { pinnedUsers };
  }

  return {};
}

export function addPinnedUser(groupGuid, userGuid) {
  return function(dispatch, getState) {
    const state = getState();

    if (!state.groups.groupSettings.pinnedUsers) {
      dispatch(saveGroupSetting(groupGuid, 'pinnedUsers', [userGuid]));
    } else if (state.groups.groupSettings.pinnedUsers.indexOf(userGuid) === -1) {
      dispatch(saveGroupSetting(groupGuid, 'pinnedUsers', state.groups.groupSettings.pinnedUsers.concat(userGuid)));
    }
  };
}

export function removePinnedUser(groupGuid, userGuid) {
  return function(dispatch, getState) {
    const state = getState();

    if (state.groups.groupSettings.pinnedUsers && state.groups.groupSettings.pinnedUsers.indexOf(userGuid) > -1) {
      const pinnedUsers = state.groups.groupSettings.pinnedUsers.slice();
      const userIndex = pinnedUsers.indexOf(userGuid);

      pinnedUsers.splice(userIndex, 1);
      dispatch(saveGroupSetting(groupGuid, 'pinnedUsers', pinnedUsers));
    }
  };
}

function saveGroupSetting(groupGuid, settingName, settingValue) {
  return function(dispatch, getState) {
    const state = getState();

    const settings = {
      ...state.groups.groupSettings,
      [settingName]: settingValue,
    };

    try {
      const allSettings = localStorage.groupSettings ? JSON.parse(localStorage.groupSettings) : {};
      allSettings[groupGuid] = settings;
      localStorage.groupSettings = JSON.stringify(allSettings);
    } catch (error) {
      localStorage.groupSettings = JSON.stringify({
        [groupGuid]: {
          [settingName]: settingValue,
        },
      });
    }

    dispatch(getGroupSettings(groupGuid));
  };
}

export function addStudent(email, guid) {
  return async function(dispatch, getState) {
    const state = getState();
    const currentGroup = getCurrentGroup(state.groups);

    const result = await dispatch(addMember(email));

    if (result.success) {
      dispatch(addNotification(result.message, { autoDismiss: true, notificationType: 'success' }));
      dispatch(getUsers(currentGroup.guid));
      dispatch(connectStudentChannels(currentGroup.guid, [email]));
      dispatch(updatePolicy(email));
      dispatch(setCurrentGroup(guid));
      return true;
    }

    dispatch(addErrorNotification(result.error));
    return false;
  };
}

export function removeStudent(userGuid) {
  return async function(dispatch, getState) {
    const state = getState();
    const currentGroup = getCurrentGroup(state.groups);
    const user = state.users.all[userGuid];

    const result = await dispatch(removeMember(user));

    if (result.success) {
      dispatch(addNotification(result.message, { autoDismiss: true, notificationType: 'success' }));
      dispatch(disconnectChannel(user.email));
      dispatch(removePinnedUser(currentGroup.guid, userGuid));
      dispatch(getUsers(currentGroup.guid));
      dispatch(updatePolicy(user.email));
      return true;
    }

    dispatch(addErrorNotification(result.error));
    return false;
  };
}

export function addMember(email) {
  return async function(dispatch, getState) {
    const state = getState();
    const isMember = Boolean(getUserByEmail(state.users.all, email));
    const currentGroup = getCurrentGroup(state.groups);

    if (isMember) {
      return { success: false, error: I18n.t('user_is_already_a_member') };
    }

    if (!email.match(/.+@.+\..+/g)) {
      return { success: false, error: I18n.t('please_enter_a_valid_email') };
    }

    const response = await ClassroomApi.post(`/groups/${currentGroup.guid}/add_member`, { email: email });

    if (!response) {
      return { success: false, error: I18n.t('network_error_please_try_again') };
    }

    if (response?.data === 'user not found') {
      return { success: false, error: I18n.t('string_could_not_be_added_no_matching', { string: email }) };
    }

    if (response?.data === 'user in different school') {
      return { success: false, error: I18n.t('string_is_not_in_your_school_use_invite_code', { string: email }) };
    }

    if (response?.data === 'class at max size') {
      return { success: false, error: I18n.t('string_could_not_be_added_max_size', { string: email }) };
    }

    if (response?.status !== 200) {
      dispatch(addError('addMember', response));
      return { success: false, error: I18n.t('string_could_not_be_added_error', { string: email }) };
    }

    return { success: true, message: I18n.t('string_was_successfully_added', { string: email }) };
  };
}

export function removeMember(user) {
  return async function(dispatch, getState) {
    if (!user) {
      return { success: false, error: I18n.t('an_unknown_error_occured_please_contact_admin') };
    }

    const state = getState();
    const currentGroup = getCurrentGroup(state.groups);
    const params = { email: user.email, user_guid: user.guid };
    const response = await ClassroomApi.post(`/groups/${currentGroup.guid}/remove_member`, params);
    const fullName = `${user.first_name} ${user.last_name}`;

    if (response?.status !== 200) {
      dispatch(addError('removeMember', response));
      return { success: false, error: I18n.t('an_unknown_error_occured_please_contact_admin') };
    }

    return { success: true, message: I18n.t('string_was_successfully_removed', { string: fullName }) };
  };
}

export function clearGroups() {
  return {
    type: CLEAR_GROUPS,
  };
}

export function clearGroup() {
  return {
    type: CLEAR_GROUP,
  };
}

export function setInvalidHoursSchedule(group, invalidSchedule) {
  if (invalidSchedule) {
    group.invalid_schedule = invalidSchedule;
    group.class_schedule = null;
  } else {
    group.invalid_schedule = null;
  }
  return {
    type: RECEIVE_GROUP,
    group,
  };
}
