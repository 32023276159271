export function setupPendo(user, isInTeams = false) {
  const params = {
    visitor: {
      id: user.guid,
      email: user.email,
      name: `${user.first_name} ${user.last_name}`,
      role: user.role,
      uiVersion: window.classroomUIVersion,
      msTeams: isInTeams,
    },
    account: {
      id: user.customer_id,
    },
  };

  window.pendo && window.pendo.initialize(params);
}

export function trackPendo(type, data) {
  if (window.pendo?.isReady && window.pendo.isReady()) {
    window.pendo.track(type, data);
  }
}
