import React, { useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import I18n from '../../utilities/i18n';

import Icon from './Icon';

function isValidUrl(url) {
  if (typeof url === 'string' && url.startsWith('http')) {
    return true;
  }
  return false;
}

function isValidHost(host) {
  const excluded = [
    'extensions',
    'newtab',
    'undefined',
  ];

  if (host.startsWith('http')) {
    return false;
  }
  return !excluded.includes(host);
}

function getHostUrl(host) {
  return `https://www.google.com/s2/favicons?domain=${host}`;
}

function imageReducer (state, action) {
  switch (action.type) {
    case 'INIT': {
      let step = 4;
      let src = '';

      if (action.faviconUrl && isValidUrl(action.faviconUrl)) {
        step = 1;
        src = getHostUrl(action.faviconUrl);
      } else if (action.host && isValidHost(action.host)) {
        step = 2;
        src = getHostUrl(action.host);
      }

      return {
        step,
        faviconUrl: action.faviconUrl,
        host: action.host,
        src,
      };
    }
    default: {
      let step = state.step + 1;
      let src = '';

      if (state.host && isValidHost(state.host)) {
        if (step === 2) {
          src = getHostUrl(state.host);
        } else if (step === 3) {
          src = `https://www.google.com/s2/favicons?domain=${state.host}`;
        }
      } else {
        step = 4;
      }

      return {
        ...state,
        step,
        src,
      };
    }
  }
}


const WebsiteImage = ({
  faviconUrl,
  host,
  classes,
}) => {
  const imageClasses = classNames(classes, 'co-favicon');
  const [image, dispatch] = useReducer(imageReducer, { step: 4 });

  useEffect(() => {
    dispatch({ type: 'INIT', faviconUrl, host });
  }, [faviconUrl, host]);

  if (image.step === 4) {
    return <Icon name='icon-globe' classes={imageClasses} />;
  }

  return (
    <img
      width='16'
      height='16'
      src={image.src}
      alt={I18n.t('site_icon_for', { string: image.src })}
      onError={() => dispatch({ type: 'NEXT' })}
      className={imageClasses}
    />
  );
};

WebsiteImage.propTypes = {
  faviconUrl: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  host: PropTypes.string,
  classes: PropTypes.string,
};

export default WebsiteImage;
