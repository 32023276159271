import React from 'react';
import ReactDOM from 'react-dom';
import ReactDOMServer from 'react-dom/server';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import { removeNotification } from '../../redux/ui/actions';

import Notification from './Notification';


const Notifications = ({
  messages,
  removeNotification,
}) => {

  const notificationRoot = document.getElementById('notificationRoot');

  const buildNotifications = () => {
    return messages.map((message) => (
      <CSSTransition
        key={ReactDOMServer.renderToStaticMarkup(message.content)}
        timeout={400}
        classNames='slideInOutQuick'
      >
        <Notification
          message={message}
          removeNotification={removeNotification}
        />
      </CSSTransition>
    ));
  };

  const buildNotificationContent = () => {
    return (
      <TransitionGroup
        component='div'
        className='CeleritasToastNotificationContainer'
      >
        {buildNotifications()}
      </TransitionGroup>
    );
  };

  return ReactDOM.createPortal(
    buildNotificationContent(),
    notificationRoot,
  );
};

Notifications.propTypes = {
  messages: PropTypes.array,
  removeNotification: PropTypes.func,
};

const mapStateToProps = (state) => ({
  messages: state.ui.messages,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  removeNotification,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
