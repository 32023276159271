import cn from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { ReactComponent as CloseIcon } from '../../images/close.svg';
import I18n from '../../utilities/i18n';

import TaskStatusIcon from './TaskStatusIcon';

const Tag = ({ className, status, ariaLabel, showIcon, isDisabled, onClick }) => {
  return (
    <span
      className={cn([
        className,
        'co-tag',
        `co-tag--${status}`,
        {
          tooltipped: Boolean(ariaLabel),
          'tooltipped--large': Boolean(ariaLabel),
          'co-tag--active': onClick,
          'co-tag--disabled': isDisabled,
        },
      ])}
      data-testid='flyout-status-button'
      aria-label={ariaLabel}
      onClick={onClick}>
      { showIcon && <TaskStatusIcon className='co-tag-icon' status={status} /> }
      {I18n.t(status)}
      { onClick && <CloseIcon className='co-tag-close' />}
    </span>
  );
};

Tag.propTypes = {
  className: PropTypes.string,
  status: PropTypes.oneOf([
    'checked_in',
    'not_checked_in',
    'offline',
    'working',
    'done',
    'need help',
  ]).isRequired,
  ariaLabel: PropTypes.string,
  showIcon: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onClick: PropTypes.func,
};

export default Tag;
