export function isNone(webzone) {
  if (!webzone?.groupGuid) {
    return false;
  }

  const hasSettings = webzone?.settings;

  return !hasSettings;
}

export function isNoBrowsing(webzone) {
  return webzone && webzone.noBrowsing;
}

export function isNoBrowsingOLD(webzone) {
  if (!webzone?.groupGuid || !webzone?.settings) {
    return false;
  }

  const isAllowedSites = !webzone.settings.blocked;
  const sites = webzone.settings.sites ?? [];
  const hasAnySites = sites.length > 0;

  return isAllowedSites && !hasAnySites;
}

export function isCustom(webzone) {
  if (!webzone?.settings || !webzone?.groupGuid) {
    return false;
  }

  const isBlockedSites = Boolean(webzone.settings.blocked);
  const isAllowWhenDone = Boolean(webzone.settings.allowWhenDone);
  const sites = webzone.settings.sites ?? [];
  const hasAnySites = sites.length > 0;

  return isBlockedSites || hasAnySites || isAllowWhenDone;
}

export function getWebRulesSites(settings) {
  return settings?.sites ?? [];
}

export function getWebRulesCustomSites(settings, segment) {
  if (segment) {
    return settings?.custom_urls ?? [];
  }
  return settings?.customUrls ?? [];
}

export function getWebRulesCustomAllowSites(settings, segment) {
  if (segment && settings?.custom_allow_urls) {
    return settings?.custom_allow_urls ?? [];
  }
  return settings?.customAllowUrls ?? [];
}

export function getWebRulesCustomBlockSites(settings, segment) {
  if (segment && settings?.custom_block_urls) {
    return settings?.custom_block_urls ?? [];
  }
  return settings?.customBlockUrls ?? [];
}

export function getWebRulesCheckedListNames(settings, segment) {
  if (segment) {
    return settings?.list_names ?? [];
  }
  return settings?.listNames ?? [];
}

export function getWebRulesType(webzone) {
  if (!webzone) {
    return 'none';
  }
  const settings = webzone.settings || webzone;
  const sites = getWebRulesSites(settings);
  const isBlocked = settings?.blocked ? true : false;
  const isAllowWhenDoneSet = settings?.allowWhenDone ? true : false;
  if (sites.length > 0 || isAllowWhenDoneSet) {
    if (isBlocked) {
      return 'block';
    }
    return 'allow';
  }
  return 'none';
}

export function getAllowWhenDone(webRules) {
  if (!webRules || !webRules.settings) {
    return false;
  }

  return Boolean(webRules.settings.allowWhenDone);
}

export function getAllowSSO(webRules) {
  if (!webRules || !webRules.settings) {
    return false;
  }

  if (webRules.settings.allow_sso) {
    return Boolean(webRules.settings.allow_sso);
  }

  return Boolean(webRules.settings.allowSSO);
}
