import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import {
  filterBellSet,
  isValidBellSet,
  isSameBellTimes,
  alignScheduleToBells,
} from '../../../utilities/schedule';
import I18n from '../../../utilities/i18n';

import BellSchedule from './BellSchedule';
import LoadingButton from '../../common/LoadingButton';


function removePeriod(daysKey, schedule) {
  const { [daysKey]: _, ...withPeriodRemoved } = schedule;
  return withPeriodRemoved;
}

const BellSchedules = ({
  schedule,
  isCurrentType,
  readOnly,
  bellSchedules,
  saveSchedule,
  resetSchedule,
  closeSettings,
}) => {
  const [isValid, setIsValid] = useState(true);
  const [validBellSchedules, setValidBellSchedules] = useState(bellSchedules);
  const [currentClassSchedule, setCurrentClassSchedule] = useState(null);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    setIsSaving(false);
  }, []);

  useEffect(() => {
    setIsValid(isValidBellSet(bellSchedules));
    setValidBellSchedules(filterBellSet(bellSchedules));
  }, [bellSchedules]);

  useEffect(() => {
    setCurrentClassSchedule(alignScheduleToBells(schedule, validBellSchedules));
  }, [schedule, validBellSchedules]);

  const hasSelectedPeriod = (schedule) => {
    return schedule && Object.keys(schedule).length > 0;
  };

  const saveScheduleClickHandler = () => {
    if (isSaving) {
      return;
    }

    setIsSaving(true);
    saveSchedule(currentClassSchedule);
  };

  const togglePeriod = (periodSchedule) => {
    setCurrentClassSchedule((lastSchedule) => {
      const schedule = lastSchedule || {};
      const daysKey = Object.keys(periodSchedule)[0];

      if (isSameBellTimes(periodSchedule[daysKey], schedule[daysKey])) {
        return removePeriod(daysKey, schedule);
      }
      return {
        ...schedule,
        [daysKey]: periodSchedule[daysKey],
      };
    });
  };

  const buildSchedules = () => {
    return validBellSchedules.map((bellSchedule) => {
      return (
        <BellSchedule
          key={bellSchedule.name}
          bellSchedule={bellSchedule}
          schedule={currentClassSchedule}
          togglePeriod={togglePeriod}
          readOnly={readOnly}
        />
      );
    });
  };

  const buildResetButton = () => {
    if (!schedule || !isCurrentType) {
      return null;
    }

    return (
      <button
        data-testid='bellSchedules-reset'
        className='button button--destructive m-right--8 analytics-resetSchedule'
        onClick={resetSchedule}
      >
        {I18n.t('reset_schedule')}
      </button>
    );
  };

  const buildButtons = () => {
    if (readOnly) {
      return null;
    }

    return (
      <div className='l-flex l-flex--hAlignRight m-top--16'>
        <button
          data-testid='bellSchedules-cancel'
          className='button button--secondaryWhite m-right--8'
          onClick={closeSettings}
        >
          {I18n.t('cancel')}
        </button>

        {buildResetButton()}

        <LoadingButton
          testId='bellSchedules-save'
          classes='button button--blue analytics-saveBellSchedule'
          onClick={saveScheduleClickHandler}
          label={I18n.t('save')}
          isDisabled={!hasSelectedPeriod(currentClassSchedule)}
          isLoading={isSaving}
        />
      </div>
    );
  };

  const buildInstructions = () => {
    if (readOnly) {
      return null;
    }

    return (
      <p className='text--gray m-vertical--16'>
        {I18n.t('click_on_a_time_to_select_it')}
      </p>
    );
  };

  const buildInvalidWarning = () => {
    if (isValid) {
      return null;
    }

    const scheduleType = I18n.t('bell_lowercase');
    return (
      <div className='alert alert--warning m-vertical--16'>
        {I18n.t('invalid_schedules_have_been_removed', { scheduleType })}
      </div>
    );
  };

  if (bellSchedules.length === 0 && !readOnly) {
    return (
      <div className='alert m-top-24'>
        {I18n.t('your_administrator_has_not_configured_a_bell_schedule')}
      </div>
    );
  }

  return (
    <Fragment>
      {buildInvalidWarning()}
      {buildInstructions()}

      <section className='co-bellSchedule'>
        {buildSchedules()}
      </section>

      {buildButtons()}
    </Fragment>
  );
};


BellSchedules.propTypes = {
  schedule: PropTypes.object,
  isCurrentType: PropTypes.bool.isRequired,
  readOnly: PropTypes.bool.isRequired,
  bellSchedules: PropTypes.array.isRequired,
  saveSchedule: PropTypes.func.isRequired,
  resetSchedule: PropTypes.func.isRequired,
  closeSettings: PropTypes.func.isRequired,
};

export default BellSchedules;
