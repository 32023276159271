import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

const ErrorMessage = ({ children, hasError }) => {
  const errorClasses = cn({
    'co-errorMessage': true,
    hasError: hasError,
  });

  return <section className={errorClasses}>{children}</section>;
};

ErrorMessage.propTypes = {
  children: PropTypes.any,
  hasError: PropTypes.bool,
};

export default ErrorMessage;
