import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Toggle = ({
  containerClasses,
  className,
  hint,
  label,
  onChange,
  onClick,
  disabled,
  checked,
  small,
  loading,
  id, // this needs to be unique
  testId,
}) => {
  const [isChecked, setChecked] = useState(checked);
  function changeHandler(e) {
    onChange && onChange(e);
  }
  const toggleClasses = classNames('CeleritasToggle', className, {
    'CeleritasToggle--checked': isChecked,
    'CeleritasToggle--disabled': disabled,
    'CeleritasToggle--small': small,
  });
  useEffect(() => {
    setChecked(checked);
  }, [checked]);
  if (loading) {
    return (
      <div
        className={classNames('CeleritasToggle--loading', {
          'CeleritasToggle--loading--small': small,
        })}
      />
    );
  }
  let toggle = (
    <label className={toggleClasses}>
      <input
        type='checkbox'
        className='visually-hidden'
        onChange={(e) => changeHandler(e)}
        onClick={(e) => onClick && onClick(e)}
        disabled={disabled}
        id={id}
        data-testid={testId}
      />
    </label>
  );
  if (hint || label) {
    toggle = (
      <div className={classNames(containerClasses, 'CeleritasToggleContainer')}>
        {toggle}
        <section className='CeleritasInputMeta'>
          {label && (
            <label htmlFor={id} className='CeleritasInputLabel'>
              {label}
            </label>
          )}
          {hint && <p className='CeleritasInputHint'>{hint}</p>}
        </section>
      </div>
    );
  }
  return toggle;
};
Toggle.propTypes = {
  containerClasses: PropTypes.string,
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
  hint: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  small: PropTypes.bool,
  loading: PropTypes.bool,
};
export default Toggle;
