import {
  FETCH_USERS,
  RECEIVE_USERS,
  SET_DISPLAYED_USERS,
  CLEAR_USERS,
  CHECK_IN_USERS,
  SET_ALREADY_CHECKED_IN,
  FETCH_CHECK_INS,
  SET_PENDING_CHECK_OUT_UPDATE,
} from './actions';

export const defaultState = {
  loading: false,
  checkingIn: false,
  alreadyCheckedIn: false,
  all: {},
  displayType: null,
  displayed: null,
  hasPendingCheckOutUpdate: false,
};

export function usersReducer(state = defaultState, action) {
  switch (action.type) {
    case FETCH_USERS:
      return {
        ...state,
        loading: true,
      };
    case RECEIVE_USERS: {
      const all = getUsersByGuid(action.users);
      return {
        ...state,
        all,
        loading: false,
      };
    }
    case FETCH_CHECK_INS:
      return {
        ...state,
        checkingIn: true,
      };
    case CHECK_IN_USERS: {
      const allUsers = updateCheckIns({ ...state.all }, action.guids, action.checkedInto);
      return {
        ...state,
        all: allUsers,
      };
    }
    case SET_DISPLAYED_USERS:
      return {
        ...state,
        displayType: action.displayType,
        displayed: action.users,
      };
    case SET_ALREADY_CHECKED_IN:
      return {
        ...state,
        alreadyCheckedIn: action.value,
      };
    case SET_PENDING_CHECK_OUT_UPDATE:
      return {
        ...state,
        hasPendingCheckOutUpdate: action.value,
      };
    case CLEAR_USERS:
      return defaultState;
    default:
      return state;
  }
}

function getUsersByGuid(users) {
  return users.reduce((all, user) => ({
    ...all,
    [user.guid]: user,
  }), {});
}

function updateCheckIns(allUsers, guids, checkedInto) {
  Object.keys(allUsers).forEach((userKey) => {
    if (guids.includes(allUsers[userKey].guid)) {
      allUsers[userKey].checkedInto = checkedInto;
    }
  });
  return allUsers;
}
