export function dateToFloatTime(date) {
  const minutes = `0${date.getMinutes()}`.slice(-2);
  return parseFloat(`${date.getHours()}.${minutes}`);
}

export function parseFloatTime(floatTime) {
  let hour = 0;
  let minutes = 0;

  const floatTimeStr = typeof floatTime === 'string' ? floatTime : `${floatTime}`;
  const [hourString, minutesString] = floatTimeStr.split('.');
  if (hourString) {
    hour = parseInt(hourString, 10);
  }
  if (minutesString) {
    minutes = parseInt(`${minutesString.padEnd(2, '0')}`.slice(-2), 10);
  }

  return [hour, minutes];
}

export function floatTimeToString(decimalTime) {
  let time;
  if (String(decimalTime).indexOf('.') > -1) {
    time = String(decimalTime).replace(/\./, ':');
  } else {
    time = String(decimalTime) + ':00';
  }
  time = time.split(':')[1].length < 2 ? time + '0' : time;
  return time;
}

export function timeUntilString(date, now = new Date()) {
  const msPerMinute = 1000 * 60;
  const msPerHour = 60 * msPerMinute;
  const time = date.getTime();
  const timeNow = now.getTime();
  const diff = time - timeNow;

  if (diff <= 0) {
    return 'past';
  }
  if (diff < msPerHour) {
    const minutes = Math.ceil(diff / msPerMinute);
    return `${minutes}m`;
  }
  const hours = Math.floor(diff / msPerHour);
  const remainder = diff - (hours * msPerHour);
  const minutes = Math.ceil(remainder / msPerMinute);
  return `${hours}h ${minutes}m`;
}

export function minutesLater(date, minutes) {
  const later = new Date(date.getTime());
  later.setMinutes(later.getMinutes() + minutes);
  return later;
}

export function setMinutesTimeout(func, minutes, delayMs = 0) {
  const now = Date.now();
  const next = new Date(now);
  next.setMinutes(next.getMinutes() + minutes);
  next.setSeconds(0, 0);
  const timeUntil = next.getTime() - now + delayMs;

  const timer = setTimeout(() => {
    func();
    setMinutesTimeout(func, minutes, delayMs);
  }, timeUntil);

  return timer;
}

export function getDisplayTime(epochTime) {
  const date = new Date(epochTime);
  const hour = date.getHours();
  let mins = date.getMinutes().toString();
  if (mins.length === 1) {
    mins = '0' + mins;
  }
  const hourAdjusted = (hour + 11) % 12 + 1;
  const meridiem = hour >= 12 ? 'pm' : 'am';
  return `${hourAdjusted}:${mins} ${meridiem}`;
}

export function getDisplayTimeFromFloat(floatTime) {
  const timeSplit = floatTime.toString().split('.');
  const hour = parseInt(timeSplit[0], 10);
  const meridiem = hour >= 12 ? 'pm' : 'am';
  const hourAdjusted = (hour + 11) % 12 + 1;

  let minutes = '00';
  if (timeSplit.length > 1) {
    minutes = timeSplit[1];
    if (minutes.length === 1) {
      minutes = minutes + '0';
    }
  }
  return `${hourAdjusted}:${minutes}${meridiem}`;
}

export function getDayTime(dayValue, hoursPast = 0, minutesPast = 0) {
  dayValue.setHours(hoursPast);
  dayValue.setMinutes(minutesPast);
  return dayValue.getTime();
}

export function getUniqueVersionString(version) {
  // used to force Rollbar not to group different version Rollbars together
  const digits = {
    0: 'Z',
    1: 'O',
    2: 'T',
    3: 'H',
    4: 'R',
    5: 'F',
    6: 'X',
    7: 'S',
    8: 'E',
    9: 'N',
    '.': '_',
  };
  const chars = version.split('');
  const withWords = chars.map((char) => (digits[char] ? digits[char] : char));
  return withWords.join('');
}

export function isVersionUpdateNeeded(currVer, minVer) {
  if (typeof(currVer) !== 'string' ||
      typeof(minVer) !== 'string' ||
      currVer.length !== minVer.length
  ) {
    //Do not force update if versions are different format
    return false;
  }
  const periodMatch = /\./g;
  const currVerNoPeriods = currVer.replace(periodMatch, '');
  const minVerNoPeriods = minVer.replace(periodMatch, '');

  if (parseInt(currVerNoPeriods, 10) < parseInt(minVerNoPeriods, 10)) {
    return true;
  }
  return false;
}

function convertFloatToTime(number) {
  const numberArr = number.toString().split('.');
  const hour = parseInt(numberArr[0], 10);
  let mins = '0';
  if (numberArr.length > 1) {
    mins = numberArr[1];
    if (mins.length === 1) {
      mins += '0';
    }
  }
  return {
    hour,
    mins: parseInt(mins, 10),
  };
}

export function getScheduleExpirationEpoch(schedule) {
  if (!schedule) {
    return -1;
  }
  const currentTime = new Date();
  const currentEpoch = Date.now();
  const currentDayValue = currentTime.getDay();
  const currentHour = currentTime.getHours();
  const currentMin = currentTime.getMinutes();

  let nextScheduleEnd = null;
  // get class ending for current session
  Object.keys(schedule).forEach((dayValues) => {
    // 0 case only should occur on ending a class
    if (dayValues.split('').includes('0')) {
      nextScheduleEnd = -1;
    }
    if (dayValues.split('').includes(currentDayValue.toString())) {
      const start = convertFloatToTime(schedule[dayValues].s);
      const end = convertFloatToTime(schedule[dayValues].e);

      const isGreaterThanStartingTime = currentHour > start.hour ||
        (currentHour === start.hour && currentMin >= start.mins);
      const isLessThanEndingTime = currentHour < end.hour ||
        (currentHour === end.hour && currentMin < end.mins);

      if (isGreaterThanStartingTime && isLessThanEndingTime) {
        const minDiff = ((end.hour - currentHour) * 60) + (end.mins - currentMin);
        const milisecDiff = minDiff * 1000 * 60;
        nextScheduleEnd = currentEpoch + milisecDiff;
      }
    }
  });
  return nextScheduleEnd;
}

export function getDateAndTimeString(timestamp) {
  const dateObject = new Date(timestamp);
  const dateOptions = { month: '2-digit', day: '2-digit', year: 'numeric' }
  const timeOptions = { hour: '2-digit', minute: '2-digit' };
  const dateAndTime = {
    date: dateObject.toLocaleDateString('en-US', dateOptions),
    time: dateObject.toLocaleTimeString('en-US', timeOptions),
  };
  return dateAndTime;
}
