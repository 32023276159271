import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';

import { closeDropdown } from '../../redux/ui/actions';

import I18n from '../../utilities/i18n';

import Dropdown from './Dropdown';
import Icon from './Icon';


const DayPickerNavBar = ({
  onPreviousClick,
  onNextClick,
  onTodayClick,
}) => {
  return (
    <Fragment>
      <div className='co-dayPicker-prevNext'>
        <button className='button-link co-dayPicker-arrowRight' onClick={(e) => {
          e.stopPropagation();
          e.nativeEvent.stopImmediatePropagation();
          onPreviousClick();
        }}>
          <Icon name='icon-left-2' classes='co-dayPicker-arrowIcon' />
        </button>

        <button className='button-link co-dayPicker-arrowLeft' onClick={(e) => {
          e.stopPropagation();
          e.nativeEvent.stopImmediatePropagation();
          onNextClick();
        }}>
          <Icon name='icon-right-2' classes='co-dayPicker-arrowIcon' />
        </button>
      </div>

      <button className='button-link co-dayPicker-today' onClick={() => onTodayClick()}>
        {I18n.t('today')}
      </button>
    </Fragment>
  );
};

DayPickerNavBar.propTypes = {
  onPreviousClick: PropTypes.func,
  onNextClick: PropTypes.func,
  onTodayClick: PropTypes.func,
};

const DayPickerDropdown = ({
  dayPickerValue,
  onDayClickHandler,
  onTodayClickHandler,
  closeDropdown,
  selectedDays,
  disabledDays,
  modifiers,
  containerClasses,
}) => {
  const dropdownName = 'browsingHistoryDayPicker';
  const WEEKDAYS_SHORT = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
  const viewableTo = moment().subtract(90, 'days').toDate();
  let disabled = {
    after: new Date(),
    before: viewableTo,
  };

  if (disabledDays) {
    disabled = [...disabledDays, disabled];
  }

  const selected = selectedDays ? [dayPickerValue, ...selectedDays] : dayPickerValue;

  const buildButtonContent = () => {
    const today = moment();
    const selectedDate = moment(dayPickerValue);
    let formattedDate = selectedDate.format('MMM DD, YYYY');

    if (today.isSame(selectedDate, 'd')) {
      formattedDate = 'Today';
    }

    return (
      <span className='co-dayPicker-label'>
        <span className='co-dayPicker-labelIcon'>
          <Icon name='icon-calendar' classes='co-dayPicker-calendarIcon' />
        </span>
        {formattedDate}
      </span>
    );
  };

  const dayClickHandler = (day) => {
    closeDropdown(dropdownName);
    onDayClickHandler(day);
  };

  const todayClickHandler = () => {
    closeDropdown(dropdownName);
    onTodayClickHandler && onTodayClickHandler();
  };

  const allModifiers = Object.assign({}, modifiers, { weekend: { daysOfWeek: [0, 6] } });

  return (
    <Dropdown
      initialLabel={buildButtonContent()}
      hasArrow={false}
      buttonLabelClasses='co-dayPicker-button'
      containerClasses='co-dropdown--naturalWidth'
      dropdownMenuClasses='co-dropdown-menu--right'
      showAsButton={true}
      hasTriangleArrow={true}
      dropdownName={dropdownName}
    >
      <DayPicker
        onDayClick={dayClickHandler}
        selectedDays={selected}
        weekdaysShort={WEEKDAYS_SHORT}
        firstDayOfWeek={1}
        navbarElement={<DayPickerNavBar onTodayClick={todayClickHandler} />}
        toMonth={new Date()}
        month={dayPickerValue}
        fromMonth={viewableTo}
        disabledDays={disabled}
        modifiers={allModifiers}
        className={containerClasses}
      />
    </Dropdown>
  );
};

DayPickerDropdown.propTypes = {
  dayPickerValue: PropTypes.object,
  onDayClickHandler: PropTypes.func,
  onTodayClickHandler: PropTypes.func,
  closeDropdown: PropTypes.func,
  selectedDays: PropTypes.array,
  disabledDays: PropTypes.array,
  modifiers: PropTypes.object,
  containerClasses: PropTypes.string,
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
  closeDropdown,
}, dispatch);

export default connect(null, mapDispatchToProps)(DayPickerDropdown);
