import { combineReducers } from 'redux';

import { ablyReducer } from './ably/reducer';
import { betasReducer } from './betas/reducer';
import { broadcastReducer } from './broadcast/reducer';
import { browserReducer } from './browser/reducer';
import { browsingHistoryReducer } from './browsingHistory/reducer';
import { classesReducer } from './classes/reducer';
import { currentUserReducer } from './currentUser/reducer';
import { districtReducer } from './district/reducer';
import { errorsReducer } from './errors/reducer';
import { groupsReducer } from './groups/reducer';
import { groupSegmentsReducer } from './groupSegments/reducer';
import { insightsReducer } from './insights/reducer';
import { invitesReducer } from './invites/reducer';
import { policyReducer } from './policy/reducer';
import { recordingsReducer } from './recordings/reducer';
import { uiReducer } from './ui/reducer';
import { usersReducer } from './users/reducer';
import { webrtcsReducer } from './webrtcs/reducer';
import { webRulesReducer } from './webRules/reducers';
import { webzonesReducer } from './webzones/reducer';

const rootReducer = combineReducers({
  ably: ablyReducer,
  betas: betasReducer,
  broadcast: broadcastReducer,
  browser: browserReducer,
  browsingHistory: browsingHistoryReducer,
  classes: classesReducer,
  currentUser: currentUserReducer,
  district: districtReducer,
  errors: errorsReducer,
  groups: groupsReducer,
  groupSegments: groupSegmentsReducer,
  insights: insightsReducer,
  invites: invitesReducer,
  policy: policyReducer,
  recordings: recordingsReducer,
  ui: uiReducer,
  users: usersReducer,
  webrtcs: webrtcsReducer,
  webRules: webRulesReducer,
  webzones: webzonesReducer,
});

export default rootReducer;
