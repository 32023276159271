import React from 'react';
import PropTypes from 'prop-types';

import I18n from '../../utilities/i18n';

import { ReactComponent as DooStern } from '../../images/dooStern.svg';

const BroadcastError = ({ message }) => {
  return (
    <div className='co-errorContainer'>
      <section className='co-error'>
        <DooStern className='co-error-doo'/>
        <div className='co-error-content' data-testid='broadcastError-content'>
          <p className='co-error-title'>{I18n.t('broadcast_error')}</p>
          <h1 className='co-error-text'>{message}</h1>
        </div>
      </section>
    </div>
  );
};

BroadcastError.propTypes = {
  message: PropTypes.string,
};

export default BroadcastError;
