import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import I18n from '../../../utilities/i18n';

import Icon from '../Icon';
import WebsiteImage from '../WebsiteImage';


const Tab = ({
  isCheckedIn,
  tab,
  focusTab,
  closeTab,
  showSubtleActive,
}) => {
  const tabClasses = classNames({
    'co-flyout-openTab': true,
    'co-flyout-openTab--active': tab.active,
    'co-flyout-openTab--active--subtle': tab.active && showSubtleActive,
  });

  const buildTitle = () => {
    if (!focusTab) {
      return <span className='co-flyout-openTab-name'>{tab.title}</span>;
    }

    return (
      <button
        className='button-link co-flyout-openTab-name'
        onClick={() => focusTab(tab)}
        data-testid='tab-focus'
      >
        {tab.title}
      </button>
    );
  };

  const buildCloseButton = () => {
    if (!closeTab || !isCheckedIn) {
      return null;
    }

    return (
      <button
        data-testid='tab-close'
        className='button-link co-flyout-openTab-x'
        onClick={() => closeTab(tab)}
      >
        <span
          className='tooltipped tooltipped--left'
          aria-label={I18n.t('close_this_tab')}
        >
          <Icon name='icon-x-solid' classes='co-flyout-openTab-xIcon' />
        </span>
      </button>
    );
  };

  return (
    <li className={tabClasses}>
      <div className='co-flyout-openTabs-favicon'>
        <WebsiteImage faviconUrl={tab.url} />
      </div>

      <section className='co-flyout-openTab-content'>
        <strong className='truncate'>{buildTitle()}</strong>

        <a href={tab.url} className='co-flyout-openTab-link truncate' target='_blank' rel='noopener noreferrer'>
          {tab.url}
        </a>
      </section>
      {buildCloseButton()}
    </li>
  );
};

Tab.propTypes = {
  tab: PropTypes.shape({
    active: PropTypes.bool,
    title: PropTypes.string,
    url: PropTypes.string,
    id: PropTypes.number,
    favico: PropTypes.string,
  }).isRequired,
  focusTab: PropTypes.func,
  closeTab: PropTypes.func,
  showSubtleActive: PropTypes.bool,
};

export default Tab;
