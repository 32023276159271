import { initAblyWithAuth, shutdownAbly } from '../ably/actions';
import { updateEvents } from '../classes/actions';
import { resetDistrict, getDistrict } from '../district/actions';
import { addError } from '../errors/actions';
import { clearGroups, getGroups } from '../groups/actions';
import { clearSegments } from '../groupSegments/actions';
import { getPolicy, clearPolicies } from '../policy/actions';
import { clearUsers } from '../users/actions';
import { clearWebzone } from '../webzones/actions';

import { getUniqueVersionString } from '../../utilities/time';
import { isInHomeDistrict } from '../../utilities/users';

import { initAnalytics, shutdownAnalytics } from '../../helpers/analytics';
import { ClassroomApi } from '../../helpers/classroomApi';
import { getCognitoCookieValue } from '../../helpers/cookies';
import { RelayWebSocket } from '../../helpers/relayWebSocket';
import { initRollbar, shutdownRollbar } from '../../helpers/rollbar';
import { addErrorNotification } from '../ui/actions';
import I18n from '../../utilities/i18n';

export const ATTEMPT_LOGIN = 'ATTEMPT_LOGIN';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const RECEIVE_CURRENT_USER = 'RECEIVE_CURRENT_USER';
export const CLEAR_CURRENT_USER = 'CLEAR_CURRENT_USER';
export const CURRENT_USER_DATA_LOADED = 'CURRENT_USER_DATA_LOADED';
export const SET_REDIRECT_URL = 'SET_REDIRECT_URL';
export const SET_CHECK_IN_NOTIFICATION_DISABLED = 'SET_CHECK_IN_NOTIFICATION_DISABLED';
export const SET_BULK_CHECK_IN_NOTIFICATION_DISABLED = 'SET_BULK_CHECK_IN_NOTIFICATION_DISABLED';

export function loginWithCognitoAccessToken(isInTeams) {
  return async function(dispatch) {
    dispatch(attemptLogin());

    const params = {
      cognitoUserInfoToken: getCognitoCookieValue('cognito_user_info'),
    };

    const response = await ClassroomApi.post('/auth', params);

    if (response?.status !== 200) {
      dispatch(loginError(response?.data?.message || response?.data));
      return dispatch(addError('loginWithCognitoAccessToken', response));
    }

    return dispatch(loginUser(response, 'loginWithCognitoAccessToken', isInTeams));
  };
}

export function loginWithJwt(isInTeams) {
  return async function(dispatch) {
    dispatch(attemptLogin());

    const response = await ClassroomApi.get('/auth/jwt_check');

    if (response?.status !== 200) {
      dispatch(logoutUser());
      return dispatch(addError('loginWithJwt', response));
    }

    return dispatch(loginUser(response, 'jwt', isInTeams));
  };
}

export function loginWithSso(type, code, isInTeams) {
  return async function(dispatch) {
    dispatch(attemptLogin());

    const url = (type === 'azure') ? '/auth/azure_callback' : '/auth/google_callback';
    const params = {
      code,
      redirect_url: process.env.REACT_APP_CLASSROOM_URL,
    };
    const response = await ClassroomApi.post(url, params);

    if (response?.status !== 200) {
      dispatch(loginError(response?.data));
      return dispatch(addError('loginWithSso', response));
    }

    return dispatch(loginUser(response, `${type}_oauth`, isInTeams));
  };
}

export function loginWithLightspeed(encodedCustomerId, token, isInTeams) {
  return async function(dispatch) {
    dispatch(attemptLogin());

    const params = {
      token,
      c: encodedCustomerId,
    };
    const response = await ClassroomApi.post('/auth/lightspeed_callback', params);

    if (response?.status !== 200) {
      dispatch(loginError(response?.data));
      return dispatch(addError('loginWithLightspeed', response));
    }

    dispatch(loginUser(response, 'lightspeed_oauth', isInTeams));
  };
}

function loginUser(response, type, isInTeams) {
  return function(dispatch) {
    const user = response.data;

    localStorage.jwt = user.jwt;
    window.relayWebSocket = new RelayWebSocket();

    initAnalytics(user, isInTeams);
    initRollbar(user);

    reportLogin(user, type);
    dispatch(receiveCurrentUser(user));
    return dispatch(getCurrentUserData());
  };
}

export function logoutUser() {
  return function(dispatch) {
    localStorage.ClassroomAutoLogin = 'false';
    delete localStorage.jwt;

    window.relayWebSocket && window.relayWebSocket.disconnectFromWebSocket();
    delete window.relayWebSocket;

    shutdownAnalytics();
    shutdownRollbar();

    dispatch(clearCurrentUser());
    dispatch(clearCurrentUserData());

    window.location.href = `${process.env.REACT_APP_RELAY_LOGIN_URL}${process.env.REACT_APP_RELAY_SIGN_OUT_PATH}`;
  };
}

export function disableCheckInNotification(guid, type) {
  return async function(dispatch) {
    // only option available is to disable
    const params = {
      /*
      current available settings types:
      check_in_notification_disabled
      bulk_check_in_notification_disabled
      */
      [type]: true,
    };
    const response = await ClassroomApi.post(`/users/${guid}/teacher`, params);
    if (response?.status !== 200) {
      dispatch(addErrorNotification(I18n.t('network_error_please_try_again')));
      return dispatch(addError('set do not show', response));
    }

    if (type === 'check_in_notification_disabled') {
      return dispatch(setCheckInNotificationDisabled());
    }

    return dispatch(setBulkCheckInNotificationDisabled());
  };
}

function reportLogin(user, type) {
  const version = window.classroomUIVersion;

  if (version !== localStorage.lastUIVersion) {
    localStorage.lastUIVersion = version;

    // This rollbar lets us track version adoption
    const message = `login: ${version} (${getUniqueVersionString(version)})`;
    window.Rollbar.info(message, {
      userGuid: user.guid,
      loginType: type,
    });
  }
}

function getCurrentUserData() {
  return async function(dispatch, getState) {
    let state = getState();
    const authCustomerId = state.currentUser.auth_customer_id;
    const schoolGuid = state.currentUser.school_guid;
    const email = state.currentUser.email;

    if (isInHomeDistrict(state.currentUser)) {
      dispatch(getPolicy(schoolGuid, 'school'));
    }
    dispatch(getDistrict());
    await dispatch(initAblyWithAuth(authCustomerId, email));
    await dispatch(getGroups());
    state = getState();
    dispatch(updateEvents(state.groups.groups));
    dispatch(setCurrentUserDataLoaded());
  };
}

function clearCurrentUserData() {
  return function(dispatch) {
    dispatch(resetDistrict());
    dispatch(clearUsers());
    dispatch(clearPolicies());
    dispatch(clearGroups());
    dispatch(clearSegments());
    dispatch(clearWebzone());
    dispatch(shutdownAbly());
  };
}

export function attemptLogin() {
  return {
    type: ATTEMPT_LOGIN,
  };
}

export function loginError(error) {
  return {
    type: LOGIN_ERROR,
    error,
  };
}

export function receiveCurrentUser(user) {
  return {
    type: RECEIVE_CURRENT_USER,
    user,
  };
}

export function clearCurrentUser() {
  return {
    type: CLEAR_CURRENT_USER,
  };
}

export function setCurrentUserDataLoaded() {
  return {
    type: CURRENT_USER_DATA_LOADED,
  };
}

export function setRedirectUrl(url) {
  return {
    type: SET_REDIRECT_URL,
    url: url,
  };
}

export function setCheckInNotificationDisabled() {
  return {
    type: SET_CHECK_IN_NOTIFICATION_DISABLED,
  };
}

export function setBulkCheckInNotificationDisabled() {
  return {
    type: SET_BULK_CHECK_IN_NOTIFICATION_DISABLED,
  };
}
