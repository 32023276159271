import { useState, useEffect, useRef } from 'react';

// Replacement for useState that stores the value in local storage
export function useLocalStorage(key, initialValue) {
  const [value, setValue] = useState(() => {
    const stored = window.localStorage.getItem(key);
    if (stored) {
      try {
        return JSON.parse(stored);
      } catch (err) {
        console.error('localStorage error:', key, stored, err); // eslint-disable-line no-console
        return initialValue;
      }
    }
    window.localStorage.setItem(key, JSON.stringify(initialValue));
    return initialValue;
  });

  useEffect(() => {
    window.localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return [value, setValue];
}

// From https://overreacted.io/making-setinterval-declarative-with-react-hooks/
export function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Update to latest callback
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Restart the interval when the delay changes
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}
