import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';

import App from './components/App';
import Errors from './components/common/Errors';
import GroupPageContainer from './components/Groups/GroupPageContainer';
import SignIn from './components/SignIn/SignIn';
import JoinGroup from './components/JoinGroup/JoinGroup';
import WatchBroadcast from './components/WatchBroadcast/WatchBroadcast';
import TeamsConfig from './components/SignIn/TeamsConfig';
import TeamsAuth from './components/SignIn/TeamsAuth';
import StudentView from './components/Student/StudentView';
import GettingStarted from './components/GettingStarted/GettingStarted';
import RosterManagement from './components/RosterManagement/RosterManagement';
import IconList from './components/common/IconList';

const Root = ({ store }) => (
  <Provider store={store}>
    <Fragment>
      <BrowserRouter>
        <Switch>
          <Route path='/groups/:groupGuid' render={() => <App><GroupPageContainer /></App>} />
          <Route exact path='/my-classes' render={() => <App noLayout={true}><RosterManagement /></App>} />
          <Route exact path='/join-group' component={JoinGroup} />
          <Route exact path='/join-group/:code' component={JoinGroup} />
          <Route exact path='/watch-broadcast/:groupGuid' component={WatchBroadcast} />
          <Route exact path='/' component={SignIn} />
          <Route exact path='/teams' component={TeamsConfig} />
          <Route exact path='/teams-student' component={StudentView} />
          <Route exact path='/teams-auth' component={TeamsAuth} />
          <Route exact path='/getting-started' render={() => <App noLayout={true}><GettingStarted /></App>} />
          {process.env.REACT_APP_ENV === 'development' && (
            <Route exact path='/icons' component={IconList} />
          )}
          <Redirect to='/'/>
        </Switch>
      </BrowserRouter>
      <Errors />
    </Fragment>
  </Provider>
);

Root.propTypes = {
  store: PropTypes.object.isRequired,
};

export default Root;
