import React, { useReducer, useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';

import I18n from '../../..//utilities/i18n';
import {
  calculateDuration,
  convertArrayToSchedule,
  convertScheduleToArray,
  expandScheduleObject,
  isDayDisabled,
  isScheduleTimeAllowed,
} from '../../../utilities/schedule';
import { floatTimeToString, getDisplayTimeFromFloat } from '../../../utilities/time';
import { isRealNumber } from '../../../utilities/types';

import Icon from '../../common/Icon';
import TimeInput from '../../common/TimeInput';
import LoadingButton from '../../common/LoadingButton';

function scheduleReducer(state, action) {
  switch (action.type) {
    case 'reassign':
      return action.newState;
    case 'changeTime':
      return [
        ...state.slice(0, action.rowIndex),
        action.newRowWithTimeChange,
        ...state.slice(action.rowIndex + 1, state.length),
      ];
    case 'addRow':
      return [
        ...state,
        { days: '', times: { s: '', e: '' } },
      ];
    case 'deleteRow': {
      const newStateWithDeletion = [...state];
      newStateWithDeletion.splice(action.rowIndex, 1);
      return newStateWithDeletion;
    }
    case 'clickDay': {
      const newState = [...state];
      newState.forEach((rowInfo, idx) => {
        const foundDay = rowInfo.days.includes(action.day);
        if (foundDay) {
          rowInfo.days = rowInfo.days.replace(action.day, '');
        } else if (!foundDay && action.rowIndex === idx) {
          rowInfo.days += action.day;
        }
      });
      return newState;
    }
    default:
      throw new Error('ScheduleReducer missing action type');
  }
}

function errorReducer(state, action) {
  switch (action.type) {
    case 'reassign':
      return action.errors;
    case 'removeRowDisplay': {
      const newState = state.map((error) => {
        if (error.rowIndex === action.rowIndex) {
          error.showErrorOnInput = false;
        }
        return error;
      });
      return newState;
    }
    default:
      throw new Error('ErrorReducer missing action type');
  }
}

function getDistinctDaysFromString (daysString) {
  //converts daysString '403' => array of days ['th', 'su', 'we']
  const allDaysDbFormat = ['su', 'mo', 'tu', 'we', 'th', 'fr', 'sa'];
  return daysString.split('').map((day) => {
    const dayIdx = parseInt(day, 10);
    return allDaysDbFormat[dayIdx];
  });
}

function getTimeForInput (floatTime) {
  //coverts floatTime 2.1 => string time '02:10'
  if (floatTime === '') {
    return undefined;
  }
  let time = floatTimeToString(floatTime);
  if (time.split(':')[0].length === 1) {
    time = '0' + time;
  }
  return time;
}

function validateSchedule(schedule, maxDuration, allowedHours) {
  //Checks for errors in schedule array
  const errors = [];
  const scheduleRowMap = convertArrayToSchedule(schedule);

  schedule.forEach((row, idx) => {
    const start = row.times.s;
    const end = row.times.e;
    const duration = calculateDuration(start, end);
    const rowMap = { [row.days]: scheduleRowMap[row.days] };

    //first check if row is disabled
    if (row.days === '') {
      return;
    }
    // Ensure start time is after end time
    if (isRealNumber(start) && isRealNumber(end) && start >= end) {
      errors.push({
        rowIndex: idx + 1,
        message: I18n.t('end_time_must_come_after_start_time'),
        showErrorOnInput: true,
      });
    }

    // Check that row times dont exceed the max duration
    if (isRealNumber(start) && isRealNumber(end) && duration > maxDuration) {
      errors.push({
        rowIndex: idx + 1,
        message: I18n.t('please_adjust_the_period_length', { maxDuration }),
        showErrorOnInput: true,
      });
    }

    // Check for empty time inputs
    if (!isRealNumber(start) || !isRealNumber(end) || start === '' || end === '') {
      errors.push({
        rowIndex: idx + 1,
        message: I18n.t('must_be_a_valid_time_format', { maxDuration }),
        showErrorOnInput: true,
      });
    }

    // Ensure times are within allowed times
    if (!isScheduleTimeAllowed(rowMap, allowedHours, 'custom')) {
      errors.push({
        rowIndex: idx + 1,
        message: I18n.t('must_be_within_allowed_schedule'),
        showErrorOnInput: true,
      });
    }
  });
  return errors;
}


const CustomSchedule = ({
  allowedHours,
  schedule,
  isCurrentType,
  readOnly,
  maxDuration,
  saveSchedule,
  resetSchedule,
  closeSettings,
  browser,
}) => {
  const daysDbFormat = ['su', 'mo', 'tu', 'we', 'th', 'fr', 'sa'];
  const dayLabels = {
    1: 'monday',
    2: 'tuesday',
    3: 'wednesday',
    4: 'thursday',
    5: 'friday',
    6: 'saturday',
    0: 'sunday',
  };
  const [scheduleArray, dispatch] = useReducer(scheduleReducer, convertScheduleToArray(schedule));
  const [errors, dispatchErrors] = useReducer(errorReducer, []);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    dispatch({ type: 'reassign', newState: convertScheduleToArray(schedule) });
  }, [schedule]);

  function dayClickHandler (day, rowIndex) {
    const dayIndex = daysDbFormat.indexOf(day);
    dispatch({ type: 'clickDay', day: dayIndex, rowIndex });
  }

  function addNewRowClickHandler() {
    dispatch({ type: 'addRow' });
  }

  function deleteRowClickHandler(rowIndex) {
    dispatch({ type: 'deleteRow', rowIndex });
  }

  function timeChangeHandler(time, rowIndex, inputTypeKey) {
    const timeFormatted = parseFloat(time.replace(/:/, '.'));
    const newRowWithTimeChange = scheduleArray[rowIndex];
    // inputTypeKey equals 's' or 'e', start or end time
    newRowWithTimeChange.times[inputTypeKey] = timeFormatted;
    dispatch({ type: 'changeTime', newRowWithTimeChange, rowIndex });
    dispatchErrors({ type: 'removeRowDisplay', rowIndex: rowIndex + 1 });
  }

  function saveScheduleClickHandler() {
    setIsSaving(true);
    const errors = validateSchedule(scheduleArray, maxDuration, allowedHours);
    dispatchErrors({ type: 'reassign', errors });
    if (errors.length === 0) {
      const schedule = convertArrayToSchedule(scheduleArray);
      saveSchedule(schedule);
    } else {
      setIsSaving(false);
    }
  }

  function buildAllowedTimesMessage() {
    const maxDurationMessage = I18n.t('maximum_period_length', { maxDuration: maxDuration });
    if (!allowedHours || Object.keys(allowedHours).length === 0) {
      return (<p className='text--gray m-vertical--8'>
        <span>
          {maxDurationMessage}
        </span>
      </p>);
    }

    let timesDisplay = '';
    const expandedAllowedHours = expandScheduleObject(allowedHours);

    const dayValues = [1, 2, 3, 4, 5, 6, 0];
    dayValues.forEach((dayValue) => {
      if (dayValue in expandedAllowedHours && expandedAllowedHours[dayValue] !== null) {
        const timeValues = expandedAllowedHours[dayValue];
        const startTime = getDisplayTimeFromFloat(timeValues.s);
        const endTime = getDisplayTimeFromFloat(timeValues.e);
        if (timesDisplay !== '') {
          timesDisplay += ', ';
        }
        timesDisplay += I18n.t(`${dayLabels[dayValue]}_times`, { startTime, endTime });
      }
    });

    let message = I18n.t('your_administrator_has_restricted_hours', { times: timesDisplay }) + ', ' +
      maxDurationMessage.toLowerCase();
    if (timesDisplay === '') {
      message = I18n.t('your_administrator_disabled_classroom_schedules');
    }

    return (
      <p className='text--gray m-vertical--8'>
        <span>
          {message}
        </span>
      </p>
    );
  }

  function buildScheduleArea() {
    const isSmall = browser.is.s;
    const isSmallMedium = browser.is.smed;
    const isGreaterThanSmall = browser.greaterThan.s;
    let startEndLabels;

    if (isSmall) {
      startEndLabels = (
        <span className='co-schedule-timeLabels'>{`${I18n.t('start')}/${I18n.t('end')}`}</span>
      );
    } else if (isGreaterThanSmall && !isSmallMedium) {
      startEndLabels = (
        <Fragment>
          <span className='co-schedule-timeLabels m-right--8'>{I18n.t('start')}</span>
          <span className='co-schedule-timeLabels'>{I18n.t('end')}</span>
        </Fragment>
      );
    }


    return (
      <section className='m-bottom--24'>
        {buildAllowedTimesMessage()}

        <div className='co-schedule-content'>
          <div className='co-schedule-labels'>
            <span className='co-schedule-days'>{I18n.t('days')}</span>
            {startEndLabels}
          </div>

          <ul>
            {buildScheduleRows()}
          </ul>

          {!readOnly ? buildAddNewRowButton() : null}
        </div>
      </section>
    );
  }

  function buildScheduleRows() {
    return scheduleArray.map((rowInfo, i) => {
      return (
        // eslint-disable-next-line react/no-array-index-key
        <li key={i} data-testid='customSchedule-rows' className='co-schedule-row'>
          <ul data-testid={`customSchedule-row${i + 1}`} className='co-schedule-days'>
            {buildDays(rowInfo, i)}
          </ul>
          {buildTimeInputs(rowInfo, i)}
          {buildRemoveRow(i)}
        </li>
      );
    });
  }

  function buildDays (rowInfo, rowIndex) {
    const allDays = ['mo', 'tu', 'we', 'th', 'fr', 'sa', 'su'];
    const scheduleIndices = [1, 2, 3, 4, 5, 6, 0];
    const distinctDays = getDistinctDaysFromString(rowInfo.days);

    return allDays.map((day, index) => {
      const isActive = Boolean(distinctDays.find((d) => d === day));
      const isWeekend = Boolean(day === 'sa' || day === 'su');
      const isDisabled = isDayDisabled(allowedHours, scheduleIndices[index]);
      const dayClasses = classNames({
        'button-link': true,
        'co-schedule-day': true,
        'co-schedule-day--active': isActive,
        'co-schedule-day--weekend': (isWeekend || isDisabled) && !isActive && !readOnly,
        'co-schedule-day--disabled': readOnly || isDisabled,
        'tooltipped tooltipped--large--noCase': isDisabled,
      });

      if (isDisabled) {
        return (
          <li key={day}>
            <button
              data-testid={`customSchedule-row${rowIndex + 1}-${day}`}
              className={dayClasses}
              aria-label={I18n.t('this_day_has_been_disabled')}
            >
              {day}
            </button>
          </li>
        );
      }

      return (
        <li key={day}>
          <button
            data-testid={`customSchedule-row${rowIndex + 1}-${day}`}
            className={dayClasses}
            onClick={() => dayClickHandler(day, rowIndex)}
          >
            {day}
          </button>
        </li>
      );
    });
  }

  function buildTimeInputs (rowInfo, rowIndex) {
    const isDisabled = Boolean(rowInfo.days === '');
    const showLabels = browser.is.xs || browser.is.smed;
    const rowErrors = errors.some((error) => (error.rowIndex === rowIndex + 1) && error.showErrorOnInput);
    const endClass = classNames({
      'co-schedule-timeError': rowErrors && !isDisabled,
    });
    const startClass = classNames({
      'm-right--8': true,
      'co-schedule-timeError': rowErrors && !isDisabled,
    });

    let currentStartTime = '';
    let currentEndTime = '';

    if (rowInfo && rowInfo.times) {
      currentStartTime = getTimeForInput(rowInfo.times.s);
      currentEndTime = getTimeForInput(rowInfo.times.e);
    }

    return (
      <div className='co-schedule-times' data-testid='customSchedule-time-inputs'>
        {showLabels && <span className='co-schedule-timeLabels m-right--8'>{I18n.t('start')}</span>}
        <TimeInput
          classes={startClass}
          initialValue={currentStartTime}
          onChange={(time) => timeChangeHandler(time, rowIndex, 's')}
          disabled={isDisabled}
        />

        {showLabels && <span className='co-schedule-timeLabels'>{I18n.t('end')}</span>}
        <TimeInput
          classes={endClass}
          initialValue={currentEndTime}
          onChange={(time) => timeChangeHandler(time, rowIndex, 'e')}
          disabled={isDisabled}
        />
      </div>
    );
  }

  function buildRemoveRow (rowIndex) {
    if (readOnly) {
      return <div className='co-schedule-x'></div>;
    }

    return (
      <button
        data-testid='customSchedule-row-remove'
        className='button-link co-schedule-x'
        onClick={deleteRowClickHandler.bind(null, rowIndex)}
      >
        <Icon name='icon-x-solid' />
      </button>
    );
  }

  function buildAddNewRowButton() {
    return (
      <button
        data-testid='customSchedule-row-add'
        className='button-link co-schedule-addRow'
        onClick={addNewRowClickHandler}
      >
        +
      </button>
    );
  }

  function buildResetButton () {
    if (!schedule || !isCurrentType) {
      return null;
    }

    return (
      <button
        data-testid='customSchedule-reset'
        onClick={resetSchedule}
        className='button button--destructive m-right--8 analytics-resetSchedule'
      >
        {I18n.t('reset_schedule')}
      </button>
    );
  }

  function buildButtons() {
    if (readOnly) {
      return null;
    }

    const hasValidEntries = scheduleArray.some((entry) => entry.days !== '');
    return (
      <div className='l-flex l-flex--hAlignRight m-top--16'>
        <button
          data-testid='customSchedule-cancel'
          className='button button--secondaryWhite m-right--8'
          onClick={closeSettings}
        >
          {I18n.t('cancel')}
        </button>

        {buildResetButton()}

        <LoadingButton
          classes='button button--blue analytics-saveCustomSchedule'
          onClick={() => saveScheduleClickHandler()}
          label={I18n.t('save')}
          isDisabled={!hasValidEntries}
          isLoading={isSaving}
        />
      </div>
    );
  }

  function buildErrors() {
    if (errors.length === 0) {
      return null;
    }

    const errorMessages = errors.map((error, idx) => {
      return (
        // eslint-disable-next-line react/no-array-index-key
        <li key={idx}>
          <span data-testid={`customSchedule-error-message${idx + 1}`}>
            <strong>{I18n.t('row')} {error.rowIndex}:</strong> {error.message}
          </span>
        </li>
      );
    });

    return (
      <div className='alert alert--error m-vertical--8'>
        {I18n.t('to_continue_the_following_issues_must_be_fixed')}

        <ul className='alert--error-errorList p-top--4'>
          {errorMessages}
        </ul>
      </div>
    );
  }

  return (
    <Fragment>
      {buildErrors()}
      {buildScheduleArea()}
      {buildButtons()}
    </Fragment>
  );
};

CustomSchedule.propTypes = {
  allowedHours: PropTypes.object,
  schedule: PropTypes.object,
  isCurrentType: PropTypes.bool.isRequired,
  readOnly: PropTypes.bool.isRequired,
  maxDuration: PropTypes.number.isRequired,
  saveSchedule: PropTypes.func.isRequired,
  resetSchedule: PropTypes.func.isRequired,
  closeSettings: PropTypes.func.isRequired,
  browser: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  browser: state.browser,
});

export default connect(mapStateToProps, null)(CustomSchedule);
