import cn from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import I18n from '../../utilities/i18n';
import Tooltip from '../common/Tooltip/Tooltip';

const Badge = ({ status, count }) => {
  return (
    <Tooltip 
      content={I18n.t(status)} 
      aria-label={I18n.t(status)} 
      position='top'
      size='small'
    >
      <span className={cn(['co-badge', `co-badge--${status}`])}>
        {count}
      </span>
    </Tooltip>
  );
};

Badge.propTypes = {
  status: PropTypes.oneOf([
    'checked_in',
    'not_checked_in',
    'offline',
    'working',
    'need_help',
    'done',
  ]).isRequired,
  count: PropTypes.number.isRequired,
};

export default Badge;
