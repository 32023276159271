import { addError } from '../errors/actions';

import { ClassroomApi } from '../../helpers/classroomApi';
import {
  combineUserData,
  getGuidsToCheckIn,
  getEmailsFromGuids,
  getNameWithPossession,
} from '../../utilities/users';
import { getScheduleExpirationEpoch } from '../../utilities/time';
import { updatePolicy } from '../ably/actions';
import { getCurrentGroup } from '../../utilities/groups';
import { addErrorNotification, addNotification } from '../ui/actions';
import I18n from '../../utilities/i18n';

export const FETCH_USERS = 'FETCH_USERS';
export const RECEIVE_USERS = 'RECEIVE_USERS';
export const SET_DISPLAYED_USERS = 'SET_DISPLAYED_USERS';
export const CLEAR_USERS = 'CLEAR_USERS';
export const CHECK_IN_USERS = 'CHECK_IN_USERS';
export const SET_ALREADY_CHECKED_IN = 'SET_ALREADY_CHECKED_IN';
export const SET_PENDING_CHECK_OUT_UPDATE = 'SET_PENDING_CHECK_OUT_UPDATE';
export const FETCH_CHECK_INS = 'FETCH_CHECK_INS';

export function getUsers(groupGuid) {
  return async function(dispatch, getState) {
    const state = getState();
    dispatch({ type: FETCH_USERS });

    const response = await ClassroomApi.get(`/groups/${groupGuid}/members`);

    if (response?.status !== 200) {
      return dispatch(addError('getUsers', response));
    }

    const users = response.data.map((user) => {
      const userValuesFromState = state.users.all[user.guid];
      // if a checkedInto.guid exists, then we want to retain that value
      // this is import for persisting out data between other pages in our application
      if (userValuesFromState?.checkedInto?.guid) {
        user.checkedInto = userValuesFromState.checkedInto;
      } else {
        // otherwise, we add default field values because user.checkedInto hasn't been added yet
        user.checkedInto = {};
      }
      return user;
    });
    return dispatch({ type: RECEIVE_USERS, users: users });
  };
}

export function getClassroomUsers() {
  return async function(dispatch, getState) {
    // get user info from Classroom megatable
    dispatch({ type: FETCH_CHECK_INS });
    const state = getState();
    const users = state.users.all;
    const userGuids = Object.keys(users);

    const response = await ClassroomApi.post('/users/classroom', { guids: userGuids });
    if (response?.status !== 200) {
      dispatch(addErrorNotification(I18n.t('network_error_please_try_again')));
      return dispatch(addError('getClassroomUsers', response));
    }

    const classroomUsers = response.data;
    const combinedUsers = combineUserData(users, classroomUsers);
    dispatch({ type: RECEIVE_USERS, users: combinedUsers });

    return combinedUsers;
  };
}

function generateParams(group, guids, currentUser) {
  // generate params for API call
  const teacherName = currentUser.first_name + ' ' + currentUser.last_name;
  const expiration = getScheduleExpirationEpoch(group.class_schedule);
  const params = {
    group_guid: group.guid,
    group_name: group.name,
    teacher_name: teacherName,
    user_guids: guids,
    expiration: expiration,
  };
  return params;
}

export function checkInStudent(group, userGuid) {
  return async function(dispatch, getState) {
    const state = getState();
    const params = generateParams(group, [userGuid], state.currentUser);
    const response = await ClassroomApi.post('/users/check_in', params);
    const errors = response.status !== 200 ? response.data.errors : null;
    if (errors) {
      dispatch(addErrorNotification(I18n.t('network_error_please_try_again')));
      return dispatch(addError('Check in student failed', response));
    }

    const studentEmail = state.users.all[userGuid].email;
    const studentFullName = `${state.users.all[userGuid].first_name} ${state.users.all[userGuid].last_name}`;

    dispatch(updatePolicy(studentEmail));

    dispatch(addNotification(I18n.t('student_is_now_checked_into', { studentName: studentFullName }),
      { autoDismiss: true, notificationType: 'success' }));

    dispatch({
      type: CHECK_IN_USERS,
      guids: [userGuid],
      checkedInto: {
        guid: params.group_guid,
        name: params.name,
        teacher_name: params.teacher_name,
        expiration: params.expiration,
      },
    });

    return dispatch(setPendingCheckOutUpdate(false));
  };
}

export function bulkCheckInStudents(group, studentGuids) {
  return async function(dispatch, getState) {
    const state = getState();
    const params = generateParams(group, studentGuids, state.currentUser);
    const response = await ClassroomApi.post('/users/check_in', params);

    if (response?.status === 200) {
      const users = state.users;
      const emailsCheckedIn = getEmailsFromGuids(users.all, studentGuids);
      emailsCheckedIn.forEach((email) => {
        dispatch(updatePolicy(email));
      });

      const successMessage =
        studentGuids.length === 1
          ? I18n.t('one_student_is_now_checked_into_your_class')
          : I18n.t('number_students_are_now_checked_into_your_class', {
            numberOfStudents: studentGuids.length,
          });
      dispatch(addNotification(successMessage, {
        autoDismiss: true,
        notificationType: 'success',
      }));

      return dispatch({
        type: CHECK_IN_USERS,
        guids: studentGuids,
        checkedInto: {
          guid: params.group_guid,
          name: params.name,
          teacher_name: params.teacher_name,
          expiration: params.expiration,
        },
      });
    }

    dispatch(addErrorNotification(I18n.t('network_error_please_try_again')));
    return dispatch(addError('Check in student failed', response));
  };
}

export function checkInClass(group) {
  return async function(dispatch, getState) {
    const state = getState();
    const currentUser = state.currentUser;
    const users = state.users;
    const teacherName = currentUser.first_name + ' ' + currentUser.last_name;
    const newExpiration = getScheduleExpirationEpoch(group.class_schedule);

    if (newExpiration === null || newExpiration === undefined) {
      return;
    }

    // only try to check in once every 2 seconds to avoid duplicates
    if (users.alreadyCheckedIn) {
      return;
    }
    dispatch({ type: SET_ALREADY_CHECKED_IN, value: true });
    setTimeout(() => {
      dispatch({ type: SET_ALREADY_CHECKED_IN, value: false });
    }, 2000);

    // update checked in statuses before automatic check in
    const students = await dispatch(getClassroomUsers());
    const guidsToCheckIn = getGuidsToCheckIn(students, group.guid, newExpiration);

    const params = generateParams(group, guidsToCheckIn, state.currentUser);

    const response = await ClassroomApi.post('/users/check_in', params);
    const errors = response.status !== 200 ? response.data.errors : null;

    if (errors) {
      dispatch(addErrorNotification(I18n.t('network_error_please_try_again')));
      return dispatch(addError('Check in student failed', response));
    }

    const guidsCheckedIn = response.data.map((updated) => updated.user_guid);
    const emailsCheckedIn = getEmailsFromGuids(users.all, guidsCheckedIn);
    emailsCheckedIn.forEach((email) => {
      dispatch(updatePolicy(email));
    });

    dispatch({
      type: CHECK_IN_USERS,
      guids: guidsCheckedIn,
      checkedInto: {
        guid: group.guid,
        name: group.name,
        teacher_name: teacherName,
        expiration: newExpiration,
      },
    });

    return dispatch(setPendingCheckOutUpdate(false));
  };
}

export function handleCheckInChange(groupUpdateMessage, studentGuid) {
  return async function(dispatch, getState) {
    const state = getState();
    const currentGroup = getCurrentGroup(state.groups);
    const currentUpdate = groupUpdateMessage.data;
    if (currentGroup.guid in currentUpdate || currentGroup.id in currentUpdate) {
      return;
    }

    const users = state.users.all;
    const student = users[studentGuid];
    if (!student) {
      return;
    }
    const notCheckedIn = currentGroup.guid !== student.checkedInto.guid;
    if (notCheckedIn) {
      return;
    }
    // was checked in, recieved group update from another group
    // need to fetch the user again and see if checked out
    const response = await ClassroomApi.post('/users/classroom', { guids: [studentGuid] });
    if (response?.status !== 200 || !response.data?.length) {
      return dispatch(addError('getUser for check in status', response));
    }

    const newStudent = response.data[0];
    const newGuid = newStudent.checked_in_guid;
    if (newStudent && newGuid !== currentGroup.guid) {
      // student has been checked out
      const teacherName = getNameWithPossession(newStudent.checked_in_teacher);
      const className = newStudent.checked_in_name;
      const newExpiration = newStudent.checked_in_expire;

      dispatch({
        type: CHECK_IN_USERS,
        guids: [studentGuid],
        checkedInto: {
          guid: newGuid,
          name: className,
          teacher_name: teacherName,
          expiration: newExpiration,
        },
      });

      return dispatch(setPendingCheckOutUpdate(false));
    }
  };
}

export function clearDisplayedUsers() {
  return function(dispatch, getState) {
    const state = getState();
    const displayedIsSet = state.users.displayed !== null;
    const typeIsSet = state.users.displayType !== null;

    if (displayedIsSet || typeIsSet) {
      return dispatch(setDisplayedUsers(null, null));
    }
  };
}

export function setDisplayedUsers(users, displayType) {
  return {
    type: SET_DISPLAYED_USERS,
    users,
    displayType,
  };
}

export function clearUsers() {
  return {
    type: CLEAR_USERS,
  };
}

export function setPendingCheckOutUpdate(value) {
  return {
    type: SET_PENDING_CHECK_OUT_UPDATE,
    value: value,
  };
}
