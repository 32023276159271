import uuidv1 from 'uuid/v1';

import { ClassroomApi } from './classroomApi';

export class RelayWebSocket {
  constructor({ webSocketURL, webSocketJWT } = {}) {
    this.connectToWebSocket(webSocketURL, webSocketJWT);
    this.hostCache = {};
    this.pendingLookups = [];
    this.lookupIntervals = {};
    this.lookupQueue = [];
  }

  async connectToWebSocket(webSocketURL, webSocketJWT) {
    try {
      if (!webSocketJWT) {
        const wsCredentialsResponse = await ClassroomApi.get('/auth/relay_websocket');
        webSocketJWT = wsCredentialsResponse.data.jwt;
        webSocketURL = wsCredentialsResponse.data.url;
      }

      this.ws = new WebSocket(`${webSocketURL}?j=${webSocketJWT}`);
      this.ws.onmessage = this.messageHandler.bind(this);
      this.ws.onopen = this.processLookupQueue.bind(this);
    } catch (error) {
      console.error(error); // eslint-disable-line no-console
    }
  }

  disconnectFromWebSocket() {
    if (this.ws) {
      this.ws.onmessage = undefined;
      this.ws.close();
    }
  }

  processLookupQueue() {
    while (this.lookupQueue.length > 0) {
      const lookup = this.lookupQueue.shift();
      this.lookupCategory(lookup[0], lookup[1], true);
    }
  }

  messageHandler(event) {
    try {
      const data = JSON.parse(event.data);

      // put the category in the cache and set it to expire in 5 minutes
      if (data.request && data.request.host) {
        this.pendingLookups = this.pendingLookups.filter((host) => host !== data.request.host);
        this.hostCache[data.request.host] = {
          categoryId: data.cat,
          expiresAt: new Date().getTime() + 60 * 5 * 1000,
        };
      }
    } catch (error) {
      console.error(error); // eslint-disable-line no-console
    }
  }

  lookupCategory(host, customerId, fromQueue) {
    return new Promise((resolve) => {
      // if we cached this category lookup and it hasn't expired, return it
      if (this.hostCache[host]) {
        if (this.hostCache[host].expiresAt > (new Date()).getTime()) {
          resolve(this.hostCache[host].categoryId);
          return;
        }
        delete this.hostCache[host];
      }

      // if we don't already have a pending request, start one
      if (!this.pendingLookups.includes(host)) {

        // '1' denotes an OPEN state. If it's not open, it must have disconnected. Let's
        // push the lookup onto the queue and reconnect.
        if (this.ws.readyState !== 1 && !fromQueue) {
          this.lookupQueue.push([host, customerId]);
          this.connectToWebSocket();
        } else {
          try {
            this.ws.send(JSON.stringify({
              action: 'dy_lookup',
              host,
              customerId,
            }));
          } catch (error) {
            console.error(error); // eslint-disable-line no-console
          }
        }
      }

      // wait a max of 2 seconds to return the category. This should give us enough time that,
      // even if the websocket was disconnected, we will have been able to reconnect and process
      // the queue.
      let numRetries = 4;
      const lookupUuid = uuidv1();
      this.lookupIntervals[lookupUuid] = setInterval(() => {
        if (numRetries--) {
          if (this.hostCache[host]) {
            clearInterval(this.lookupIntervals[lookupUuid]);
            delete this.lookupIntervals[lookupUuid];
            resolve(this.hostCache[host].categoryId);
            return;
          }
        }

        // this will clear the interval if there are no more retries left
        clearInterval(this.lookupIntervals[lookupUuid]);
        delete this.lookupIntervals[lookupUuid];

      }, 500);
    });
  }
}
