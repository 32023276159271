import { addError } from '../errors/actions';
import { getGroups } from '../groups/actions';
import { addErrorNotification } from '../ui/actions';

import I18n from '../../utilities/i18n';

import { ClassroomApi } from '../../helpers/classroomApi';


export function updateUserSetting(params) {
  return async function(dispatch) {
    const response = await ClassroomApi.post('/classroom_settings', params);

    if (response?.status !== 200) {
      dispatch(addErrorNotification(I18n.t('network_error_please_try_again')));
      return dispatch(addError('updateUserSetting', response));
    }

    return dispatch(getGroups());
  };
}
