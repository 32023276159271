/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const ArrowRightIcon = ({ classes, onClick, style }) => {
  let clickProps;
  if (onClick) {
    clickProps = { onClick, tabIndex: '0', role: 'button' };
  }
  return (
    <svg
      className={classNames('', classes)}
      {...clickProps}
      style={style}
      data-testid='CeleritasArrowRightIcon'
      width='24'
      height='25'
      viewBox='0 0 24 25'
    >
      <path
        d='M3.11111 11.1779C2.49778 11.1779 2 11.6388 2 12.2066C2 12.7745 2.49778 13.2354 3.11111 13.2354H18.2065L13.4374 17.6523C13.2196 17.8526 13.1115 18.116 13.1115 18.3793C13.1115 18.6427 13.2196 18.9061 13.4374 19.1064C13.8715 19.5083 14.5737 19.5083 15.0078 19.1064L21.6555 12.9511C21.8677 12.7637 22 12.4994 22 12.2066C22 11.9154 21.8691 11.6524 21.6589 11.4652L15.0078 5.30681C14.5737 4.90489 13.8715 4.90489 13.4374 5.30681C13.0033 5.70873 13.0033 6.35892 13.4374 6.76083L18.2066 11.1779H3.11111Z'
        fill='currentColor'
      />
    </svg>
  );
};

ArrowRightIcon.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  style: PropTypes.object,
};

export default ArrowRightIcon;
