import { addError } from '../errors/actions';

import { ClassroomApi } from '../../helpers/classroomApi';

export const FETCH_DISTRICT = 'FETCH_DISTRICT';
export const RECEIVE_DISTRICT = 'RECEIVE_DISTRICT';
export const DISTRICT_ERROR = 'DISTRICT_ERROR';
export const RESET_DISTRICT = 'RESET_DISTRICT';

export function getDistrict() {
  return async function(dispatch) {
    dispatch(fetchDistrict());

    const response = await ClassroomApi.get('/districts');

    if (response?.status !== 200) {
      dispatch(districtError('district fetch failed'));
      return dispatch(addError('getDistrict', response));
    }

    return dispatch(receiveDistrict(response.data));
  };
}

function fetchDistrict() {
  return {
    type: FETCH_DISTRICT,
  };
}

function receiveDistrict(data) {
  return {
    type: RECEIVE_DISTRICT,
    betas: data.betaFeatures,
    ips: data.ips,
    minimumUIVersion: data.minimumUIVersion,
  };
}

function districtError(error) {
  return {
    type: DISTRICT_ERROR,
    error,
  };
}

export function resetDistrict() {
  return {
    type: RESET_DISTRICT,
  };
}
