import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter, Link } from 'react-router-dom';

import { closeDropdown, openModal, closeModal } from '../../../redux/ui/actions';

import { findBroadcast, ownsBroadcast } from '../../../utilities/ably';
import { getCurrentGroup } from '../../../utilities/groups';
import { stopBroadcast } from '../../../redux/broadcast/actions';
import I18n from '../../../utilities/i18n';

import Dropdown from '../Dropdown';
import Modal from '../../common/Modal';


const ClassDropdown = ({
  history,
  currentUser,
  currentGroup,
  groups,
  ablyData,
  currentlyOpenModal,
  closeDropdown,
  openModal,
  closeModal,
  stopBroadcast,
}) => {
  const dropdownName = 'classroom';
  const modalName = 'broadcastClose';

  const [selectedGroupGuid, setSelectedGroupGuid] = useState(null);

  const itemClickHandler = (groupGuid) => {
    const broadcast = findBroadcast(ablyData, currentGroup.guid);
    const isMyBroadcast = ownsBroadcast(broadcast, currentUser.email);

    closeDropdown(dropdownName);
    if (isMyBroadcast) {
      setSelectedGroupGuid(groupGuid);
      openModal(modalName);
    } else {
      history.push(`/groups/${groupGuid}`);
    }
  };

  const continueHandler = () => {
    stopBroadcast(currentGroup.guid, currentUser.email);
    closeModal();
    if (selectedGroupGuid) {
      history.push(`/groups/${selectedGroupGuid}`);
      setSelectedGroupGuid(null);
    }
  };

  const buildMenu = () => {
    const groupItems = groups.map(function(group) {
      if (group.hidden) {
        return null;
      }

      return (
        <li key={group.guid}>
          <button
            className='co-dropdown-item button-link'
            onClick={() => itemClickHandler(group.guid)}
            data-testid='classDropdown-item-button'
          >
            {group.display_name || group.name}
          </button>
        </li>
      );
    });

    return (
      <ul>
        {groupItems}
        <li className='dropdown-divider'></li>
        <li>
          <Link
            className='co-dropdown-item'
            to='/my-classes'
            onClick={() => closeDropdown(dropdownName)}
            data-testid='classDropdown-manage-button'
          >
            {I18n.t('manage')}
          </Link>
        </li>
      </ul>
    );
  };

  const buildBroadcastModal = () => {
    return (
      <Modal
        show={currentlyOpenModal === modalName}
        title={I18n.t('confirm_navigation')}
        saveHandler={continueHandler}
        saveText={I18n.t('continue')}
        closeModal={closeModal}
        isSmall
        legacyModalVersion
        modalRedesign2022Q1
      >
        <p className='alert m-vertical--0'>
          <strong className='m-right--4'>{I18n.t('important')}:</strong>
          {I18n.t('navigating_away_will_end_screen_sharing')}
        </p>
      </Modal>
    );
  };

  return (
    <Fragment>
      <Dropdown
        initialLabel={currentGroup.display_name || currentGroup.name}
        dropdownName={dropdownName}
        containerClasses='co-dropdown--classes'
      >
        {buildMenu()}
      </Dropdown>

      {buildBroadcastModal()}
    </Fragment>
  );
};

ClassDropdown.propTypes = {
  history: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  currentGroup: PropTypes.object.isRequired,
  groups: PropTypes.array.isRequired,
  ablyData: PropTypes.object.isRequired,
  currentlyOpenModal: PropTypes.string,
  closeDropdown: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  stopBroadcast: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  currentUser: state.currentUser,
  currentGroup: getCurrentGroup(state.groups),
  groups: state.groups.groups,
  ablyData: state.ably.data,
  currentlyOpenModal: state.ui.currentlyOpenModal,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  closeDropdown,
  openModal,
  closeModal,
  stopBroadcast,
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ClassDropdown));
