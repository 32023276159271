import PropTypes from 'prop-types';
import React from 'react';

import I18n from '../../../utilities/i18n';

import RadioTab from './RadioTab';

const EveryoneFilter = ({ value, count, isSelected, onClick }) => {
  return (
    <section className='co-everyoneFilter'>
      <RadioTab
        value={value}
        count={count}
        isSelected={isSelected}
        onClick={onClick}>
        <span className='co-everyoneFilter-text'>
          {I18n.t('everyone')}
        </span>
      </RadioTab>
    </section>
  );
};

EveryoneFilter.propTypes = {
  value: PropTypes.string,
  count: PropTypes.number,
  isSelected: PropTypes.bool,
  onClick: PropTypes.func,
};

export default EveryoneFilter;
