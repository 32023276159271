import {
  RECEIVE_GROUPS,
  CLEAR_GROUPS,
  RECEIVE_GROUP,
  CLEAR_GROUP,
  SET_CURRENT_GROUP,
  SET_LOADED_GROUP,
  RECEIVE_GROUP_SETTINGS,
  PRUNE_GROUP,
} from './actions';

export const defaultState = {
  groups: [],
  currentGuid: null,
  loadedGuid: null,
  groupSettings: {},
};

export const groupsReducer = (state = defaultState, action) => {
  switch (action.type) {
    case RECEIVE_GROUPS:
      return {
        ...state,
        groups: action.groups,
      };
    case CLEAR_GROUPS:
      return defaultState;
    case RECEIVE_GROUP: {
      const guid = action.group.guid;
      const index = state.groups.findIndex((group) => group.guid === guid);
      let groups;
      if (index === -1) {
        groups = [...state.groups, action.group];
      } else {
        groups = [...state.groups.slice(0, index), action.group, ...state.groups.slice(index + 1)];
      }
      return {
        ...state,
        groups,
      };
    }
    case CLEAR_GROUP:
      return {
        ...state,
        currentGuid: null,
        loadedGuid: null,
      };
    case SET_CURRENT_GROUP:
      return {
        ...state,
        currentGuid: action.guid,
      };
    case SET_LOADED_GROUP:
      return {
        ...state,
        loadedGuid: action.guid,
      };
    case RECEIVE_GROUP_SETTINGS:
      return {
        ...state,
        groupSettings: action.settings,
      };
    case PRUNE_GROUP: {
      const removed = state.groups.filter((group) => {
        return group.guid !== action.guid;
      });
      return {
        ...state,
        groups: removed,
      }; 
    }
    default:
      return state;
  }
};
