import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import { responsiveStoreEnhancer } from 'redux-responsive';
import startOfDay from 'date-fns/start_of_day';

import rootReducer from './redux/rootReducer';

import { loadLocalStorage } from './helpers/localStorage';

const middleware = [
  thunk,
];

const composeEnhancers = composeWithDevTools({ maxAge: 200 });
const oldestValidTime = startOfDay(new Date()).getTime();
const persistedState = loadLocalStorage('ClassroomReduxState', oldestValidTime) || {};

export const store = createStore(
  rootReducer,
  persistedState,
  composeEnhancers(
    responsiveStoreEnhancer,
    applyMiddleware(...middleware),
  ),
);
