import React, { Fragment } from 'react';
import enTranslations from '../resources/translations/i18n.en.json';
import esTranslations from '../resources/translations/i18n.en_es-LA.json';
import daTranslations from '../resources/translations/i18n.en_da-DK.json';
import deTranslations from '../resources/translations/i18n.en_de-DE.json';
import fiTranslations from '../resources/translations/i18n.en_fi-FI.json';
import itTranslations from '../resources/translations/i18n.en_it-IT.json';
import frTranslations from '../resources/translations/i18n.en_fr-FR.json';
import nbTranslations from '../resources/translations/i18n.en_nb-NO.json';
import nlTranslations from '../resources/translations/i18n.en_nl-NL.json';
import svTranslations from '../resources/translations/i18n.en_sv-SE.json';

const allTranslations = {
  en: enTranslations,
  es: esTranslations,
  da: daTranslations,
  de: deTranslations,
  fi: fiTranslations,
  it: itTranslations,
  fr: frTranslations,
  nb: nbTranslations,
  nl: nlTranslations,
  sv: svTranslations,
};

export function translate(name, params, translations) {
  let out = translations[name];

  if (typeof out !== 'string') {
    //default to english
    out = enTranslations.en[name];
  }

  if (typeof out !== 'string') {
    console.error(`Translation missing: '${name}'`); // eslint-disable-line no-console
    return <span className='translationNotFound'>{name}</span>;
  }

  if (params) {
    out = replaceParams(out, params);
  }

  if (!hasBold(out) && !hasLink(out)) {
    return out;
  }

  return replaceBold(out);
}

export function replaceParams(value, params) {
  for (const param in params) {
    value = value.replace('%{' + param + '}', params[param]);
  }
  return value;
}

export function hasLink(value) {
  return value.search(/\[(.*?)\]\((.*?)\)/) !== -1;
}

export function replaceLink(value, key) {
  const linkMatches = value.match(/\[(.*?)\]\((.*?)\)/);
  if (!linkMatches) {
    return value;
  }

  const fullLink = linkMatches[0];
  const linkText = linkMatches[1];
  const url = linkMatches[2];

  const otherText = value.split(fullLink);
  const beforeLink = replaceLink(otherText[0]);
  const afterLink = replaceLink(otherText[1]);

  return (
    <Fragment key={key}>
      {beforeLink}
      <a href={url} title={url} target='_blank' rel='noopener noreferrer'>
        {linkText}
      </a>
      {afterLink}
    </Fragment>
  );
}

export function hasBold(value) {
  return value.indexOf('*') !== -1;
}

export function replaceBold(value) {
  const parts = value.split('*');
  const hasMatchingEnds = (parts.length >= 3) && (parts.length % 2 === 1);

  if (hasMatchingEnds) {
    return parts.map((part, i) => {
      const isBold = (i % 2 === 1);
      if (isBold) {
        return <strong key={i}>{replaceLink(part, `${i}url`)}</strong>; // eslint-disable-line react/no-array-index-key
      }
      return replaceLink(part, i);
    });
  }

  return replaceLink(value, 0);
}

export function getLocaleLanguage() {
  if (navigator && navigator.language) {
    const language = navigator.language.split('-')[0];
    if (Object.keys(allTranslations).includes(language)) {
      return language;
    }
  }
  //default to english
  return 'en';
}

class I18n {
  static t(name, params) {
    const toLang = getLocaleLanguage();
    const fromLang = 'en';
    const translations = allTranslations[toLang][fromLang];
    return translate(name, params, translations);
  }
}

export default I18n;
