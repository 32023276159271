import CoRTCConnection from './rtcConnection';

// Copied over from the Classroom Chrome agent
// used to create teacher screen share without the extension

export default class CoRTCConnectionHandler {
  constructor(stream, channel, iceServers, errorCallback) {
    this.stream = stream;
    this.channel = channel;
    this.connections = {};
    this.presentSessionIds = [];
    this.applyBindings();
    this.errorCallback = errorCallback;
    this.iceServers = iceServers;
    // this.cleanConnectionsInt = setInterval(
    //   this.cleanConnections.bind(this),
    //   30000,
    // );
  }

  applyBindings() {
    this.channel.subscribe('request_rtc', this.onRequestRtc.bind(this));
    this.channel.subscribe('answer_rtc', this.onAnswerRtc.bind(this));
    this.channel.subscribe('answer_rtc_ice', this.onAnswerIce.bind(this));
  }

  onRequestRtc(msg) {
    const sessionId = msg.data.sessionId;
    this.sessionId = sessionId;
    this.addPresence(sessionId);
    let rtc = this.connections[sessionId];

    const args = {};
    args.iceServers = this.iceServers;
    if (Array.isArray(msg.data.customIce)) {
      args.customIce = msg.data.customIce;
    }
    rtc = this.connections[sessionId] = new CoRTCConnection(args);
    rtc.setStream(this.stream);

    rtc.addIceCallback((ice) => {
      if (ice) {
        this.sendIce(sessionId, ice);
      }
    });
    rtc.createOffer().then(this.onRtcOffer.bind(this, sessionId));
  }

  setNewStream(stream) {
    this.stream = stream;
  }

  onRtcOffer(sessionId, offer) {
    const rtc = this.connections[sessionId];
    rtc.setOffer(offer);
    this.sendOffer(sessionId, offer);
  }

  sendOffer(sessionId, offer) {
    const data = {
      sessionId: sessionId,
      offer: offer,
    };
    this.channel.publish('offer_rtc', data);
  }

  sendIce(sessionId, ice) {
    const data = {
      sessionId: sessionId,
      ice: ice,
    };
    this.channel.publish('offer_rtc_ice', data);
  }

  onAnswerRtc(msg) {
    try {
      const sessionId = msg.data.sessionId;
      const rtc = this.connections[sessionId];
      rtc.setAnswer(msg.data.answer);
    } catch (error) {
      this.errorCallback(error);
    }
  }

  onAnswerIce(msg) {
    const sessionId = msg.data.sessionId;
    const rtc = this.connections[sessionId];
    rtc.addIce(msg.data.ice);
  }

  updatePresence(sessionIds) {
    this.presentSessionIds = sessionIds;
  }

  addPresence(sessionId) {
    if (this.presentSessionIds.indexOf(sessionId) === -1) {
      this.presentSessionIds.push(sessionId);
    }
  }

  // cleanConnections() {
  //   console.log('clean connections');
  //   Object.keys(this.connections).forEach((sessionId) => {
  //     if (this.presentSessionIds.indexOf(sessionId) === -1) {
  //       const rtcObj = this.connections[sessionId];
  //       if (typeof(rtcObj) !== 'undefined' && typeof(rtcObj.rtc) !== 'undefined') {
  //         rtcObj.rtc.close();
  //         delete rtcObj.rtc;
  //       }
  //       delete this.connections[sessionId];
  //     }
  //   });
  // }
}
