import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import I18n from '../../../utilities/i18n';

import AttendanceStatusIndicator from '../AttendanceStatusIndicator';

import RadioGroupExpander from './RadioGroupExpander';
import RadioTab from './RadioTab';

const ATTENDANCE_STATUSES = ['checked_in', 'not_checked_in', 'offline'];

const AttendanceFilterList = ({ attendanceStatus, selectedFilter, onTabClick }) => {
  const savedExpandedOption = localStorage.getItem(I18n.t('attendance'));
  const filterList = useMemo(() => (
    ATTENDANCE_STATUSES.map((status) => ({
      status,
      count: attendanceStatus[status].length,
      isSelected: selectedFilter === status,
      isDisabled: !attendanceStatus[status].length,
      onClick: () => onTabClick(status),
    }))
  ), [attendanceStatus, selectedFilter, onTabClick]);

  return (
    <RadioGroupExpander
      label={I18n.t('attendance')}
      defaultExpanded={savedExpandedOption ? savedExpandedOption === 'true' : true}
      optionalBadgeList={filterList}
    >
      {
        filterList.map(({ status, count, isSelected, isDisabled, onClick }) => (
          <RadioTab
            key={status}
            value={status}
            count={count}
            isSelected={isSelected}
            isDisabled={isDisabled}
            onClick={onClick}
          >
            <AttendanceStatusIndicator status={status} isDisabled={isDisabled} />
          </RadioTab>
        ))
      }
    </RadioGroupExpander>
  );
};

AttendanceFilterList.propTypes = {
  attendanceStatus: PropTypes.object,
  selectedFilter: PropTypes.string,
  onTabClick: PropTypes.func,
};

export default AttendanceFilterList;
