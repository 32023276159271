import { ClassroomApi } from '../../helpers/classroomApi';
import { addError } from '../errors/actions';
import { addErrorNotification, addNotification } from '../ui/actions';
import I18n from '../../utilities/i18n';
import { handleArrayWithTypeNames } from '../../utilities/types';
import { updatePolicy } from '../ably/actions';

export const FETCH_SEGMENTS = 'FETCH_SEGMENTS';
export const RECEIVE_SEGMENTS = 'RECEIVE_SEGMENTS';
export const RECEIVE_SEGMENT = 'RECEIVE_SEGMENT';
export const PRUNE_SEGMENT = 'PRUNE_SEGMENT';
export const CLEAR_SEGMENTS = 'CLEAR_SEGMENTS';
export const SET_SEGMENTS_ERROR = 'SET_SEGMENTS_ERROR';
export const UPDATE_SEGMENT = 'UPDATE_SEGMENT';

export function getSegments(parentGuid) {
  return async function(dispatch) {
    dispatch(fetchSegments());

    const response = await ClassroomApi.get(`/groups/${parentGuid}/segments`);
    if (response?.status !== 200) {
      return dispatch(addError('getSegments', response));
    }

    const segments = response.data.segments;
    if (segments && segments.length > 0) {
      segments.forEach((segment) => {
        const webRules = segment.web_rules;
        if (webRules && webRules.sites && webRules.sites.length === 0 && !webRules.blocked) {
          segment.web_rules = {};
          dispatch(modifySegment(parentGuid, segment.guid, { web_rules: {} }));
        }
      });
    }
    return dispatch(receiveSegments(response.data.segments));
  };
}

export function removeStudentFromSegmentOnDelete(groupGuid, userGuid, segment) {
  return async function(dispatch) {
    await removeSegmentMember(segment, groupGuid, userGuid);
    return dispatch(updateSegment(segment, userGuid));
  };
}

export function addSegment(parentGuid, name) {
  return async function(dispatch) {
    const data = { name, parent_group_guid: parentGuid };
    const response = await ClassroomApi.post(`/groups/${parentGuid}/segments`, data);

    if (response?.status !== 200) {
      dispatch(addErrorNotification(I18n.t('network_error_please_try_again')));
      return dispatch(addError('addSegment', response));
    }

    return dispatch(getSegments(parentGuid));
  };
}

export function removeSegment(groupGuid, segmentGuid) {
  return async function(dispatch) {
    dispatch(fetchSegments());

    const response = await ClassroomApi.delete(`/groups/${groupGuid}/segments/${segmentGuid}`);

    if (response?.status !== 200) {
      dispatch(addErrorNotification(I18n.t('network_error_please_try_again')));
      return dispatch(addError('removeSegment', response));
    }

    dispatch(updatePolicy());
    return dispatch(pruneSegment(segmentGuid));
  };
}

export function modifySegment(parentGuid, segmentGuid, attributes, successMessage) {
  return async function(dispatch, getState) {
    const state = getState();
    dispatch(fetchSegments());

    if (attributes.web_rules) {
      attributes.email = state.currentUser.email;
    }

    const response = await ClassroomApi.put(`/groups/${parentGuid}/segments/${segmentGuid}`, attributes);

    if (response?.status !== 200) {
      return dispatch(addError('modifySegment', response));
    }

    if (successMessage) {
      dispatch(addNotification(successMessage, { autoDismiss: true, notificationType: 'success' }));
    }

    return dispatch(receiveSegment(response.data.segment));
  };
}

export function moveSegmentMember(groupGuid, segmentGuid, userGuid, email) {
  return async function(dispatch, getState) {
    const state = getState();
    const segments = state.groupSegments.segments;
    const removeFrom = segments.filter((segment) => segment.member_guids.includes(userGuid));
    const addTo = segments.find((segment) => segment.guid === segmentGuid);

    dispatch(fetchSegments());

    const promises = removeFrom.map((segment) => removeSegmentMember(segment, groupGuid, userGuid));
    if (addTo) {
      promises.push(addSegmentMember(addTo, groupGuid, userGuid));
    }
    const data = await Promise.all(promises);
    const mappedData = handleArrayWithTypeNames(data, 'segment');
    mappedData.forEach((segment) => {
      dispatch(receiveSegment(segment));
    });
    
    dispatch(updatePolicy(email));
  };
}

export async function addSegmentMember(segment, groupGuid, userGuid) {
  const data = { member_guids: [...segment.member_guids, userGuid] };
  const response = await ClassroomApi.put(`/groups/${groupGuid}/segments/${segment.guid}`, data);

  if (response?.status !== 200) {
    throw new Error(response?.data || 'No response');
  }

  return response.data;
}

export async function removeSegmentMember(segment, groupGuid, userGuid) {
  const removed = segment.member_guids.filter((guid) => guid !== userGuid);
  const data = { member_guids: removed };
  const response = await ClassroomApi.put(`/groups/${groupGuid}/segments/${segment.guid}`, data);

  if (response?.status !== 200) {
    throw new Error(response?.data || 'No response');
  }
  return response.data;
}

function fetchSegments() {
  return {
    type: FETCH_SEGMENTS,
    time: Date.now(),
  };
}

function receiveSegments(segments) {
  return {
    type: RECEIVE_SEGMENTS,
    segments,
    time: Date.now(),
  };
}

function receiveSegment(segment) {
  return {
    type: RECEIVE_SEGMENT,
    segment,
    time: Date.now(),
  };
}

function pruneSegment(guid) {
  return {
    type: PRUNE_SEGMENT,
    guid,
    time: Date.now(),
  };
}

function updateSegment(segment, userGuid) {
  return {
    type: UPDATE_SEGMENT,
    segment,
    userGuid,
  };
}

export function clearSegments() {
  return {
    type: CLEAR_SEGMENTS,
  };
}
