import { ClassroomApi } from '../../helpers/classroomApi';
import { addError } from '../errors/actions';

export const FETCH_WEBRULES = 'FETCH_WEBRULES';
export const UPDATING_WEBRULES = 'UPDATING_WEBRULES';
export const UPDATED_WEBRULES = 'UPDATED_WEBRULES';
export const DELETING_WEBRULES = 'DELETING_WEBRULES';
export const DELETED_WEBRULES = 'DELETED_WEBRULES';
export const CREATING_WEBRULES = 'CREATING_WEBRULES';
export const CREATED_WEBRULES = 'CREATED_WEBRULES';
export const RECEIVE_WEBRULES = 'RECEIVE_WEBRULES';
export const GET_EDIT_LIST = 'GET_EDIT_LIST';

const defaultWebRules = {
  allowList: {},
  blockList: {},
  customAllow: [],
  customBlock: [],
};

export function getWebRules() {
  return async function(dispatch) {
    dispatch(fetchingWebRules());
    const response = await ClassroomApi.get('/web_rule_lists/');
    if (response?.status === 200) {
      // ensure that if we get an empty object we correctly populate the store
      const webRules = { ...defaultWebRules, ...response.data };
      return dispatch(receiveWebRules(webRules));
    }
    return dispatch(addError('Could not fetch web_rules', response));
  };
}

export function deleteWebRules(webRules) {
  /*
  { 
    name: name of list to delete,
    type: 'allow' or 'block'
  }
  */
  return async function(dispatch) {
    dispatch(deletingWebRules(webRules));
    const response = await ClassroomApi.delete('/web_rule_lists/', webRules);
    if (response?.status === 200) {
      return dispatch(webRulesDeleted(webRules));
    }
    return dispatch(addError('Could not delete web_rules', response));
  };
}

export function createWebRules(webRules) {
  /*
    Appropriate shape of data:
    name: name of list,
    urls: array of urls,
    type: 'allow' or 'block'
  */
  return async function(dispatch) {
    dispatch(creatingWebRules(webRules));
    const response = await ClassroomApi.put('/web_rule_lists/', webRules);
    if (response?.status === 200) {
      return dispatch(webRulesCreated(response.data));
    }
    dispatch(addError('Could not create web_rules', response));
    throw new Error(response.data);
  };
}

export function updateWebRules(webRules) {
  /* 
    appropriate shape of data:
  { 
    name : current name of list,
    urls : array of urls,
    type: either 'allow' or 'block'
    *OPTIONAL but required together*
    newName : new name for list if applicable,
    createdAt : original createdAt for the renamed list
  }
  */
  return async function(dispatch) { 
    dispatch(updatingWebRules(webRules));
    const response = await ClassroomApi.patch('/web_rule_lists/', webRules);
    if (response?.status === 200) {
      return dispatch(updatedWebRules(response.data));
    }
    dispatch(addError('Could not update web_rules', response));
    throw new Error(response.data);
  };
}

export function fetchingWebRules() {
  return {
    type: FETCH_WEBRULES, 
  };
}

export function receiveWebRules({ allowList, blockList, customAllow, customBlock }) {
  return { 
    type: RECEIVE_WEBRULES,
    webRules: {
      allowList,
      blockList,
      customAllow,
      customBlock, 
    },
  };
}

export function creatingWebRules(webRules) {
  return { 
    type: CREATING_WEBRULES,
    webRules,
  };
}

export function deletingWebRules(webRules) {
  return { 
    type: DELETING_WEBRULES,
    webRules,
  };
}

export function webRulesDeleted() {
  return { 
    type: DELETED_WEBRULES,
  };
}
export function updatedWebRules() {
  return { 
    type: UPDATED_WEBRULES,
  };
}

export function updatingWebRules() {
  return { 
    type: UPDATING_WEBRULES,
  };
}

export function webRulesCreated() {
  return { 
    type: CREATED_WEBRULES,
  };
}

export function getEditList(listName, urls, createdAt, updatedAt) {
  return {
    type: GET_EDIT_LIST,
    listName: listName,
    urls: urls,
    createdAt: createdAt,
    updatedAt: updatedAt,
  };
}
