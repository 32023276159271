import React, { Fragment, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';

import {
  closeTab,
  focusTab,
  startRecording,
  lockScreen,
  unlockScreen,
  sendMessage,
} from '../../../redux/ably/actions';
import { startBroadcast } from '../../../redux/broadcast/actions';
import { addNotification, openModal, closeModal } from '../../../redux/ui/actions';

import { findBroadcast } from '../../../utilities/ably';
import { getCurrentGroup } from '../../../utilities/groups';
import I18n from '../../../utilities/i18n';
import { canRecordScreens } from '../../../utilities/policy';
import { hasBeta } from '../../../utilities/users';

import { trackPendo } from '../../../helpers/pendo';

import { ReactComponent as RecordingOn } from '../../../images/recordingOn.svg';

import Icon from '../Icon';
import Modal from '../Modal';
import Tab from './Tab';
import Tooltip from '../../common/Tooltip/Tooltip';

const OpenTabs = ({
  userInfo,
  tabs,
  currentGroup,
  ablyData,
  groupPolicy,
  streams,
  currentlyOpenModal,
  closeTab,
  focusTab,
  startRecording,
  lockScreen,
  unlockScreen,
  startBroadcast,
  addNotification,
  openModal,
  closeModal,
  sendMessage,
  districtBetas,
  userBetas,
  isCheckedIn,
}) => {
  const modalName = 'broadcastingModal';
  const closeTabMessageId = 5;
  const stream = streams[userInfo.email];
  const hasWebRulesDisabledBeta = hasBeta('web_rules_temp', districtBetas, userBetas);
  
  const videoElement = useRef(null);

  useEffect(() => {
    if (videoElement.current && videoElement.current.srcObject !== stream) {
      videoElement.current.srcObject = stream;
    }
  }, [videoElement, stream]);

  const checkStartBroadcast = () => {
    if (!findBroadcast(ablyData, currentGroup.guid)) {
      openModal(modalName);
    } else {
      addNotification(I18n.t('the_screen_could_not_be_shared_only_one'), { notificationType: 'warning' });
    }
  };

  const handleStartBroadcast = () => {
    closeModal();

    if (!findBroadcast(ablyData, currentGroup.guid) && userInfo.online) {
      startBroadcast(userInfo.email);
    }
  };

  const handleCloseTab = (tabId, url, email, tabTitle) => {
    if (!tabTitle || tabTitle === '') {
      tabTitle = 'tab';
    }
    const message = I18n.t('closed_tab', { string: tabTitle });

    trackPendo('closeTab', { url });
    closeTab(tabId, email);
    sendMessage(closeTabMessageId, message, email);
  };

  const handleStartRecording = () => {
    if (!userInfo.recording) {
      startRecording(userInfo.email);
    }
  };

  const buildButtons = () => {

    return (
      <>
        {isCheckedIn &&
          <ul className='co-flyout-controls'>
            <Tooltip
              content={I18n.t('share_screen')} 
              aria-label={I18n.t('share_screen')} 
              position='left'
              size='small'
            >
              <li
                className={'co-flyout-control'}
                onClick={() => checkStartBroadcast()}
                data-testid='openTabs-share'
              >
                <Icon name='icon-screen' />
              </li>
            </Tooltip>

            {!hasWebRulesDisabledBeta && 
              <Tooltip
                content={I18n.t('lock_screen')} 
                aria-label={I18n.t('lock_screen')} 
                position='left'
                size='small'
              >
                <li
                  className={'co-flyout-control'}
                  onClick={() => lockScreen(userInfo.email)}
                  data-testid='openTabs-lock'
                >
                  <Icon name='icon-lock' />
                </li>
              </Tooltip>}

            {!hasWebRulesDisabledBeta &&
              <Tooltip
                content={I18n.t('unlock_screen')} 
                aria-label={I18n.t('unlock_screen')} 
                position='left'
                size='small'
              >
                <li
                  className={'co-flyout-control'}
                  onClick={() => unlockScreen(userInfo.email)}
                  data-testid='openTabs-unlock'
                >
                  <Icon name='icon-unlock' />
                </li>
              </Tooltip>}
            
            {buildRecordButton('co-flyout-control')}
          </ul>
        }
      </>
    );
  };

  const buildRecordButton = () => {
    if (!canRecordScreens(groupPolicy)) {
      return null;
    }

    const title = userInfo.recording ? I18n.t('recording') : I18n.t('record_for_ten_minutes');
    const dotClasses = classNames({
      'co-recordingIcon': true,
      'co-recordingIcon--isRecording': userInfo.recording,
    });
    
    return (
      <Tooltip
        content={title} 
        aria-label={title} 
        position='left'
        size='small'
      >
        <li
          className={'co-flyout-control'}
          onClick={handleStartRecording}
          data-testid='openTabs-record'
        >
          <RecordingOn className={dotClasses}/>
        </li>
      </Tooltip>
    );
  };

  const buildRecordingIndicator = () => {
    if (!userInfo.recording) {
      return null;
    }

    return (
      <div
        data-testid='openTabs-recording'
        className='co-flyout-control--isRecording co-flyout-control'
      >
        <RecordingOn className='co-recordingIcon co-recordingIcon--isRecording'/>
      </div>
    );
  };

  const buildLoadingMessage = () => {
    if (stream) {
      return null;
    }

    return (
      <span
        data-testid='openTabs-loading'
        className='co-flyout-video-loading'
      >
        {`${I18n.t('loading')}...`}
      </span>
    );
  };

  const buildOpenTabs = () => {
    return tabs.map((tab) => {
      return (
        <Tab
          isCheckedIn={isCheckedIn}
          key={tab.id + tab.url}
          tab={tab}
          focusTab={(tab) => focusTab(tab.windowId, tab.id, userInfo.email)}
          closeTab={(tab) => handleCloseTab(tab.id, tab.url, userInfo.email, tab.title)}
        />
      );
    });
  };

  const buildModalContent = () => {
    const fullName = `${userInfo.firstName} ${userInfo.lastName}'s`;

    return (
      <p className='alert m-vertical--0' data-testid='openTabs-modal'>
        {I18n.t('screen_will_be_shared_with_all', { string: fullName })}
      </p>
    );
  };

  return (
    <Fragment>
      <section className='co-flyout-content' data-testid='openTabs-main'>
        <div className='co-flyout-videoContainer'>
          {buildButtons()}
          {buildRecordingIndicator()}

          <video
            ref={videoElement}
            className='co-flyout-video'
            autoPlay
            muted
          />
          {buildLoadingMessage()}
        </div>

        <p className='text--gray m-top--16 m-bottom--4'>{I18n.t('clicking_any_tab_will')}</p>

        <section className='co-flyout-openTabs'>
          <ul>
            {buildOpenTabs()}
          </ul>
        </section>
      </section>

      <Modal
        show={currentlyOpenModal === modalName}
        title={I18n.t('share_screen')}
        saveHandler={handleStartBroadcast}
        saveText={I18n.t('continue')}
        closeModal={closeModal}
        isSmall
        modalRedesign2022Q1
        legacyModalVersion
      >
        {buildModalContent()}
      </Modal>
    </Fragment>
  );
};

OpenTabs.propTypes = {
  userInfo: PropTypes.object.isRequired,
  tabs: PropTypes.array.isRequired,
  currentGroup: PropTypes.object.isRequired,
  ablyData: PropTypes.object.isRequired,
  groupPolicy: PropTypes.object.isRequired,
  streams: PropTypes.object.isRequired,
  currentlyOpenModal: PropTypes.string,
  closeTab: PropTypes.func.isRequired,
  focusTab: PropTypes.func.isRequired,
  startRecording: PropTypes.func.isRequired,
  lockScreen: PropTypes.func.isRequired,
  unlockScreen: PropTypes.func.isRequired,
  startBroadcast: PropTypes.func.isRequired,
  addNotification: PropTypes.func,
  openModal: PropTypes.func,
  closeModal: PropTypes.func.isRequired,
  sendMessage: PropTypes.func.isRequired,
  districtBetas: PropTypes.object.isRequired,
  userBetas: PropTypes.object.isRequired,
  isCheckedIn: PropTypes.bool,
};

const mapState = (state) => ({
  currentGroup: getCurrentGroup(state.groups),
  ablyData: state.ably.data,
  groupPolicy: state.policy.group,
  streams: state.webrtcs.streams,
  currentlyOpenModal: state.ui.currentlyOpenModal,
  districtBetas: state.district.betas,
  userBetas: state.currentUser.betas,
});

const mapDispatch = {
  closeTab,
  focusTab,
  startRecording,
  lockScreen,
  unlockScreen,
  startBroadcast,
  addNotification,
  openModal,
  closeModal,
  sendMessage,
};

export default connect(mapState, mapDispatch)(OpenTabs);
