import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import validUrl from 'valid-url';

import { closeModal } from '../../redux/ui/actions';

import I18n from '../../utilities/i18n';

import Modal from './Modal';
import { addInsightSharedLink } from '../../redux/insights/actions';
import { sendLink } from '../../redux/ably/actions';
import { getCurrentGroup } from '../../utilities/groups';
import Fade from './animations/Fade';

const SendLinkModal = ({
  currentlyOpenModal,
  closeModal,
  addInsightSharedLink,
  emails,
  sendToName,
  currentGroup,
  sendLink,
}) => {
  const modalName = 'sendLink';
  const modalTitle = sendToName ? I18n.t('send_link_to', { string: sendToName }) : I18n.t('send_link');
  const [url, setUrl] = useState('');
  const [error, setError] = useState('');
  const [isSaving, setIsSaving] = useState(false);

  function saveHandler() {
    if (isSaving) {
      return;
    }
    
    let newUrl = url;
    if (!url.match('^https://') && !url.match('^http://')) {
      newUrl = `https://${url}`;
    } 

    if (validUrl.isUri(newUrl)) {
      setIsSaving(true);
      for (const email of emails) {
        sendLink(newUrl, email);
      }
      addInsightSharedLink(currentGroup.guid, newUrl);

      closeModal();
      setTimeout(() => {
        //wait for animation delay to close modal
        setUrl('');
        setIsSaving(false);
      }, 500);
    } else {
      setError(I18n.t('please_enter_a_valid_url'));
    }
  }

  return (
    <Modal
      show={currentlyOpenModal === modalName}
      title={modalTitle}
      closeModal={closeModal}
      saveHandler={saveHandler}
      saveText={I18n.t('send')}
      isSmall
      modalRedesign2022Q1
      legacyModalVersion
    >
      <section className='co-nav-shareLink'>
        <input
          type='text'
          name='sendLink'
          onChange={(e) => {
            setError('');
            setUrl(e.target.value);
          }}
          onKeyDown={(e) => {
            const eventCode = e.charCode || e.keyCode;
            eventCode === 13 && saveHandler();
          }}
          className='co-nav-shareLink-input co-nav-shareLink-input--wide'
          value={url}
          autoFocus
          data-testid='sendLink-input'
        />

        <p className='text--gray text--small m-bottom--0 m-top--4'>
          {I18n.t('the_link_will_open_in_a_new_tab')}
        </p>

        <Fade condition={Boolean(error)}>
          <div className='alert alert--error m-top--8'>
            <span className='alert--error-errorList'>
              {error}
            </span>
          </div>
        </Fade>
      </section>
    </Modal>
  );
};

SendLinkModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  currentlyOpenModal: PropTypes.string,
  addInsightSharedLink: PropTypes.func.isRequired,
  sendLink: PropTypes.func.isRequired,
  emails: PropTypes.array.isRequired,
  sendToName: PropTypes.string,
  currentGroup: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  currentlyOpenModal: state.ui.currentlyOpenModal,
  currentGroup: getCurrentGroup(state.groups),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  addInsightSharedLink,
  closeModal,
  sendLink,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SendLinkModal);
