export function setupIntercom(user) {
  const params = {
    app_id: window.intercomSettings.app_id,
    user_id: user.guid,
    email: user.email,
    name: `${user.first_name} ${user.last_name}`,
    role: user.role,
    ui_version: window.classroomUIVersion,
    company: {
      company_id: user.customer_id,
    },
  };
  window.Intercom && window.Intercom('boot', params);
}

export function resetIntercom() {
  window.Intercom && window.Intercom('shutdown');
}
