import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import I18n from '../../../../utilities/i18n';
import { getDateAndTimeString } from '../../../../utilities/time';
import { closeModal, openModal } from '../../../../redux/ui/actions';

import { TrashIcon } from 'design-system';

import Icon from '../../Icon';
import TextInput from '../../TextInput';
import UrlChecker from '../../UrlChecker';
import ErrorMessage from '../../ErrorMessage/ErrorMessage';
import Modal from '../../Modal';

const ListForm = ({
  handleClose,
  handleDelete,
  handleSave,
  currentlyOpenSubFlyout,
  currentlyOpenModal,
  currentWebRulesSection,
  error,
  openModal,
  closeModal,
  urls = [],
  webListToEdit,
  getCanClose,
}) => {
  const [subFlyoutHeading] = useState(currentlyOpenSubFlyout);
  const maxListLength = `${currentWebRulesSection}List`.length >= 20;
  const [entries, setEntries] = useState(urls);
  const [initialListInfo] = useState(webListToEdit);
  const [editListName, setEditListName] = useState(initialListInfo.name);
  const [listName, setListName] = useState('');
  const [isEdited, setIsEdited] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [errors, setErrors] = useState(0);
  const [inputLength, setInputLength] = useState(0);
  const isEditMode = currentlyOpenSubFlyout === 'edit_list';
  const [duplicateError, setDuplicateError] = useState(null);
  const maxCharactersError = inputLength === 50;
  const [currentlySaving, setCurrentlySaving] = useState(false);
  const urlsCheckerError = errors > 0 || error === 'invalid list' || entries.length > 100;
  const editedRef = useRef();
  const modalNeededBeforeClose = (
    (isEdited && !isEditMode && (listName.length > 0 || entries.length > 0)) || (isEditMode && isEdited) ||
    (!isEditMode && listName.length > 0) || (isEditMode && (initialListInfo.name !== editListName))
  );
  const newWebRules = {
    name: listName,
    urls: entries, 
    type: currentWebRulesSection,
  };
  const updatedWebRules = {
    name: initialListInfo.name,
    urls: urls, 
    type: currentWebRulesSection,
    newName: editListName,
    createdAt: initialListInfo.createdAt,
  };
  
  useEffect(() => {
    getCanClose(modalNeededBeforeClose);
  }, [modalNeededBeforeClose, getCanClose]);

  useEffect(() => {
    setDuplicateError(error && error.toString().includes('Duplicate'));
  }, [error]);
  
  useEffect(() => {
    const invalidListName = isEditMode ? editListName.trim().length === 0 : listName.trim().length === 0;
    const invalidEntries = entries.length === 0 || errors > 0;
    setDisabled(
      (invalidListName || invalidEntries ||
      (!isEdited && initialListInfo.name === editListName) ||
      currentlySaving || entries.length > 100));
  }, [isEdited, entries, listName, editListName, isEditMode, errors, currentlySaving, initialListInfo.name]);

  useEffect(() => {
    if (!isEditMode && entries.length > 0) {
      setIsEdited(true);
    } 
    if (isEditMode && initialListInfo.urls.length && editedRef && editedRef.current) {
      editedRef.current.addEventListener('keydown', () => setIsEdited(true));
    }
  }, [entries, setEntries, errors, initialListInfo.urls, isEditMode]);
  
  const overrideClasses = cn({
    'co-listForm-listNameInput': true,
    hasError: maxCharactersError || duplicateError,
  });

  const buildSaveButton = () => {
    if (isEditMode) {
      return (
        <button
          data-testid='listForm-saveButton-update'
          className='button button--blue m-right--16' 
          disabled={disabled}
          onClick={() => {
            setCurrentlySaving(true);
            updatedWebRules.urls = entries.map((x) => x.text);
            return handleSave(updatedWebRules); 
          }} 
          type='submit'
        >
          {I18n.t('save')}
        </button>
      );
    }
    return (
      <button
        data-testid='listForm-saveButton-create'
        className='button button--blue m-right--16' 
        disabled={disabled}
        onClick={() => {
          setCurrentlySaving(true);
          newWebRules.urls = entries.map((x) => x.text); 
          return handleSave(newWebRules); 
        }} 
        type='submit'
      >
        {I18n.t('save')}
      </button>
    );
  };

  const handleModalClose = () => {
    if (modalNeededBeforeClose) {
      verifyLeavePage();
    } else {
      handleClose(isEdited, entries);
    }
  };

  const verifyLeavePage = () => {
    const modalName = 'leavePageModal';
    openModal(modalName);
  };

  const buildLeavePageModal = () => {
    const leaveButton = (
      <button
        data-testid='listForm-leave-modalButton'
        className='button button--blue'
        onClick={() => {
          closeModal();
          handleClose(false, 0);
        }}
      >
        {I18n.t('leave')}
      </button>
    );

    return (
      <Modal
        data-testid='listForm-leaveModal'
        show={currentlyOpenModal === 'leavePageModal'}
        title={I18n.t('leave_page')}
        customSaveButton={leaveButton}
        closeModal={closeModal}
        modalRedesign2022Q1
      >
        <div>
          {I18n.t('are_you_sure_you_want_to_leave_your_changes_wont_be_saved')}
        </div>
      </Modal>
    );
  };

  const buildDeleteList = () => {
    const deleteList = {
      name: initialListInfo.name,
      type: currentWebRulesSection,
    };
    const deleteUrls = initialListInfo.urls;

    const verifyDeleteList = () => {
      const modalName = 'deleteListModal';
      openModal(modalName);
    };

    return (
      <div className='co-listForm-delete-container' data-testid='listForm-delete-container'>
        <button
          data-testid='listForm-deleteButton'
          className='co-listForm-delete-quietButton'
          onClick={verifyDeleteList}
        >
          <TrashIcon className='co-listForm-delete-trashIcon' data-testid='listForm-trashIcon'/>
          <div className='co-listForm-delete-text' data-testid='listForm-delete-text'>
            {I18n.t('delete')}
          </div>
        </button>
        {buildDeleteModal(deleteList, deleteUrls)}
      </div>
    );
  };

  const buildDeleteModal = (deleteList) => {
    const deleteButton = (
      <button
        data-testid='listForm-delete-modalButton'
        className='co-modal-warningButton'
        onClick={() => {
          closeModal();
          return handleDelete(deleteList);
        }}
      >
        {I18n.t('yes_delete')}
      </button>
    );

    return (
      <Modal
        data-testid='listForm-deleteModal'
        show={currentlyOpenModal === 'deleteListModal'}
        title={I18n.t('delete_list')}
        customSaveButton={deleteButton}
        closeModal={closeModal}
        modalRedesign2022Q1
      >
        <div>
          {I18n.t('are_you_sure_you_want_to_delete_list_name')}
          <span className='co-listForm-deleteModal-listName'> {initialListInfo.name}</span>?
        </div>
      </Modal>
    );
  };

  const buildTimestamps = () => {
    const dateAndTime = (type) => getDateAndTimeString(parseInt(initialListInfo[type], 10));
    return (
      <div className='co-listForm-timestamp'>
        <div className='co-listForm-timestamp-section' data-testid='listForm-timestamp-created'>
          <span className='co-listForm-timestamp-section-heading' data-testid='listForm-timestamp-created-heading'>
            {I18n.t('created')}
          </span>
          <span className='co-listForm-timestamp-dateTime' data-testid='listForm-timestamp-created-info'>
            {I18n.t('date_at_time', { date: dateAndTime(['createdAt']).date, time: dateAndTime(['createdAt']).time })}
          </span>
        </div>
        <div className='co-listForm-timestamp-section' data-testid='listForm-timestamp-updated'>
          <span className='co-listForm-timestamp-section-heading' data-testid='listForm-timestamp-updated-heading'>
            {I18n.t('updated')}
          </span>
          <span className='co-listForm-timestamp-dateTime' data-testid='listForm-timestamp-updated-info'>
            {I18n.t('date_at_time', { date: dateAndTime(['updatedAt']).date, time: dateAndTime(['updatedAt']).time })}
          </span>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className='co-flyout-close' data-testid='listForm-flyoutCloseButton-container'>
        <button
          data-testid='listForm-flyoutCloseButton'
          onClick={() => handleModalClose()}
          className='button-link tooltipped tooltipped--left tooltipped--small tooltipped--opaque'
          aria-label={I18n.t('close')}
        >
          <span className='co-icon-x' data-testid='listForm-flyoutCloseButton-icon'>&times;</span>
        </button>
      </div>

      <div className='co-listForm-container' data-testid='listForm-container'>
        <button
          data-testid='listForm-flyoutCloseButton-breadcrumb'
          className='button-link link--underlineHover text--teal inlineBlock' 
          onClick={() => handleModalClose()}
        >
          <Icon name='icon-left-2' classes='p-right--8 text--big' />
          {I18n.t('web_rules')}
        </button>

        <div className='l-flex l-flex--vAlignCenter m-vertical--24' data-testid='listForm-subFlyout-heading' >
          <h1>{I18n.t(`${subFlyoutHeading}`)}</h1>
        </div>

        <label htmlFor='listName' className='co-listForm-subHeadings' data-testid='listForm-subFlyout-subHeading'>
          {I18n.t('name')}
        </label>
        <div className='co-listForm-hint' data-testid='listForm-requiredHint-one'>{I18n.t('required')}</div>
        <div className='co-listForm-hasErrorMessage-wrapper' data-testid='listForm-hasErrorWrapper-one'>
          <TextInput
            data-testid='listForm-listName-input'
            type='text'
            maxLength={50}
            minLength={1}
            required
            disabled={isEditMode ? maxListLength : false}
            overrideClasses={overrideClasses}
            value={isEditMode ? editListName : listName}
            onChange={(value) => {
              if (duplicateError) {
                setDuplicateError(false);
              }
              setCurrentlySaving(false);
              setInputLength(value.length);
              if (isEditMode) {
                setEditListName(value);
              } else {
                setListName(value);
              }
            }}
          />
          <ErrorMessage hasError={maxCharactersError || duplicateError} data-testid='listForm-listName-error'>
            {maxCharactersError && I18n.t('maximum_character_limit')}
            {duplicateError && I18n.t('name_already_exists_please_choose_a_different_name')}
          </ErrorMessage>
        </div>

        <label className='co-listForm-subHeadings' required data-testid='listForm-subFlyout-subHeading-addWebsites'>
          {I18n.t('add_websites')}
        </label>
        <div 
          className='co-listForm-hint m-bottom--16'
          data-testid='listForm-requiredHint-two'
        >
          {I18n.t('required')}
        </div>
        <div ref={editedRef} className='co-listForm-hasErrorMessage-wrapper' data-testid='listForm-hasErrorWrapper-two'>
          {currentlyOpenSubFlyout && 
            <UrlChecker
              initialSites={isEditMode ? initialListInfo.urls : []}
              showEntries={true}
              updateEntries = {(entries) => {
                setEntries(entries);
                const errorsFound = entries.filter((entry) => entry.error);
                setErrors(errorsFound.length);
              }}
              hasError={urlsCheckerError}
            />}
          <ErrorMessage hasError={urlsCheckerError} data-testid='listForm-urls-error'>
            {errors > 0 && entries.length <= 100 && I18n.t('invalid_urls', { count: errors })}
            {(error === 'invalid list' || entries.length > 100) && 
              (I18n.t('max_of_x_websites_exceeded_please_correct_before_proceeding', { count: '100' }))}
          </ErrorMessage>
        </div>

        {isEditMode && buildTimestamps()}

        <footer className='co-listForm-footer' data-testid='listForm-footer'>
          {buildSaveButton()}
          <button
            className='button button--secondaryWhite co-modal-cancel'
            data-testid='listForm-cancelButton'
            onClick={() => handleModalClose()}
          >
            {I18n.t('cancel')}
          </button>
          {isEditMode && buildDeleteList()}
          {buildLeavePageModal()}
        </footer>
      </div>
    </>
  );
};

ListForm.propTypes = {
  currentlyOpenSubFlyout: PropTypes.string,
  currentWebRulesSection: PropTypes.string,
  currentlyOpenModal: PropTypes.string,
  error: PropTypes.object,
  handleClose: PropTypes.func.isRequired,
  handleDelete: PropTypes.func,
  handleSave: PropTypes.func.isRequired,
  urls: PropTypes.array,
  webListToEdit: PropTypes.object.isRequired,
  openModal: PropTypes.func,
  closeModal: PropTypes.func,
  getCanClose: PropTypes.func,
};

const mapState = (state) => ({
  currentlyOpenSubFlyout: state.ui.currentlyOpenSubFlyout,
  currentlyOpenModal: state.ui.currentlyOpenModal,
  currentWebRulesSection: state.ui.currentWebRulesSection,
  webListToEdit: state.webRules.webListToEdit,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  openModal,
  closeModal,
}, dispatch);

export default connect(mapState, mapDispatchToProps)(ListForm);
