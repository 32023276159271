import I18n from '../../utilities/i18n';

import {
  ATTEMPT_LOGIN,
  LOGIN_ERROR,
  RECEIVE_CURRENT_USER,
  CLEAR_CURRENT_USER,
  CURRENT_USER_DATA_LOADED,
  SET_REDIRECT_URL,
  SET_CHECK_IN_NOTIFICATION_DISABLED,
  SET_BULK_CHECK_IN_NOTIFICATION_DISABLED,
} from './actions';

export const defaultState = {
  loggingIn: false,
  loggedIn: false,
  hasLoggedOut: false,
  dataLoaded: false,
  is_admin: false,
  loginError: '',
  jwt: null,
  email: null,
  redirectUrl: null,
  iceServers: [],
  first_name: null,
  last_name: null,
  betas: {},
  check_in_notification_disabled: false,
};

export const currentUserReducer = (state = defaultState, action) => {
  switch (action.type) {
    case ATTEMPT_LOGIN:
      return {
        ...defaultState,
        loggingIn: true,
        redirectUrl: state.redirectUrl,
      };
    case LOGIN_ERROR: {
      let loginError = '';

      switch (action.error) {
        case 'no license':
          loginError = I18n.t('you_do_not_have_a_license_for_this_product');
          break;
        case 'bad roles':
          loginError = I18n.t('insufficient_privileges_please_contact_admin');
          break;
        case 'no classes':
          loginError = I18n.t('you_do_not_have_any_classes_assigned');
          break;
        case 'jwt expired':
          loginError = I18n.t('login_link_has_expired');
          break;
        case 'not_found':
          loginError = I18n.t('the_username_password_you_entered_is_invalid');
          break;
        case 'username must not be empty':
          loginError = I18n.t('email_must_not_be_blank');
          break;
        case 'password must not be empty':
          loginError = I18n.t('password_must_not_be_blank');
          break;
        case 'unauthenticated':
          loginError = I18n.t('the_username_password_you_entered_is_invalid');
          break;
        case 'The incoming token has expired':
          loginError = I18n.t('login_link_has_expired');
          break;
        default:
          loginError = I18n.t('an_unknown_error_occured_please_contact_admin');
          break;
      }

      return {
        ...defaultState,
        loginError,
      };
    }
    case RECEIVE_CURRENT_USER:
      return {
        ...defaultState,
        loggedIn: true,
        auth_customer_id: action.user.auth_customer_id,
        auth_group_guid: action.user.auth_group_guid,
        betas: action.user.betas,
        customer_id: action.user.customer_id,
        email: action.user.email,
        first_name: action.user.first_name,
        guid: action.user.guid,
        iceServers: action.user.ice_servers,
        is_admin: action.user.is_admin,
        jwt: action.user.jwt,
        last_name: action.user.last_name,
        redirectUrl: action.user.redirect_url || state.redirectUrl,
        school_guid: action.user.school_guid,
        check_in_notification_disabled: action.user.check_in_notification_disabled,
        lightspeedSupportUserGuid: action.user.lightspeedSupportUserGuid,
      };
    case CURRENT_USER_DATA_LOADED:
      return {
        ...state,
        dataLoaded: true,
      };
    case CLEAR_CURRENT_USER:
      return {
        ...defaultState,
        hasLoggedOut: true,
      };
    case SET_REDIRECT_URL:
      return {
        ...state,
        redirectUrl: action.url,
      };
    case SET_CHECK_IN_NOTIFICATION_DISABLED:
      return {
        ...state,
        check_in_notification_disabled: true,
      };
    case SET_BULK_CHECK_IN_NOTIFICATION_DISABLED:
      return {
        ...state,
        bulk_check_in_notification_disabled: true,
      };
    default:
      return state;
  }
};
