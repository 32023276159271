import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { canAddStudentsByEmail } from '../../utilities/policy';
import I18n from '../../utilities/i18n';

import Icon from '../common/Icon';
import UpdateStatus from '../common/UpdateStatus';


const AddStudents = ({
  addStudent,
  currentGroupGuid,
  groupPolicy,
  inviteCode,
}) => {
  const [email, setEmail] = useState('');
  const [isAdding, setIsAdding] = useState(false);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsAdding(true);
    if (await addStudent(email, currentGroupGuid)) {
      setEmail('');
    }
    setIsAdding(false);
  };

  const buildAddStudentByEmail = () => {
    if (!canAddStudentsByEmail(groupPolicy)) {
      return null;
    }

    return (
      <section className='co-settings-section co-settings-section--flex' data-testid='addStudents-email'>
        <strong className='co-settings-section-title'>{I18n.t('using_their_school_email_address')}</strong>

        <UpdateStatus isActive={isAdding}>
          <form className='l-flex buttonGroup' onSubmit={handleSubmit}>
            <input
              data-testid='addStudents-email-input'
              type='email'
              className='input--large'
              placeholder='student@school.org'
              value={email}
              onChange={handleEmailChange}
            />
            <button
              data-testid='addStudents-email-button'
              className='button co-settings-addStudents-button'
              title={I18n.t('add_student')}
              type='submit'
            >
              <Icon name='icon-plus' />
            </button>
          </form>
        </UpdateStatus>
      </section>
    );
  };

  const buildAddStudentByInvite = () => {
    return (
      <section className='co-settings-section'>
        <p className='m-top--0'>
          <strong>{I18n.t('using_an_invite_code')}</strong>
        </p>
        <p className='m-vertical--0'>{I18n.t('students_can_request_to_add')}</p>

        <ul className='co-settings-invites-options'>
          <li>
            {I18n.t('option')} 1:
            <span className='p-horizontal--4'>{I18n.t('students_can_visit')}:</span>
            <span
              className='co-settings-invites-classCode'
              data-testid='addStudents-classcode'>
              {inviteCode}
            </span>
          </li>

          <li>
            {I18n.t('option')} 2:
            <span className='p-horizontal--4'>{I18n.t('students_can_use_the_following')}:</span>
            <br />
            <span className='m-left--12 co-settings-invites-classCode'>
              {process.env.REACT_APP_CLASSROOM_URL}join-group/{inviteCode}
            </span>
          </li>
        </ul>
      </section>
    );
  };

  return (
    <section className='co-settings-invitesContainer'>
      <h3 className='m-top--48'>{I18n.t('add_students')}</h3>
      <section className='co-settings-invites'>
        <p className='text--gray m-top--0'>
          {I18n.t('to_avoid_conflicts_with_other_classes_only_add')}
        </p>
        {buildAddStudentByEmail()}
        {buildAddStudentByInvite()}
      </section>
    </section>
  );
};

AddStudents.propTypes = {
  addStudent: PropTypes.func.isRequired,
  currentGroupGuid: PropTypes.string,
  groupPolicy: PropTypes.object.isRequired,
  inviteCode: PropTypes.string.isRequired,
};

export default AddStudents;
