import { 
  FETCH_WEBRULES,
  RECEIVE_WEBRULES,
  DELETED_WEBRULES,
  DELETING_WEBRULES,
  UPDATED_WEBRULES,
  UPDATING_WEBRULES,
  CREATED_WEBRULES,
  CREATING_WEBRULES,
  GET_EDIT_LIST,
} from './actions';

export const defaultState = {
  allowList: {},
  blockList: {},
  customAllow: [],
  customBlock: [],
  fetching: false,
  webListToEdit: {
    name: null,
    urls: [],
    createdAt: null,
    updatedAt: null,
  },
};

export function webRulesReducer(state = defaultState, action) {
  switch (action.type) {
    case RECEIVE_WEBRULES: 
      return {
        ...state,
        ...action.webRules,
        fetching: false,
      };
    case DELETED_WEBRULES:
      return {
        ...state,
        fetching: false,
      };
    case CREATED_WEBRULES:
      return {
        ...state,
        fetching: false,
      };
    case UPDATED_WEBRULES:
      return {
        ...state,
        fetching: false,
      };
    case FETCH_WEBRULES:
      return {
        ...state,
        fetching: true,
      };
    case DELETING_WEBRULES:
      return {
        ...state,
        fetching: true,
      };
    case CREATING_WEBRULES:
      return {
        ...state,
        fetching: true,
      };
    case UPDATING_WEBRULES:
      return {
        ...state,
        fetching: true,
      };
    case GET_EDIT_LIST: {
      return {
        ...state,
        webListToEdit: {
          name: action.listName,
          urls: action.urls,
          createdAt: action.createdAt,
          updatedAt: action.updatedAt,
        },
      };
    }
    default:
      return state;
  }
}
