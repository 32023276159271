import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { setDisplayedUsers, clearDisplayedUsers } from '../../../redux/users/actions';
import { updateSchedule } from '../../../redux/groups/actions';

import {
  isClassroomEnabled,
  maximumPeriodDuration,
  validScheduleTypes,
  isOnCampusOnly,
} from '../../../utilities/policy';
import { getCurrentGroup, getCurrentGroupIsLoaded } from '../../../utilities/groups';
import I18n from '../../../utilities/i18n';
import { createDummySchedule, hasValidScheduleDuration } from '../../../utilities/schedule';
import { getTimeRemainingString } from '../../../utilities/scheduleTimes';
import { getGroupUrl } from '../../../utilities/urls';
import {
  getUserGuids,
  getUserInfos,
  getUserHosts,
  getEmptyHost,
  getEmptyStatusesCI,
  getUserStatusesCI,
  getEmptyAttendences,
  getUserAttendences,
} from '../../../utilities/users';

import { useInterval } from '../../../helpers/hooks';

import { ReactComponent as AlarmClock } from '../../../images/alarmClockCI.svg';
import { ReactComponent as DooExcited } from '../../../images/dooExcited.svg';

import ClassDropdown from './ClassDropdown';
import LoadingButton from '../LoadingButton';
import Invites from './Invites';
import EveryoneFilter from './EveryoneFilter';
import TopBrowsingFilterList from './TopBrowsingFilterList';
import AttendanceFilterList from './AttendanceFilterList';
import TaskStatusFilterList from './TaskStatusFilterList';
import Tm from '../../SignIn/Tm';
import NavCollapseTab from './NavCollapseTab';

const Nav = ({
  currentUser,
  currentGroup,
  currentGroupIsLoaded,
  allUsers,
  ablyData,
  classes,
  groupPolicy,
  districtIps,
  setDisplayedUsers,
  clearDisplayedUsers,
  updateSchedule,
  checkingInUsers,
  navigationCollapsed,
}) => {
  const tenSeconds = 10000;
  const everyone = 'everyone';
  const noTimeRemaining = '0m';
  const hasClassroom = isClassroomEnabled(groupPolicy);
  const hasClassSchedule = Boolean(currentGroup.class_schedule);
  const maxDuration = maximumPeriodDuration(groupPolicy);
  const hasValidDuration = hasValidScheduleDuration(currentGroup.class_schedule, maxDuration);
  const scheduleType = currentGroup.schedule_type;
  const isInSession = classes.inSession.includes(currentGroup.guid);
  const isInSessionAdHoc = (scheduleType === 'ad_hoc') && isInSession;
  const validTypes = validScheduleTypes(groupPolicy, currentUser.is_admin);
  const isTypeInvalid = scheduleType && !validTypes.includes(scheduleType);
  const ips = isOnCampusOnly(groupPolicy) ? districtIps : null;
  const hasInvalidHoursSchedule = currentGroup.invalid_schedule;

  const [selectedFilter, setSelectedFilter] = useState(everyone);
  const [timeRemaining, setTimeRemaining] = useState(noTimeRemaining);
  const [adHocIsStopping, setAdHocIsStopping] = useState(false);
  const [hosts, setHosts] = useState([]);
  const [statuses, setStatuses] = useState(getEmptyStatusesCI());
  const [attendences, setAttendences] = useState(getEmptyAttendences());

  useEffect(() => {
    const userGuids = getUserGuids(allUsers);
    const userInfos = getUserInfos(userGuids, allUsers, ablyData, ips);

    setHosts(getUserHosts(userInfos));
    setStatuses(getUserStatusesCI(userInfos));
    setAttendences(getUserAttendences(userInfos, currentGroup.guid));
  }, [allUsers, ablyData, ips, checkingInUsers]);

  useEffect(() => {
    setSelectedFilter(everyone);
  }, [currentGroup.guid, isInSession]);

  useEffect(() => {
    const isEveryone = selectedFilter === everyone;
    const statusEmails = statuses[selectedFilter];
    const attendenceEmails = attendences[selectedFilter];
    const isStatus = Array.isArray(statusEmails);
    const isAttendence = Array.isArray(attendenceEmails);
    const host = hosts.find((host) => host.label === selectedFilter);
    const isHost = Boolean(host);

    if (currentGroupIsLoaded) {
      if (isEveryone) {
        clearDisplayedUsers();
      } else if (isStatus) {
        setDisplayedUsers(statusEmails, I18n.t(selectedFilter));
      } else if (isAttendence) {
        setDisplayedUsers(attendenceEmails, I18n.t(selectedFilter));
      } else if (isHost) {
        setDisplayedUsers(host.users, host.label);
      } else {
        setDisplayedUsers([], selectedFilter);
      }
    }
  }, [selectedFilter, hosts, statuses, currentGroupIsLoaded, setDisplayedUsers, clearDisplayedUsers]);

  useEffect(() => {
    if (isInSessionAdHoc) {
      setTimeRemaining(getTimeRemainingString(currentGroup.class_schedule));
      setAdHocIsStopping(false);
    }
  }, [isInSessionAdHoc, currentGroup.class_schedule]);

  useInterval(() => {
    setTimeRemaining(getTimeRemainingString(currentGroup.class_schedule));
  }, isInSessionAdHoc && !adHocIsStopping ? tenSeconds : null);

  const stopAdHocHandler = () => {
    updateSchedule(currentGroup.guid, createDummySchedule(groupPolicy.allowed_hours));
    setAdHocIsStopping(true);
    setTimeRemaining(noTimeRemaining);
  };

  const buildScheduleStatus = () => {
    if (!hasClassroom || !isInSessionAdHoc) {
      return null;
    }

    return (
      <div className='co-nav-adHocSchedule buttonGroup l-flex'>
        <Link
          className='co-nav-adHocSchedule-button button button--secondaryWhite l-flex-item--allotWidth'
          to={getGroupUrl(currentGroup, '/settings')}
        >
          {I18n.t('remaining', { time: timeRemaining })}
        </Link>

        <LoadingButton
          classes='co-nav-adHocSchedule-button button button--secondaryWhite text--gold700'
          onClick={stopAdHocHandler}
          label= {I18n.t('stop')}
          isLoading={adHocIsStopping}
          testId='adhoc-stop-button'
        />
      </div>
    );
  };

  const buildInvites = () => {
    if (!hasClassroom || !currentGroupIsLoaded) {
      return null;
    }
    return (
      <Invites />
    );
  };

  const buildSettingsButton = () => {
    if (!hasClassroom) {
      return null;
    }

    const buttonClasses = classNames({
      button: true,
      'co-nav-settings-link': true,
      'button-link': true,
      'button--secondaryWhite': true,
      'link--noUnderline text--colorInherit': true,
    });

    return (
      <div className='l-flex'>
        <Link
          className={buttonClasses}
          to={getGroupUrl(currentGroup, '/settings')}
          data-testid='moreOptions-classSettings-button'
        >
          {I18n.t('class_settings')}
        </Link>
      </div>
    );
  };

  const buildClassStatus = () => {
    if (!hasClassroom || !currentGroupIsLoaded) {
      return null;
    }

    if (isInSession) {
      return buildFilterArea();
    }

    return buildOutOfSessionMessage();
  };

  const buildOutOfSessionMessage = () => {
    if (isTypeInvalid) {
      const typeName = I18n.t(scheduleType);
      const lowerCaseTypeName = typeName.toLowerCase();
      const message = I18n.t('your_administrator_disabled_schedules', { scheduleType: lowerCaseTypeName });
      return buildScheduleMessage(message);
    }

    if (hasInvalidHoursSchedule) {
      const message = I18n.t('current_schedule_outside_hours');
      return buildScheduleMessage(message);
    }

    if (hasClassSchedule) {
      const message = I18n.t('this_class_is_not_in_session');
      return buildScheduleMessage(message);
    }

    return buildWelcomeMessage();
  };

  const buildScheduleMessage = (message) => {
    return (
      <section className='co-nav-message'>
        <AlarmClock className='co-nav-message-icon' />
        <p className='co-nav-message-text'>{message}</p>
        <Link
          className='co-nav-message-link link--underlineHover'
          to={getGroupUrl(currentGroup, '/settings')}
        >
          {buildDurationError()}
          {I18n.t('view_schedule')}
        </Link>
      </section>
    );
  };

  const buildWelcomeMessage = () => {
    return (
      <section className='co-nav-message'>
        <DooExcited />
        <h3 className='m-top--8'>{I18n.t('welcome_to_your_class')}</h3>
        <p>{I18n.t('before_student_activity_can_be_displayed_your_class')}</p>
        <Link className='button button--yellow' to={getGroupUrl(currentGroup, '/settings')}>
          {I18n.t('configure_schedule')}
        </Link>
      </section>
    );
  };

  const buildDurationError = () => {
    if (hasValidDuration || (scheduleType !== 'custom')) {
      return null;
    }

    return (
      <div className='m-top--4 m-bottom--8'>
        <div>
          <strong>{I18n.t('update_required')}</strong>
        </div>
        <div>
          {I18n.t('your_administrator_has_set_the_maximum_period', { maxDuration })}
        </div>
      </div>
    );
  };

  const buildFilterArea = () => {
    if (!isInSession) {
      return null;
    }

    const selectedFilterHandler = (label) => setSelectedFilter(label);

    return (
      <>
        {buildEveryoneFilter()}
        {buildCurrentlyBrowsing()}
        <AttendanceFilterList
          attendanceStatus={attendences}
          selectedFilter={selectedFilter}
          onTabClick={selectedFilterHandler}
        />
        <TaskStatusFilterList
          taskStatus={statuses}
          selectedFilter={selectedFilter}
          onTabClick={selectedFilterHandler}
        />
      </>
    );
  };

  const buildEveryoneFilter = () => {
    const isActive = selectedFilter === everyone;
    const userCount = allUsers ? Object.keys(allUsers).length : 0;

    return (
      <EveryoneFilter
        value={everyone}
        count={userCount}
        isSelected={isActive}
        onClick={() => setSelectedFilter(everyone)}
      />
    );
  };

  const buildCurrentlyBrowsing = () => {
    const isEveryone = selectedFilter === everyone;
    const isStatus = Array.isArray(statuses[selectedFilter]);
    const isAttendence = Array.isArray(attendences[selectedFilter]);
    const isHost = Boolean(hosts.find((host) => host.label === selectedFilter));
    const isEmptyHostSelected = !isEveryone && !isStatus && !isAttendence && !isHost;

    let allHosts = hosts;
    if (isEmptyHostSelected) {
      allHosts = [...hosts, getEmptyHost(selectedFilter)];
    }

    return (
      <TopBrowsingFilterList
        hosts={allHosts}
        selectedFilter={selectedFilter}
        onTabClick={(label) => setSelectedFilter(label)}
      />
    );
  };

  const navContainerToggleClasses = classNames({
    'co-navContainer': true,
    'co-navContainer--hidden': navigationCollapsed,
  });

  return (
    <section className={navContainerToggleClasses}>
      <section className='co-nav'>
        <NavCollapseTab />
        <div className='co-nav-trademark-text' >
          {/* same in every language */}
          Lightspeed Classroom Management
          <Tm className='co-GenericLogin-tm' />
        </div>

        <div className='co-nav-actionArea'>
          <ClassDropdown />
          {buildScheduleStatus()}
          {buildInvites()}
          {buildSettingsButton()}
        </div>

        {buildClassStatus()}
      </section>
    </section>
  );
};

Nav.propTypes = {
  currentUser: PropTypes.object.isRequired,
  currentGroup: PropTypes.object.isRequired,
  currentGroupIsLoaded: PropTypes.bool.isRequired,
  allUsers: PropTypes.object.isRequired,
  ablyData: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  groupPolicy: PropTypes.object,
  districtIps: PropTypes.arrayOf(PropTypes.string).isRequired,
  setDisplayedUsers: PropTypes.func,
  clearDisplayedUsers: PropTypes.func,
  updateSchedule: PropTypes.func.isRequired,
  checkingInUsers: PropTypes.bool.isRequired,
  navigationCollapsed: PropTypes.bool.isRequired,
};

const mapState = (state) => ({
  currentUser: state.currentUser,
  currentGroup: getCurrentGroup(state.groups),
  currentGroupIsLoaded: getCurrentGroupIsLoaded(state.groups),
  allUsers: state.users.all,
  ablyData: state.ably.data,
  classes: state.classes,
  groupPolicy: state.policy.group,
  districtIps: state.district.ips,
  checkingInUsers: state.users.checkingIn,
  navigationCollapsed: state.ui.navigationCollapsed,
});

const mapDispatch = {
  setDisplayedUsers,
  clearDisplayedUsers,
  updateSchedule,
};

export default connect(mapState, mapDispatch)(Nav);
