
const MAX_LIST_LEN = 20;
const MAX_URL_LEN = 100;
const VALID_KEYS = ['customAllow', 'customBlock', 'allowList', 'blockList'];

export function validateShape(obj) {
  const keys = Object.keys(obj);
  return keys.filter((key) => VALID_KEYS.includes(key) > 0).length === VALID_KEYS.length;
}

export function validateWebRules(webRules) {
  if (!validateShape(webRules)) {
    return false; 
  }
  const validListUrls = (type, list) => webRules[type][list].length <= MAX_URL_LEN;
  const validListLength = (list) => Object.values(list).length <= MAX_LIST_LEN;
  const validLists = validListLength(webRules.allowList) && validListLength(webRules.blockList);
  const validCustomURLS = webRules.customAllow.length <= MAX_URL_LEN && webRules.customBlock.length <= MAX_URL_LEN;
  const validAllow = Object.keys(webRules.allowList).reduce((p, c) => validListUrls('allowList', c) && p, true);
  const validBlock = Object.keys(webRules.blockList).reduce((p, c) => validListUrls('blockList', c) && p, true);
  return validBlock && validAllow && validLists && validCustomURLS;
}

export function getAllowList(webRules) {
  return Object.entries(webRules.allowList);
}

export function getBlockList(webRules) {
  return Object.entries(webRules.blockList);
}

export function getAllowListLength(webRules) {
  return getAllowList(webRules).length;
}

export function getBlockListLength(webRules) {
  return getBlockList(webRules).length;
}

export function webRulesInit(segment, currentWebzone) {
  if (segment) {
    if (!segment?.web_rules.sites) {
      // 'None' web rules
      return {
        groupGuid: segment.guid,
      };
    }
    return {
      groupGuid: segment.guid,
      settings: segment.web_rules,
    };
  }

  return currentWebzone;
}
