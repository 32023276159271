import React, { useCallback, useEffect, useReducer, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { initThumbnail, shutdownThumbnail } from '../../redux/ably/actions';
import {
  addSegment,
  removeSegment,
  modifySegment,
  moveSegmentMember,
} from '../../redux/groupSegments/actions';
import {
  closeFlyout,
  openModal,
  closeModal,
  stopBrowsingTimeout,
} from '../../redux/ui/actions';

import { isClassroomEnabled, canViewAllScreens, isOnCampusOnly } from '../../utilities/policy';
import {
  getCurrentGroup,
  getCurrentGroupIsLoaded,
  getUpdatingDefault,
  updatingReducer,
} from '../../utilities/groups';
import I18n from '../../utilities/i18n';
import { byKey, getUserGuids, getUserInfos } from '../../utilities/users';
// import { hasGroupOrUserBetas } from '../../utilities/betas';

import { useLocalStorage } from '../../helpers/hooks';
import { policySettingChecker, policySettings } from '../../helpers/policySettingChecker';

import GroupError from './GroupError';
import GroupSegment from './GroupSegment';
import GroupShowControls from './GroupShowControls';
import Insights from './Insights/Insights';
import Loader from '../common/Loader';
import Modal from '../common/Modal';
import VideoChat from './VideoChat';
import UpdateStatus from '../common/UpdateStatus';
import Icon from '../common/Icon';
import ShareScreenModal from '../common/Nav/MoreOptions/ShareScreenModal';
import ScreenLockModal from '../common/Nav/MoreOptions/ScreenLockModal';
import { CloseIcon } from 'design-system';

const GroupShowPage = ({
  currentGroup,
  currentGroupIsLoaded,
  currentSegments,
  currentUser,
  usersLoading,
  allUsers,
  displayType,
  displayed,
  ablyData,
  insights,
  groupPolicy,
  classes,
  districtIps,
  currentlyOpenModal,
  initThumbnail,
  shutdownThumbnail,
  moveSegmentMember,
  closeFlyout,
  closeModal,
  openModal,
  modifySegment,
  addSegment,
  removeSegment,
  stopBrowsingTimeout,
  // userBetas,
  // classroomGroupBetas,
}) => {
  const modalName = 'showAllScreens';
  const isFiltered = Boolean(displayType);
  const usersFilter = isFiltered ? displayType : I18n.t('everyone');
  const haveNoUsers = (displayed ?? getUserGuids(allUsers)).length === 0;
  const ips = isOnCampusOnly(groupPolicy) ? districtIps : null;
  const isInSession = classes.inSession.includes(currentGroup.guid);
  const hasScreensViewPolicy = policySettingChecker(groupPolicy, policySettings.ENHANCED_SCREENS_VIEW);
  const hasHeatMapViewPolicy = policySettingChecker(groupPolicy, policySettings.HEAT_MAP_VIEW);
  // named legacy to correspond with setting name in TD (Retain legacy "View all Screens")
  const hasLegacyViewAllScreens = groupPolicy?.legacy_view_all_screens;

  const [insightInfos, setInsightInfos] = useState([]);
  const [defaultInfos, setDefaultInfos] = useState([]);
  const [infoSegments, setInfoSegments] = useState([]);
  const [viewingAllScreens, setViewingAllScreens] = useState(false);
  const [usersBeingViewed, setUsersBeingViewed] = useState([]);
  // to handle the enhanced_screens_view policy being toggled on or off in Relay
  const initialCurrentGroupNavTab = hasScreensViewPolicy ? 'screensView' : 'listView';
  const [currentGroupNavTab, setCurrentGroupNavTab] = useLocalStorage(
    // local storage key
    'CurrentGroupContainerTab',
    // local storage value
    initialCurrentGroupNavTab,
  );
  const [updating, dispatchUpdating] = useReducer(updatingReducer, getUpdatingDefault());
  const [segmentToRemove, setSegmentToRemove] = useState(null);
  const [showScreensViewBannerFlag, setShowScreensViewBannerFlag] = useState(true);
  // const hasScreensViewBeta = hasGroupOrUserBetas(
  //   'enhanced_screens_view',
  //   userBetas,
  //   classroomGroupBetas,
  // );

  function showAllScreens() {
    const allInfos = infoSegments.reduce((infos, segment) => {
      return [...infos, ...segment.infos];
    }, [...defaultInfos]);
    const showInfos = allInfos.filter((userInfo) => {
      return userInfo.online && !usersBeingViewed.includes(userInfo.email);
    });
    const showEmails = showInfos.map((userInfo) => userInfo.email);

    for (const email of showEmails) {
      initThumbnail(email);
    }

    setUsersBeingViewed((viewed) => [...viewed, ...showEmails]);
    setViewingAllScreens(true);
  }

  useEffect(() => {
    if (isInSession) {
      setTimeout(() => {
        stopBrowsingTimeout();
      }, 3000);
    }
  }, [isInSession]);

  const hideAllScreens = useCallback(() => {
    setUsersBeingViewed((viewed) => {
      for (const email of viewed) {
        shutdownThumbnail(email);
      }
      return [];
    });
    setViewingAllScreens(false);
  }, [shutdownThumbnail]);

  useEffect(() => {
    return closeFlyout;
  }, [closeFlyout]);

  const toggleScreen = useCallback((email, shouldView) => {
    if (shouldView) {
      setUsersBeingViewed((viewed) => [...viewed, email]);
      initThumbnail(email);
    } else {
      setUsersBeingViewed((viewed) => viewed.filter((viewedEmail) => viewedEmail !== email));
      shutdownThumbnail(email);
    }
  }, [initThumbnail, shutdownThumbnail]);

  useEffect(() => {
    const insightGuids = insights.active.map((insight) => insight.guid);
    setInsightInfos(getUserInfos(insightGuids, allUsers, ablyData, ips));
  }, [insights.active, allUsers, ablyData, ips]);

  useEffect(() => {
    const displayedGuids = displayed ?? getUserGuids(allUsers);
    let defaultGuids = [...displayedGuids];
    const segments = [];

    for (const segment of currentSegments) {
      const segmentGuids = [];
      defaultGuids = defaultGuids.filter((guid) => {
        const isInSegment = segment.member_guids.includes(guid);
        if (isInSegment) {
          segmentGuids.push(guid);
        }
        return !isInSegment;
      });

      segments.push({
        guid: segment.guid,
        name: segment.name,
        member_guids: segment.member_guids,
        infos: getUserInfos(segmentGuids, allUsers, ablyData, ips),
        web_rules: segment.web_rules,
      });
    }

    const byName = byKey('name');
    setDefaultInfos(getUserInfos(defaultGuids, allUsers, ablyData, ips));
    setInfoSegments([...segments].sort(byName));
  }, [displayed, allUsers, ablyData, ips, currentSegments]);

  useEffect(() => {
    return () => hideAllScreens();
  }, [hideAllScreens]);

  useEffect(() => {
    hideAllScreens();
  }, [hideAllScreens, currentGroup.guid, isInSession]);


  const viewAllScreensHandler = () => {
    if (viewingAllScreens) {
      hideAllScreens();
    } else {
      openModal(modalName);
    }
  };

  const confirmShowAllScreens = () => {
    showAllScreens();
    closeModal();
  };

  const handleDropMember = async (guid, memberGuid) => {
    if (guid) {
      const segment = currentSegments.find((segment) => segment.guid === guid);
      const alreadyMember = segment.member_guids.includes(memberGuid);
      if (alreadyMember) {
        return;
      }
    }

    dispatchUpdating({ type: 'member', guid, memberGuid });
    const memberEmail = allUsers[memberGuid].email;
    await moveSegmentMember(currentGroup.guid, guid, memberGuid, memberEmail);
    dispatchUpdating({ type: 'done' });
  };

  const handleSaveName = async (guid, name) => {
    dispatchUpdating({ type: 'name', guid });
    await modifySegment(currentGroup.guid, guid, { name });
    dispatchUpdating({ type: 'done' });
  };

  const findUniqueName = (nameStart, segments) => {
    const buildName = (count) => `${nameStart} ${count}`;
    const isUnique = (value) => !segments.find((segment) => segment.name === value);

    let count = 1;
    let name = buildName(count);

    while (!isUnique(name)) {
      count = count + 1;
      name = buildName(count);
    }

    return name;
  };

  const handleAddSegment = async () => {
    dispatchUpdating({ type: 'add' });
    const name = findUniqueName('New Group', currentSegments);
    await addSegment(currentGroup.guid, name);
    dispatchUpdating({ type: 'done' });
  };

  const buildInsights = () => {
    return (
      <Insights
        activeInsights={insights.active}
        userInfos={insightInfos}
      />
    );
  };

  const handleRemoveSegment = (guid) => {
    const segment = currentSegments.find((segment) => segment.guid === guid);
    if (segment) {
      setSegmentToRemove(segment);
    }
  };

  const confirmRemoveSegment = async () => {
    const guid = segmentToRemove && segmentToRemove.guid;
    setSegmentToRemove(null);

    if (guid) {
      dispatchUpdating({ type: 'remove', guid });
      await removeSegment(currentGroup.guid, guid);
      localStorage.removeItem(`ClassroomLastCustom::g::${guid}`);
      localStorage.removeItem(`_ClassroomLastCustom::g::${guid}_timestamp`);
      localStorage.removeItem(`ClassroomLastWebzoneType::g::${guid}`);
      localStorage.removeItem(`_ClassroomLastWebzoneType::g::${guid}_timestamp`);
      dispatchUpdating({ type: 'done' });
    }
  };

  const buildShowControls = () => {
    return (
      <GroupShowControls
        hasScreensViewPolicy={hasScreensViewPolicy}
        hasHeatMapViewPolicy={hasHeatMapViewPolicy}
        noUsers={haveNoUsers}
        usersFilter={usersFilter}
        setCurrentGroupNavTab={setCurrentGroupNavTab}
        currentGroupNavTab={currentGroupNavTab}
      />
    );
  };

  const buildAddButton = () => {
    if (haveNoUsers) {
      return null;
    }

    return (
      <div className='co-groupPage'>
        <UpdateStatus isActive={updating.add}>
          <button
            className='co-segments-addButton button-link'
            aria-label={I18n.t('create_group')}
            onClick={handleAddSegment}
            data-testid='groupSegmentsPage-add-button'
          >
            <div className='co-segments-plusIcon'>
              <Icon name='icon-plus' />
            </div>
            {I18n.t('create_group')}
          </button>
        </UpdateStatus>
      </div>
    );
  };

  const buildGroups = () => {
    const defaultSegment = {
      guid: null,
      name: I18n.t('default_group'),
      member_guids: defaultInfos.map((info) => info.guid),
      infos: defaultInfos,
    };

    if (haveNoUsers) {
      return (
        <div className='text--gray text--alignCenter m-vertical--40' data-testid='groupShowPage-noUsers'>
          {I18n.t('nothing_nada_zilch')}
        </div>
      );
    }
    // TODO restore isFiltered once groups can be reordered by drag
    return (
      <ul className='co-groupPage-list' data-testid='groupShowPage-groupsList'>
        {infoSegments.map((segment) => (
          <li key={segment.guid}>
            <GroupSegment
              hasScreensViewPolicy={hasScreensViewPolicy}
              hasHeatMapViewPolicy={hasHeatMapViewPolicy}
              segment={segment}
              noUsers={haveNoUsers}
              currentGroupNavTab={currentGroupNavTab}
              isDefault={false}
              isFiltered={true}
              isLoading={usersLoading}
              updating={updating}
              viewingAllScreens={viewingAllScreens}
              usersBeingViewed={usersBeingViewed}
              toggleViewUserScreen={toggleScreen}
              dropMember={handleDropMember}
              saveName={handleSaveName}
              removeSegment={handleRemoveSegment}
            />
          </li>
        ))}
        <li key='defaultSegment'>
          <GroupSegment
            hasScreensViewPolicy={hasScreensViewPolicy}
            hasHeatMapViewPolicy={hasHeatMapViewPolicy}
            segment={defaultSegment}
            noUsers={haveNoUsers}
            currentGroupNavTab={currentGroupNavTab}
            isDefault={true}
            isFiltered={true}
            isLoading={usersLoading}
            updating={updating}
            viewingAllScreens={viewingAllScreens}
            usersBeingViewed={usersBeingViewed}
            toggleViewUserScreen={toggleScreen}
            dropMember={handleDropMember}
          />
        </li>
      </ul>
    );
  };

  const buildHeatMapControls = () => {
    if (haveNoUsers || (currentGroupNavTab !== 'heatMapView')) {
      return null;
    }

    let showScreensButton;

    if (
      (!hasScreensViewPolicy && !hasHeatMapViewPolicy && canViewAllScreens(groupPolicy)) ||
      (hasHeatMapViewPolicy && hasLegacyViewAllScreens)
    ) {
      const allScreensText = viewingAllScreens
        ? I18n.t('hide_all_screens')
        : I18n.t('show_all_screens');
      showScreensButton = (
        <button
          className='button-link text--uppercase'
          onClick={() => viewAllScreensHandler()}
          disabled={!isInSession}
          data-testid='groupShowPage-shareScreens-button'
        >
          {allScreensText}
        </button>
      );
    }

    return (
      <div className='co-groupPage co-usersHeatMap-controls' data-testid='groupShowPage-heatMap'>
        <div className='l-flex-item'>
          {showScreensButton}
        </div>

        {(!hasScreensViewPolicy || (hasScreensViewPolicy && hasHeatMapViewPolicy)) &&
          <section className='co-usersHeatMap-controlsLegend'>
            <span>{I18n.t('has_insight')}</span>
            <span className='co-usersHeatMap-insightIndicator'></span>
            <span>{I18n.t('recent_websites')}</span>
            <span className='co-usersHeatMap-recentRange-legend'>
              {I18n.t('few')}
              <span className='co-usersHeatMap-recentRange'></span>
              {I18n.t('many')}
            </span>
          </section>}
      </div>
    );
  };

  const buildRemoveModal = () => {
    const show = Boolean(segmentToRemove);
    const name = show ? segmentToRemove.name : '';

    // TODO add warning icon
    // TODO position modal below delete button (see design)
    return (
      <Modal
        title={I18n.t('remove_group')}
        show={show}
        customSaveButton={buildRemoveButton()}
        closeText={I18n.t('keep')}
        closeModal={() => setSegmentToRemove(null)}
        isSmall
        modalRedesign2022Q1
        legacyModalVersion
      >
        <p>
          {I18n.t('are_you_sure_delete', { name, confirmName: name })}
        </p>
      </Modal>
    );
  };

  const buildRemoveButton = () => (
    <button
      className='co-modal-warningButton'
      onClick={() => confirmRemoveSegment()}
      data-testid='groupSegmentsPage-remove-button'
    >
      {I18n.t('delete')}
    </button>
  );

  const buildShowScreensModal = () => {
    return (
      <Modal
        show={currentlyOpenModal === modalName}
        title={I18n.t('show_all_screens')}
        saveHandler={confirmShowAllScreens}
        saveText={I18n.t('continue')}
        closeModal={closeModal}
        isSmall
        modalRedesign2022Q1
        legacyModalVersion
      >
        <p className='alert m-vertical--0'>
          <strong className=' m-right--4'>{I18n.t('important')}:</strong>
          {I18n.t('showing_all_screen_at_the_same_time')}
        </p>
      </Modal>
    );
  };

  if (!currentGroupIsLoaded) {
    return <Loader />;
  }

  if (!isClassroomEnabled(groupPolicy)) {
    return (
      <GroupError
        title={I18n.t('group_unavailable')}
        message={I18n.t('your_administrator_has_not_enabled_monitoring_for_this_group')}
      />
    );
  }

  const handleScreensViewBannerClick = () => {
    setShowScreensViewBannerFlag(false);
    localStorage.setItem(`${currentUser.guid}_showScreensViewBanner`, JSON.stringify(!showScreensViewBannerFlag));
  };

  const buildScreensViewBanner = () => {
    if (!groupPolicy.enhanced_screens_view) {
      return null;
    }
    
    const screensViewBannerFlagInLocalStorage = localStorage.getItem(`${currentUser.guid}_showScreensViewBanner`);

    if (currentGroupNavTab === 'screensView' && screensViewBannerFlagInLocalStorage === null) {
      return (
        <div className='text--alignCenter'>
          <span className='alert'>
            Currently recommended for Chrome and Windows devices only. Full Mac support coming soon.
            <CloseIcon
              className='co-page-close-icon'
              onClick={handleScreensViewBannerClick}
              data-testid='CeleritasCloseIcon'
            />
          </span>
        </div>
      );
    }
  };

  return (
    <>
      <main className='co-page-main' data-testid='groupShowPage-content'>
        <section className='co-page-main-content'>
          <div className='co-page-insights-video-wrapper'>
            <VideoChat inSession={isInSession} />
            {buildInsights()}
          </div>
          {buildShowControls()}
          {buildScreensViewBanner()}
          {buildGroups()}
          {buildAddButton()}
          {buildHeatMapControls()}
        </section>
      </main>
      {buildShowScreensModal()}
      {buildRemoveModal()}
      <ShareScreenModal />
      <ScreenLockModal />
    </>
  );
};

GroupShowPage.propTypes = {
  currentGroup: PropTypes.object.isRequired,
  currentGroupIsLoaded: PropTypes.bool.isRequired,
  currentSegments: PropTypes.arrayOf(PropTypes.object).isRequired,
  currentUser: PropTypes.object.isRequired,
  usersLoading: PropTypes.bool.isRequired,
  allUsers: PropTypes.object.isRequired,
  displayType: PropTypes.string,
  displayed: PropTypes.arrayOf(PropTypes.string),
  ablyData: PropTypes.object.isRequired,
  insights: PropTypes.object.isRequired,
  groupPolicy: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  districtIps: PropTypes.arrayOf(PropTypes.string).isRequired,
  currentlyOpenModal: PropTypes.string,
  initThumbnail: PropTypes.func.isRequired,
  shutdownThumbnail: PropTypes.func.isRequired,
  moveSegmentMember: PropTypes.func.isRequired,
  closeFlyout: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  openModal: PropTypes.func,
  modifySegment: PropTypes.func.isRequired,
  addSegment: PropTypes.func.isRequired,
  removeSegment: PropTypes.func.isRequired,
  stopBrowsingTimeout: PropTypes.func.isRequired,
  // classroomGroupBetas: PropTypes.object,
  // userBetas: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  currentGroup: getCurrentGroup(state.groups),
  currentGroupIsLoaded: getCurrentGroupIsLoaded(state.groups),
  currentSegments: state.groupSegments.segments,
  currentUser: state.currentUser,
  usersLoading: state.users.loading,
  allUsers: state.users.all,
  displayType: state.users.displayType,
  displayed: state.users.displayed,
  ablyData: state.ably.data,
  insights: state.insights,
  groupPolicy: state.policy.group,
  classes: state.classes,
  districtIps: state.district.ips,
  currentlyOpenModal: state.ui.currentlyOpenModal,
  userBetas: state.currentUser.betas,
  classroomGroupBetas: state.betas,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  initThumbnail,
  shutdownThumbnail,
  moveSegmentMember,
  closeFlyout,
  closeModal,
  openModal,
  modifySegment,
  addSegment,
  removeSegment,
  stopBrowsingTimeout,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(GroupShowPage);
