import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getDistrict } from '../redux/district/actions';
import { processEvents, updateGroup } from '../redux/classes/actions';
import { loginWithJwt, logoutUser, setRedirectUrl } from '../redux/currentUser/actions';
import { addNotification, openModal, closeModal } from '../redux/ui/actions';

import I18n from '../utilities/i18n';
import { setMinutesTimeout, isVersionUpdateNeeded } from '../utilities/time';
import { getLocationUrl } from '../utilities/urls';
import { getWebRules } from '../redux/webRules/actions';
import { useInterval } from '../helpers/hooks';
import { getBetas } from '../redux/betas/actions';
import { getCurrentGroup } from '../utilities/groups';

import BroadcastNotification from './common/BroadcastNotification';
import FlyoutContainer from './common/Flyout/FlyoutContainer';
import Header from './common/Header';
import Loader from './common/Loader';
import Notifications from './common/Notifications';
import Nav from './common/Nav/Nav';
import Modal from './common/Modal';

import '../css/__compiled__/main.css';

const App = ({
  children,
  noLayout,
  history,
  location,
  currentGroup,
  currentUser,
  ablyStatus,
  processEvents,
  updateGroup,
  loginWithJwt,
  setRedirectUrl,
  currentlyOpenModal,
  openModal,
  closeModal,
  district,
  getDistrict,
  getWebRules,
  getBetas,
}) => {
  const fiveMinutes = 1000 * 60 * 5;
  const twentyFourHours = 1000 * 60 * 60 * 24;
  const ablyConnected = ablyStatus === 'connected';
  const modalName = 'forceRefreshModal';

  useInterval(updateGroup, fiveMinutes);
  useInterval(getDistrict, twentyFourHours);

  useEffect(() => {
    if (currentUser.customer_id) {
      getBetas(currentUser.customer_id, 'district');
    }

    if (currentGroup.school_guid) {
      getBetas(currentGroup.school_guid, 'school');
    }

    if (currentGroup.guid) {
      getBetas(currentGroup.guid, 'group');
    }
  }, [currentUser.customer_id, getBetas, currentGroup.school_guid, currentGroup.guid]);

  useEffect(() => {
    const msAfterMinute = 100;
    const eventsTimeout = setMinutesTimeout(processEvents, 1, msAfterMinute);

    return () => {
      clearTimeout(eventsTimeout);
    };
  }, [updateGroup, processEvents]);

  useEffect(() => {
    if (!district.loaded || !district.minimumUIVersion) {
      return;
    }
    const currentVersion = process.env.REACT_APP_VERSION;
    if (isVersionUpdateNeeded(currentVersion, district.minimumUIVersion)) {
      openModal(modalName);
    }
  }, [district.loaded, district.minimumUIVersion, openModal, twentyFourHours]);

  useEffect(() => {
    if (!currentUser.loggedIn && !currentUser.loggingIn) {
      if (localStorage.jwt) {
        loginWithJwt();
      } else {
        if (!currentUser.hasLoggedOut) {
          // Set redirect to return to the current url after logging in
          setRedirectUrl(getLocationUrl(location));
        }
        history.push('/');
      }
    }
  }, [currentUser.loggedIn, currentUser.loggingIn, currentUser.hasLoggedOut,
    location, history, loginWithJwt, setRedirectUrl]);

  // get web rules at App level so lists are loaded when flyout opens
  useEffect(() => {
    if (currentUser.dataLoaded) {
      const guid = currentUser.guid;
      getWebRules(guid);
    }
  }, [currentUser.dataLoaded, currentUser.guid, getWebRules]);

  const buildContent = () => {
    if (noLayout) {
      return (
        <Fragment>
          {children}
        </Fragment>
      );
    }

    return (
      <Fragment>
        <Header />
        <main className='co-dashboard'>
          <Nav />
          {children}
          <FlyoutContainer />
        </main>
      </Fragment>
    );
  };

  const handleForceRefresh = () => {
    window.location.reload();
    closeModal(modalName);
  };

  const buildModalContent = () => {
    return (
      <p className='alert m-vertical--0' data-testid='openTabs-modal'>
        {I18n.t('classroom_requires_update')}
      </p>
    );
  };

  if (!currentUser.loggedIn || !currentUser.dataLoaded || !ablyConnected) {
    return (
      <Loader isFullscreen={true} />
    );
  }

  return (
    <Fragment>
      <Notifications />
      {buildContent()}
      <BroadcastNotification />
      <Modal
        show={currentlyOpenModal === modalName}
        title={I18n.t('update_classroom')}
        saveHandler={handleForceRefresh}
        saveText={I18n.t('refresh')}
        preventClosing={true}
        isSmall
        modalRedesign2022Q1
        legacyModalVersion
      >
        {buildModalContent()}
      </Modal>
    </Fragment>
  );
};

App.propTypes = {
  children: PropTypes.node,
  noLayout: PropTypes.bool,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  ablyStatus: PropTypes.string.isRequired,
  getDistrict: PropTypes.func.isRequired,
  processEvents: PropTypes.func.isRequired,
  updateGroup: PropTypes.func.isRequired,
  loginWithJwt: PropTypes.func.isRequired,
  setRedirectUrl: PropTypes.func.isRequired,
  openModal: PropTypes.func,
  closeModal: PropTypes.func.isRequired,
  currentlyOpenModal: PropTypes.string,
  district: PropTypes.object,
  getWebRules: PropTypes.func.isRequired,
  getBetas: PropTypes.func.isRequired,
  currentGroup: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  district: state.district,
  currentGroup: getCurrentGroup(state.groups),
  currentUser: state.currentUser,
  ablyStatus: state.ably.status,
  currentlyOpenModal: state.ui.currentlyOpenModal,
  webRules: state.webRules,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  getDistrict,
  processEvents,
  updateGroup,
  loginWithJwt,
  logoutUser,
  setRedirectUrl,
  addNotification,
  openModal,
  closeModal,
  getWebRules,
  getBetas,
  getCurrentGroup,
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
