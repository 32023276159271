import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import I18n from '../../../utilities/i18n';
import { createAdHocSchedule, isScheduleTimeAllowed } from '../../../utilities/schedule';

import Dropdown from '../../common/Dropdown';
import LoadingButton from '../../common/LoadingButton';

function getAvailableTimes (allowedHours, maxDuration) {
  return [15, 30, 45, 60, 75, 90, 105, 120].filter((time) => {
    const now = new Date();
    const adHocSchedule = createAdHocSchedule(now, time);
    return time <= maxDuration && isScheduleTimeAllowed(adHocSchedule, allowedHours, 'adHoc');
  });
}

function getDefaultTime(allowedHours, maxDuration) {
  const availableTimes = getAvailableTimes(allowedHours, maxDuration);
  // Default to 15 minutes if it is only available time
  if (availableTimes.includes(15) && availableTimes.length === 1) {
    return 15;
  }
  return 30;
}

const AdHocSchedule = ({
  readOnly,
  maxDuration,
  saveSchedule,
  allowedHours,
}) => {
  const [duration, setDuration] = useState(getDefaultTime(allowedHours, maxDuration));
  const [isSaving, setIsSaving] = useState(false);
  const dropdownDisabled = getAvailableTimes(allowedHours, maxDuration).length === 0;

  useEffect(() => {
    setIsSaving(false);
  }, []);

  const start = () => {
    if (isSaving) {
      return;
    }

    setIsSaving(true);
    const now = new Date();
    const schedule = createAdHocSchedule(now, duration);
    saveSchedule(schedule);
  };

  const buildDurationLabel = (minutes) => {
    const minsToHours = {
      75: '1:15',
      90: '1:30',
      105: '1:45',
      120: '2',
    };

    if (minutes > 60) {
      return I18n.t('number_of_hours', { numOfHours: minsToHours[minutes] });
    }

    if (minutes === 60) {
      return I18n.t('one_hour');
    }

    return `${minutes} ${I18n.t('minutes')}`;
  };

  const buildDropdown = () => {
    const dropdownName = 'adHocSchedule';
    const availableTimes = getAvailableTimes(allowedHours, maxDuration);
    const dropdownItems = availableTimes.map((time) => {
      return {
        label: buildDurationLabel(time),
        action: () => setDuration(time),
      };
    });

    return (
      <Dropdown
        initialLabel={buildDurationLabel(duration)}
        dropdownName={dropdownName}
        dropdownItems={dropdownItems}
        containerClasses='co-dropdown--fullWidth'
        buttonLabelClasses='co-dropdown--scheduleType-button'
        dropdownMenuClasses='co-dropdown-menu--fullWidth'
        showAsButton={true}
        hasTriangleArrow={true}
        hasArrow={false}
        isDisabled={dropdownDisabled}
        disabledLabel={'cannot_start_class_outside_allowed_schedule'}
      />
    );
  };

  const buildStartButton = () => {
    return (
      <LoadingButton
        classes='button button--blue m-left--8 analytics-startAdHocSchedule'
        onClick={start}
        label={I18n.t('start')}
        isLoading={isSaving}
        isDisabled={dropdownDisabled}
      />
    );
  };

  if (readOnly) {
    return null;
  }

  return (
    <section className='co-settings-section co-settings-section--flex'>
      <strong className='co-settings-section-title'>{I18n.t('session_duration')}</strong>

      <div className='co-schedules-dropdownButtonContainer'>
        {buildDropdown()}
        {buildStartButton()}
      </div>
    </section>
  );
};

AdHocSchedule.propTypes = {
  readOnly: PropTypes.bool.isRequired,
  maxDuration: PropTypes.number.isRequired,
  saveSchedule: PropTypes.func.isRequired,
  allowedHours: PropTypes.object,
};

export default AdHocSchedule;
