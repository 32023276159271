import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import SlideShow from 'react-slideshow-ui';

import {
  getSelectableDays,
  getRecordings,
  getTabs,
  clearRecordings,
} from '../../../redux/recordings/actions';

import I18n from '../../../utilities/i18n';

import DataLoader from '../DataLoader';
import DayPickerDropdown from '../DayPickerDropdown';
import Tab from './Tab';


const Recordings = ({
  userGuid,
  recordings,
  getSelectableDays,
  getRecordings,
  getTabs,
  clearRecordings,
}) => {
  const [dayPickerValue, setDayPickerValue] = useState(new Date());
  const [index, setIndex] = useState(0);
  const [, setTabTimeout] = useState(null);

  useEffect(() => {
    const tabs = recordings.tabsByIndex[index];

    if (!tabs && index < recordings.tabUrls.length) {
      const tabUrl = recordings.tabUrls[index];
      const fetchDelay = 300;

      setTabTimeout((timeout) => {
        clearTimeout(timeout);
        return setTimeout(() => getTabs(index, tabUrl), fetchDelay);
      });
    }
  }, [recordings.tabsByIndex, recordings.tabUrls, index, getTabs]);

  useEffect(() => {
    getSelectableDays(userGuid);
    return clearRecordings;
  }, [userGuid, getSelectableDays, clearRecordings]);

  useEffect(() => {
    getRecordings(userGuid, dayPickerValue);
  }, [userGuid, getRecordings, dayPickerValue]);

  const onCalendarClickHandler = (day) => {
    setDayPickerValue(day || new Date());
  };

  const buildContent = () => {
    const isLoading = recordings.loading;
    const hasImageUrls = recordings.imageUrls.length > 0;

    if (isLoading) {
      return <DataLoader />;
    }

    if (!hasImageUrls) {
      return buildEmptyView();
    }

    return buildSlideshow();
  };

  const buildEmptyView = () => {
    return (
      <div
        className='co-flyout-emptyMessageContainer'
        data-testid='recordings-empty'
      >
        <span className='co-flyout-emptyMessage'>
          {I18n.t('no_recordings_on_this_date')}
        </span>
      </div>
    );
  };

  const buildSlideshow = () => {
    return (
      <div>
        <section className='co-recordings-videoContainer'>
          <SlideShow
            className='co-recordings-video'
            images={recordings.imageUrls}
            pageWillUpdate={(idx) => setIndex(idx)}
          />

          <a
            href={recordings.imageUrls[index]}
            className='co-recordings-videoDownload'
            download
          >
            {I18n.t('download_screen')}
          </a>
        </section>

        <section
          data-testid='recordings-tabs'
          className='co-flyout-openTabs m-top--48'
        >
          <ul>
            {buildTabs()}
          </ul>
        </section>
      </div>
    );
  };

  const buildTabs = () => {
    const tabs = recordings.tabsByIndex[index];
    if (!tabs) {
      return null;
    }

    return tabs.map((tab) => (
      <Tab
        key={tab.id + tab.url}
        tab={tab}
        showSubtleActive
      />
    ));
  };

  return (
    <section className='co-flyout-content' data-testid='recordings-section'>
      <div className='co-recordings' >
        <section className='co-flyout-contentHeading'>
          <h3 className='m-vertical--8'>
            {I18n.t('screenshot_viewer')}
          </h3>
          <span data-testid='recordings-dayPicker'>
            <DayPickerDropdown
              dayPickerValue={dayPickerValue}
              onDayClickHandler={onCalendarClickHandler}
              onCalendarClickHandler={onCalendarClickHandler}
              modifiers={{ selectableDays: recordings.selectableDays }}
              onTodayClickHandler={onCalendarClickHandler}
            />
          </span>
        </section>

        {buildContent()}
      </div>
    </section>
  );
};

Recordings.propTypes = {
  userGuid: PropTypes.string.isRequired,
  recordings: PropTypes.object.isRequired,
  getSelectableDays: PropTypes.func.isRequired,
  getRecordings: PropTypes.func.isRequired,
  getTabs: PropTypes.func.isRequired,
  clearRecordings: PropTypes.func.isRequired,
};

const mapState = (state) => ({
  recordings: state.recordings,
});

const mapDispatch = {
  getSelectableDays,
  getRecordings,
  getTabs,
  clearRecordings,
};

export default connect(mapState, mapDispatch)(Recordings);
