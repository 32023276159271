import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classNames from 'classnames';

import { openFlyout } from '../../../redux/ui/actions';

import I18n from '../../../utilities/i18n';
import { getCurrentGroup } from '../../../utilities/groups';
import {
  getUserInfosWithStatuses,
  getUserInfoCompare,
} from '../../../utilities/users';

import UsersTableRow from './UsersTableRow';
import Icon from '../../common/Icon';

const UsersTable = ({
  segment,
  browser,
  openFlyout,
  dragType,
  canDropMember,
  updating,
  browsingTimeout,
  currentGroup,
}) => {
  const COLUMN_SPAN = 7;
  const sortByName = segment.name + 'SortBy';
  const sortOrderName = segment.name + 'SortOrder';
  const savedSortby = localStorage.getItem(sortByName) ? localStorage.getItem(sortByName) : 'visits';
  const savedSortOrder = localStorage.getItem(sortOrderName) ? localStorage.getItem(sortOrderName) : 'desc';
  const [sortBy, setSortBy] = useState(savedSortby);
  const [sortOrder, setSortOrder] = useState(savedSortOrder);

  const sortColumn = (userInfos) => {
    const isAscending = sortOrder === 'asc';
    return userInfos.sort(getUserInfoCompare(sortBy, isAscending));
  };

  const updateSortBy = (type) => {
    const newSortOrder = Boolean(sortBy === type) && sortOrder === 'asc' ? 'desc' : 'asc';
    setSortBy(type);
    setSortOrder(newSortOrder);
    localStorage.setItem(segment.name + 'SortBy', type);
    localStorage.setItem(segment.name + 'SortOrder', newSortOrder);
  };

  const buildHeader = () => {
    const sortIconName = sortOrder === 'asc' ? 'icon-up-2' : 'icon-down-2';
    const SortIcon = () => <Icon name={sortIconName} classes='co-usersTable-sortIcon' />;

    return (
      <thead className='co-table-users-thead'>
        <tr>
          <th
            className='co-table-users-th'
            style={{ width: 12 }}
          />

          <th
            className='co-table-users-th'
            style={{ width: 30 }}
            data-testid='usersTable-taskStatus'
          />

          <th
            className='co-table-users-th co-table-users-th--hasPointer'
            onClick={() => updateSortBy('fullName')}
            data-testid='usersTable-name'>
            <span className='l-flex l-flex--vAlignCenter'>
              {I18n.t('name')}
              {sortBy === 'fullName' && <SortIcon />}
            </span>
          </th>
          <th
            className='co-table-users-th co-table-users-th--hasPointer'
            onClick={() => updateSortBy('checkInStatus')}
            data-testid='usersTable-attendance'
          >
            <span className='l-flex l-flex--vAlignCenter'>
              {I18n.t('attendance')}
              {sortBy === 'checkInStatus' && <SortIcon />}
            </span>
          </th>
          <th
            className='co-table-users-th co-table-users-th--hasPointer co-currentlyBrowsing'
            onClick={() => updateSortBy('currentHost')}
            data-testid='usersTable-currentlyBrowsing'>
            <span className='l-flex l-flex--vAlignCenter'>
              {I18n.t('currently_browsing')}
              {sortBy === 'currentHost' && <SortIcon />}
            </span>
          </th>

          <th
            className='co-table-users-th co-table-users-th--hasPointer'
            onClick={() => updateSortBy('top')}
            data-testid='usersTable-mostViewed'>
            <span className='l-flex l-flex--vAlignCenter'>
              {I18n.t('most_viewed')}
              {sortBy === 'top' && <SortIcon />}
            </span>
          </th>

          <th
            className='co-table-users-th co-table-users-th--hasPointer'
            style={{ textAlign: 'center', width: 140 }}
            onClick={() => updateSortBy('visits')}
            data-testid='usersTable-recentWebsites'>
            <span className='l-flex l-flex--vAlignCenter'>
              {I18n.t('recent_websites')}
              {sortBy === 'visits' && <SortIcon />}
            </span>
          </th>
        </tr>
      </thead>
    );
  };

  const buildDroppableRow = () => {
    const droppingIntoSegment = updating.segment === segment.guid;
    const sortedUserInfos = sortColumn(segment.infos);
    const noUsers = sortedUserInfos.length === 0;

    if (!canDropMember && !droppingIntoSegment) {
      return null;
    }

    const rowClasses = classNames({
      'co-table-tr--tbody--empty': true,
      'co-table-tr--droppable': !updating.member,
    });

    const emptyRow = (
      <tr className='co-table-tr--tbody'>
        <td className='text--alignCenter text--gray p-top--64' colSpan={COLUMN_SPAN} data-testid='usersTable-empty'>
        </td>
      </tr>
    );

    return (
      <>
        <tr className={rowClasses}>
          <td className='co-table-td' colSpan={COLUMN_SPAN}>
            {updating.member &&
            <div className='co-updateStatus-tr-icon' data-testid='updateStatus-icon'>
              <Icon name='icon-loading' classes='icon-loading--animating' />
            </div>
            }
          </td>
        </tr>
        {noUsers && emptyRow}
      </>
    );
  };

  const buildUserRows = () => {
    const isGreaterThanSmall = browser.greaterThan.s;
    const segmentInfosWithStatuses = getUserInfosWithStatuses(segment.infos, currentGroup);
    const sortedUserInfos = sortColumn(segmentInfosWithStatuses);
    const showNoStudentsMessage = sortedUserInfos.length === 0 && !canDropMember && !updating.member;

    if (showNoStudentsMessage) {
      return (
        <tr className='co-table-users-tr'>
          <td
            className='co-table-users-td--empty'
            colSpan={COLUMN_SPAN}
            data-testid='usersTable-empty'
          >
            {I18n.t('no_students')}
          </td>
        </tr>
      );
    }

    return sortedUserInfos.map((userInfo) => {
      return (
        <UsersTableRow
          key={userInfo.guid}
          userInfo={userInfo}
          openFlyout={openFlyout}
          isGreaterThanSmall={isGreaterThanSmall}
          dragType={dragType}
          isMoving={userInfo.guid === updating.member}
          browsingTimeout={browsingTimeout}
          currentGroup={currentGroup}
        />
      );
    });
  };

  return (
    <table className='co-table-users'>
      {buildHeader()}
      <tbody>
        <>
          {buildUserRows()}
          {buildDroppableRow()}
        </>
      </tbody>
    </table>
  );
};

UsersTable.propTypes = {
  segment: PropTypes.object.isRequired,
  browser: PropTypes.object,
  openFlyout: PropTypes.func.isRequired,
  dragType: PropTypes.string.isRequired,
  canDropMember: PropTypes.bool.isRequired,
  updating: PropTypes.object.isRequired,
  browsingTimeout: PropTypes.bool.isRequired,
  currentGroup: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  browser: state.browser,
  browsingTimeout: state.ui.browsingTimeout,
  classroomGroupBetas: state.betas,
  currentGroup: getCurrentGroup(state.groups),
  districtBetas: state.district.betas,
  userBetas: state.currentUser.betas,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  openFlyout,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(UsersTable);
