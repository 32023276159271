import axios from 'axios';

import { addError } from '../errors/actions';

import { ClassroomApi } from '../../helpers/classroomApi';

import moment from 'moment';

export const RECEIVE_SELECTABLE_DAYS = 'RECEIVE_SELECTABLE_DAYS';
export const RECEIVE_RECORDINGS = 'RECEIVE_RECORDINGS';
export const RECEIVE_TABS = 'RECEIVE_TABS';
export const CLEAR_RECORDINGS = 'CLEAR_RECORDINGS';

export function getSelectableDays(guid, numberOfDays = 90) {
  return async function(dispatch) {
    if (!guid) {
      return {};
    }

    const end = moment().utc().valueOf();
    const start = moment().subtract(numberOfDays, 'days').startOf('day').utc().valueOf();
    const url = `/users/${guid}/recordings?start_date=${start}&end_date=${end}&getDays=true`;

    const response = await ClassroomApi.get(url);

    if (response?.status !== 200) {
      return dispatch(addError('getSelectableDays', response));
    }

    const days = response.data?.days;

    if (Array.isArray(days)) {
      const convertToUTC = (date) => new Date(date.toLocaleDateString('en-US', { timeZone: 'UTC' }));
      const selectableDays = days.map((day) => convertToUTC(new Date(day)));
      dispatch({ type: RECEIVE_SELECTABLE_DAYS, selectableDays });
    }
  };
}

export function getRecordings(guid, dayValue) {
  return async function(dispatch) {
    if (!guid || !dayValue) {
      return {};
    }

    const startOfDay = moment(dayValue).startOf('day').utc().valueOf();
    const endOfDay = moment(dayValue).endOf('day').utc().valueOf();
    const url = `/users/${guid}/recordings?start_date=${startOfDay}&end_date=${endOfDay}`;

    const response = await ClassroomApi.get(url);

    if (response?.status !== 200) {
      return dispatch(addError('getRecordings', response));
    }

    if (Array.isArray(response.data)) {
      const urls = response.data.reduce((urls, item) => {
        return {
          tab: [...urls.tab, item.tabsFile],
          image: [...urls.image, item.imageFile],
        };
      }, { tab: [], image: [] });

      dispatch({
        type: RECEIVE_RECORDINGS,
        tabUrls: urls.tab,
        imageUrls: urls.image,
      });
    }
  };
}

export function getTabs(index, url) {
  return async function(dispatch) {
    if (!url) {
      return {};
    }

    try {
      const response = await axios(url);

      const tabs = response?.data;

      dispatch({ type: RECEIVE_TABS, index, tabs });
    } catch (error) {
      window.Rollbar.error('recordings.getTabs', {
        errorMessage: error.message,
        errorResponse: error.response,
        index,
        url,
      });
    }
  };
}

export function clearRecordings() {
  return { type: CLEAR_RECORDINGS };
}
