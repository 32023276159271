import React from 'react';
import PropTypes from 'prop-types';

import LightspeedLogo from './LightspeedLogo';
import LightspeedLogoText from './LightspeedLogoText';
import GoogleLogoIcon from './GoogleLogoIcon';
import OfficeLogoIcon from './OfficeLogoIcon';
import ArrowRightIcon from './ArrowRightIcon';

import LightspeedClassroomText from './LightspeedClassroomText';
import I18n from '../../utilities/i18n';
import { Redirect } from 'react-router-dom';

function buildGoogleSSOLink() {
  return `${process.env.REACT_APP_RELAY_LOGIN_URL}users/auth/google_oauth2?source=classroom`;
}

function buildAzureSSOLink() {
  return `${process.env.REACT_APP_RELAY_LOGIN_URL}users/auth/azure_oauth2?source=classroom`;
}

function buildHelpLinks() {
  return (
    <div className='co-GenericLogin-help-options'>
      <a
        href='https://help.lightspeedsystems.com/s/datacatarticles?language=en_US&cat=RC%3FSubC%3DRelease_Notes'>
        {I18n.t('release_notes')}
      </a> | <a
        href='https://help.lightspeedsystems.com/s/datacatarticles?language=en_US&cat=RC%3FSubC%3DClassroom_for_Teachers'>
        {I18n.t('documentation')}
      </a> | <a
        href='https://help.lightspeedsystems.com/s/support-hours?language=en_US'>
        {I18n.t('support')}
      </a>
    </div>
  );
}

const GenericLogin = ({
  loginError,
  isInTeams,
}) => {

  const buildError = () => {
    if (!loginError) {
      return null;
    }

    if (isInTeams && loginError === I18n.t('insufficient_privileges_please_contact_admin')) {
      return <Redirect to='/teams-student?teamsId=true' />;
    }

    let error = I18n.t('an_unknown_error_occured_please_contact_admin');
    if (typeof loginError === 'string') {
      error = loginError;
    }

    return (
      <div className='co-GenericLogin-error'>{error}</div>
    );
  };

  const buildNewUserMessage = () => {
    if (!isInTeams) {
      return null;
    }

    return (
      <section className='signinNewUserMessage m-top--16'>
        {I18n.t('new_to_classroom')}
      </section>
    );
  };

  const buildSSOButtons = () => {
    return (
      <>
        <a href={buildGoogleSSOLink()} target={isInTeams ? '_blank' : null} rel={isInTeams ? 'noreferrer noopener' : null}>
          <div className='co-GenericLogin-login-option'>
            <GoogleLogoIcon className='co-GenericLogin-icon' />
            <p className='p-left--20'>
              Google
            </p>
            <ArrowRightIcon classes='co-GenericLogin-arrow' />
          </div>
        </a>
        <a href={buildAzureSSOLink()} target={isInTeams ? '_blank' : null} rel={isInTeams ? 'noreferrer noopener' : null}>
          <div className='co-GenericLogin-login-option'>
            <OfficeLogoIcon className='co-GenericLogin-icon' />
            <p className='p-left--20'>
              Office 365
            </p>
            <ArrowRightIcon classes='co-GenericLogin-arrow' />
          </div>
        </a>
      </>
    );
  };

  return (
    <div className='co-GenericLogin-login-container'>
      <LightspeedLogoText className='co-GenericLogin-lightspeed-logo-text' />
      <LightspeedClassroomText className='co-GenericLogin-lsText' />
      <div className='co-GenericLogin-welcome-message'>
        {I18n.t('welcome_back_please_sign_in')}
      </div>
      <a href={`${process.env.REACT_APP_RELAY_LOGIN_URL}sign_in?source=classroom`} data-testid='relay-signin-link'>
        <div className='co-GenericLogin-login-option' data-testid='signIn-email'>
          <LightspeedLogo className='co-GenericLogin-icon' />
          <p className='p-left--20'>
            {I18n.t('sign_in_with_your_email')}
          </p>
          <ArrowRightIcon classes='co-GenericLogin-arrow' />
        </div>
      </a>
      {buildSSOButtons()}
      {buildError()}
      {buildNewUserMessage()}
      {buildHelpLinks()}
    </div>
  );
};

GenericLogin.propTypes = {
  loginError: PropTypes.string,
  isInTeams: PropTypes.bool,
};

export default GenericLogin;
