import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { openFlyout } from '../../../redux/ui/actions';

import { getCurrentGroup } from '../../../utilities/groups';
import { findHighestVisits, byKey } from '../../../utilities/users';

import UsersHeatMapItem from './UsersHeatMapItem';
import UpdateStatus from '../../common/UpdateStatus';


const UsersHeatMap = ({
  segment,
  viewingAllScreens,
  usersBeingViewed,
  toggleViewUserScreen,
  streams,
  insights,
  openFlyout,
  dragType,
  canDropMember,
  updating,
}) => {
  const buildUserItems = () => {
    const byFirstName = byKey('firstName');
    const sortedUserInfos = segment.infos.sort(byFirstName);
    const highestVisits = findHighestVisits(segment.infos);

    return sortedUserInfos.map((userInfo) => {
      const stream = streams[userInfo.email];
      const userBeingViewed = usersBeingViewed.indexOf(userInfo.email) > -1;
      const hasInsight = Boolean(insights.active.find((active) => active.email === userInfo.email));

      return (
        <UsersHeatMapItem
          key={userInfo.guid}
          userInfo={userInfo}
          hasInsight={hasInsight}
          highestVisits={highestVisits}
          viewingAllScreens={viewingAllScreens}
          userBeingViewed={userBeingViewed}
          stream={stream}
          openFlyout={openFlyout}
          toggleViewUserScreen={toggleViewUserScreen}
          dragType={dragType}
          isMoving={userInfo.guid === updating.member}
        />
      );
    });
  };

  const buildDroppable = () => {
    const droppingIntoSegment = updating.segment === segment.guid;
    if (!canDropMember && !droppingIntoSegment) {
      return null;
    }

    return (
      <li>
        <UpdateStatus isActive={droppingIntoSegment}>
          <div
            className='co-usersHeatMap-item co-usersHeatMap-item--droppable'
            data-testid='usersHeatMap-item-droppable'
          >
          </div>
        </UpdateStatus>
      </li>
    );
  };

  return (
    <ul className='co-heatMap'>
      {buildUserItems()}
      {buildDroppable()}
    </ul>
  );
};

UsersHeatMap.propTypes = {
  segment: PropTypes.object.isRequired,
  viewingAllScreens: PropTypes.bool.isRequired,
  usersBeingViewed: PropTypes.arrayOf(PropTypes.string).isRequired,
  toggleViewUserScreen: PropTypes.func.isRequired,
  streams: PropTypes.object.isRequired,
  insights: PropTypes.object.isRequired,
  openFlyout: PropTypes.func.isRequired,
  dragType: PropTypes.string.isRequired,
  canDropMember: PropTypes.bool.isRequired,
  updating: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  currentGroup: getCurrentGroup(state.groups),
  streams: state.webrtcs.streams,
  insights: state.insights,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  openFlyout,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(UsersHeatMap);
