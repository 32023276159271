import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import I18n from '../../utilities/i18n';

import Icon from './Icon';


const Loader = ({ text, isFullscreen, useRelayLogo }) => {
  const loaderClasses = classNames('co-loader', {
    'co-loader--mainContent': !isFullscreen,
    'co-loader--fullscreen': isFullscreen,
  });
  const logoName = useRelayLogo ? 'icon-relay-logo-32' : 'icon-classroom-logo-32';

  return (
    <div className={loaderClasses} data-testid='loader'>
      <div className='co-loader-text'>{text}</div>
      <div className='co-loader-logo' data-testid='loader-icon'>
        <Icon name={logoName} />
      </div>
      <div className='co-loader-text'>{I18n.t('loading')}...</div>
    </div>
  );
};

Loader.propTypes = {
  text: PropTypes.string,
  isFullscreen: PropTypes.bool,
  useRelayLogo: PropTypes.bool,
};

export default Loader;
