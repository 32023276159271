import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { closeFlyout, closeSubFlyout, openModal, closeModal } from '../../../redux/ui/actions';
import { getSegmentGuid } from '../../../utilities/groups';
import I18n from '../../../utilities/i18n';

import Flyout from './Flyout';
import SlideInOut from '../animations/SlideInOut';
import Fade from '../animations/Fade';
import WebRulesFlyout from './WebRulesFlyout';
import CreateList from './WebRuleLists/CreateList';
import EditList from './WebRuleLists/EditList';
import Modal from '../Modal';
import BulkCheckInFlyout from './BulkCheckInFlyout';

const FlyoutContainer = ({
  closeFlyout,
  closeSubFlyout,
  currentlyOpenFlyout,
  currentlyOpenSubFlyout,
  openModal,
  closeModal,
  currentlyOpenModal,
}) => {
  const flyoutRoot = document.getElementById('flyoutRoot');
  const isBulkCheckInOpen = Boolean(currentlyOpenFlyout) && currentlyOpenFlyout.startsWith('bulkCheckInFlyout');
  const isWebRulesOpen = Boolean(currentlyOpenFlyout) && currentlyOpenFlyout.startsWith('webRules');
  const isUserOpen = Boolean(currentlyOpenFlyout);
  const [deletedListName, setDeletedListName] = useState(null);
  const [editedList, setEditedList] = useState(null);
  const [canCloseFlyout, setCanCloseFlyout] = useState(true);
  const [subFlyoutActive, setSubFlyoutActive] = useState(false);

  const getDeletedList = (deletedName) => {
    setDeletedListName(deletedName);
  };

  const getEditedList = (newWebRules) => {
    setEditedList(newWebRules);
  };

  // to check for user input in list form / web rules flyout before closing the flyout
  const getCanClose = (modalNeededBeforeClose) => {
    if (modalNeededBeforeClose) {
      setCanCloseFlyout(false);
    } else {
      setCanCloseFlyout(true);
    }
  };

  const verifyLeavePage = () => {
    const modalName = 'leavePageOnClickModal';
    openModal(modalName);
  };

  const buildLeavePageOnClickModal = () => {
    const stayButton = (
      <button
        data-testid='flyoutContainer-closeFlyout-modalButton'
        className='button button--blue'
        onClick={() => {
          closeModal();
          handleCloseFlyout();
        }}
      >
        {I18n.t('leave')}
      </button>
    );

    return (
      <Modal
        data-testid='flyoutContainer-closeFlyoutModal'
        show={currentlyOpenModal === 'leavePageOnClickModal'}
        title={I18n.t('leave_page')}
        customSaveButton={stayButton}
        closeModal={closeModal}
        modalRedesign2022Q1
      >
        <div>
          {I18n.t('are_you_sure_you_want_to_leave_your_changes_wont_be_saved')}
        </div>
      </Modal>
    );
  };

  const handleCloseFlyout = () => {
    if (currentlyOpenSubFlyout) {
      setSubFlyoutActive(false);
      closeSubFlyout();
      // to allow subFlyout to close first before flyout unmounts
      setTimeout(() => {
        closeFlyout();
      }, 500);
    } else {
      closeFlyout();
    }
  };

  // using set timeout to assist with web rules flyout / subFlyout remaining loaded while SlideInOut renders
  // previously the components were unmounting prior to SlideInOut completing
  useEffect(() => {
    if (currentlyOpenSubFlyout) {
      setTimeout(() => {
        setSubFlyoutActive(true);
      }, 500);
    } else {
      setSubFlyoutActive(false);
    }
  }, [currentlyOpenSubFlyout]);

  if (isWebRulesOpen) {
    return ReactDOM.createPortal(
      <Fragment>
        <SlideInOut condition={isWebRulesOpen}>
          <div className='co-flyoutContainer'>
            {!subFlyoutActive &&
              <WebRulesFlyout
                deletedListName={deletedListName}
                editedList={editedList}
                getCanClose={getCanClose}
                segmentGuid={getSegmentGuid(currentlyOpenFlyout)}
              />}
          </div>
        </SlideInOut>
        <SlideInOut condition={Boolean(currentlyOpenSubFlyout)}>
          <div className='co-flyoutContainer co-flyoutContainer-scrollWrapper'>
            {currentlyOpenSubFlyout === 'create_list' ? (
              <CreateList getCanClose={getCanClose} />
            ) : (
              <EditList
                getDeletedList={getDeletedList}
                getEditedList={getEditedList}
                getCanClose={getCanClose}
              />
            )}
          </div>
        </SlideInOut>
        <Fade
          condition={isWebRulesOpen}
          onClick={() => {
            if (!canCloseFlyout) {
              verifyLeavePage();
            } else {
              handleCloseFlyout();
            }
          }}>
          <div className ='co-flyoutContainer-backdrop'/>
        </Fade>
        {buildLeavePageOnClickModal()}
      </Fragment>,
      flyoutRoot,
    );
  }

  if (isBulkCheckInOpen) {
    return ReactDOM.createPortal(
      <Fragment>
        <SlideInOut condition={isBulkCheckInOpen}>
          <div className='co-flyoutContainer'>
            <BulkCheckInFlyout
              getCanClose={getCanClose}
              userGuid={currentlyOpenFlyout}
            />
          </div>
        </SlideInOut>
        <Fade
          condition={isBulkCheckInOpen}
          onClick={() => {
            if (!canCloseFlyout) {
              verifyLeavePage();
            } else {
              handleCloseFlyout();
            }
          }}
        >
          <div className ='co-flyoutContainer-backdrop'/>
        </Fade>
        {buildLeavePageOnClickModal()}
      </Fragment>,
      flyoutRoot,
    );
  }

  return ReactDOM.createPortal(
    <Fragment>
      <SlideInOut condition={isUserOpen}>
        <div className='co-flyoutContainer'>
          <Flyout userGuid={currentlyOpenFlyout} />
        </div>
      </SlideInOut>
      <Fade condition={isUserOpen} onClick={handleCloseFlyout}>
        <div className ='co-flyoutContainer-backdrop'/>
      </Fade>
    </Fragment>,
    flyoutRoot,
  );
};

FlyoutContainer.propTypes = {
  closeFlyout: PropTypes.func.isRequired,
  closeSubFlyout: PropTypes.func,
  currentlyOpenFlyout: PropTypes.string,
  currentlyOpenSubFlyout: PropTypes.string,
};

const mapState = (state) => ({
  currentlyOpenFlyout: state.ui.currentlyOpenFlyout,
  currentlyOpenSubFlyout: state.ui.currentlyOpenSubFlyout,
  currentlyOpenModal: state.ui.currentlyOpenModal,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  closeFlyout,
  closeSubFlyout,
  openModal,
  closeModal,
}, dispatch);

export default connect(mapState, mapDispatchToProps)(FlyoutContainer);
